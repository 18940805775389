var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li>\n                <a data-history-navigate href=\"javascript:void(0);\">\n                    <article class=\"nav-card\">\n                        <div class=\"content\">\n                            <section class=\"media\">\n                                <span class=\"fa pe-7s-user\" aria-hiddden=\"true\"></span>\n                            </section>\n\n                            <section class=\"info\">\n                                <section class=\"primary-info\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parent") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":19,"column":36},"end":{"line":23,"column":43}}})) != null ? stack1 : "")
    + "                                </section>\n                                <!-- Additinoal info section can come here if need be -->\n                            </section>\n                        </div>\n                        <!-- An action bar can come here if need be -->\n                    </article>\n                </a>\n            </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <h1>"
    + alias3(((helper = (helper = lookupProperty(helpers,"fullName") || (depth0 != null ? lookupProperty(depth0,"fullName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"fullName","hash":{},"data":data,"loc":{"start":{"line":20,"column":40},"end":{"line":20,"column":52}}}) : helper)))
    + " "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:primaryUser",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":53},"end":{"line":20,"column":82}}}))
    + "</h1>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <h1>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"fullName") || (depth0 != null ? lookupProperty(depth0,"fullName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"fullName","hash":{},"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":22,"column":52}}}) : helper)))
    + "</h1>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n    <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n        <header class=\"page-header\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:whoToLogin",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":44}}}))
    + "</h1>\n        </header>\n\n        <ul class=\"nav-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"lovedOnes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":32,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n\n        <div class=\"text-center margin-top-small\">\n            <a data-history-navigate href=\"/volunteers/add-loved-one\"><span class=\"fa fa-user-plus fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:addLovedOne",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":142},"end":{"line":36,"column":180}}}))
    + "</a>\n        </div>\n\n    </section>\n</div>\n";
},"useData":true});