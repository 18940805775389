(function() {
	'use strict';
	var util = require('../../../../shared-scripts/app-util/util.js');


	var ScreeningQuestionsModule = function () {
		this.checkVolunteerEligibility = function(profileEligibilityMap, redirectUponSuccess, successCallback) {
			var studyNum = profileEligibilityMap['study-num'];
			$.get(
				{
					url: '/backend/secure/volunteer/check-profile-eligibility',
					data: profileEligibilityMap,
					contentType: 'application/json',
					success: function (data) {
						switch (data.value) {
						case 'failure-study-inactive':
							util.redirect('/studies/' + studyNum);
							break;
						case 'failure-already-showed-interest':
							util.redirect('/studies/' + studyNum);
							break;
						case 'failure-profile-not-matching':
							util.redirect('/volunteers/interest-failure/' + studyNum);
							break;
						case 'success':
							if (redirectUponSuccess) {
								util.redirect('/volunteers/interest-success/' + studyNum);
							} else {
								successCallback();
							}
							break;
						default:
							throw new Error('Unknown volunteer eligibility result');
						}
					}
				}
			);
		};
	};

	module.exports = new ScreeningQuestionsModule();
})();