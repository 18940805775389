var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "new";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"hide-for-small-only\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"time") || (depth0 != null ? lookupProperty(depth0,"time") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"time","hash":{},"data":data,"loc":{"start":{"line":17,"column":54},"end":{"line":17,"column":62}}}) : helper)))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div><span class=\"badge\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"unreadMessageCount") || (depth0 != null ? lookupProperty(depth0,"unreadMessageCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"unreadMessageCount","hash":{},"data":data,"loc":{"start":{"line":21,"column":45},"end":{"line":21,"column":67}}}) : helper)))
    + "</span></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a data-history-navigate href=\"/volunteers/messages/for/"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyVolunteerId") || (depth0 != null ? lookupProperty(depth0,"studyVolunteerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyVolunteerId","hash":{},"data":data,"loc":{"start":{"line":1,"column":56},"end":{"line":1,"column":76}}}) : helper)))
    + "\" class=\"message-snippet "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"unreadMessageCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":101},"end":{"line":1,"column":137}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"flex-container\">\n        <section class=\"media\" aria-hidden=\"true\">\n            <span class=\"fa pe-7s-user\"></span>\n        </section>\n        <section class=\"content\">\n            <header>\n                <h1>"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyTitle") || (depth0 != null ? lookupProperty(depth0,"studyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyTitle","hash":{},"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":8,"column":34}}}) : helper)))
    + "</h1>\n                <div class=\"summary\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"studyTeamMemberFullNamesString") || (depth0 != null ? lookupProperty(depth0,"studyTeamMemberFullNamesString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyTeamMemberFullNamesString","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":54}}}) : helper)))
    + "\n                </div>\n            </header>\n            <div class=\"aux-info\">\n                <time>\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"lastReceivedDateString") || (depth0 != null ? lookupProperty(depth0,"lastReceivedDateString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastReceivedDateString","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":46}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"time") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":18,"column":27}}})) != null ? stack1 : "")
    + "                </time>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"unreadMessageCount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </section>\n    </div>\n</a>\n";
},"useData":true});