var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.section.lovedOneProfileVisibility.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":90}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.section.selfProfileVisibility.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":98},"end":{"line":3,"column":158}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.profileVisibility.visible.lovedOneDesc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":101}}}));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.profileVisibility.visible.selfDesc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":109},"end":{"line":13,"column":172}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"special-fieldset\">\n    <legend>\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":165}}})) != null ? stack1 : "")
    + "\n    </legend>\n    <div class=\"grid-row\">\n        <div class=\"grid-sm-1 grid-md-1-2 field-container\">\n            <label for=\"radioProfileVisibilityAll\" class=\"other-label\">\n                <input type=\"radio\" id=\"radioProfileVisibilityAll\" name=\"visibleToStudyTeams\"\n                       value=\"true\" checked=\"checked\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.profileVisibility.visible.label",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":126}}}))
    + "\n            </label>\n            <div class=\"field-help-text\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":179}}})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n        <div class=\"grid-sm-1 grid-md-1-2 field-container\">\n\n            <label for=\"radioProfileVisibilitySpecificStudies\" class=\"other-label\">\n                <input type=\"radio\" id=\"radioProfileVisibilitySpecificStudies\"\n                       name=\"visibleToStudyTeams\" value=\"false\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.profileVisibility.notVisible.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":79}}}))
    + "\n            </label>\n        </div>\n    </div>\n</fieldset>";
},"useData":true});