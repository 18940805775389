var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"grid-row canned-queries-list\">\n    <div class=\"grid-1\">\n        <h1 class=\"special-header-with-top-border center-container\"><em>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":72},"end":{"line":3,"column":110}}}))
    + "</em></h1>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-1\">\n        <a data-history-navigate href=\"/study-results?topics=285138\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.covidStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":66}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-2\">\n        <a data-history-navigate href=\"/study-results?gender=272001&onlyHealthy=395000\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForMen",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":23},"end":{"line":12,"column":67}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-3\">\n        <a data-history-navigate href=\"/study-results?gender=272000&onlyHealthy=395000\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForWomen",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":23},"end":{"line":17,"column":69}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-4\">\n        <a data-history-navigate href=\"/study-results?locations=420002\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesFromHome",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":23},"end":{"line":22,"column":69}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-5\">\n        <a data-history-navigate href=\"/study-results?topics=285006,285028,285035,285042,285052,285055,285063,285082,285125,285129,285152,285157,285179,285188,285257,285358,285360,285379,285380,285383,285413,285414,285446,285405,285478,285479,285496,285498,285511,285512,285531,285517\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.studiesForMentalHealth",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":23},"end":{"line":27,"column":76}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-6\">\n        <a data-history-navigate href=\"/study-results?topics=285009,285012,285026,285054,285062,285064,285066,285067,285071,285072,285085,285093,285108,285114,285127,285128,285181,285182,285200,285204,285214,285246,285252,285277,285308,285321,285324,285334,285335,285363,285366,285370,285377,285385,285394,285403,285410,285435,285444,285460,285475,285477,285494,285495,285503,285506,285508,285524,285529,285552\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.cancerStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":23},"end":{"line":32,"column":67}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-7\">\n        <a data-history-navigate href=\"/study-results?topics=285538,285539,285423,285436,285438,285161,285162,285163,285164,285165\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.diabeticStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":37,"column":23},"end":{"line":37,"column":69}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-8\">\n        <a data-history-navigate href=\"/study-results?topics=285261,285286,285314,285316,285341,285343,285344,285347,285554,285558,285398,285401,285406,285407,285462,285473,285525,285000,285001,285002,285016,285017,285018,285037,285038,285043,285044,285045,285046,285053,285080,285084,285091,285097,285098,285099,285100,285133,285148,285185,285249,285256\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.heartStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":23},"end":{"line":42,"column":66}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-9\">\n        <a data-history-navigate href=\"/study-results?topics=285395\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.obesityStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":23},"end":{"line":47,"column":68}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-10\">\n        <a data-history-navigate href=\"/study-results?topics=285260,285336,285341,285351,285352,285365,285533,285549,285553,285555,285558,285564,285375,285386,285389,285390,285416,285441,285444,285461,285474,285485,285486,285488,285515,285525,285002,285010,285011,285016,285027,285034,285035,285040,285044,285056,285059,285060,285103,285131,285152,285153,285154,285184,285187,285238,285247,285256,410790\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.agingStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":52,"column":23},"end":{"line":52,"column":66}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-11\">\n        <a data-history-navigate href=\"/study-results?topics=285029,285030,285031,285032,285033,285048,285051,285104,285121,285122,285135,285158,285190,285203,285428,285445\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.asthmaStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":57,"column":23},"end":{"line":57,"column":67}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 grid-md-1-2 grid-lg-1-3 canned-query query-image-12\">\n        <a data-history-navigate href=\"/study-results?topics=285006,285008,285020,285028,285035,285036,285042,285050,285052,285056,285057,285061,285091,285101,285103,285125,285129,285130,285131,285136,285137,285157,285159,285166,285173,285179,285225,285238,285259,285260,285288,285289,285332,285345,285351,285357,285358,285360,285365,285379,285384,285413,285414,285415,285416,285446,285461,285465,285478,285479,285486,285500,285501,285502,285512,285515,285517,285564,285565,285570\">\n            <div><span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.disabilitiesStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":62,"column":23},"end":{"line":62,"column":73}}}))
    + "</span></div>\n        </a>\n    </div>\n    <div class=\"grid-1 text-center delta\">\n        <a data-history-navigate href=\"/study-results\">\n            <div class=\"white-background border\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:cannedQuery.allStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":49},"end":{"line":67,"column":90}}}))
    + "</div>\n        </a>\n    </div>\n</section>\n\n\n\n\n\n\n\n\n";
},"useData":true});