(function () {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		urlRoot: '/backend/application-settings',
		initialize: function (name) {
			if (name) {
				this.urlRoot += '?name=' + name;
			}
		}
	});
	Model.urlParams = {
		name: {
			passwordRegex: 'PASSWORD_REGEX',
			passwordRegexDescription: 'PASSWORD_REGEX_DESCRIPTION',
			yearsToBeConsideredMature: 'YEARS_TO_BE_CONSIDERED_MATURE',
			applicationUrl: 'APPLICATION_URL'
		}
	};
	module.exports = Model;
})();
