var appConfig = require('~institution-specific-config/app.config.json');
var i18next = require('i18next');
var languageSpecificFiles = require('~institution-specific-config/locales');
var LanguageDetector = require('i18next-browser-languagedetector')['default'];

function readTranslationResources() {
	return {
		'en': {
			accountSettings: require('../../../locales/build/en/accountSettings.json'),
			advancedSearch: require('../../../locales/build/en/advancedSearch.json'),
			appError:require('../../../locales/build/en/appError.json'),
			changeEmailPassword:require('../../../locales/build/en/changeEmailPassword.json'),
			common: require('../../../locales/build/en/common.json'),
			cookieConsent: require('../../../locales/build/en/cookieConsent.json'),
			feedbackMessages: require('../../../locales/build/en/feedbackMessages.json'),
			forgotPassword: require('../../../locales/build/en/forgotPassword.json'),
			history: require('../../../locales/build/en/history.json'),
			landing: require('../../../locales/build/en/landing.json'),
			login: require('../../../locales/build/en/login.json'),
			messageList: require('../../../locales/build/en/messageList.json'),
			notAuthorized: require('../../../locales/build/en/notAuthorized.json'),
			profile: require('../../../locales/build/en/profile.json'),
			pagination: require('../../../locales/build/en/pagination.json'),
			resetPassword: require('../../../locales/build/en/resetPassword.json'),
			screeningQuestions: require('../../../locales/build/en/screeningQuestions.json'),
			showInterest: require('../../../locales/build/en/showInterest.json'),
			studyDetails: require('../../../locales/build/en/studyDetails.json'),
			studyResults: require('../../../locales/build/en/studyResults.json'),
			studyCards: require('../../../locales/build/en/studyCards.json'),
			studiesForYou: require('../../../locales/build/en/studiesForYou.json'),
			howCanYHRHelpYou: require('../../../locales/build/en/howCanYHRHelpYou.json'),
			signUp: require('../../../locales/build/en/signUp.json'),
			errorMessages: require('../../../locales/build/en/errorMessages.json'),
			userAgreement: require('../../../locales/build/en/userAgreement.json'),
			volunteerHeader: require('../../../locales/build/en/volunteerHeader.json')

		},
		'es': {
			accountSettings: require('../../../locales/build/es/accountSettings.json'),
			advancedSearch: require('../../../locales/build/es/advancedSearch.json'),
			appError:require('../../../locales/build/es/appError.json'),
			changeEmailPassword:require('../../../locales/build/es/changeEmailPassword.json'),
			common: require('../../../locales/build/es/common.json'),
			cookieConsent: require('../../../locales/build/es/cookieConsent.json'),
			feedbackMessages: require('../../../locales/build/es/feedbackMessages.json'),
			forgotPassword: require('../../../locales/build/es/forgotPassword.json'),
			history: require('../../../locales/build/es/history.json'),
			landing: require('../../../locales/build/es/landing.json'),
			login: require('../../../locales/build/es/login.json'),
			messageList: require('../../../locales/build/es/messageList.json'),
			notAuthorized: require('../../../locales/build/es/notAuthorized.json'),
			profile: require('../../../locales/build/es/profile.json'),
			pagination: require('../../../locales/build/es/pagination.json'),
			resetPassword: require('../../../locales/build/es/resetPassword.json'),
			screeningQuestions: require('../../../locales/build/es/screeningQuestions.json'),
			showInterest: require('../../../locales/build/es/showInterest.json'),
			studyDetails: require('../../../locales/build/es/studyDetails.json'),
			studyResults: require('../../../locales/build/es/studyResults.json'),
			studyCards: require('../../../locales/build/es/studyCards.json'),
			studiesForYou: require('../../../locales/build/es/studiesForYou.json'),
			howCanYHRHelpYou: require('../../../locales/build/es/howCanYHRHelpYou.json'),
			signUp: require('../../../locales/build/es/signUp.json'),
			errorMessages: require('../../../locales/build/es/errorMessages.json'),
			userAgreement: require('../../../locales/build/es/userAgreement.json'),
			volunteerHeader: require('../../../locales/build/es/volunteerHeader.json')
		}
	};
}
var jsonFileResources = readTranslationResources();

var commonConfig = {
	debug: false,
	fallbackLng: 'en',
	defaultNS: 'common',
	supportedLngs: appConfig.i18next.supportedLanguages,
	nonExplicitSupportedLngs: true, // Need this to support variants (en-US will be valid if en is in supportedLngs)
	detection: {
		lookupCookie: 'lang',
		order: ['cookie', 'navigator'],
		caches: ['cookie'],
		cookieOptions: {path: '/', sameSite: 'strict'},
		cookieMinutes: 60*24*30*12,
	}
};

var jsonFilesConfig = {
	resources: jsonFileResources,
	compatibilityJSON: 'v4'
};

var backendConfig = {
	ns: Object.keys(jsonFileResources.en),
	load: 'languageOnly',
	backend: {
		loadPath: 'https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key='+appConfig.i18next.apiKey,
	},
	initImmediate: true,
};

var plugins = [LanguageDetector];
var config;
if(appConfig.i18next.apiKey){
	config = Object.assign(commonConfig, backendConfig);
	plugins.push(require('i18next-http-backend/cjs'));
}else{
	config = Object.assign(commonConfig, jsonFilesConfig);
}

for(var idx=0; idx<plugins.length; idx++) {
	i18next.use(plugins[idx]);
}
i18next.initPromise = i18next.init(config);

/**
 * @param {string} keyStartsWith The string with which i18next library key should start including namespace using nsSeparator that i18next was setup with.
 * @returns {number} The count of keys in i18next resourceStore for the selected language starting with the input argument
 */
i18next.countKeys = function (keyStartsWith) {
	return Object.keys(i18next.t(keyStartsWith, { returnObjects: true })).length;
};

i18next.getLanguageInstitutionSpecificFile = function(type){
	return languageSpecificFiles[type][i18next.resolvedLanguage];
};

i18next.getSupportedLanguages = function(){
	var supportedLanguages = [];
	for(var idx=0;idx<i18next.options.supportedLngs.length;idx++){
		var item = i18next.options.supportedLngs[idx];
		if (item !== 'cimode'){
			supportedLanguages.push({key:item, name: new  Intl.DisplayNames(item,{type: 'language'}).of(item)});
		}
	}
	return supportedLanguages;
};

i18next.getDisplayNameByLanguageCode = function(languageCode){
	return new  Intl.DisplayNames(languageCode,{type: 'language'}).of(languageCode);
};

module.exports = i18next;
