var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studyDetails:criteriaInfo",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"eligibilityCriteriaGroupNumber") : depth0),"eligibilityCriteriaGroupNumber":(depth0 != null ? lookupProperty(depth0,"eligibilityCriteriaGroupNumber") : depth0)},"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":159}}}))
    + ":\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"eligibilityCriteria") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":60,"column":29}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <section class=\"border neutral-background margin-top-small\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"moreThanOneGroup") : depths[1]),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":32},"end":{"line":34,"column":39}}})) != null ? stack1 : "")
    + "                                <section>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInclusion") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":36},"end":{"line":46,"column":43}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasExclusion") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":36},"end":{"line":57,"column":43}}})) != null ? stack1 : "")
    + "                                </section>\n                            </section>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <header>\n                                        <h3>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":32,"column":44},"end":{"line":32,"column":52}}}) : helper)))
    + "</h3>\n                                    </header>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div>\n                                            <strong class=\"sub-head\"><span class=\"fa fa-check fa-margin-right success-font-color\"\n                                                                           aria-hidden=\"true\"></span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studyDetails:inclusionCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":39,"column":101},"end":{"line":39,"column":142}}}))
    + ":</strong>\n                                            <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"inclusionTranslatedExpressions") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":48},"end":{"line":43,"column":57}}})) != null ? stack1 : "")
    + "                                            </ul>\n                                        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                                                    <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <div>\n                                            <strong class=\"sub-head\"><span class=\"fa fa-times fa-margin-right error-font-color\"\n                                                                           aria-hidden=\"true\"></span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studyDetails:exclusionCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":50,"column":101},"end":{"line":50,"column":142}}}))
    + "</strong>\n                                            <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"exclusionTranslatedExpressions") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":48},"end":{"line":54,"column":57}}})) != null ? stack1 : "")
    + "                                            </ul>\n                                        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                          <p>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyDetails:noCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":62,"column":29},"end":{"line":62,"column":63}}}))
    + "</p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"tel\"><span class=\"fa fa-phone fa-fw\" aria-hidden=\"true\"></span>\n                                        <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.telephoneLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":87,"column":66},"end":{"line":87,"column":104}}}))
    + ":</span>"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactPhone") || (depth0 != null ? lookupProperty(depth0,"contactPhone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"contactPhone","hash":{},"data":data,"loc":{"start":{"line":87,"column":112},"end":{"line":87,"column":128}}}) : helper)))
    + "\n                                    </span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"website\">\n                                        <span class=\"fa fa-external-link fa-fw\" aria-hidden=\"true\"></span>\n                                        <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.websiteLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":96,"column":66},"end":{"line":96,"column":102}}}))
    + ":</span><a data-history-navigate href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"contactWebsite") || (depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactWebsite","hash":{},"data":data,"loc":{"start":{"line":96,"column":141},"end":{"line":96,"column":159}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactWebsite") || (depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactWebsite","hash":{},"data":data,"loc":{"start":{"line":96,"column":161},"end":{"line":96,"column":179}}}) : helper)))
    + "</a>\n                                    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"study-details-overview show-interest-failure\">\n    <div class=\"study-header\">\n        <div class=\"grid-row\">\n            <section class=\"grid-sm-1 grid-md-10-12 grid-lg-8-12 grid-centered text-center\">\n                <section class=\"media extra-large-font-size\">\n                    <span class=\"fa fa-exclamation-triangle\"></span>\n                </section>\n                <section class=\"content\">\n                    <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:failure.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":61}}}))
    + "</h1>\n                    <div class=\"gamma\">\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:failure.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":60}}}))
    + "\n                    </div>\n                </section>\n\n                <a data-bypass href=\"#sectionEligibility\" data-bs-toggle=\"collapse\" aria-expanded=\"false\"\n                   aria-controls=\"sectionEligibility\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:failure.showStudyCriteria.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":54},"end":{"line":16,"column":113}}}))
    + "</a>\n            </section>\n        </div>\n\n        <div class=\"stylized-divider\" aria-hidden=\"true\">\n        </div>\n\n        <section class=\"collapse\" id=\"sectionEligibility\">\n            <div class=\"grid-row\">\n                <div class=\"grid-sm-1 grid-md-8-12 grid-lg-6-12 grid-centered eligibility\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasGroups") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":63,"column":27}}})) != null ? stack1 : "")
    + "                    <h4 class=\"margin-top\">\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:failure.profileMismatch",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":65,"column":24},"end":{"line":65,"column":71}}}))
    + "\n                    </h4>\n                    <div class=\"stylized-divider\" aria-hidden=\"true\">\n                    </div>\n                </div>\n            </div>\n        </section>\n\n        <section class=\"grid-row\">\n            <div class=\"grid-sm-1 grid-md-8-12 grid-lg-6-12 grid-centered\">\n                <h2 class=\"margin-top delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:failure.reasons.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":75,"column":45},"end":{"line":75,"column":90}}}))
    + ":</h2>\n                <ol>\n                    <li>\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:failure.reasons.one",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":78,"column":24},"end":{"line":78,"column":67}}}))
    + "\n                    </li>\n                    <li>\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:failure.reasons.two",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":81,"column":24},"end":{"line":81,"column":67}}}))
    + ":\n                        <div class=\"contact-card\">\n                            <address>\n                                <span class=\"name\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactName") || (depth0 != null ? lookupProperty(depth0,"contactName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactName","hash":{},"data":data,"loc":{"start":{"line":84,"column":51},"end":{"line":84,"column":66}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contactPhone") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":32},"end":{"line":89,"column":39}}})) != null ? stack1 : "")
    + "                                <span class=\"email\"><span class=\"fa fa-envelope fa-fw\" aria-hidden=\"true\"></span>\n                                    <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.emailLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":91,"column":62},"end":{"line":91,"column":96}}}))
    + ":</span><a data-history-navigate href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"contactEmail") || (depth0 != null ? lookupProperty(depth0,"contactEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactEmail","hash":{},"data":data,"loc":{"start":{"line":91,"column":135},"end":{"line":91,"column":151}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactEmail") || (depth0 != null ? lookupProperty(depth0,"contactEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactEmail","hash":{},"data":data,"loc":{"start":{"line":91,"column":153},"end":{"line":91,"column":169}}}) : helper)))
    + "</a>\n                                </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":32},"end":{"line":98,"column":39}}})) != null ? stack1 : "")
    + "                            </address>\n                        </div>\n                    </li>\n                </ol>\n            </div>\n        </section>\n    </div>\n</div>\n";
},"useData":true,"useDepths":true});