var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Login box -->\n"
    + ((stack1 = container.invokePartial(require("./stripped.header.hbs"),depth0,{"name":"stripped.header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"grid-row login-content\" id=\"sectionLoginBox\">\n    <div class=\"grid-sm-1 grid-md-7-12 grid-md-centered grid-lg-5-12 grid-lg-centered\">\n        <header class=\"hide-for-small-only\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":40}}}))
    + "</h1>\n        </header>\n        <div id=\"divFeedback\"></div>\n        <form class=\"form-stacked\" id=\"frmLogin\" method=\"post\">\n            <div class=\"field-container email validation-error-container\">\n                <label id=\"labelEmail\" for=\"emailUsername\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login:field.username.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":59},"end":{"line":11,"column":96}}}))
    + "</label>\n                <input class=\"input-1\" id=\"emailUsername\" type=\"email\" name=\"username\" data-parsley-type=\"email\" required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:invalidEmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":150},"end":{"line":12,"column":187}}}))
    + "\">\n            </div>\n\n            <div class=\"field-container password validation-error-container\">\n                <label id=\"labelPassword\" for=\"passwordPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login:field.password.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":65},"end":{"line":16,"column":102}}}))
    + "</label>\n                <input class=\"input-1\" id=\"passwordPassword\" type=\"password\" autocomplete=\"off\" name=\"password\" required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:requiredField",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":149},"end":{"line":17,"column":187}}}))
    + "\">\n                <label class=\"password-show\">\n                    <input type=\"checkbox\" id=\"checkboxShowPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login:action.showPassword.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":69},"end":{"line":19,"column":111}}}))
    + "\n                </label>\n\n                <div class=\"field-help-text\">\n                    <a data-history-navigate id=\"aForgotPassword\" href=\"/login/forgot-password\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login:action.forgotPassword.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":96},"end":{"line":23,"column":140}}}))
    + "</a>\n                </div>\n            </div>\n            <button type=\"submit\" class=\"primary-button\" id=\"submitLogin\" value=\"SIGN IN\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login:action.login.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":90},"end":{"line":26,"column":125}}}))
    + "</button>\n        </form>\n        <div class=\"login-links-container\">\n            <a data-history-navigate id=\"aCreateAccount\" href=\"/signup\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"login:action.signUp.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":72},"end":{"line":29,"column":108}}}))
    + "</a>\n        </div>\n    </div>\n</section>\n";
},"usePartial":true,"useData":true});