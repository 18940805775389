(function () {
	'use strict';
	var template = require('../../templates/hbs/screening.questions.hbs');
	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var StudyQuestionView = require('./view.study.question.js');
	var volunteerProfileModule = require('../modules/module.volunteer.profile.js');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'typeahead:select #textCurrentConditions': 'validateTypeahead',
			'typeahead:select #textPastConditions': 'validateTypeahead',
			'click #checkboxNoCurrentConditions': 'toggleTypeahead',
			'click #checkboxNoPastConditions': 'toggleTypeahead',
			'submit #formScreeningQuestionnaire': 'submitScreeningQuestionnaire',
			'change input[name=pregnancy]': 'pregnancyChanged',
			'keyup #textDueYear': 'validateDueDate',
		},

		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
				this.constantLookupValues = new ConstantLookupValues(options.seedModel.attributes);
				this.volunteerEligibilityMap = options.volunteerEligibilityMap;
			}
			this.subViews = {};
		},

		validateTypeahead: function () {
			this.$el.find('#checkboxNoCurrentConditions').parsley(parsleyConfig).validate();
			this.$el.find('#checkboxNoPastConditions').parsley(parsleyConfig).validate();
		},

		submitScreeningQuestionnaire: function (event) {
			event.preventDefault();

			var studyNum = this.model.get('studyNum');

			var formArray = $('#formScreeningQuestionnaire').serializeArray();

			var volunteerScreeningQnaireAnswersDto = this.generateVolunteerScreeningQnaireAnswersDto(formArray);
			this.processDueDate(volunteerScreeningQnaireAnswersDto);

			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'If-Match': this.model.get('screeningQuestionnaire').version
				},
				type: 'POST',
				url: '/backend/secure/volunteer/submit-screening-questionnaire',
				data: JSON.stringify(volunteerScreeningQnaireAnswersDto),
				success: function () {
					util.redirect('/volunteers/interest-success/' + studyNum);
				},
				error: function (jqxhr) {
					if (jqxhr.status !== 401 && jqxhr.status !== 403) {
						if (jqxhr.responseJSON.name === 'Precondition Failed') {
							popupModule.showPopup(
								$('#asideQuestionnaireChanged'),
								$('#buttonOkay'),
								function () {
									window.location.reload(true);
								}
							);
							return;
						}
						switch (jqxhr.responseJSON.value) {
						case 'failure-study-inactive':
							util.redirect('/studies/' + studyNum);
							break;
						case 'failure-already-showed-interest':
							util.redirect('/studies/' + studyNum);
							break;
						case 'failure-profile-not-matching':
							util.redirect('/volunteers/interest-failure/' + studyNum);
							break;
						default:
							throw new Error('unknown error: ' + jqxhr.responseJSON.value);
						}
					}
				}
			});
		},

		generateVolunteerScreeningQnaireAnswersDto: function (formArray) {
			var volunteerScreeningQnaireAnswersDto = {};

			volunteerScreeningQnaireAnswersDto.studyNum = this.volunteerEligibilityMap.studyNum;

			var profilePropertyValueArray = [];
			var questionnaireAnswerArray = [];
			var studySpecificIdentifier = 'study-specific-';
			$.each(formArray, function (index, formField) {
				if(formField.name === 'pregnancy') {
					return true;
				}
				else if (formField.name.indexOf(studySpecificIdentifier) === -1) {
					profilePropertyValueArray.push(formField);
				} else {
					var from = studySpecificIdentifier.length;
					formField.name = formField.name.substring(from);
					questionnaireAnswerArray.push(formField);
				}
			});
			volunteerScreeningQnaireAnswersDto.profilePropertyValues = volunteerProfileModule.getPropertyValuesFromArray(profilePropertyValueArray);
			volunteerScreeningQnaireAnswersDto.answers = this.generateQuestionnaireAnswers(questionnaireAnswerArray);
			volunteerScreeningQnaireAnswersDto.qnaireId = this.model.get('screeningQuestionnaire') ? this.model.get('screeningQuestionnaire').id : 0;
			volunteerScreeningQnaireAnswersDto.studyId = this.model.get('studyId');
			return volunteerScreeningQnaireAnswersDto;
		},

		processDueDate: function (volunteerScreeningQnaireAnswersDto) {
			var dueDate = '';
			if (!$('#divDueDate').hasClass('hide')) {
				dueDate = util.newDate($('#textDueYear').val(), $('#selectDueMonth').val(), 1);
				dueDate = util.formatDateForProperty(dueDate);
			}
			volunteerScreeningQnaireAnswersDto.profilePropertyValues.push({savedValue: dueDate, property: {name: 'estimatedDueDate'}});
		},

		generateQuestionnaireAnswers: function (questionnaireAnswerArray) {
			var answers = [];
			var screeningQuestionnaire = this.model.get('screeningQuestionnaire');
			if (!screeningQuestionnaire || !screeningQuestionnaire.questions || screeningQuestionnaire.questions.length === 0) {
				return answers;
			}
			var questions = screeningQuestionnaire.questions;

			var aggregatedAnswerArray = util.aggregateArrayValues(questionnaireAnswerArray);

			$.each(aggregatedAnswerArray, function (index, questionnaireAnswer) {
				var name = questionnaireAnswer.name;
				var questionId = Number(name.split('-')[name.split('-').length - 1]);
				var question = _.findWhere(questions, {id: questionId});
				var answer = {};
				answer.questionId = questionId;
				answer.questionType = question.questionType;

				if (question.questionType === 'VOLUNTEER_PROFILE') {
					answer.profilePropertyId = question.question.volunteerProfileProperty.id;
					answer.profilePropertyName = question.question.volunteerProfileProperty.name;

					if (question.inputType === 'TEXTBOX' || question.inputType === 'PARAGRAPH_TEXT' || question.inputType === 'DATE_PICKER_MM_YY') {
						answer.freeTextAnswer = questionnaireAnswer.value;
					} else {
						answer.lookupValues = [];
						if (questionnaireAnswer.value.constructor !== Array) {
							questionnaireAnswer.value = [questionnaireAnswer.value];
						}
						$.each(questionnaireAnswer.value, function (j, lookupValueId) {
							answer.lookupValues.push({id: lookupValueId});
						});
					}
				} else {
					if (question.inputType === 'TEXTBOX' || question.inputType === 'PARAGRAPH_TEXT') {
						answer.freeTextAnswer = questionnaireAnswer.value;
					} else {
						answer.options = [];
						if (questionnaireAnswer.value.constructor !== Array) {
							questionnaireAnswer.value = [questionnaireAnswer.value];
						}
						$.each(questionnaireAnswer.value, function (j, lookupValueId) {
							answer.options.push({id: lookupValueId});
						});
					}
				}
				if (answer.options) {
					answer.options = answer.options.filter(function (o) {
						return o.id;
					});
				}
				answers.push(answer);
			});

			return answers;

		},

		toggleTypeahead: function (event) {
			var checkboxId = event.currentTarget.id;

			var $textTypeahead;

			if (checkboxId.indexOf('Current') !== -1) {
				$textTypeahead = this.$el.find('#textCurrentConditions');
			} else if (checkboxId.indexOf('Past') !== -1) {
				$textTypeahead = this.$el.find('#textPastConditions');
			}

			$textTypeahead.typeIO('toggleResultsContainerVisibility');
			$textTypeahead.typeIO('toggleDisabledResultsContainer');

			if ($textTypeahead.is(':disabled')) {
				$textTypeahead.prop('disabled', false);
			} else {
				$textTypeahead.prop('disabled', true);
			}
			this.validateTypeahead();
		},

		pregnancyChanged: function (event) {
			if (event.currentTarget.id === 'radioYesPregnant') {
				$('#divDueDate').removeClass('hide');
			} else {
				$('#divDueDate').addClass('hide');
				$('#selectDueMonth').val('0');
				$('#textDueYear').val('');
			}
		},

		validateDueDate: function () {
			$('#textDueYear').parsley(parsleyConfig).validate();
		},

		populateProfileForm: function () {
			var profile = this.model.get('profile');
			var initialCurrentConditions = [];
			var initialPastConditions = [];

			for (var i = 0; i < profile.propertyValues.length; i++) {
				var propertyValue = profile.propertyValues[i];

				switch (propertyValue.property.name) {
				case 'currentConditions':
					initialCurrentConditions = propertyValue.lookupValues;
					break;
				case 'pastConditions':
					initialPastConditions = propertyValue.lookupValues;
					break;
				case 'gender':
					var participantGenderId = propertyValue.lookupValues[0].id;
					var maleLookupValueId = this.constantLookupValues.get('GENDER','MALE');
					if(participantGenderId === maleLookupValueId) {
						$('#divPregnancy').addClass('hide');
					}
					break;
				case 'estimatedDueDate':
					if(propertyValue.savedValue && !$('#divPregnancy').hasClass('hide')){
						var dueDate = new Date(propertyValue.savedValue);
						var currentDate = new Date();

						if (new Date(dueDate.getUTCFullYear(), dueDate.getUTCMonth(), dueDate.getUTCDate()).getTime() >= new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1).getTime()) {
							$('#radioYesPregnant').prop('checked', true);
							$('#divDueDate').removeClass('hide');

							$('#selectDueMonth').val(dueDate.getUTCMonth());
							$('#textDueYear').val(dueDate.getUTCFullYear());
						}
					}
					break;
				case 'parentOrGuardianOfAChild':
					var parentOrGuardianOfAChild = propertyValue.lookupValues[0].id;
					this.$el.find('input[name=parentOrGuardianOfAChild][value=' + parentOrGuardianOfAChild + ']').prop('checked', true);
					break;
				}
			}

			this.setupTypeahead('textCurrentConditions', initialCurrentConditions, 'currentConditions', 'divCurrentConditionsResults', 'MEDICAL_CONDITION',i18n.t('multiSelectRemoveLabel'));
			if (initialCurrentConditions && initialCurrentConditions.length > 0 && !initialCurrentConditions[0].visible) {
				this.$el.find('#checkboxNoCurrentConditions').trigger('click');
			}
			this.setupTypeahead('textPastConditions', initialPastConditions, 'pastConditions', 'divPastConditionsResults', 'MEDICAL_CONDITION',i18n.t('multiSelectRemoveLabel'));
			if (initialPastConditions && initialPastConditions.length > 0 && !initialPastConditions[0].visible) {
				this.$el.find('#checkboxNoPastConditions').trigger('click');
			}
		},

		setupTypeahead: function (inputId, initialConditions, name, resultsContainerId, sourceAttributeName, removeText) {
			this.$el.find('#' + inputId).typeIO({
				hint: true,
				highlight: true,
				resultsContainer: '#' + resultsContainerId,
				initialResults: this.transformDataForTypeahead(initialConditions),
				name: name,
				minLength: 1,
				removeText: removeText
			},
			{
				limit: 10,
				display: 'text',
				source: this.transformDataForTypeahead(this.seedModel.get(sourceAttributeName)),
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					empty: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		transformDataForTypeahead: function (dataArray) {
			var result = [];
			$.each(dataArray, function (index, data) {
				if (data.visible) {
					result.push({value: data.id, text: data.displayText});
				}
			});
			return result;
		},

		renderStudyQuestions: function () {
			var thisView = this;
			var screeningQuestionnaire = this.model.get('screeningQuestionnaire');
			if (!screeningQuestionnaire || !screeningQuestionnaire.questions || screeningQuestionnaire.questions.length === 0) {
				return;
			}
			var questions = screeningQuestionnaire.questions;
			thisView.$el.find('#fieldsetScreeningQuestions').removeClass('hide');
			$.each(questions, function (index, question) {
				var studyQuestionViewName = 'studyQuestionView' + index;
				thisView.setProfileQuestionAnswer(question);
				thisView.subViews[studyQuestionViewName] = new StudyQuestionView({model: question});
				thisView.renderSubviewAppend(
					thisView.$el.find('#fieldsetScreeningQuestions'),
					studyQuestionViewName,
					thisView.subViews[studyQuestionViewName]);
			});
		},

		setProfileQuestionAnswer: function (question) {
			if (question.questionType === 'VOLUNTEER_PROFILE') {
				question.profileQuestionAnswer = this.getProfilePropertyValue(question.question.volunteerProfileProperty.id);
			}
		},

		getProfilePropertyValue: function (profilePropertyId) {
			var propertyValues = this.model.get('profile').propertyValues;
			var resultPropertyValue = null;
			$.each(propertyValues, function (index, propertyValue) {
				var profileProperty = propertyValue.profileProperty;
				if (profileProperty.id === profilePropertyId) {
					resultPropertyValue = propertyValue;
					return;
				}
			});
			return resultPropertyValue;
		},

		generateTemplateData: function () {
			util.transformTemplateDataForBooleanLookUpValues(this.seedModel.attributes);
			var templateData = this.seedModel.attributes;

			templateData.noConditionId = this.constantLookupValues.get('MEDICAL_CONDITION','NO_CONDITION');

			templateData.studyTitle = this.model.get('studyTitle');
			templateData.isParent = this.model.get('profile').user.parentAccount;
			templateData.organizationInfo = appConfig.organizationInfo;

			return templateData;
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			popupModule.initializePopup(this.$el.find('#asideQuestionnaireChanged'));
			this.populateProfileForm();
			this.renderStudyQuestions();
			this.$el.find('form').parsley(parsleyConfig);
			this.setupValidationSummaryHandler();
			return this;
		}
	});
})();
