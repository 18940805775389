var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"hide\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <nav class=\"text-center\">\n                <a data-history-navigate id=\"\" href=\"/volunteers/messages\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messageList:heading.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":75},"end":{"line":6,"column":115}}}))
    + "</a>\n                <span class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span>\n                <span class=\"show-for-sr\">is parent of</span>\n            </nav>\n            <h1>\n                "
    + alias3(((helper = (helper = lookupProperty(helpers,"studyTitle") || (depth0 != null ? lookupProperty(depth0,"studyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"studyTitle","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":30}}}) : helper)))
    + "\n            </h1>\n            <p class=\"secondary-font-color text-center hide-for-small-only\">\n                "
    + alias3(((helper = (helper = lookupProperty(helpers,"studyTeamMemberNames") || (depth0 != null ? lookupProperty(depth0,"studyTeamMemberNames") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"studyTeamMemberNames","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":40}}}) : helper)))
    + "\n            <div>\n\n            </div>\n            </p>\n        </header>\n        <section class=\"conversation-container\">\n            <div class=\"text-center\">\n                <a data-history-navigate href=\"javascript:void(0);\" id=\"aShowPreviousMessages\">\n                    <span class=\"fa fa-arrow-up fa-margin-right\" aria-hidden=\"true\"></span>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messageList:previousMessages.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":69}}}))
    + "\n                </a>\n            </div>\n            <div class=\"conversation\">\n                <ol class=\"message-list\" id=\"olMessageList\">\n\n                </ol>\n            </div>\n\n            <div class=\"write-message\">\n\n                <div id=\"divSendMessage\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStudyPublishable") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":41},"end":{"line":35,"column":94}}})) != null ? stack1 : "")
    + ">\n                    <form id=\"formSendMessage\">\n                        <label for=\"textareaMessage\" class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messageList:yourMessage.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":37,"column":73},"end":{"line":37,"column":113}}}))
    + "</label>\n                        <textarea maxlength=\"2000\" id=\"textareaMessage\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messageList:typeYourMessage",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":38,"column":85},"end":{"line":38,"column":123}}}))
    + "\"></textarea>\n                        <button disabled type=\"submit\" id=\"buttonSendMessage\"><span class=\"fa fa-paper-plane-o\"\n                                                                                    aria-hidden=\"true\"></span><span\n                                class=\"link\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messageList:sendMessage.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":45},"end":{"line":41,"column":91}}}))
    + "</span></button>\n                    </form>\n                </div>\n\n                <div id=\"divCanNotReply\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStudyPublishable") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":41},"end":{"line":45,"column":86}}})) != null ? stack1 : "")
    + ">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messageList:studyInactive",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":46,"column":20},"end":{"line":46,"column":56}}}))
    + "\n                </div>\n\n            </div>\n\n        </section>\n\n    </section>\n</div>\n\n";
},"useData":true});