(function () {
	'use strict';

	function assign(tgt, src) {
		var k;
		for (k in src) {
			tgt[k] = src[k];
		}
		return tgt;
	}

	var template = require('../../templates/hbs/study.team.login.hbs');
	var util = require('../../../../shared-scripts/app-util/util');
	var BasicFooterView = require('../../../../shared-scripts/views/view.footer.basic');
	var identityProvidersInfo = require('~institution-specific-config/app.config.json')['identityProviders'];
	var StudyTeamLoginModel = require('../models/model.study.team.login');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');

	var View = Backbone.View.extend({
		events: {
			'change #selectInstitution': 'selectIdP'
		},
		initialize: function (options) {
			this.redirectUrl = options.redirectUrl || window.location.origin + '/study-team/studies/current';
			this.collection = options.collection;
			var applicationUrl = '';
			this.collection.each(function (applicationSetting) {
				if (applicationSetting.attributes.name === 'APPLICATION_URL') {
					applicationUrl = applicationSetting.attributes.value;
				}
			});

			var identityProvidersForInstance = identityProvidersInfo.filter(function (idp) {
				return idp.applications.find(function (application) {
					return application.toLowerCase() === applicationUrl.toLowerCase();
				});
			});

			this.model = new StudyTeamLoginModel({identityProviders: identityProvidersForInstance});
			this.model.set('target',this.redirectUrl);
			var identityProviders = this.model.get('identityProviders');
			if (identityProviders.length === 1) {
				var entityID = encodeURIComponent(identityProviders[0]['entityID']);
				var target = encodeURIComponent(this.redirectUrl);
				// we're immediately redirecting to the IdP, so we can't show any feedback specified in local storage,
				// but we don't want to accidentally show it at the next possible time (eg, viewing the '/' page),
				// so we'll clear it.
				util.redirect('/Shibboleth.sso/Login?target=' + target + '&entityID=' + entityID);
			}

			this.subViews = {};
			this.listenTo(this.model, 'change', this.render);
		},
		el: 'main',

		selectIdP: function (event) {
			this.model.set('selectedIdP', this.model.get('identityProviders').find(function (idp) {
				return idp['entityID'] === event.target.value;
			}));
		},

		render: function () {
			/* eslint-disable backbone/no-view-model-attributes */
			this.renderSubviewReplace($('footer'), 'footer', new BasicFooterView());
			this.$el.html(template(this.model.attributes));
			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				localStorage.setItem('feedbackCode', '');
			}
			return this;
		}
	});

	module.exports = View;
})();
