(function() {
	'use strict';

	var StudyCardView = require('./view.study.card.js');
	var StudyCardUserRecView = require('./view.study.card.user.rec.js');
	var StudyCardModel = require('../models/model.study.card.js');

	module.exports = Backbone.View.extend({
		tagName: 'ol',

		initialize: function(options) {
			this.subViews = {};
			this.model.on('reset', this.render, this);

			if(options) {
				this.isSuggestedByStudyTeams = options.isSuggestedByStudyTeams;
				this.isMatchedBySystem = options.isMatchedBySystem;
			}
		},
		render: function() {
			var thisView = this;
			this.closeSubViews();
			_.each(this.model.models, function (model) {
				var cardView;
				if (thisView.isSuggestedByStudyTeams) {
					cardView = new StudyCardUserRecView({model:model});
				} else if(thisView.isMatchedBySystem) {
					var studyCardModel = new StudyCardModel(model.get('study'));
					studyCardModel.set('new', model.get('isNew'));
					cardView = new StudyCardView({model:studyCardModel, onStudiesForYouPage: true});
				} else {
					cardView = new StudyCardView({model:model});
				}
				this.renderSubviewAppend(this.$el, model.cid, cardView);
			}, this);

			return this;
		}
	});
})();
