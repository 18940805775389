var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"collapse\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label for=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":9,"column":37},"end":{"line":9,"column":43}}}) : helper)))
    + "\" class=\"block-label\">\n                    <input type=\"checkbox\" id=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":56},"end":{"line":10,"column":62}}}) : helper)))
    + "\" name=\"genderIdentity\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":92},"end":{"line":10,"column":98}}}) : helper)))
    + " data-parsley-trigger=\"change\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"displayText") || (depth0 != null ? lookupProperty(depth0,"displayText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayText","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":35}}}) : helper)))
    + "\n                </label>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label for=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":28,"column":37},"end":{"line":28,"column":43}}}) : helper)))
    + "\" class=\"block-label\">\n                    <input type=\"checkbox\" id=\"checkbox-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":29,"column":56},"end":{"line":29,"column":62}}}) : helper)))
    + "\" name=\"genderPronoun\" value="
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":29,"column":91},"end":{"line":29,"column":97}}}) : helper)))
    + ">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"displayText") || (depth0 != null ? lookupProperty(depth0,"displayText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayText","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":35}}}) : helper)))
    + "\n                </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"genderIdentityNotCollapsible") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":5},"end":{"line":1,"column":72}}})) != null ? stack1 : "")
    + " id=\"divGenderIdentityContainer\">\n    <div class=\"field-container\">\n        <label>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.identity.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":73}}}))
    + "\n        </label>\n        <div>\n            <label for=\"genderIdentity\" class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.identity.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":60},"end":{"line":7,"column":128}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"GENDER_IDENTITY") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "            <label for=\"textOtherGenderIdentity\" class=\"other-label\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.identity.options.other.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":16},"end":{"line":15,"column":91}}}))
    + "\n            </label>\n            <input type=\"text\" name=\"otherGenderIdentity\" id=\"textOtherGenderIdentity\" data-parsley-check-gender-identity>\n        </div>\n    </div>\n\n    <div class=\"field-container\">\n        <label>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.pronoun.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":12},"end":{"line":23,"column":72}}}))
    + "\n        </label>\n        <div class=\"validation-error-container\">\n            <label for=\"genderPronoun\" class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.pronoun.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":59},"end":{"line":26,"column":126}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"GENDER_PRONOUN") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":32,"column":21}}})) != null ? stack1 : "")
    + "            <label for=\"textOtherGenderPronoun\" class=\"other-label\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.pronoun.options.other.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":34,"column":90}}}))
    + "\n            </label>\n            <input type=\"text\" name=\"otherGenderPronoun\" id=\"textOtherGenderPronoun\" data-parsley-check-gender-identity>\n        </div>\n    </div>\n</div>";
},"useData":true});