(function () {
	'use strict';
	var template = require('../../shared-templates/hbs/user.agreement.hbs');
	var Readmore = require('readmore-js/dist/readmore');
	var popupModule = require('../modules/module.popup');
	var i18n = require('../../shared-scripts/app-util/i18n');

	var executeCallback = function (callback, callbackParams) {
		if (typeof callback === 'function') {
			callback.call(null, callbackParams);
		} else {
			popupModule.closePopup($('#asideUserAgreement'));
		}
	};

	module.exports = Backbone.View.extend({
		el: '#asideUserAgreement',
		events: {
			'click #buttonIagree': 'agreeToUserAgreement',
			'click #checkboxDiscussed': 'toggleCheckbox',
			'click #checkboxReadAndAccept': 'toggleCheckbox',
			'click #aDecline': 'declineUserAgreement'
		},

		agreeToUserAgreement: function (event) {
			event.stopPropagation();
			event.preventDefault();
			var agreeCallback = this.model.get('agreeCallback');
			var agreeCallbackParams = this.model.get('agreeCallbackParams');

			executeCallback(agreeCallback, Object.assign({
				username: this.model.get('username'),
				userAgreementType: this.model.get('userAgreementType')
			},agreeCallbackParams));
		},

		declineUserAgreement: function (event) {
			event.stopPropagation();
			event.preventDefault();
			var declineCallback = this.model.get('declineCallback');
			var declineCallbackParams = this.model.get('declineCallbackParams');

			executeCallback(declineCallback, declineCallbackParams);
		},

		toggleCheckbox: function () {
			var readAndAcceptChecked = $('#checkboxReadAndAccept').prop('checked');
			var discussedChecked = $('#checkboxDiscussed').prop('checked');

			if (readAndAcceptChecked && discussedChecked) {
				$('#buttonIagree').removeAttr('disabled');
			} else {
				$('#buttonIagree').attr('disabled', 'disabled');
			}
		},

		getUserAgreementContent: function (userAgreementType) {
			if (userAgreementType === 'VOL') {
				return i18n.getLanguageInstitutionSpecificFile('VOLUNTEER_USER_AGREEMENT');
			} else if (userAgreementType === 'STM') {
				return i18n.getLanguageInstitutionSpecificFile('STUDY_TEAM_USER_AGREEMENT');
			} else {
				return '';
			}
		},

		generateReadMoreLink: function (iconClassName, text) {
			var aElement = document.createElement('a');
			aElement.href = 'javascript:void(0);';
			aElement.className = 'margin-top';

			var spanElement = document.createElement('span');
			spanElement.classList.add('fa', iconClassName, 'fa-margin-right');
			spanElement.setAttribute('aria-hidden', 'true');

			var textNode = document.createTextNode(text);

			aElement.appendChild(spanElement);
			aElement.appendChild(textNode);

			return aElement;
		},

		printUserAgreement: function (userAgreementHtml) {
			this.$el.find('#aPrint').on('click', function () {
				var PrintPage = window.open('about:blank');

				PrintPage.document.open();
				PrintPage.document.write(userAgreementHtml);
				PrintPage.document.close();

				PrintPage.document.title = window.location.hostname;
				PrintPage.location.reload();
				PrintPage.print();

				PrintPage.close();
			});
		},

		setupUserAgreementContent: function (userAgreementType) {
			var userAgreementContent = this.getUserAgreementContent(userAgreementType);

			this.$el.find('#divUserAgreement').html(userAgreementContent);

			new Readmore('.consent', {
				speed: 75,
				collapsedHeight: 400,
				moreLink: this.generateReadMoreLink('fa-plus', i18n.t('readFullUserAgreement')).outerHTML,
				lessLink: this.generateReadMoreLink('fa-minus', i18n.t('readLess')).outerHTML
			});
			this.printUserAgreement(userAgreementContent);
		},

		render: function () {
			this.$el.html(template(this.model.attributes));
			this.setupUserAgreementContent(this.model.get('userAgreementType'));
			return this;
		}
	});
})();
