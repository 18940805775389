var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePresentMedicalConditions.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":97}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPresentMedicalConditions.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":105},"end":{"line":7,"column":168}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePresentMedicalConditions.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":38},"end":{"line":14,"column":112}}}));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPresentMedicalConditions.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":120},"end":{"line":14,"column":190}}}));
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePresentMedicalConditions.placeHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":54},"end":{"line":17,"column":131}}}));
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPresentMedicalConditions.placeHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":139},"end":{"line":17,"column":212}}}));
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePresentMedicalConditions.no.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":38},"end":{"line":25,"column":108}}}));
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPresentMedicalConditions.no.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":116},"end":{"line":25,"column":182}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePastMedicalConditions.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":30},"end":{"line":33,"column":94}}}));
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPastMedicalConditions.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":102},"end":{"line":33,"column":162}}}));
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePastMedicalConditions.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":38},"end":{"line":40,"column":109}}}));
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPastMedicalConditions.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":117},"end":{"line":40,"column":184}}}));
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePastMedicalConditions.placeHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":43,"column":54},"end":{"line":43,"column":128}}}));
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPastMedicalConditions.placeHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":43,"column":136},"end":{"line":43,"column":206}}}));
},"29":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.lovedOnePastMedicalConditions.no.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":38},"end":{"line":51,"column":105}}}));
},"31":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"signUp:form.field.selfPastMedicalConditions.no.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":113},"end":{"line":51,"column":176}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset>\n    <legend>\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.healthInfo.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":57}}}))
    + "\n    </legend>\n    <div class=\"field-container validation-error-container typeahead-container\">\n        <label for=\"textCurrentConditions\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":175}}})) != null ? stack1 : "")
    + "\n        </label>\n        <div id=\"divCurrentConditionsResults\" role=\"list\"></div>\n\n        <section id=\"the-basics\" class=\"action-container\">\n            <section class=\"add-container\">\n                <div class=\"field-help-text show-for-sr\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":197}}})) != null ? stack1 : "")
    + "\n                </div>\n                <input id=\"textCurrentConditions\" class=\"typeahead\" type=\"text\"\n                       placeholder=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":219}}})) != null ? stack1 : "")
    + "\">\n            </section>\n            <div class=\"badge-container\">\n                <span class=\"badge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.OR",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":36},"end":{"line":20,"column":67}}}))
    + "</span>\n            </div>\n            <section class=\"not-container\">\n                <label for=\"checkboxNoCurrentConditions\" class=\"other-label\">\n                    <input type=\"checkbox\" id=\"checkboxNoCurrentConditions\" name=\"currentConditions-checkbox\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noConditionId") || (depth0 != null ? lookupProperty(depth0,"noConditionId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noConditionId","hash":{},"data":data,"loc":{"start":{"line":24,"column":117},"end":{"line":24,"column":134}}}) : helper)))
    + "\" aria-controls=\"divCurrentConditionsResults\" data-parsley-validate-if-empty data-parsley-check-typeahead-selection>\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":25,"column":20},"end":{"line":25,"column":189}}})) != null ? stack1 : "")
    + "\n                </label>\n            </section>\n        </section>\n    </div>\n\n    <div class=\"field-container validation-error-container typeahead-container\">\n        <label for=\"textPastConditions\">\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":33,"column":169}}})) != null ? stack1 : "")
    + "\n        </label>\n        <div id=\"divPastConditionsResults\" role=\"list\"></div>\n\n        <section id=\"the-basics\" class=\"action-container\">\n            <section class=\"add-container\">\n                <div class=\"field-help-text show-for-sr\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":40,"column":191}}})) != null ? stack1 : "")
    + "\n                </div>\n                <input id=\"textPastConditions\" class=\"typeahead\" type=\"text\"\n                       placeholder=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":43,"column":36},"end":{"line":43,"column":213}}})) != null ? stack1 : "")
    + "\">\n            </section>\n            <div class=\"badge-container\">\n                <span class=\"badge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.OR",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":46,"column":36},"end":{"line":46,"column":67}}}))
    + "</span>\n            </div>\n            <section class=\"not-container\">\n                <label for=\"checkboxNoPastConditions\" class=\"other-label\">\n                    <input type=\"checkbox\" id=\"checkboxNoPastConditions\" name=\"pastConditions-checkbox\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noConditionId") || (depth0 != null ? lookupProperty(depth0,"noConditionId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noConditionId","hash":{},"data":data,"loc":{"start":{"line":50,"column":111},"end":{"line":50,"column":128}}}) : helper)))
    + "\" aria-controls=\"divPastConditionsResults\" data-parsley-validate-if-empty data-parsley-check-typeahead-selection>\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLovedOne") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":51,"column":183}}})) != null ? stack1 : "")
    + "\n                </label>\n            </section>\n        </section>\n    </div>\n\n</fieldset>";
},"useData":true});