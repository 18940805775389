var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <section class=\"suggestor-message\">\n        <section class=\"media\" aria-hidden=\"true\">\n            <span class=\"fa fa-user\"></span>\n        </section>\n\n        <section class=\"content\">\n            <h1>"
    + alias4(((helper = (helper = lookupProperty(helpers,"recommendingUserFullName") || (depth0 != null ? lookupProperty(depth0,"recommendingUserFullName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"recommendingUserFullName","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":44}}}) : helper)))
    + "</h1>\n            <p>\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"messageBody") || (depth0 != null ? lookupProperty(depth0,"messageBody") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageBody","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":31}}}) : helper)))
    + "\n            </p>\n        </section>\n    </section>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"study-suggestion\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"messages") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":15,"column":13}}})) != null ? stack1 : "")
    + "    <div id=\"studyCardContainer\">\n\n    </div>\n</div>\n";
},"useData":true});