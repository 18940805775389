(function () {
	'use strict';
	var Backbone = require('backbone');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var ageRangeParserModule = require('../modules/module.age.range.parser');

	var getSavedValueExpressions = function (eligibilityCriteria, variableName) {
		var expressions = [];
		if (!_.isArray(eligibilityCriteria)) {
			return expressions;
		}
		$.each(eligibilityCriteria, function (criterionIndex, criterion) {
			if (!criterion || !_.isArray(criterion.clauses)) {
				return false;
			}
			$.each(criterion.clauses, function (clauseIndex, clause) {
				if (!clause || !_.isArray(clause.expressions)) {
					return false;
				}
				$.each(clause.expressions, function (expressionIndex, expression) {
					if (!expression.variable || !expression.value || (!expression.operator && expression.variable.name !== 'OTHER')) {
						return false;
					}
					if (expression.variable.name === variableName) {
						expressions.push(expression);
					}
				});
			});
		});

		return expressions;
	};

	module.exports = Backbone.Model.extend({

		getStudyTitle: function () {
			return this.get('title');
		},
		getStudyPurpose: function () {
			return this.get('purpose');
		},
		getParticipantTypes: function () {
			var studyPropertyValues = this.get('propertyValues');
			var participantTypePropertyValue = _.find(studyPropertyValues, function (studyPropertyValue) {
				return studyPropertyValue.property.name === 'participantType';
			});

			if (!participantTypePropertyValue ||
				!_.isArray(participantTypePropertyValue.lookupValues) ||
				participantTypePropertyValue.lookupValues.length === 0) {
				return i18n.t('studyCards:participantType.allParticipants');
			}
			var participantTypes = participantTypePropertyValue.lookupValues;

			if (participantTypes.length === 1 && participantTypes[0].type === 'PARTICIPANT_TYPE' && participantTypes[0].name === 'HEALTHY') {
				return i18n.t('studyCards:participantType.healthyParticipants');
			} else if (participantTypes.length === 1 && participantTypes[0].type === 'PARTICIPANT_TYPE' && participantTypes[0].name === 'CONDITION') {
				return i18n.t('studyCards:participantType.conditionParticipants');
			} else {
				return i18n.t('studyCards:participantType.allParticipants');
			}
		},

		getAges: function () {
			var combinedAgeRanges = ageRangeParserModule.parseAgeRangeFromCriteria(this.get('eligibilityCriteria'));

			for (var i = 0; i < combinedAgeRanges.length; i++) {
				if (combinedAgeRanges[i].max === -1) {
					combinedAgeRanges.splice(i, 1);
				}
			}

			if (combinedAgeRanges.length === 0) {
				return [{ageError: 'Study team specified a bad age criteria and should be contacted for age information.'}];
			} else if (combinedAgeRanges.length > 2) {
				return [{multiple: true}];
			} else {
				return combinedAgeRanges.map(function (combinedAgeRange) {
					var combinedLow = combinedAgeRange.min;
					var combinedHigh = combinedAgeRange.max;

					if (combinedLow === -Infinity && combinedHigh === Infinity) {
						return {allAge: true};
					} else if (combinedLow === -Infinity) {
						return {ageOp: 'younger', age1: combinedHigh, hasAge1: true};
					} else if (combinedHigh === Infinity) {
						return {ageOp: 'older', age1: combinedLow, hasAge1: true};
					} else {
						return {age1: combinedLow, age2: combinedHigh, hasAge1: true, hasAge2: true};
					}
				});
			}
		},

		getGender: function () {
			var genderExpressions = getSavedValueExpressions(this.get('eligibilityCriteria'), 'GENDER');

			if (this.get('eligibilityCriteria') && genderExpressions && this.get('eligibilityCriteria').length > genderExpressions.length) {
				return 'BOTH';
			}

			var genderLookupValueArray = [].concat.apply([], genderExpressions.map(function (genderExpression) {
				return genderExpression.value ? genderExpression.value.lookupValues : [];
			}));

			var genderMap = genderLookupValueArray.reduce(function (map, lookupValue) {
				map[lookupValue.name] = true;
				return map;
			}, {});

			var genders = _.keys(genderMap);
			if (genders.length === 0 || genders.length > 1) {
				return 'BOTH';
			}
			return genders[0];
		},

		getActive: function () {
			return this.get('active');
		},

		getLocations: function () {
			return this.get('locations');
		}
	});
})();
