var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "-->\n                            <!--<option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":55,"column":47},"end":{"line":55,"column":53}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayText") || (depth0 != null ? lookupProperty(depth0,"displayText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayText","hash":{},"data":data,"loc":{"start":{"line":55,"column":55},"end":{"line":55,"column":70}}}) : helper)))
    + "</option>-->\n                        <!--";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row fadeInRight\">\n    <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <nav class=\"text-center\">\n                <a data-history-navigate id=\"aAccountSettings\" href=\"/volunteers/profile\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":90},"end":{"line":6,"column":116}}}))
    + "</a> <span class=\"fa fa-chevron-right\"\n                                                                                     aria-hidden=\"true\"></span> <span\n                    class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.screenReader.isParentOf",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":110}}}))
    + "</span>\n            </nav>\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:editProfile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":46}}}))
    + "</h1>\n        </header>\n\n        <form class=\"form-stacked margin-top\" id=\"formStudyInterests\">\n            <input type=\"hidden\" id=\"hiddenCriterionId\" name=\"findStudiesCriterionId\"\n                   value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"FIND_STUDIES_CRITERION_ID") || (depth0 != null ? lookupProperty(depth0,"FIND_STUDIES_CRITERION_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"FIND_STUDIES_CRITERION_ID","hash":{},"data":data,"loc":{"start":{"line":15,"column":26},"end":{"line":15,"column":55}}}) : helper)))
    + "\">\n            <input type=\"hidden\" id=\"hiddenClauseId\" name=\"findStudiesCriterionClauseId\"\n                   value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"FIND_STUDIES_CRITERION_CLAUSE_ID") || (depth0 != null ? lookupProperty(depth0,"FIND_STUDIES_CRITERION_CLAUSE_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"FIND_STUDIES_CRITERION_CLAUSE_ID","hash":{},"data":data,"loc":{"start":{"line":17,"column":26},"end":{"line":17,"column":62}}}) : helper)))
    + "\">\n            <fieldset>\n                <legend>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":74}}}))
    + "\n                </legend>\n                <div class=\"field-container validation-error-container typeahead-container\">\n                    <label for=\"selectStudyTopics\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.topics.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":51},"end":{"line":23,"column":118}}}))
    + "</label>\n                    <div id=\"divStudyTopicsResults\" role=\"list\"></div>\n\n                    <section id=\"the-basics\" class=\"action-container\">\n                        <section class=\"add-container\">\n                            <div class=\"field-help-text show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.screenReader.addStudyTopic",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":69},"end":{"line":28,"column":142}}}))
    + "</div>\n                            <input id=\"textStudyTopics\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:typeToAddPlaceHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":99},"end":{"line":29,"column":141}}}))
    + "\">\n                        </section>\n                    </section>\n                </div>\n\n                <div class=\"field-container validation-error-container typeahead-container\">\n                    <label for=\"selectStudyLocations\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.locations.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":54},"end":{"line":35,"column":124}}}))
    + "</label>\n                    <div id=\"divStudyLocationsResults\" role=\"list\"></div>\n\n                    <section id=\"the-basics\" class=\"action-container\">\n                        <section class=\"add-container\">\n                            <div class=\"field-help-text show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.screenReader.addStudyLocation",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":69},"end":{"line":40,"column":145}}}))
    + "</div>\n                            <input id=\"textStudyLocations\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:typeToAddPlaceHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":102},"end":{"line":41,"column":144}}}))
    + "\">\n                        </section>\n                    </section>\n                </div>\n\n                <!--<div class=\"field-container\">-->\n                    <!--<label for=\"selectStudyLocations\">I am willing to travel to <em>only</em> the following locations-->\n                        <!--for study visits</label>-->\n\n                    <!--<select id=\"selectStudyLocations\" class=\"input-1\" multiple=\"\"-->\n                            <!--data-expression-id=\""
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"STUDY_LOCATIONS") : depth0)) != null ? lookupProperty(stack1,"EXPRESSION") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-order=\"1\" data-operator=\"ANY_OF\"-->\n                            <!--data-value-id=\""
    + alias3(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"STUDY_LOCATIONS") : depth0)) != null ? lookupProperty(stack1,"EXPRESSION") : stack1)) != null ? lookupProperty(stack1,"value") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"-->\n                            <!--data-variable-id=\""
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"STUDY_LOCATIONS") : depth0)) != null ? lookupProperty(stack1,"CRITERION_VARIABLE") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">-->\n                        <!--"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"STUDY_LOCATIONS") : depth0)) != null ? lookupProperty(stack1,"CRITERION_VARIABLE") : stack1)) != null ? lookupProperty(stack1,"lookupValues") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":56,"column":37}}})) != null ? stack1 : "")
    + "-->\n                    <!--</select>-->\n                <!--</div>-->\n                <div class=\"field-container\">\n                    <label for=\"checkboxCompensationOnly\" class=\"block-label\">\n                        <input type=\"checkbox\" id=\"checkboxCompensationOnly\" name=\"compensationOnly\"\n                               value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"COMPENSATION_ONLY_LOOKUP_VALUE") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n                               data-expression-id=\""
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"COMPENSATION_PREFERENCE") : depth0)) != null ? lookupProperty(stack1,"EXPRESSION") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n                               data-value-id=\""
    + alias3(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"COMPENSATION_PREFERENCE") : depth0)) != null ? lookupProperty(stack1,"EXPRESSION") : stack1)) != null ? lookupProperty(stack1,"value") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-order=\"2\"\n                               data-operator=\"EQUAL\"\n                               data-variable-id=\""
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"COMPENSATION_PREFERENCE") : depth0)) != null ? lookupProperty(stack1,"CRITERION_VARIABLE") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.compensation.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":66,"column":100},"end":{"line":66,"column":173}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"field-container\">\n                    <label for=\"checkboxSeekingHealthyVolunteers\" class=\"block-label\">\n                        <input type=\"checkbox\" id=\"checkboxSeekingHealthyVolunteers\" name=\"seekingHealthyVolunteers\"\n                               value=\""
    + alias3(alias5(((stack1 = (depth0 != null ? lookupProperty(depth0,"SEEKING_HEALTHY_PARTICIPANT_LOOKUP_VALUE") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n                               data-expression-id=\""
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PARTICIPANT_TYPE_STUDY_SEEKING") : depth0)) != null ? lookupProperty(stack1,"EXPRESSION") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-order=\"3\"\n                               data-operator=\"ANY_OF\"\n                               data-value-id=\""
    + alias3(alias5(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PARTICIPANT_TYPE_STUDY_SEEKING") : depth0)) != null ? lookupProperty(stack1,"EXPRESSION") : stack1)) != null ? lookupProperty(stack1,"value") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\"\n                               data-variable-id=\""
    + alias3(alias5(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"PARTICIPANT_TYPE_STUDY_SEEKING") : depth0)) != null ? lookupProperty(stack1,"CRITERION_VARIABLE") : stack1)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.healthyParticipants.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":76,"column":107},"end":{"line":76,"column":187}}}))
    + "\n                    </label>\n                </div>\n            </fieldset>\n            <div class=\"center-button-container\">\n                <button id=\"buttonSave\" type=\"submit\" class=\"primary-button\"><span\n                        class=\"fa fa-floppy-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.save.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":82,"column":89},"end":{"line":82,"column":117}}}))
    + "\n                </button>\n                <a data-history-navigate id=\"aCancel\" class=\"outline-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.cancel.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":84,"column":77},"end":{"line":84,"column":107}}}))
    + "</a>\n            </div>\n\n        </form>\n    </section>\n</div>\n";
},"useData":true});