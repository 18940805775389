(function () {
	'use strict';
	var template = require('../../templates/hbs/deactivate.account.hbs');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var deactivateAccountModule = require('../../../../shared-scripts/modules/module.deactivate.account');
	var appConfig = require('~institution-specific-config/app.config.json');

	require('bootstrap');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'submit #formDeactivateAccount': 'deactivateAccount'
		},

		initialize: function (options) {
			this.seedModel = options.seedModel;
			this.subViews = {};
		},

		deactivateAccount: function (event) {
			event.preventDefault();
			popupModule.showPopup(
				$('#asideConfirmAccountDeletion'), $('#aConfirmDeactivateAccount'),
				this.saveModel, {view: this}
			);
		},

		saveModel: function (event) {
			var thisView = event.data.view;
			popupModule.closePopup($('#asideConfirmAccountDeletion'));
			thisView.populateDeactivateModel();

			var successCallback = function() {
				Backbone.history.fragment = null;
				localStorage.setItem('feedbackCode', 'DEACTIVATE_ACCOUNT_SUCCESS');
				if (Number(thisView.model.get('userId')) === Number(thisView.seedModel.get('lovedOnes')[0].userId)) {
					Backbone.history.navigate('', true);
				} else {
					Backbone.history.navigate('/volunteers/deactivate-account', true);
				}
			};
			deactivateAccountModule.deactivateAccount(thisView.model.attributes, successCallback);
		},

		populateDeactivateModel: function () {
			var formArray = $('#formDeactivateAccount').serializeArray();

			for (var i = 0; i < formArray.length; i++) {
				var name = formArray[i].name;
				var value = formArray[i].value;
				if (name === 'reason') {
					this.model.set(name, {id: value});
				} else {
					this.model.set(name, value);
				}

			}
		},

		sortLovedOnes: function () {
			this.seedModel.get('lovedOnes').sort(function (a, b) {
				if (a.parent) {
					return -1;
				} else if (b.parent) {
					return 1;
				} else {
					return 0;
				}
			});
		},

		displayLovedOnesIfExists: function () {
			if (this.seedModel.get('lovedOnes').length > 1) {
				this.$el.find('#divLovedOnes').show();
			}
		},

		generateTemplateData: function() {
			var templateData = $.extend(true, {}, this.seedModel.attributes);
			templateData.organizationInfo = appConfig.organizationInfo;
			templateData.supportInfo = appConfig.supportInfo;
			return templateData;
		},

		render: function () {
			this.sortLovedOnes(); //make sure parent always comes the first
			this.$el.html(template(this.generateTemplateData()));
			this.displayLovedOnesIfExists();
			popupModule.initializePopup(this.$el.find('#asideConfirmAccountDeletion')); //Activate popup content

			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				localStorage.setItem('feedbackCode', '');
			}

			return this;
		}
	}
	);
	module.exports = View;
})();
