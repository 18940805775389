(function () {
	'use strict';
	var template;

	var View = Backbone.View.extend({
		initialize: function () {
			this.subViews = {};

			var questionType = this.model.inputType;

			switch (questionType) {
			case 'RADIO_BUTTONS':
				template = require('../../templates/hbs/study.question.radio.button.hbs');
				break;
			case 'TEXTBOX':
				template = require('../../templates/hbs/study.question.text.hbs');
				break;
			case 'DATE_PICKER_MM_YY':
				template = require('../../templates/hbs/study.question.datemmyy.hbs');
				break;
			case 'PARAGRAPH_TEXT':
				template = require('../../templates/hbs/study.question.paragraph.hbs');
				break;
			case 'CHECKBOX':
				template = require('../../templates/hbs/study.question.checkbox.hbs');
				break;
			case 'DROPDOWN':
				template = require('../../templates/hbs/study.question.dropdown.hbs');
				break;
			default:
				throw new Error('unknown question type: ' + questionType);
			}
		},

		populateProfileQuestionAnswer: function () {
			var thisView = this;
			var profileQuestionAnswer = this.model.profileQuestionAnswer;
			if (profileQuestionAnswer) {
				var inputType = this.model.inputType;
				if (profileQuestionAnswer.lookupValues && profileQuestionAnswer.lookupValues.length) {
					if (inputType === 'RADIO_BUTTONS' || inputType === 'CHECKBOX') {
						$.each(profileQuestionAnswer.lookupValues, function (index, lookupValue) {
							thisView.$el.find('input[value=' + lookupValue.id + ']').prop('checked', true);
						});
					} else if (inputType === 'DROPDOWN') {
						$.each(profileQuestionAnswer.lookupValues, function (index, lookupValue) {
							thisView.$el.find('select').val(lookupValue.id);
						});
					}
				} else if (profileQuestionAnswer.savedValue) {
					if (inputType === 'DATE_PICKER_MM_YY') {
						var dueDate = new Date(profileQuestionAnswer.savedValue);
						this.$el.find('#selectDueMonth').val(dueDate.getUTCMonth());
						this.$el.find('#textDueYear').val(dueDate.getUTCFullYear());
					} else {
						thisView.$el.find('#' + this.model.id).val(profileQuestionAnswer.savedValue);

					}
				}
			}
		},

		generateTemplateData: function () {
			var templateData = this.model;

			if (this.model.volunteerProfileProperty) {
				var propertyName = this.model.volunteerProfileProperty.name;

				templateData.propertyName = propertyName;
			}

			return templateData;
		},
		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			this.populateProfileQuestionAnswer();
			return this;
		}

	});
	module.exports = View;
})();
