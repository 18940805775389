var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<aside id=\"asideNoEmailAccount\">\n    <header>\n        <h1 class=\"grid-sm-1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.noEmail.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":69}}}))
    + "</h1>\n        <div class=\"top-close-button\">\n            <a data-history-navigate href=\"javascript:void(0);\" class=\"asideNoEmailAccount_close\">\n                <span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span>\n                <span class=\"hide show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":47},"end":{"line":7,"column":87}}}))
    + "</span>\n            </a>\n        </div>\n    </header>\n    <section class=\"popup-main\">\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.noEmail.body",{"name":"i18n","hash":{"interestedParticipantLabel":((stack1 = (depth0 != null ? lookupProperty(depth0,"marketingContent") : depth0)) != null ? lookupProperty(stack1,"interestedParticipantLabel") : stack1),"supportPhoneNumber":((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportPhoneNumber") : stack1)},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":169}}}))
    + "\n        </p>\n    </section>\n    <footer>\n        <div class=\"button-container text-center\">\n            <a data-history-navigate class=\"primary-button asideNoEmailAccount_close\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.ok.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":86},"end":{"line":18,"column":112}}}))
    + "</a>\n        </div>\n    </footer>\n</aside>\n";
},"useData":true});