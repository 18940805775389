var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"tel\">\n            <span class=\"fa fa-phone fa-fw\" aria-hidden=\"true\"></span>\n            <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.telephoneLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":38},"end":{"line":19,"column":76}}}))
    + ":</span>"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactPhone") || (depth0 != null ? lookupProperty(depth0,"contactPhone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"contactPhone","hash":{},"data":data,"loc":{"start":{"line":19,"column":84},"end":{"line":19,"column":100}}}) : helper)))
    + "\n        </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"website\">\n            <span class=\"fa fa-external-link fa-fw\" aria-hidden=\"true\"></span>\n            <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.websiteLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":38},"end":{"line":29,"column":74}}}))
    + ":</span><a data-history-navigate href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"contactWebsite") || (depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactWebsite","hash":{},"data":data,"loc":{"start":{"line":29,"column":113},"end":{"line":29,"column":131}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactWebsite") || (depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactWebsite","hash":{},"data":data,"loc":{"start":{"line":29,"column":133},"end":{"line":29,"column":151}}}) : helper)))
    + "</a>\n        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"study-details-overview show-interest-success\">\n<div class=\"study-header\">\n<div class=\"grid-row\">\n<section class=\"grid-sm-1 grid-md-10-12 grid-lg-8-12 grid-centered text-center\">\n    <section class=\"media success-font-color extra-large-font-size\">\n        <span class=\"fa fa-check-circle\"></span>\n    </section>\n<section class=\"content\">\n    <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:success.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":45}}}))
    + "</h1>\n    <div class=\"gamma\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:success.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":44}}}))
    + ":\n    </div>\n<div class=\"contact-card neutral-background\">\n<address>\n    <span class=\"name\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactName") || (depth0 != null ? lookupProperty(depth0,"contactName") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactName","hash":{},"data":data,"loc":{"start":{"line":15,"column":23},"end":{"line":15,"column":38}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contactPhone") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "    <span class=\"email\">\n        <span class=\"fa fa-envelope fa-fw\" aria-hidden=\"true\"></span>\n        <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.emailLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":34},"end":{"line":24,"column":68}}}))
    + ":</span><a data-history-navigate href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"contactEmail") || (depth0 != null ? lookupProperty(depth0,"contactEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactEmail","hash":{},"data":data,"loc":{"start":{"line":24,"column":107},"end":{"line":24,"column":123}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactEmail") || (depth0 != null ? lookupProperty(depth0,"contactEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactEmail","hash":{},"data":data,"loc":{"start":{"line":24,"column":125},"end":{"line":24,"column":141}}}) : helper)))
    + "</a>\n    </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "</address>\n</div>\n</section>\n</section>\n</div>\n\n    <div class=\"stylized-divider\" aria-hidden=\"true\">\n    </div>\n\n    <section class=\"grid-row text-center margin-top\">\n        <div class=\"grid-sm-1 grid-lg-5-12\">\n            <a data-history-navigate href=\"/\" class=\"primary-button\">\n            <span class=\"fa fa-search fa-margin-right\" aria-hidden=\"true\"></span>\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:success.searchStudies.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":45,"column":73}}}))
    + "\n            </a>\n        </div>\n\n        <div class=\"grid-sm-1 grid-lg-2-12 vertical-center-container\">\n            <span class=\"badge vertical-center\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:success.field.or",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":50,"column":48},"end":{"line":50,"column":88}}}))
    + "</span>\n        </div>\n\n        <div class=\"grid-sm-1 grid-lg-5-12\">\n            <a data-history-navigate href=\"/volunteers/studies-for-you/suggested-by-study-teams\" class=\"primary-button\"><span class=\"fa fa-thumbs-up fa-margin-right\" aria-hidden=\"true\"></span>See\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"showInterest:success.studiesForYou.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":55,"column":16},"end":{"line":55,"column":73}}}))
    + "</a>\n        </div>\n    </section>\n</div>\n</div>\n";
},"useData":true});