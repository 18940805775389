(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.genderIdentity.hbs');

	var util = require('../../../../shared-scripts/app-util/util.js');

	var volunteerProfileModule = require('../modules/module.volunteer.profile.js');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	module.exports = Backbone.View.extend({
		el: 'main',
		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
			}
			this.subViews = {};
		},

		events: {
			'submit #formGenderIdentity': 'saveSection',
			'click #aCancel': 'cancel'
		},

		cancel: function () {
			localStorage.setItem('feedback-card-cancel-container', 'genderIdentity-card-feedback');
			util.redirect('/volunteers/profile');
		},

		saveSection: function (event) {
			event.preventDefault();

			var requestData = volunteerProfileModule.getPropertyValues('#formGenderIdentity');

			var userId = this.model.get('userId');

			volunteerProfileModule.sendPropertyValuesRequest(userId, requestData, 'genderIdentity-card-feedback');
		},

		populateForm: function () {
			var genderIdentityAnswers = this.model.get('genderIdentity');

			for (var i = 0; i < genderIdentityAnswers.length; i++) {
				var genderIdentityAnswer = genderIdentityAnswers[i];
				switch (genderIdentityAnswer.property.name) {
				case 'genderIdentity':
					for (var j = 0; j < genderIdentityAnswer.lookupValues.length; j++) {
						var genderIdentityLookupValue = genderIdentityAnswer.lookupValues[j];
						this.$el.find('input[name=genderIdentity][value=' + genderIdentityLookupValue.id + ']').prop('checked', true);
					}
					break;
				case 'otherGenderIdentity':
					var otherGenderIdentity = genderIdentityAnswer.savedValue;
					this.$el.find('#textOtherGenderIdentity').val(otherGenderIdentity);
					break;
				case 'genderPronoun':
					for (var k = 0; k < genderIdentityAnswer.lookupValues.length; k++) {
						var pronounLookupValue = genderIdentityAnswer.lookupValues[k];
						this.$el.find('input[name=genderPronoun][value=' + pronounLookupValue.id + ']').prop('checked', true);
					}
					break;
				case 'otherGenderPronoun':
					var otherGenderPronoun = genderIdentityAnswer.savedValue;
					this.$el.find('#textOtherGenderPronoun').val(otherGenderPronoun);
					break;
				}
			}
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		parseTemplateData: function () {
			util.transformTemplateDataForBooleanLookUpValues(this.seedModel.attributes);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER_IDENTITY);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER_PRONOUN);
			var templateData = $.extend(true, {}, this.seedModel.attributes);
			templateData.isLovedOne = false;
			templateData.genderIdentityNotCollapsible = true;
			return templateData;
		},

		render: function () {
			this.$el.html(template(this.parseTemplateData()));
			this.populateForm();
			this.$el.find('form').parsley(parsleyConfig);
			this.setupValidationSummaryHandler();
			return this;
		}
	});
})();
