(function() {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		initialize: function() {

			switch(this.get('action')) {
			case 'showed-interest':
				this.set('backendActionEnum', 'SHOWED_INTEREST');
				break;
			case 'dismissed':
				this.set('backendActionEnum', 'DISMISSED');
				break;
			case 'suggested-by-study-teams':
				this.set('backendActionEnum', 'INVESTIGATOR_RECOMMENDED');
				break;
			case 'matched-by-system':
				this.set('backendActionEnum', 'SYSTEM_RECOMMENDED');
				break;
			}

		},
		url: function() {
			var url = '/backend/';

			if(this.get('action')) {
				url = url + 'secure/volunteer/studies?action=' + this.get('backendActionEnum') + '&page='+this.get('pageNum')+'&page-size='+this.get('pageSize') + '&user-id=' + this.get('userId');
			} else if(this.get('searchQuery')) {
				url = url + 'secure/staff/studies?' + this.get('searchQuery') + '&page-size=' + this.get('pageSize');
			} else {
				throw new Error('Should be either search studies or specify an action');
			}
			return url;

		}
	});
	module.exports = Model;
})();

