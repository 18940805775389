(function () {
	'use strict';
	var template = require('../../templates/hbs/profile.hbs');
	var profileFeedbackTemplate = require('../../templates/hbs/profile.feedback.hbs');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values.js');
	var volunteerProfileModule = require('../modules/module.profile.completeness.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n');
	var util = require('../../../../shared-scripts/app-util/util');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var appConfig = require('~institution-specific-config/app.config.json');

	module.exports = Backbone.View.extend({
		el: 'main',

		initialize: function () {
			this.subViews = {};
		},

		parseTemplateData: function () {
			var templateData = {};
			templateData.noInfoForChildExists = true;

			this.parseDemographics(templateData, this.model.get('demographics'));
			this.parseGenderIdentity(templateData, this.model.get('genderIdentity'));
			this.parseBasicHealthInfo(templateData, this.model.get('basicHealthInfo'));
			this.parseMedicalConditions(templateData, this.model.get('medicalConditions'));
			this.parseMedications(templateData, this.model.get('medications'));
			this.parseEmailNotifications(templateData, this.model.get('emailNotifications'));

			if (this.model.get('studyInterestsCriterion')) {
				this.parseStudyInterestsCriterion(templateData, this.model.get('studyInterestsCriterion'));
			}
			templateData.userInfo = this.model.get('userInfo');
			templateData.visibleToStudyTeams = this.model.get('visibleToStudyTeams');
			templateData.showPregnancyField = this.model.get('showPregnancyField');
			templateData.organizationInfo = appConfig.organizationInfo;
			templateData.language = i18n.getDisplayNameByLanguageCode(this.model.get('language'));
			templateData.multipleLangaugeAvailable = i18n.getSupportedLanguages().length>1;
			return templateData;
		},

		parseDemographics: function (templateData, demographics) {
			var raceArray = [];
			var otherRace = '';

			for (var i = 0; i < demographics.length; i++) {
				var demographic = demographics[i];
				switch (demographic.property.name) {
				case 'gender':
					templateData.gender = demographic.lookupValues[0].displayText;
					break;
				case 'estimatedDueDate':
					templateData.estimatedDueDate = util.formatDate(demographic.savedValue, false);
					break;
				case 'dateOfBirth':
					templateData.dateOfBirth = util.formatDate(demographic.savedValue, true);
					break;
				case 'race':
					for (var j = 0; j < demographic.lookupValues.length; j++) {
						raceArray.push(demographic.lookupValues[j].displayText);
					}
					break;
				case 'otherRace':
					otherRace = demographic.savedValue;
					break;
				case 'englishFluency':
					if (demographic.lookupValues.length) {
						templateData.englishFluency = demographic.lookupValues[0].displayText;
					}
					break;
				case 'parentOrGuardianOfAChild':
					templateData.noInfoForChildExists = false;
					if (demographic.lookupValues[0].name === 'TRUE') {
						templateData.parentOrGuardianOfAChild = true;
					} else if (demographic.lookupValues[0].name === 'FALSE') {
						templateData.parentOrGuardianOfAChild = false;
					}
					break;
				}
			}
			if (otherRace) {
				raceArray.push(otherRace);
			}
			templateData.race = raceArray.join(', ');
		},

		parseGenderIdentity: function(templateData, genderIdentityAnswersInProfile){
			var genderIdentityArray = [];
			var otherGenderIdentity = '';

			var genderPronounArray = [];
			var otherGenderPronoun = '';

			for (var i = 0; i < genderIdentityAnswersInProfile.length; i++) {
				var genderIdentityAnswer = genderIdentityAnswersInProfile[i];
				switch (genderIdentityAnswer.property.name) {
				case 'genderIdentity':
					for (var j = 0; j < genderIdentityAnswer.lookupValues.length; j++) {
						genderIdentityArray.push(genderIdentityAnswer.lookupValues[j].displayText);
					}
					break;
				case 'otherGenderIdentity':
					otherGenderIdentity = genderIdentityAnswer.savedValue;
					break;
				case 'genderPronoun':
					for (var k = 0; k < genderIdentityAnswer.lookupValues.length; k++) {
						genderPronounArray.push(genderIdentityAnswer.lookupValues[k].displayText);
					}
					break;
				case 'otherGenderPronoun':
					otherGenderPronoun = genderIdentityAnswer.savedValue;
					break;
				}
			}
			if (otherGenderIdentity) {
				genderIdentityArray.push(otherGenderIdentity);
			}
			if (otherGenderPronoun) {
				genderPronounArray.push(otherGenderPronoun);
			}
			templateData.genderIdentity = genderIdentityArray.join(', ');
			templateData.genderPronoun = genderPronounArray.join(', ');
		},

		parseBasicHealthInfo: function (templateData, basicHealthInfo) {
			for (var i = 0; i < basicHealthInfo.length; i++) {
				switch (basicHealthInfo[i].property.name) {
				case 'height':
					templateData.height = this.formatHeight(basicHealthInfo[i].savedValue);
					break;
				case 'weight':
					var weight = basicHealthInfo[i].savedValue;
					if (!isNaN(weight) && Number(weight) !== 0) {
						templateData.weight = Math.round(Number(weight));
					}
					break;
				case 'smokingStatus':
					templateData.smokingStatus = basicHealthInfo[i].lookupValues[0].displayText;
					break;
				}
			}
		},

		parseMedicalConditions: function (templateData, medicalConditions) {
			templateData.currentConditions = [];
			templateData.pastConditions = [];
			templateData.noCurrentConditionInfo =
				medicalConditions.filter(function (mc) {
					return mc.property.name === 'currentConditions';
				}).length === 0;
			templateData.noPastConditionInfo =
				medicalConditions.filter(function (mc) {
					return mc.property.name === 'pastConditions';
				}).length === 0;

			for (var i = 0; i < medicalConditions.length; i++) {
				var medicalCondition = medicalConditions[i];
				switch (medicalCondition.property.name) {
				case 'currentConditions':
					for (var j = 0; j < medicalCondition.lookupValues.length; j++) {
						if (medicalCondition.lookupValues[j].visible) {
							templateData.currentConditions.push(medicalCondition.lookupValues[j].displayText);
						} else {
							templateData.noCurrentCondition = true;
						}
					}
					break;
				case 'pastConditions':
					for (var k = 0; k < medicalCondition.lookupValues.length; k++) {
						if (medicalCondition.lookupValues[k].visible) {
							templateData.pastConditions.push(medicalCondition.lookupValues[k].displayText);
						} else {
							templateData.noPastCondition = true;
						}
					}
					break;
				case 'hasMetalImplants':
					if (medicalCondition.lookupValues.length === 0) {
						continue;
					} else if (medicalCondition.lookupValues[0].name === 'TRUE') {
						templateData.hasMetalImplants = true;
					} else if (medicalCondition.lookupValues[0].name === 'FALSE') {
						templateData.doesNotHaveMetalImplants = true;
					}
					break;
				}
			}
		},

		parseMedications: function (templateData, medications) {
			for (var i = 0; i < medications.length; i++) {
				var medication = medications[i];
				switch (medication.property.name) {
				case 'willingToChangeMeds':
					if (medication.lookupValues.length === 0) {
						continue;
					} else if (medication.lookupValues[0].name === 'TRUE') {
						templateData.willingToChangeMeds = true;
					} else if (medication.lookupValues[0].name === 'FALSE') {
						templateData.notWillingToChangeMeds = true;
					}
					break;
				case 'willingTakeExpDrugs':
					if (medication.lookupValues.length === 0) {
						continue;
					} else if (medication.lookupValues[0].name === 'TRUE') {
						templateData.willingTakeExpDrugs = true;
					} else if (medication.lookupValues[0].name === 'FALSE') {
						templateData.notWillingTakeExpDrugs = true;
					}
					break;
				}
			}
		},

		parseStudyInterestsCriterion: function (templateData, studyInterestsCriterion) {
			templateData.studyCriterionMedicalConditions = [];
			templateData.studyCriterionStudyLocations = [];
			var expressions = studyInterestsCriterion.clauses[0].expressions;

			for (var i = 0; i < expressions.length; i++) {
				var expression = expressions[i];

				switch (expression.variable.name) {
				case 'TYPE_OF_RESEARCH_PATIENTS_THIS_STUDY_IS_SEEKING':
					templateData.typeOfResearchPatient = expression.value.lookupValues[0].displayText;
					templateData.typeOfResearchPatient = templateData.typeOfResearchPatient.substr(0, 1).toLowerCase() + templateData.typeOfResearchPatient.substr(1);

					break;
				case 'PRESENT_MEDICAL_CONDITION':
					for (var j = 0; j < expression.value.lookupValues.length; j++) {
						templateData.studyCriterionMedicalConditions.push(expression.value.lookupValues[j].displayText);
					}
					break;
				case 'LOCATIONS':
					for (var k = 0; k < expression.value.lookupValues.length; k++) {
						templateData.studyCriterionStudyLocations.push(expression.value.lookupValues[k].displayText);
					}
					break;
				case 'OFFERS_COMPENSATION':
					templateData.OffersCompensation = true;
					break;

				}
			}
		},

		parseEmailNotifications: function (templateData, emailNotifications) {
			templateData.newMessageNotification = emailNotifications.messageNotificationFrequency !== 'NEVER';

			templateData.recommendationNotificationFrequency = emailNotifications.recommendationNotificationFrequency.toLowerCase();

			templateData.recommendationNotificationFrequency = templateData.recommendationNotificationFrequency.replace(new RegExp('_', 'g'), '-');
		},

		formatHeight: function (savedValue) {
			var inches = savedValue;
			var result = '';
			if (!isNaN(inches) && Number(inches) !== 0) {
				inches = Math.round(inches);
				var feet = Math.floor(inches / 12);
				inches %= 12;
				result = feet + '\' ' + inches + '\'\'';
			}

			return result;
		},

		render: function () {
			this.$el.html(template(this.parseTemplateData()));

			volunteerProfileModule.showProfileCompleteness(this.model);

			var $containerToScroll;
			if (localStorage.getItem('feedback-card-success-container')) {
				$containerToScroll = $('#' + localStorage.getItem('feedback-card-success-container'));
				$containerToScroll.html(profileFeedbackTemplate());
				$containerToScroll[0].scrollIntoView();
				 localStorage.removeItem('feedback-card-success-container');
			} else if (localStorage.getItem('feedback-card-cancel-container')) {
				$containerToScroll = $('#' + localStorage.getItem('feedback-card-cancel-container'));
				$containerToScroll[0].scrollIntoView();
				localStorage.removeItem('feedback-card-cancel-container');
			}

			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				localStorage.setItem('feedbackCode', '');

			}
			return this;
		}
	});
})();
