(function () {
	'use strict';

	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var MenuPopupView = require('../views/view.menu.popup');
	var appConfig = require('~institution-specific-config/app.config.json');
	var logoutModule = require('../../../../shared-scripts/modules/module.logout');
	var userRoleModule = require('../../../../shared-scripts/modules/module.user.role');
	var i18next = require('../../../../shared-scripts/app-util/i18n');

	var getMenuTemplate = function (userRole) {

		if (userRole === 'STM') {
			return require('../../templates/hbs/non.volunteer.logged.in.menu.hbs');
		} else if (userRole === 'VOL') {
			return require('../../templates/hbs/volunteer.logged.in.menu.hbs');
		} else {
			return require('../../templates/hbs/unauthenticated.menu.hbs');
		}
	};

	module.exports = Backbone.View.extend({
		el: '#sectionMainMenu',
		events: {
			'click #aMenuMore': 'showMenuPopup',
			'click .nav-tile a': 'makeActive',
			'click #aLogout': 'logout',
			'change #selectLanguage': 'changeLanguage',
			'click #aLanguage': 'changeLanguage',
		},

		activeClassPathToSelectorMap: {
			'#messages': '#aMessages',
			'#studies-for-you': '#aStudiesForYou',
			'#history': '#aHistory',
			'#profile': '#aProfile',
			'#profile-contact-info': '#aProfile',
			'#profile-demographics': '#aProfile',
			'#profile-health-info': '#aProfile',
			'#profile-med-record-access': '#aProfile',
			'#profile-email-notifications': '#aProfile',
			'#profile-visibility': '#aProfile',
			'#profile-study-interests': '#aProfile',
			'#profile-med-conditions': '#aProfile',
			'#profile-medications': '#aProfile'
		},

		logout: function () {
			logoutModule.logout(userRoleModule.getUserRole(this.model.loggedInUser));
		},

		render: function () {
			var loggedInUser = this.model.loggedInUser;
			var fullName = loggedInUser ? loggedInUser.fullName : undefined;
			var template = getMenuTemplate(userRoleModule.getUserRole(loggedInUser));

			var lovedOnes = this.model.lovedOnes;
			var lovedOneExists = (lovedOnes && lovedOnes.length > 1);

			var totalUnreadMessageCount = this.model.totalUnreadMessageCount;
			var recommendedStudyCounts = this.model.recommendedStudyCounts;
			var matchingFinished = this.model.matchingFinished;
			var totalNewStudiesCount = 0;

			if (recommendedStudyCounts) {
				var newUserRecStudyCount = recommendedStudyCounts.newUserRecStudyCount;
				var newSysRecStudyCount = recommendedStudyCounts.newSysRecStudyCount;
				totalNewStudiesCount = newUserRecStudyCount + newSysRecStudyCount;
			}

			var availableLanguages = i18next.getSupportedLanguages().map(function (language) {
				language.highlighted = language.key === i18next.resolvedLanguage;
				return language;
			});

			var notSelectedLanguages = availableLanguages.filter(function (language) {
				return language.key !== i18next.resolvedLanguage;
			});

			this.$el.html(template({
				userFullName: fullName,
				lovedOneExists: lovedOneExists,
				totalUnreadMessageCount: totalUnreadMessageCount,
				totalNewStudiesCount: totalNewStudiesCount,
				organizationInfo: appConfig.organizationInfo,
				matchingFinished: matchingFinished,
				availableLanguages: availableLanguages,
				dropDownLanguageSelector: availableLanguages.length > 2,
				notSelectedLanguages: notSelectedLanguages
			}));

			this.setActiveClass();
			return this;
		},

		changeLanguage: function (event) {
			var $selectLanguage = $('#selectLanguage');
			var language = $selectLanguage.length === 1 ? event.target.value : $(event.target).attr('data-language');

			i18next.changeLanguage(language).then(function () {
				$.ajaxSetup({
					headers: {
						'Accept-Language': language
					}
				});
				window.location.reload();
			});
		},

		showMenuPopup: function (event) {
			var menuPopupView = new MenuPopupView({model: this.model});
			menuPopupView.render();
			event.preventDefault();
			popupModule.showPopup($('#asideMobileMenuPopup'));
		},
		setActiveClass: function () {
			$('.nav-tile').removeClass('active');
			var url = window.location.href;
			var path;
			if (url.lastIndexOf('#') !== -1) {

				path = url.substring(url.lastIndexOf('#')).split('/')[0];

				var selector = this.activeClassPathToSelectorMap[path];
				if (selector) {
					$(selector).parent('.nav-tile').addClass('active');
				}
			}
		}
	}
	);
})();
