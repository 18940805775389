(function() {
	'use strict';
	var util = require('../../../../shared-scripts/app-util/util.js');


	var VolunteerProfileModule = function () {
		this.getPropertyValues = function(formSelector) {
			var form = $(formSelector);
			var formArray = form.serializeArray();
			if(formSelector === '#formDemographics'){
				var fieldName = formArray.filter(function(field){
					if (field.name === 'race') {
						return field.name;
					}
				});
				if (!fieldName || !fieldName.length) {
					formArray.push({name:'race',value:null});
				}
			}
			else if(formSelector === '#formGenderIdentity'){
				var isGenderIdentitySelected = false;
				var isPronounSelected = false;

				formArray.forEach(function(field){
					if (field.name === 'genderIdentity') {
						isGenderIdentitySelected = true;
					}else if(field.name === 'genderPronoun'){
						isPronounSelected = true;
					}
				});
				if (!isGenderIdentitySelected) {
					formArray.push({name:'genderIdentity',value:null});
				}
				if(!isPronounSelected){
					formArray.push({name:'genderPronoun',value:null});
				}
			}
			return this.getPropertyValuesFromArray(formArray);
		};

		this.getPropertyValuesFromArray = function(formArray) {
			var requestData = [];

			var propertyNameToLookupValuesMap = {};
			$.each(formArray, function(i, field) {

				if(field.name === 'pregnancy') {
					return true;
				}

				if(field.name.indexOf('-checkbox') !== -1) {
					field.name = field.name.substring(0,field.name.indexOf('-'));
				}
				var $inputArray = $('input[name=' + field.name + ']');
				var $selectArray = $('select[name=' + field.name + ']');

				if($selectArray.length === 0 && $inputArray[0].getAttribute('type') === 'text') {
					requestData.push({savedValue: field.value, property: {name: field.name}});
				} else if(field.value){
					if (!propertyNameToLookupValuesMap[field.name]) {
						propertyNameToLookupValuesMap[field.name] = [];
					}
					propertyNameToLookupValuesMap[field.name].push({id: field.value});
				} else {
					propertyNameToLookupValuesMap[field.name] = null;
				}
			});

			for(var propertyName in propertyNameToLookupValuesMap) {
				requestData.push({property: {name: propertyName}, lookupValues: propertyNameToLookupValuesMap[propertyName]});
			}
			return requestData;
		};

		this.sendPropertyValuesRequest = function(userId, data, feedbackCardSuccessContainer) {
			$.ajax({
				headers : {
					'Accept' : 'application/json',
					'Content-Type' : 'application/merge-patch+json'
				},
				type:'PATCH',
				url: '/backend/secure/volunteer/property-values?user-id=' + userId,
				data:JSON.stringify(data),
				success: function() {
					localStorage.setItem('feedback-card-success-container', feedbackCardSuccessContainer);
					util.redirect('/volunteers/profile');
				}
			});
		};
	};

	module.exports = new VolunteerProfileModule();
})();