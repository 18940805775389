var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"field-container validation-error-container\" id=\"divParentOrGuardianOfAChild\">\n                        <label>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"profile:form.section.demographics.parentOrGuardianOfAChild.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":43,"column":31},"end":{"line":43,"column":114}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"BOOLEAN") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":58,"column":33}}})) != null ? stack1 : "")
    + "                        <div class=\"field-error-text\">\n                        </div>\n                    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":45,"column":28},"end":{"line":57,"column":35}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <label for=\"radioParentOrGuardianOfAChildYes\" class=\"block-label\">\n                                    <input type=\"radio\" id=\"radioParentOrGuardianOfAChildYes\" name=\"parentOrGuardianOfAChild\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":47,"column":133},"end":{"line":47,"column":139}}}) : helper)))
    + "\"\n                                           required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:requiredField",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":80},"end":{"line":48,"column":118}}}))
    + "\">\n                                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":36},"end":{"line":49,"column":72}}}))
    + "\n                                </label>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <label for=\"radioParentOrGuardianOfAChildNo\" class=\"block-label\">\n                                    <input type=\"radio\" id=\"radioParentOrGuardianOfAChildNo\" name=\"parentOrGuardianOfAChild\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":53,"column":132},"end":{"line":53,"column":138}}}) : helper)))
    + "\"\n                                           required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:requiredField",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":54,"column":80},"end":{"line":54,"column":118}}}))
    + "\">\n                                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":55,"column":36},"end":{"line":55,"column":71}}}))
    + "\n                                </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-9-12 grid-md-centered\">\n        <div id=\"divFeedback\">\n        </div>\n        <header class=\"page-header show-for-sr\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":53}}}))
    + "</h1>\n            <p class=\"secondary-font-color text-center\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":50}}}))
    + "\n            </p>\n        </header>\n    </section>\n</div>\n\n<div class=\"grid-row\" aria-hidden=\"true\">\n    <section class=\"grid-sm-1 grid-md-9-12 grid-lg-7-12 grid-md-centered special-background\">\n        <div class=\"small-caps\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:form.yourStudy",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":56}}}))
    + "\n        </div>\n        <h1 class=\"delta institution-primary-color no-margin-bottom\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"studyTitle") || (depth0 != null ? lookupProperty(depth0,"studyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"studyTitle","hash":{},"data":data,"loc":{"start":{"line":19,"column":69},"end":{"line":19,"column":83}}}) : helper)))
    + "</h1>\n    </section>\n</div>\n\n<section class=\"grid-row\">\n    <div class=\"grid-sm-1 grid-md-9-12 grid-lg-7-12 grid-centered\">\n        <form class=\"form-stacked fadeIn\" id=\"formScreeningQuestionnaire\">\n            <fieldset id=\"fieldsetScreeningQuestions\" class=\"hide\">\n                <legend>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":57}}}))
    + "\n                </legend>\n\n                <p class=\"secondary-font-color hide-for-small\">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":20},"end":{"line":32,"column":54}}}))
    + "\n                </p>\n            </fieldset>\n\n            <fieldset class=\"special-fieldset\">\n                <legend>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:form.field.isInfoCorrect.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":38,"column":20},"end":{"line":38,"column":80}}}))
    + "\n                </legend>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isParent") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":16},"end":{"line":62,"column":23}}})) != null ? stack1 : "")
    + "\n\n                <div class=\"field-container\" id=\"divPregnancy\">\n                    <label>\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfPregnancy.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":24},"end":{"line":67,"column":72}}}))
    + "\n                    </label>\n                    <div>\n                        <label for=\"radioYesPregnant\" class=\"block-label\">\n                            <input type=\"radio\" id=\"radioYesPregnant\" name=\"pregnancy\">\n                            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfPregnancy.options.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":72,"column":82}}}))
    + "\n                        </label>\n                        <label for=\"radioNoPregnant\" class=\"block-label\">\n                            <input type=\"radio\" id=\"radioNoPregnant\" name=\"pregnancy\" checked=\"checked\">\n                            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfPregnancy.options.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":76,"column":28},"end":{"line":76,"column":81}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n\n                <div class=\"field-container hide\" id=\"divDueDate\">\n                    <label>\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfEstimatedDueDate.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":83,"column":24},"end":{"line":83,"column":79}}}))
    + "\n                    </label>\n                    <div class=\"inline-fields validation-error-container\">\n                        <div class=\"field-container\">\n                            <label for=\"selectDueMonth\" class=\"show-for-sr\">\n                                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfEstimatedDueDate.screenReader.dueMonth",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":88,"column":32},"end":{"line":88,"column":103}}}))
    + "\n                            </label>\n                            <select id=\"selectDueMonth\">\n                                <option value=\"0\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.jan",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":91,"column":50},"end":{"line":91,"column":71}}}))
    + "</option>\n                                <option value=\"1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.feb",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":92,"column":50},"end":{"line":92,"column":71}}}))
    + "</option>\n                                <option value=\"2\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.mar",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":93,"column":50},"end":{"line":93,"column":71}}}))
    + "</option>\n                                <option value=\"3\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.apr",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":94,"column":50},"end":{"line":94,"column":71}}}))
    + "</option>\n                                <option value=\"4\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.may",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":95,"column":50},"end":{"line":95,"column":71}}}))
    + "</option>\n                                <option value=\"5\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.jun",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":96,"column":50},"end":{"line":96,"column":71}}}))
    + "</option>\n                                <option value=\"6\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.jul",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":97,"column":50},"end":{"line":97,"column":71}}}))
    + "</option>\n                                <option value=\"7\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.aug",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":98,"column":50},"end":{"line":98,"column":71}}}))
    + "</option>\n                                <option value=\"8\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.sep",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":99,"column":50},"end":{"line":99,"column":71}}}))
    + "</option>\n                                <option value=\"9\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.oct",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":100,"column":50},"end":{"line":100,"column":71}}}))
    + "</option>\n                                <option value=\"10\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.nov",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":101,"column":51},"end":{"line":101,"column":72}}}))
    + "</option>\n                                <option value=\"11\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"months.dec",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":102,"column":51},"end":{"line":102,"column":72}}}))
    + "</option>\n                            </select>\n                        </div>\n                        <div class=\"field-container\">\n                            <label for=\"textDueYear\" class=\"show-for-sr\">\n                                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfEstimatedDueDate.screenReader.dueYear",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":107,"column":32},"end":{"line":107,"column":102}}}))
    + "\n                            </label>\n                            <input type=\"text\" id=\"textDueYear\" maxlength=\"4\" size=\"4\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"placeHolder.year.yyyy",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":109,"column":100},"end":{"line":109,"column":132}}}))
    + "\" data-parsley-check-not-empty-due-date data-parsley-check-valid-due-date data-parsley-validate-if-empty>\n                        </div>\n                    </div>\n                </div>\n\n                <div class=\"field-container validation-error-container typeahead-container\">\n                    <label for=\"selectCurrentConditions\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfPresentMedicalConditions.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":115,"column":57},"end":{"line":115,"column":120}}}))
    + "</label>\n                    <div id=\"divCurrentConditionsResults\" role=\"list\"></div>\n\n                    <section id=\"the-basics\" class=\"action-container\">\n                        <section class=\"add-container\">\n                            <div class=\"field-help-text show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.lovedOnePresentMedicalConditions.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":120,"column":69},"end":{"line":120,"column":143}}}))
    + "</div>\n                            <input id=\"textCurrentConditions\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:typeToAddPlaceHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":121,"column":105},"end":{"line":121,"column":147}}}))
    + "\">\n                        </section>\n                        <div class=\"badge-container\">\n                            <span class=\"badge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.OR",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":124,"column":48},"end":{"line":124,"column":79}}}))
    + "</span>\n                        </div>\n                        <section class=\"not-container\">\n                            <label for=\"checkboxNoCurrentConditions\" class=\"other-label\">\n                                <input type=\"checkbox\" id=\"checkboxNoCurrentConditions\"\n                                       name=\"currentConditions-checkbox\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noConditionId") || (depth0 != null ? lookupProperty(depth0,"noConditionId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noConditionId","hash":{},"data":data,"loc":{"start":{"line":129,"column":80},"end":{"line":129,"column":97}}}) : helper)))
    + "\"\n                                       aria-controls=\"divCurrentConditionsResults\" data-parsley-validate-if-empty\n                                       data-parsley-check-typeahead-selection>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.lovedOnePresentMedicalConditions.no.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":131,"column":78},"end":{"line":131,"column":148}}}))
    + "\n                            </label>\n                        </section>\n                    </section>\n                </div>\n\n                <div class=\"field-container validation-error-container typeahead-container\">\n                    <label for=\"selectPastConditions\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfPastMedicalConditions.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":138,"column":54},"end":{"line":138,"column":114}}}))
    + "</label>\n                    <div id=\"divPastConditionsResults\" role=\"list\"></div>\n\n                    <section id=\"the-basics\" class=\"action-container\">\n                        <section class=\"add-container\">\n                            <div class=\"field-help-text show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.lovedOnePastMedicalConditions.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":143,"column":69},"end":{"line":143,"column":140}}}))
    + "</div>\n                            <input id=\"textPastConditions\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:typeToAddPlaceHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":144,"column":102},"end":{"line":144,"column":144}}}))
    + "\">\n                        </section>\n                        <div class=\"badge-container\">\n                            <span class=\"badge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.OR",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":147,"column":48},"end":{"line":147,"column":79}}}))
    + "</span>\n                        </div>\n                        <section class=\"not-container\">\n                            <label for=\"checkboxNoPastConditions\" class=\"other-label\">\n                                <input type=\"checkbox\" id=\"checkboxNoPastConditions\" name=\"pastConditions-checkbox\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noConditionId") || (depth0 != null ? lookupProperty(depth0,"noConditionId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noConditionId","hash":{},"data":data,"loc":{"start":{"line":151,"column":123},"end":{"line":151,"column":140}}}) : helper)))
    + "\" aria-controls=\"divPastConditionsResults\" data-parsley-validate-if-empty data-parsley-check-typeahead-selection>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.selfPastMedicalConditions.no.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":151,"column":253},"end":{"line":151,"column":316}}}))
    + "\n                            </label>\n                        </section>\n                    </section>\n                </div>\n            </fieldset>\n\n            <div class=\"center-button-container\">\n                <button type=\"submit\" class=\"special-button\">\n                    <span class=\"fa fa-check fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:form.submit.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":160,"column":88},"end":{"line":160,"column":135}}}))
    + "\n                </button>\n            </div>\n\n        </form>\n    </div>\n</section>\n\n<aside id=\"asideQuestionnaireChanged\">\n    <header>\n        <h1 class=\"grid-sm-1\">Error</h1>\n        <div class=\"top-close-button\">\n            <a data-history-navigate href=\"javascript:void(0);\" class=\"asideUserAgreement_close\"><span class=\"fa pe-7s-close\"\n                                                                           aria-hidden=\"true\"></span><span\n                    class=\"hide show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":174,"column":45},"end":{"line":174,"column":85}}}))
    + "</span></a>\n        </div>\n    </header>\n    <section class=\"popup-main\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screeningQuestions:questionnaireChanged",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":178,"column":8},"end":{"line":178,"column":58}}}))
    + "\n    </section>\n    <footer>\n        <div class=\"button-container text-center center-container\">\n            <button class=\"primary-button\" id=\"buttonOkay\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.ok.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":182,"column":59},"end":{"line":182,"column":85}}}))
    + "</button>\n        </div>\n    </footer>\n</aside>\n";
},"useData":true});