var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":34,"column":35}}})) != null ? stack1 : "")
    + "                            </label>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"radioChangeMedsYes\" class=\"block-label\">\n                                <input type=\"radio\" id=\"radioChangeMedsYes\" name=\"willingToChangeMeds\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":27,"column":110},"end":{"line":27,"column":116}}}) : helper)))
    + "\">\n                                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":32},"end":{"line":28,"column":68}}}))
    + "\n                            </label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"radioChangeMedsNo\" class=\"block-label\">\n                                <input type=\"radio\" id=\"radioChangeMedsNo\" name=\"willingToChangeMeds\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":32,"column":109},"end":{"line":32,"column":115}}}) : helper)))
    + "\">\n                                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":32},"end":{"line":33,"column":67}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":45,"column":28},"end":{"line":56,"column":35}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"radioExpDrugsYes\" class=\"block-label\">\n                                <input type=\"radio\" id=\"radioExpDrugsYes\" name=\"willingTakeExpDrugs\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":47,"column":108},"end":{"line":47,"column":114}}}) : helper)))
    + "\">\n                                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":32},"end":{"line":48,"column":68}}}))
    + "\n                            </label>\n\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <label for=\"radioExpDrugsNo\" class=\"block-label\">\n                                    <input type=\"radio\" id=\"radioExpDrugsNo\" name=\"willingTakeExpDrugs\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":53,"column":111},"end":{"line":53,"column":117}}}) : helper)))
    + "\">\n                                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":54,"column":36},"end":{"line":54,"column":71}}}))
    + "\n                                </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <nav class=\"text-center\">\n                <a data-history-navigate id=\"aAccountSettings\" href=\"/volunteers/profile\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":90},"end":{"line":6,"column":116}}}))
    + "</a> <span class=\"fa fa-chevron-right\"                                                                           aria-hidden=\"true\"></span> <span\n                    class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.screenReader.isParentOf",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":110}}}))
    + "</span>\n            </nav>\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:editProfile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":46}}}))
    + "</h1>\n        </header>\n    </section>\n</div>\n\n<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n        <form class=\"form-stacked margin-top\" id=\"formMedications\">\n            <fieldset>\n                <legend>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medications.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":19,"column":71}}}))
    + "\n                </legend>\n                <div class=\"field-container\">\n                    <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medications.medications.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":27},"end":{"line":22,"column":96}}}))
    + "</label>\n                    <div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"BOOLEAN") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":36,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"field-help-text\"><a data-history-navigate id=\"aClearChangeMeds\" href=\"javascript: void(0);\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:clearMyAnswer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":38,"column":124},"end":{"line":38,"column":155}}}))
    + "</a></div>\n                    <div class=\"field-error-text\"></div>\n                </div>\n                <div class=\"field-container\">\n                    <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medications.experimentalDrugs.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":27},"end":{"line":42,"column":102}}}))
    + "</label>\n                    <div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"BOOLEAN") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":57,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"field-help-text\"><a data-history-navigate id=\"aClearExpDrugs\" href=\"javascript: void(0);\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:clearMyAnswer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":122},"end":{"line":59,"column":153}}}))
    + "</a></div>\n                    <div class=\"field-error-text\"></div>\n                </div>\n            </fieldset>\n            <div class=\"center-button-container\">\n                <button type=\"submit\" class=\"primary-button\"><span class=\"fa fa-floppy-o fa-margin-right\"\n                                                                   aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.save.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":65,"column":93},"end":{"line":65,"column":121}}}))
    + "\n                </button>\n                <a data-history-navigate class=\"outline-button\" id=\"aCancel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.cancel.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":77},"end":{"line":67,"column":107}}}))
    + "</a>\n            </div>\n\n        </form>\n    </section>\n</div>\n";
},"useData":true});