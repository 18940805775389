//language files should be balanced: All files should have entries for all translations specified in this file. e.g.:
// If one file has en,es translations then all should have en, es translation entries. If no translation exists
// then the english version should be used in place of other translations.
module.exports = {
	VOLUNTEER_USER_AGREEMENT: {
		en: require('~institution-specific-config/locales/en/user-agreement-volunteer.html').default,
		es: require('~institution-specific-config/locales/es/user-agreement-volunteer.html').default,
	},
	STUDY_TEAM_USER_AGREEMENT: {
		en: require('~institution-specific-config/locales/en/user-agreement-study-team.html').default,
		es: require('~institution-specific-config/locales/es/user-agreement-study-team.html').default,
	},
	PRIVACY_STATEMENT: {
		en: require('~institution-specific-config/locales/en/privacy.hbs'),
		es: require('~institution-specific-config/locales/es/privacy.hbs')
	}
};
