(function() {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		urlRoot: '/backend/public/stat',
		initialize: function(statNames) {
			if (statNames) {
				for (var index=0; index<statNames.length; index++) {
					if (index === 0) {
						this.urlRoot += '?';
					} else {
						this.urlRoot += '&';
					}
					this.urlRoot += 'name=' + statNames[index];
				}
			} else {
				throw new Error('Please provide stat for the model to fetch');
			}
		}
	});
	module.exports = Model;
})();

