(function(){
	'use strict';

	function findUserFieldValue(profile, field){
		return profile.user[field];
	}

	function findContactInfoFieldValue(profile, field){
		return profile.contactInfo[field];
	}

	function findProfilePropertyValue(profile, property){
		return _.find(profile.propertyValues, function(value){
			return value.property.name === property;
		});
	}

	function validateRequiredVolunterProfileProperty(propVal){
		return (!propVal||(!propVal.savedValue&&(!propVal.lookupValues||propVal.lookupValues.length===0)));
	}

	var requiredFields={
		self:{
			fields: [
				{name:'firstName', i18nKey:'firstName'},
				{name:'lastName', i18nKey:'lastName'}
			],
			validate: function(profile, fields){
				return fields.filter(function(field){
					return !findUserFieldValue(profile, field.name);
				}).map(function(field){
					return field.i18nKey;
				});
			}
		},
		contactInfo:{
			fields:[
				{name:'country', i18nKey:'profile:form.section.contactInfo.country.title'},
				{name:'zip', i18nKey:'profile:form.section.contactInfo.zip.title'}
			],
			validate: function(profile, fields){
				return fields.filter(function(field){
					return !findContactInfoFieldValue(profile, field.name);
				}).map(function(field){
					return field.i18nKey;
				});
			}
		},
		propertyValues:{
			fields: [
				{name:'gender', i18nKey:'profile:form.section.demographics.gender.title'},
				{name:'dateOfBirth', i18nKey:'profile:form.section.demographics.dob.title'},
				{name:'race', i18nKey:'profile:form.section.demographics.race.title'},
				{name:'currentConditions', i18nKey:'profile:form.section.medicalConditions.currentConditions.title'},
				{name:'pastConditions', i18nKey:'profile:form.section.medicalConditions.pastConditions.title'}
			],
			validate: function(profile, fields){
				return fields.filter(function (field){
					var propVal = findProfilePropertyValue(profile, field.name);
					return validateRequiredVolunterProfileProperty(propVal);
				}).map(function(field){
					return field.i18nKey;
				});
			}
		}
	};

	module.exports = function(profile){
		var result = [];
		if (profile.user.parentAccount){
			requiredFields.propertyValues.fields.push({name:'parentOrGuardianOfAChild', i18nKey:'profile:form.section.demographics.parentOrGuardianOfAChild.title'});
		}else{
			requiredFields.propertyValues.fields = requiredFields.propertyValues.fields.filter(function(field) {
				return field.name !== 'parentOrGuardianOfAChild';
			});
		}
		Object.keys(requiredFields).forEach(function(section) {
			var fields = requiredFields[section].fields;
			var validateFn = requiredFields[section].validate;
			result = result.concat(validateFn(profile, fields));
		});
		return result;
	};
})();
