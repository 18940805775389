(function() {
	var $ = require('jquery');
	var expressionTranslator = require('yhr-expression-translator');
	var i18next = require('./i18n');
	var html = require('./html');
	var appConfig = require('~institution-specific-config/app.config.json');

	var exclusionPrefix = appConfig.exclusionPrefix;
	var negativeOperatorPrefix = 'NOT_';

	module.exports = {
		negateExpressionOperator: function(expression) {
			var copied = $.extend(true, {}, expression);
			var operator = copied.operator;

			if(!operator) {
				return copied;
			}
			if(operator.lastIndexOf(negativeOperatorPrefix,0) === 0) {
				operator = operator.replace(negativeOperatorPrefix, '');
			} else {
				operator = negativeOperatorPrefix.concat(operator);
			}

			copied.operator = operator;
			return copied;
		},

		transformTranslatedExpression: function(translatedExpression) {
			var values = translatedExpression.values;
			if (values) {
				var listItems = values.map(function (value) {
					return '<li>' + html.escapeHtml(value) + '</li>';
				}).join('');

				return html.markAsSafe(translatedExpression.text + '<ul>' + listItems + '</ul>');
			}
			return translatedExpression.text;
		},

		isExclusionExpression: function(expression) {
			var operator = expression.operator;

			if(!operator) {
				return expression.value.savedValue && expression.value.savedValue.lastIndexOf(exclusionPrefix, 0) === 0;
			} else {
				return operator.lastIndexOf(negativeOperatorPrefix, 0) === 0;
			}
		},

		generateEligibilityCriteriaTemplateData: function(eligibilityCriteria) {
			var thisUtil = this;
			return eligibilityCriteria.map(function(criterion){

				var criterionTemplateData = {name: criterion.name};
				var clause = criterion.clauses[0] || {expressions: []};
				var inclusionTranslatedExpressions = [];
				var exclusionTranslatedExpressions = [];

				_.forEach(clause.expressions, function (expression) {

					if(thisUtil.isExclusionExpression(expression)) {
						expression.value.savedValue = expression.value.savedValue?expression.value.savedValue.replace(exclusionPrefix, ''):undefined;
						exclusionTranslatedExpressions.push(thisUtil.transformTranslatedExpression(expressionTranslator.translateExpression(thisUtil.negateExpressionOperator(expression),i18next.resolvedLanguage)));
					} else {
						inclusionTranslatedExpressions.push(thisUtil.transformTranslatedExpression(expressionTranslator.translateExpression(expression,i18next.resolvedLanguage)));
					}
				});
				criterionTemplateData.inclusionTranslatedExpressions = inclusionTranslatedExpressions;
				criterionTemplateData.exclusionTranslatedExpressions = exclusionTranslatedExpressions;
				criterionTemplateData.hasInclusion = inclusionTranslatedExpressions.length > 0;
				criterionTemplateData.hasExclusion = exclusionTranslatedExpressions.length > 0;

				return criterionTemplateData;
			});
		}
	};

})();
