(function() {

	'use strict';

	var LearnedThroughModule = function () {

		this.setupLearnedThrough = function (view) {
			view.$el.find('#selectLearnedThrough').change(function () {
				var learnedThrough = $('#selectLearnedThrough').val();
				var otherLookupVal = view.seedModel.get('LEARNED_ABOUT_WEBSITE_FROM').find(
					function (lookupValue) {
						if (lookupValue.name === 'OTHER')
						{
							return lookupValue;
						}
					}
				);

				if (learnedThrough && learnedThrough == otherLookupVal.id) {
					view.$el.find('#divOtherLearnedThrough').removeClass('hide');
					view.$el.find('#textOtherLearnedThrough').removeAttr('disabled');
				} else {
					view.$el.find('#divOtherLearnedThrough').addClass('hide');
					view.$el.find('#textOtherLearnedThrough').val('');
					view.$el.find('#textOtherLearnedThrough').attr('disabled', true);
				}
			});

		};
	};
	module.exports = new LearnedThroughModule();
})();