(function () {
	'use strict';

	var template = require('../../templates/hbs/volunteer.login.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var BasicFooterView = require('../../../../shared-scripts/views/view.footer.basic');
	var csrfModule = require('../../../../shared-scripts/modules/module.csrf');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	module.exports = Backbone.View.extend({
		events: {
			'click #checkboxShowPassword': 'togglePasswordVisibility',
			'submit #frmLogin': 'login'
		},

		initialize: function (options) {
			if (options) {
				this.redirectUrl = options.redirectUrl;
			}

			this.subViews = {};
		},
		el: 'main',

		login: function (event) {
			var thisView = this;
			event.preventDefault();
			var $frmLogin = $('#frmLogin');
			csrfModule(function () {
				$.ajax({
					type: 'POST',
					url: '/backend/login',
					data: $frmLogin.serialize(),
					success: function () {
						if (thisView.redirectUrl) {
							util.redirect(thisView.redirectUrl);
						} else {
							util.redirect('/volunteers/studies-for-you/suggested-by-study-teams');
							localStorage.removeItem('missingRequiredFieldsDismissed');
						}
					},
					error: function (jqxhr) {
						if (jqxhr.status === 401) {
							thisView.handleLoginError(jqxhr);
							thisView.clearPasswordField();
							jqxhr.exceptionThrower = 'login';// to prevent global ajax error handler that was setup in ajax.setup.js from taking over 500 errors to display error page
						}
					}
				});
			});
		},

		togglePasswordVisibility: function () {
			var $password = $('#passwordPassword');
			if ($password.attr('type') === 'password') {
				$password.attr('type', 'text');
			} else {
				$password.attr('type', 'password');
			}
		},

		clearPasswordField: function () {
			var $passwordField = $('#passwordPassword');
			$passwordField.val('');
		},

		handleLoginError: function (jqxhr) {
			var loginError = jqxhr.responseJSON['authError'];
			var attemptLimit = jqxhr.responseJSON['attemptLimit'];
			var lockoutWindow = jqxhr.responseJSON['lockoutWindow'];
			var notVerifiedAccount = jqxhr.responseJSON['notVerifiedAccount'];
			var notVerifiedAccountId = jqxhr.responseJSON['notVerifiedAccountId'];

			if (loginError) {
				loginError = loginError.toUpperCase().replace(/-/g, '_');
			}
			feedbackModule.showFeedback(this, jqxhr.status + '_' + loginError + '_VOLUNTEER',
				[
					{name: 'LOCKOUT_WINDOW', value: lockoutWindow},
					{name: 'ATTEMPT_LIMIT', value: attemptLimit},
					{name: 'NOT_VERIFIED_ACCOUNT', value: encodeURIComponent(notVerifiedAccount)},
					{name: 'NOT_VERIFIED_ACCOUNT_ID', value: notVerifiedAccountId},
					{name: 'SUPPORT_PHONE', value: appConfig.supportInfo.supportPhoneNumber}
				]);
		},

		render: function () {
			this.renderSubviewReplace($('footer'), 'footer', new BasicFooterView());
			this.$el.html(template());
			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				localStorage.setItem('feedbackCode', '');
			}
			this.$el.find('#frmLogin').parsley(parsleyConfig);
			return this;
		}
	});
})();
