var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <label for=\"checkbox-gender-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":43,"column":50},"end":{"line":43,"column":56}}}) : helper)))
    + "\" class=\"block-label\">\n                          <input id=\"checkbox-gender-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":44,"column":53},"end":{"line":44,"column":59}}}) : helper)))
    + "\" type=\"checkbox\" name=\"gender\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":44,"column":98},"end":{"line":44,"column":104}}}) : helper)))
    + "\"/>\n                          "
    + alias4(((helper = (helper = lookupProperty(helpers,"displayText") || (depth0 != null ? lookupProperty(depth0,"displayText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayText","hash":{},"data":data,"loc":{"start":{"line":45,"column":26},"end":{"line":45,"column":41}}}) : helper)))
    + "\n                      </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n  <section class=\"grid-sm-1 grid-md-10-12 grid-lg-8-12 grid-centered\">\n    <header class=\"page-header\">\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":43}}}))
    + "</h1>\n        <p class=\"secondary-font-color text-center \">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":42}}}))
    + "\n        </p>\n    </header>\n  </section>\n</div>\n\n<div class=\"grid-row\">\n  <div class=\"grid-sm-1 grid-md-10-12 grid-lg-7-12 grid-centered white-background\">\n\n      <form class=\"form-stacked accordion\" id=\"formSearchForStudies\">\n        <section id=\"sectionResearchTopics\">\n          <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divResearchTopics\" aria-expanded=\"false\" aria-controls=\"divResearchTopics\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.researchTopics.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":92},"end":{"line":18,"column":147}}}))
    + "</h2>\n          </a>\n\n          <div class=\"collapse \" id=\"divResearchTopics\">\n            <!-- Topics -->\n              <div class=\"field-container typeahead-container\">\n                  <label for=\"textTopics\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.researchTopics.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":96}}}))
    + "</label>\n\n                  <div id=\"divTopics\" role=\"list\"></div>\n\n                  <input id=\"textTopics\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.typeToAdd",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":84},"end":{"line":28,"column":122}}}))
    + "\">\n              </div>\n          </div>\n        </section>\n\n        <section id=\"sectionGenderAgePregnancy\" class=\"filter-applied\">\n          <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divGenderAgePregnancy\" aria-expanded=\"false\" aria-controls=\"divGenderAgePregnancy\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":92},"end":{"line":35,"column":154}}}))
    + "</h2>\n          </a>\n\n            <div class=\"collapse \" id=\"divGenderAgePregnancy\">\n              <!-- Gender -->\n              <div class=\"field-container\">\n                  <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.gender.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":25},"end":{"line":41,"column":93}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"GENDER") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":18},"end":{"line":47,"column":27}}})) != null ? stack1 : "")
    + "              </div>\n\n              <!-- Pregnancy -->\n              <div class=\"field-container hide\" id=\"divPregnancy\">\n                  <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.pregnancy.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":52,"column":25},"end":{"line":52,"column":96}}}))
    + "</label>\n                  <div>\n                      <label for=\"radioPregnancyYes\" class=\"block-label\">\n                          <input id=\"radioPregnancyYes\" type=\"radio\" name=\"pregnant\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"yesLookupValueId") || (depth0 != null ? lookupProperty(depth0,"yesLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"yesLookupValueId","hash":{},"data":data,"loc":{"start":{"line":55,"column":92},"end":{"line":55,"column":112}}}) : helper)))
    + "\" disabled>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.pregnancy.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":55,"column":123},"end":{"line":55,"column":193}}}))
    + "\n                      </label>\n\n                      <label for=\"radioPregnancyNo\" class=\"block-label\">\n                          <input id=\"radioPregnancyNo\" type=\"radio\" name=\"pregnant\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noLookupValueId") || (depth0 != null ? lookupProperty(depth0,"noLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noLookupValueId","hash":{},"data":data,"loc":{"start":{"line":59,"column":91},"end":{"line":59,"column":110}}}) : helper)))
    + "\" disabled>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.pregnancy.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":121},"end":{"line":59,"column":190}}}))
    + "\n                      </label>\n                  </div>\n                  <div class=\"field-help-text\">\n                      <a data-history-navigate href=\"javascript:void(0);\" id=\"aClearPregnancy\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.clear",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":63,"column":95},"end":{"line":63,"column":157}}}))
    + "</a>\n                  </div>\n              </div>\n\n              <!-- age -->\n              <div id=\"divAge\" class=\"field-container validation-error-container\">\n                 <label for=\"textAge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.age.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":69,"column":38},"end":{"line":69,"column":103}}}))
    + "</label>\n                  <input type=\"text\" id=\"textAge\" maxlength=\"3\" size=\"3\" name=\"age\" value=\"\" data-parsley-pattern=\"^\\d{0,3}$\" data-parsley-validate-if-empty data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:invalidAge",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":70,"column":185},"end":{"line":70,"column":220}}}))
    + "\"/>\n              </div>\n            </div>\n        </section>\n\n        <section id=\"sectionHealthyVolStudies\">\n          <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divHealthyVolStudies\" aria-expanded=\"false\" aria-controls=\"divHealthyVolStudies\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.healthyVolunteer.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":77,"column":92},"end":{"line":77,"column":149}}}))
    + "</h2>\n          </a>\n\n          <div class=\"collapse \" id=\"divHealthyVolStudies\">\n            <!-- Healthy -->\n              <div class=\"field-container\">\n                  <label for=\"checkboxOnlyShowHealthy\" class=\"block-label\">\n                      <input id=\"checkboxOnlyShowHealthy\" type=\"checkbox\" name=\"onlyHealthy\"\n                             value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"healthyVolunteerLookupValueId") || (depth0 != null ? lookupProperty(depth0,"healthyVolunteerLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"healthyVolunteerLookupValueId","hash":{},"data":data,"loc":{"start":{"line":85,"column":36},"end":{"line":85,"column":69}}}) : helper)))
    + "\"/>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.healthyVolunteer.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":85,"column":72},"end":{"line":85,"column":128}}}))
    + "\n                  </label>\n              </div>\n          </div>\n        </section>\n\n        <section id=\"sectionCompensationPreference\">\n          <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divCompensationPreference\" aria-expanded=\"false\" aria-controls=\"divCompensationPreference\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.compensation.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":93,"column":92},"end":{"line":93,"column":145}}}))
    + "</h2>\n          </a>\n\n          <div class=\"collapse \" id=\"divCompensationPreference\">\n            <div class=\"field-container\">\n              <!-- Compensation -->\n                <label for=\"checkboxOffersCompensation\" class=\"block-label\">\n                    <input id=\"checkboxOffersCompensation\" type=\"checkbox\" name=\"onlyComp\"\n                           value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"yesLookupValueId") || (depth0 != null ? lookupProperty(depth0,"yesLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"yesLookupValueId","hash":{},"data":data,"loc":{"start":{"line":101,"column":34},"end":{"line":101,"column":54}}}) : helper)))
    + "\"/>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.compensation.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":101,"column":57},"end":{"line":101,"column":109}}}))
    + "\n                </label>\n            </div>\n          </div>\n        </section>\n\n        <section id=\"sectionCanBeDoneFromHome\">\n          <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divCanBeDoneFromHome\" aria-expanded=\"false\" aria-controls=\"divCanBeDoneFromHome\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.studiesThatCanBeDoneFromHome.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":109,"column":92},"end":{"line":109,"column":161}}}))
    + "</h2>\n          </a>\n\n          <div class=\"collapse \" id=\"divCanBeDoneFromHome\">\n            <!-- Can be done from home -->\n            <div class=\"field-container\">\n                <label for=\"checkboxCanBeDoneFromHome\" class=\"block-label\">\n                    <input id=\"checkboxCanBeDoneFromHome\" type=\"checkbox\" name=\"locations\"\n                           value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"canBeDoneFromHomeLookupValueId") || (depth0 != null ? lookupProperty(depth0,"canBeDoneFromHomeLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"canBeDoneFromHomeLookupValueId","hash":{},"data":data,"loc":{"start":{"line":117,"column":34},"end":{"line":117,"column":68}}}) : helper)))
    + "\"/>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.studiesThatCanBeDoneFromHome.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":117,"column":71},"end":{"line":117,"column":139}}}))
    + "\n                </label>\n            </div>\n          </div>\n        </section>\n\n        <section id=\"sectionPIandDept\">\n          <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divPIandDept\" aria-expanded=\"false\" aria-controls=\"divPIandDept\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.principalInvestigator.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":125,"column":92},"end":{"line":125,"column":154}}}))
    + "</h2>\n          </a>\n\n          <div class=\"collapse \" id=\"divPIandDept\">\n            <!-- PI -->\n            <div class=\"field-container typeahead-container\">\n                <label for=\"textPIName\">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.principalInvestigator.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":132,"column":20},"end":{"line":132,"column":81}}}))
    + "\n                </label>\n                <div id=\"divPIName\" role=\"list\"></div>\n\n                <input id=\"textPIName\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.typeToAdd",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":136,"column":82},"end":{"line":136,"column":120}}}))
    + "\">\n            </div>\n          </div>\n        </section>\n\n        <div class=\"center-button-container\">\n          <a data-history-navigate id=\"aSearchForStudies\" href=\"javascript:void(0);\" class=\"primary-button\"><span class=\"fa fa-search\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:search",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":142,"column":161},"end":{"line":142,"column":193}}}))
    + "</a>\n        </div>\n      </form>\n    </div>\n  </div>\n\n  <div class=\"grid-row margin-top\">\n    <div class=\"grid-sm-1 text-center\">\n      <span class=\"badge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:form.or",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":150,"column":26},"end":{"line":150,"column":59}}}))
    + "</span>\n    </div>\n  </div>\n\n  <div class=\"grid-row margin-top\">\n    <div class=\"grid-sm-1 grid-md-10-12 grid-lg-7-12 grid-centered white-background\">\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:form.studyIdentifier.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":156,"column":24},"end":{"line":156,"column":76}}}))
    + "</h2>\n      <form id=\"formSearchByStudyNum\" class=\"form-stacked\">\n        <div class=\"field-container\">\n          <label for=\"textStudy\" class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:form.studyIdentifier.screenReader.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":159,"column":53},"end":{"line":159,"column":117}}}))
    + "</label>\n          <input id=\"textStudy\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:form.studyIdentifier.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":160,"column":57},"end":{"line":160,"column":108}}}))
    + "\">\n          <div id=\"divFindStudyByStudyIdentifierErrorText\" class=\"field-error-text\">\n\n          </div>\n          <a data-history-navigate id=\"aFindStudyBystudyNum\" href=\"javascript:void(0);\" class=\"primary-button\"><span class=\"fa fa-search\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"advancedSearch:form.studyIdentifier.find",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":164,"column":164},"end":{"line":164,"column":215}}}))
    + "</a>\n        </div>\n      </form>\n    </div>\n  </div>\n";
},"useData":true});