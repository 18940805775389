(function () {
	'use strict';
	var Backbone = require('backbone');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var relTypeLabelMap = {
		'LEGAL_GUARDIAN_CHILD': i18n.t('signUp:form.field.relationWithLovedOne.LEGAL_GUARDIAN_CHILD'),
		'PARENT_CHILD': i18n.t('signUp:form.field.relationWithLovedOne.PARENT_CHILD'),
		'LEGAL_GUARDIAN_ADULT': i18n.t('signUp:form.field.relationWithLovedOne.LEGAL_GUARDIAN_ADULT'),
		'HEALTHCARE_ADVOCATE_ADULT': i18n.t('signUp:form.field.relationWithLovedOne.HEALTHCARE_ADVOCATE_ADULT'),
		'KIN_ADULT': i18n.t('signUp:form.field.relationWithLovedOne.KIN_ADULT'),
	};
	var Model = Backbone.Model.extend({
		urlRoot: '/backend/public/relationships',

		parse: function (data) {
			var relationshipCategories = [];
			var result = {};
			for (var key in data) {
				if (data.hasOwnProperty(key)) {
					relationshipCategories.push(key);
				}
			}

			relationshipCategories.sort();

			for (var i = 0; i < relationshipCategories.length; i++) {
				var relTypeArray = data[relationshipCategories[i]];
				var relationships = [];
				for (var relCounter = 0; relCounter < relTypeArray.length; relCounter++) {
					var relationshipType = relTypeArray[relCounter];
					var resultValue = {
						value: relationshipType,
						display: relTypeLabelMap[relationshipType]
					};
					relationships.push(resultValue);
				}
				result[relationshipCategories[i]] = relationships;
			}
			return result;
		}
	});
	module.exports = Model;
})();

