var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"victors\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"marketingContent.interestedParticipantLabel",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"victorCount") : depth0)},"data":data,"loc":{"start":{"line":25,"column":38},"end":{"line":25,"column":110}}}))
    + "</span> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:volunteersNeeded",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":118},"end":{"line":25,"column":158}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"stats\">\n                    "
    + alias3(((helper = (helper = lookupProperty(helpers,"victorCount") || (depth0 != null ? lookupProperty(depth0,"victorCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"victorCount","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":35}}}) : helper)))
    + "\n                </div>\n                <div class=\"stats-label\"><span class=\"victors\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"marketingContent.interestedParticipantLabel",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"victorCount") : depth0)},"data":data,"loc":{"start":{"line":30,"column":63},"end":{"line":30,"column":135}}}))
    + "</span> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:shownInterest",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":143},"end":{"line":30,"column":180}}}))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"logged-in-as-study-team\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyDetails:cannotShowInterest",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":58},"end":{"line":34,"column":100}}}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showedInterestDate") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":38,"column":31}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div><a data-history-navigate href=\"javascript:void(0);\" id=\"aIamInterested\" class=\"special-button\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button.interested.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":37,"column":124},"end":{"line":37,"column":158}}}))
    + "!</a></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"victor-count\">\n              <span class=\"victors\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"marketingContent.interestedParticipantLabel",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"victorCount") : depth0)},"data":data,"loc":{"start":{"line":53,"column":36},"end":{"line":53,"column":108}}}))
    + "</span> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:volunteersNeeded",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":53,"column":116},"end":{"line":53,"column":156}}}))
    + "\n            </span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"victor-count\">\n                <span class=\"stat\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"victorCount") || (depth0 != null ? lookupProperty(depth0,"victorCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"victorCount","hash":{},"data":data,"loc":{"start":{"line":57,"column":35},"end":{"line":57,"column":50}}}) : helper)))
    + "\n                </span>\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"marketingContent.interestedParticipantLabel",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"victorCount") : depth0)},"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":59,"column":88}}}))
    + " "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:shownInterest",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":89},"end":{"line":59,"column":126}}}))
    + "\n            </span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showedInterestDate") : depth0),{"name":"unless","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":65,"column":23}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a data-history-navigate href=\"javascript:void(0);\" id=\"aIamInterested\" class=\"special-button\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button.interested.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":64,"column":111},"end":{"line":64,"column":145}}}))
    + "!</a>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studyDetails:criteriaInfo",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"eligibilityCriteriaGroupNumber") : depth0),"eligibilityCriteriaGroupNumber":(depth0 != null ? lookupProperty(depth0,"eligibilityCriteriaGroupNumber") : depth0)},"data":data,"loc":{"start":{"line":76,"column":21},"end":{"line":76,"column":156}}}))
    + ":\n                </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"eligibilityCriteria") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":111,"column":25}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <section class=\"inclusion-exclusion-block\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"moreThanOneGroup") : depths[1]),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":24},"end":{"line":84,"column":31}}})) != null ? stack1 : "")
    + "                        <section class=\"inclusion-exclusion\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasInclusion") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":28},"end":{"line":97,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasExclusion") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":98,"column":28},"end":{"line":108,"column":35}}})) != null ? stack1 : "")
    + "                        </section>\n                    </section>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <header>\n                                <h4>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":82,"column":36},"end":{"line":82,"column":44}}}) : helper)))
    + "</h4>\n                            </header>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"inclusion\">\n                                    <strong class=\"sub-head\"><span\n                                            class=\"fa fa-check fa-margin-right success-font-color\"\n                                            aria-hidden=\"true\"></span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studyDetails:inclusionCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":90,"column":70},"end":{"line":90,"column":111}}}))
    + ":</strong>\n                                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"inclusionTranslatedExpressions") : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":40},"end":{"line":94,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    return "                                            <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <div class=\"exclusion\">\n                                    <strong class=\"sub-head\"><span class=\"fa fa-times fa-margin-right error-font-color\"\n                                                                   aria-hidden=\"true\"></span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studyDetails:exclusionCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":101,"column":93},"end":{"line":101,"column":134}}}))
    + "</strong>\n                                    <ul>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"exclusionTranslatedExpressions") : depth0),{"name":"each","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":40},"end":{"line":105,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyDetails:noCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":113,"column":19},"end":{"line":113,"column":53}}}))
    + "</p>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"compensation") || (depth0 != null ? lookupProperty(depth0,"compensation") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"compensation","hash":{},"data":data,"loc":{"start":{"line":134,"column":20},"end":{"line":134,"column":36}}}) : helper)))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyDetails:noCompensation",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":136,"column":20},"end":{"line":136,"column":58}}}))
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <!-- More about this study section -->\n            <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#sectionAdditionalInformation\" aria-expanded=\"false\"\n               aria-controls=\"sectionAdditionalInformation\" class=\"collapse-handler\">\n                <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:additionalInfo",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":145,"column":96},"end":{"line":145,"column":134}}}))
    + "\n                </h2>\n            </a>\n            <section class=\"collapse\" id=\"sectionAdditionalInformation\">\n                <p>"
    + alias3(((helper = (helper = lookupProperty(helpers,"aboutStudy") || (depth0 != null ? lookupProperty(depth0,"aboutStudy") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"aboutStudy","hash":{},"data":data,"loc":{"start":{"line":149,"column":19},"end":{"line":149,"column":33}}}) : helper)))
    + "</p>\n            </section>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"tel\">\n                        <span class=\"fa fa-phone fa-fw\" aria-hidden=\"true\"></span>\n                        <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.telephoneLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":201,"column":50},"end":{"line":201,"column":88}}}))
    + ":</span>"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactPhone") || (depth0 != null ? lookupProperty(depth0,"contactPhone") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"contactPhone","hash":{},"data":data,"loc":{"start":{"line":201,"column":96},"end":{"line":201,"column":112}}}) : helper)))
    + "\n                    </span>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"website\">\n                    <span class=\"fa fa-external-link fa-fw\" aria-hidden=\"true\"></span>\n                    <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.websiteLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":211,"column":46},"end":{"line":211,"column":82}}}))
    + ":</span><a data-history-navigate href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"contactWebsite") || (depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactWebsite","hash":{},"data":data,"loc":{"start":{"line":211,"column":121},"end":{"line":211,"column":139}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"contactWebsite") || (depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"contactWebsite","hash":{},"data":data,"loc":{"start":{"line":211,"column":141},"end":{"line":211,"column":159}}}) : helper)))
    + "</a>\n                </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"study-details-overview\">\n    <!--Feedback block that shows if you have previously shown interest in this study -->\n    <div id=\"divFeedback\"></div>\n\n    <!--Study title -->\n    <section class=\"grid-row study-header\">\n        <div class=\"grid-sm-1\">\n            <header>\n                <h1>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":29}}}) : helper)))
    + "</h1>\n                <div>\n                    <span class=\"fa fa-map-marker fa-fw\" aria-hidden=\"true\"></span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"locations") || (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locations","hash":{},"data":data,"loc":{"start":{"line":11,"column":84},"end":{"line":11,"column":97}}}) : helper)))
    + "\n                </div>\n            </header>\n        </div>\n        <span class=\"separator\" aria-hidden=\"true\"></span>\n    </section>\n\n    <!--Study Purpose + Action Buttons-->\n    <section class=\"grid-row study-details-purpose-action\">\n        <section class=\"grid-sm-1 grid-lg-golden-larger\">\n            <p class=\"study-purpose\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"purpose") || (depth0 != null ? lookupProperty(depth0,"purpose") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"purpose","hash":{},"data":data,"loc":{"start":{"line":21,"column":37},"end":{"line":21,"column":48}}}) : helper)))
    + "</p>\n        </section>\n        <section class=\"grid-sm-1 grid-lg-golden-smaller action-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"victorsNeeded") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"button-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStudyTeamMember") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":39,"column":23}}})) != null ? stack1 : "")
    + "                <a data-history-navigate href=\"javascript:void(0);\" id=\"aPrintStudy\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:printStudy",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":85},"end":{"line":40,"column":119}}}))
    + "</a>\n            </div>\n        </section>\n    </section>\n</div>\n<!--Study details -->\n<section class=\"grid-row study-details\">\n    <div class=\"sticky-header\" aria-hidden=\"true\">\n		<span class=\"logo-thumbnail\">\n            <a data-history-navigate href=\"\"><span class=\"fa fa-home fa-margin-right\" aria-hidden></span><span class=\"show-for-sr\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.backToHome.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":131},"end":{"line":49,"column":165}}}))
    + "</span></a>\n		</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"volunteersNeeded") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":51,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isStudyTeamMember") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":66,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"grid-1 accordion\">\n        <!--Who can participate -->\n        <a data-bypass role=\"button\" data-bs-toggle=\"collapse\" href=\"#sectionEligibility\" aria-expanded=\"false\"\n           aria-controls=\"sectionEligibility\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:whoCanParticipate",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":72,"column":92},"end":{"line":72,"column":133}}}))
    + "</h2>\n        </a>\n        <section class=\"collapse eligibility\" id=\"sectionEligibility\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasGroups") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(26, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":75,"column":12},"end":{"line":114,"column":19}}})) != null ? stack1 : "")
    + "        </section>\n\n        <!-- What is involved section -->\n        <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#sectionWhatIsInvolved\" aria-expanded=\"false\"\n           aria-controls=\"sectionWhatIsInvolved\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:whatIsInvolved",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":120,"column":92},"end":{"line":120,"column":130}}}))
    + "</h2>\n        </a>\n        <section class=\"collapse\" id=\"sectionWhatIsInvolved\">\n            <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyDescription") || (depth0 != null ? lookupProperty(depth0,"studyDescription") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyDescription","hash":{},"data":data,"loc":{"start":{"line":123,"column":15},"end":{"line":123,"column":35}}}) : helper)))
    + "</p>\n        </section>\n\n        <!-- Compensation section -->\n        <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#sectionCompensation\" aria-expanded=\"false\"\n           aria-controls=\"sectionCompensation\" class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:compensation",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":129,"column":92},"end":{"line":129,"column":128}}}))
    + "</h2>\n        </a>\n        <section class=\"collapse\" id=\"sectionCompensation\">\n            <p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"compensation") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.program(30, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":133,"column":16},"end":{"line":137,"column":23}}})) != null ? stack1 : "")
    + "            </p>\n        </section>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"aboutStudy") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":141,"column":8},"end":{"line":151,"column":15}}})) != null ? stack1 : "")
    + "        <!-- PI, Study # etc      -->\n        <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#sectionStudyNum\" aria-expanded=\"false\"\n           aria-controls=\"sectionStudyNum\"\n           class=\"collapse-handler\">\n            <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":156,"column":92},"end":{"line":156,"column":130}}}))
    + "</h2>\n        </a>\n        <section class=\"additional-information collapse\" id=\"sectionStudyNum\">\n            <section>\n                <h4>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.studyNum",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":160,"column":20},"end":{"line":160,"column":62}}}))
    + ":</h4>\n                <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyNum") || (depth0 != null ? lookupProperty(depth0,"studyNum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyNum","hash":{},"data":data,"loc":{"start":{"line":161,"column":21},"end":{"line":161,"column":33}}}) : helper)))
    + "<a data-history-navigate title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":161,"column":65},"end":{"line":161,"column":74}}}) : helper)))
    + "\" class=\"hide-for-small-only\"><span\n                        class=\"fa fa-info-circle\"></span></a>\n                </div>\n            </section>\n            <section>\n                <h4>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.PI",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":166,"column":20},"end":{"line":166,"column":56}}}))
    + ":</h4>\n                <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"pi") || (depth0 != null ? lookupProperty(depth0,"pi") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pi","hash":{},"data":data,"loc":{"start":{"line":167,"column":21},"end":{"line":167,"column":27}}}) : helper)))
    + "</div>\n            </section>\n            <section>\n                <h4>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.department",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":170,"column":20},"end":{"line":170,"column":64}}}))
    + ":</h4>\n                <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"department") || (depth0 != null ? lookupProperty(depth0,"department") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"department","hash":{},"data":data,"loc":{"start":{"line":171,"column":21},"end":{"line":171,"column":35}}}) : helper)))
    + "</div>\n            </section>\n            <section>\n                <h4>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.activatedOn",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":174,"column":20},"end":{"line":174,"column":65}}}))
    + ":</h4>\n                <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"postingActivationDate") || (depth0 != null ? lookupProperty(depth0,"postingActivationDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"postingActivationDate","hash":{},"data":data,"loc":{"start":{"line":175,"column":21},"end":{"line":175,"column":46}}}) : helper)))
    + "<a\n                        title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.activatedDateInfo",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":176,"column":31},"end":{"line":176,"column":131}}}))
    + "\"\n                        class=\"hide-for-small-only\"><span\n                        class=\"fa fa-info-circle \"></span></a></div>\n            </section>\n            <section>\n                <h4>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.deactivatedOn",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":181,"column":20},"end":{"line":181,"column":67}}}))
    + ":</h4>\n                <div>"
    + alias4(((helper = (helper = lookupProperty(helpers,"postingDeActivationDate") || (depth0 != null ? lookupProperty(depth0,"postingDeActivationDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"postingDeActivationDate","hash":{},"data":data,"loc":{"start":{"line":182,"column":21},"end":{"line":182,"column":48}}}) : helper)))
    + "<a\n                        title=\""
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInfo.deactivatedDateInfo",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":183,"column":31},"end":{"line":183,"column":133}}}))
    + "\"\n                        class=\"hide-for-small-only\"><span\n                        class=\"fa fa-info-circle\"></span></a></div>\n            </section>\n        </section>\n    </div>\n</section><!-- End of study details -->\n\n<!-- Study Contact section -->\n<section class=\"study-contact\">\n    <div class=\"grid-row\">\n        <div class=\"contact-card grid-1\">\n            <h2>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyContact",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":195,"column":16},"end":{"line":195,"column":52}}}))
    + ":</h2>\n            <address>\n                <span class=\"name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"contactName") || (depth0 != null ? lookupProperty(depth0,"contactName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contactName","hash":{},"data":data,"loc":{"start":{"line":197,"column":35},"end":{"line":197,"column":50}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contactPhone") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":198,"column":16},"end":{"line":203,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"email\">\n                    <span class=\"fa fa-envelope-o fa-fw\" aria-hidden=\"true\"></span>\n                    <span class=\"show-for-sr\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.emailLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":206,"column":46},"end":{"line":206,"column":80}}}))
    + ":</span><a data-history-navigate href=\"mailto:"
    + alias4(((helper = (helper = lookupProperty(helpers,"contactEmail") || (depth0 != null ? lookupProperty(depth0,"contactEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contactEmail","hash":{},"data":data,"loc":{"start":{"line":206,"column":126},"end":{"line":206,"column":142}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"contactEmail") || (depth0 != null ? lookupProperty(depth0,"contactEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contactEmail","hash":{},"data":data,"loc":{"start":{"line":206,"column":144},"end":{"line":206,"column":160}}}) : helper)))
    + "</a>\n                </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contactWebsite") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":12},"end":{"line":213,"column":19}}})) != null ? stack1 : "")
    + "            </address>\n        </div>\n    </div>\n</section>\n\n<!-- 'I am interested' Popup -->\n<aside id=\"asideLoginOrCreateAccount\" class=\"login-or-create-account\">\n    <header>\n        <h1 class=\"grid-sm-1\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:account.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":222,"column":30},"end":{"line":222,"column":67}}}))
    + "</h1>\n        <div class=\"top-close-button\">\n            <a data-history-navigate href=\"javascript:void(0);\" class=\"asideLoginOrCreateAccount_close\">\n                <span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span>\n                <span class=\"hide show-for-sr\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":226,"column":47},"end":{"line":226,"column":87}}}))
    + "</span></a>\n        </div>\n    </header>\n    <section class=\"popup-main\">\n        <div class=\"grid-row\">\n            <div class=\"grid-1\">\n                <p>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:account.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":232,"column":19},"end":{"line":232,"column":55}}}))
    + "\n                    <br><br>\n                    <strong>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:account.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":234,"column":28},"end":{"line":234,"column":68}}}))
    + "</strong>\n                </p>\n\n                <nav class=\"yes-no-container\">\n                    <a data-bypass href=\"/login/volunteer?redirectUrl=/volunteers/studies/"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyNum") || (depth0 != null ? lookupProperty(depth0,"studyNum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyNum","hash":{},"data":data,"loc":{"start":{"line":238,"column":90},"end":{"line":238,"column":102}}}) : helper)))
    + "/screening-questions\">\n                        <span class=\"fa fa-check-circle-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:account.yesAnswer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":239,"column":101},"end":{"line":239,"column":142}}}))
    + "\n                    </a>\n                    <a data-history-navigate href=\"javascript:void(0);\" id=\"aSignup\">\n                        <span class=\"fa fa-times-circle-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:account.noAnswer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":242,"column":101},"end":{"line":242,"column":141}}}))
    + "\n                    </a>\n                </nav>\n\n            </div>\n        </div>\n    </section>\n    <footer class=\"text-center\">\n        <a data-history-navigate href=\"/about/volunteers\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:account.accountBenefits",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":250,"column":58},"end":{"line":250,"column":105}}}))
    + "</a>\n    </footer>\n</aside>\n";
},"useData":true,"useDepths":true});