var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"city") : stack1), depth0))
    + ", "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"state") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"zip") : stack1), depth0))
    + "<br>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"no-info\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.personalInfo.noAddress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":39},"end":{"line":48,"column":93}}}))
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <div class=\"no-info\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.personalInfo.noPhone",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":54,"column":39},"end":{"line":54,"column":91}}}))
    + "</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"gender") || (depth0 != null ? lookupProperty(depth0,"gender") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gender","hash":{},"data":data,"loc":{"start":{"line":72,"column":44},"end":{"line":72,"column":54}}}) : helper)))
    + "</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value no-info\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:noInfoProvided",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":74,"column":52},"end":{"line":74,"column":85}}}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n                        <span class=\"label\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"profile:form.section.demographics.pregnancy.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":79,"column":44},"end":{"line":79,"column":104}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"estimatedDueDate") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":80,"column":24},"end":{"line":84,"column":31}}})) != null ? stack1 : "")
    + "                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"estimatedDueDate") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":20},"end":{"line":91,"column":27}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.demographics.pregnancy.answer.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":81,"column":48},"end":{"line":81,"column":113}}}))
    + "</span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.demographics.pregnancy.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":83,"column":48},"end":{"line":83,"column":112}}}))
    + "</span>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li>\n                            <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.demographics.estimatedDueDate.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":88,"column":48},"end":{"line":88,"column":115}}}))
    + "</span>\n                            <span class=\"value\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"estimatedDueDate") || (depth0 != null ? lookupProperty(depth0,"estimatedDueDate") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"estimatedDueDate","hash":{},"data":data,"loc":{"start":{"line":89,"column":48},"end":{"line":89,"column":68}}}) : helper)))
    + "</span>\n                        </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"dateOfBirth") || (depth0 != null ? lookupProperty(depth0,"dateOfBirth") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"dateOfBirth","hash":{},"data":data,"loc":{"start":{"line":96,"column":44},"end":{"line":96,"column":59}}}) : helper)))
    + "</span>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"race") || (depth0 != null ? lookupProperty(depth0,"race") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"race","hash":{},"data":data,"loc":{"start":{"line":104,"column":44},"end":{"line":104,"column":52}}}) : helper)))
    + "</span>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"englishFluency") || (depth0 != null ? lookupProperty(depth0,"englishFluency") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"englishFluency","hash":{},"data":data,"loc":{"start":{"line":112,"column":44},"end":{"line":112,"column":62}}}) : helper)))
    + "</span>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li>\n                        <span class=\"label\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"profile:form.section.demographics.parentOrGuardianOfAChild.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":119,"column":44},"end":{"line":119,"column":119}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noInfoForChildExists") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":120,"column":24},"end":{"line":128,"column":31}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"value no-info\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:noInfoProvided",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":121,"column":56},"end":{"line":121,"column":89}}}))
    + "</span>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parentOrGuardianOfAChild") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":123,"column":28},"end":{"line":127,"column":35}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.demographics.parentOrGuardianOfAChild.answer.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":124,"column":52},"end":{"line":124,"column":132}}}))
    + "</span>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.demographics.parentOrGuardianOfAChild.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":126,"column":52},"end":{"line":126,"column":131}}}))
    + "</span>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"genderIdentity") || (depth0 != null ? lookupProperty(depth0,"genderIdentity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"genderIdentity","hash":{},"data":data,"loc":{"start":{"line":148,"column":44},"end":{"line":148,"column":62}}}) : helper)))
    + "</span>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"genderPronoun") || (depth0 != null ? lookupProperty(depth0,"genderPronoun") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"genderPronoun","hash":{},"data":data,"loc":{"start":{"line":156,"column":44},"end":{"line":156,"column":61}}}) : helper)))
    + "</span>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"weight") || (depth0 != null ? lookupProperty(depth0,"weight") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"weight","hash":{},"data":data,"loc":{"start":{"line":178,"column":44},"end":{"line":178,"column":54}}}) : helper)))
    + " "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.healthInfo.weight.units",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":178,"column":55},"end":{"line":178,"column":110}}}))
    + "</span>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"height") || (depth0 != null ? lookupProperty(depth0,"height") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"height","hash":{},"data":data,"loc":{"start":{"line":186,"column":44},"end":{"line":186,"column":54}}}) : helper)))
    + "</span>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"smokingStatus") || (depth0 != null ? lookupProperty(depth0,"smokingStatus") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"smokingStatus","hash":{},"data":data,"loc":{"start":{"line":194,"column":44},"end":{"line":194,"column":61}}}) : helper)))
    + "</span>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "                            <span class=\"tag\">"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</span>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medicalConditions.currentConditions.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":219,"column":48},"end":{"line":219,"column":125}}}))
    + "</span>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noCurrentConditionInfo") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":220,"column":24},"end":{"line":222,"column":24}}})) != null ? stack1 : "");
},"49":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"value no-info\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:noInfoProvided",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":221,"column":56},"end":{"line":221,"column":89}}}))
    + "</span>\n                        ";
},"51":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medicalConditions.pastConditions.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":232,"column":48},"end":{"line":232,"column":122}}}))
    + "</span>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noPastConditionInfo") : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":233,"column":24},"end":{"line":235,"column":24}}})) != null ? stack1 : "");
},"55":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medicalConditions.metalObjects.answer.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":241,"column":44},"end":{"line":241,"column":117}}}))
    + "</span>\n";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"doesNotHaveMetalImplants") : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":242,"column":20},"end":{"line":246,"column":20}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medicalConditions.metalObjects.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":243,"column":44},"end":{"line":243,"column":116}}}))
    + "</span>\n";
},"60":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value no-info\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:noInfoProvided",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":245,"column":52},"end":{"line":245,"column":85}}}))
    + "</span>\n                    ";
},"62":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medications.medications.answer.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":264,"column":44},"end":{"line":264,"column":110}}}))
    + "</span>\n";
},"64":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notWillingToChangeMeds") : depth0),{"name":"if","hash":{},"fn":container.program(65, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":265,"column":20},"end":{"line":269,"column":20}}})) != null ? stack1 : "");
},"65":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medications.medications.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":266,"column":44},"end":{"line":266,"column":109}}}))
    + "</span>\n";
},"67":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medications.experimentalDrugs.answer.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":274,"column":44},"end":{"line":274,"column":116}}}))
    + "</span>\n";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"notWillingTakeExpDrugs") : depth0),{"name":"if","hash":{},"fn":container.program(70, data, 0),"inverse":container.program(60, data, 0),"data":data,"loc":{"start":{"line":275,"column":20},"end":{"line":279,"column":20}}})) != null ? stack1 : "");
},"70":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.medications.experimentalDrugs.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":276,"column":44},"end":{"line":276,"column":115}}}))
    + "</span>\n";
},"72":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.studyInterests.compensation.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":317,"column":44},"end":{"line":317,"column":117}}}))
    + "</span>\n";
},"74":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.studyInterests.healthyParticipants.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":325,"column":44},"end":{"line":325,"column":124}}}))
    + "</span>\n";
},"76":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.emailNotifications.messageNotificationFrequency.answer.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":347,"column":44},"end":{"line":347,"column":134}}}))
    + "</span>\n";
},"78":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.emailNotifications.messageNotificationFrequency.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":349,"column":44},"end":{"line":349,"column":133}}}))
    + "</span>\n";
},"80":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.profileVisibility.visibility.answer.yes",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":372,"column":44},"end":{"line":372,"column":164}}}))
    + "</span>\n";
},"82":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile:form.section.profileVisibility.visibility.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":374,"column":44},"end":{"line":374,"column":114}}}))
    + "</span>\n";
},"84":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"parentAccount") : stack1),{"name":"if","hash":{},"fn":container.program(85, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":381,"column":13},"end":{"line":400,"column":16}}})) != null ? stack1 : "");
},"85":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div id=\"language-card-feedback\"></div>\n        <section class=\"profile-section-card wow special-section\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.language.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":385,"column":34},"end":{"line":385,"column":82}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-language\">\n                        <span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\"></span>\n                        <span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":389,"column":58},"end":{"line":389,"column":81}}}))
    + "</span>\n                    </a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.language.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":395,"column":40},"end":{"line":395,"column":86}}}))
    + "</span>\n                    <span class=\"value\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.language.answer.preferredLanguage",{"name":"i18n","hash":{"language":(depth0 != null ? lookupProperty(depth0,"language") : depth0)},"data":data,"loc":{"start":{"line":396,"column":40},"end":{"line":396,"column":123}}}))
    + "</span>\n                </li>\n            </ul>\n        </section>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n    <section class=\"grid-sm-1 grid-md-9-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":42}}}))
    + "</h1>\n        </header>\n    </section>\n</div>\n\n<div class=\"error-background\">\n    <div class=\"grid-row\">\n        <div class=\"grid-sm-1 grid-md-9-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n            <article class=\"profile-completeness-section\">\n                <!-- Suggested plugin and script for profile completeness meter (circular) at end of the template -->\n                <div class=\"progressbar\">\n                    <div class=\"progress\" id=\"progress\"></div>\n                </div>\n\n                <div class=\"content\">\n                    <header>\n                        <h1 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:completeness",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":42},"end":{"line":21,"column":73}}}))
    + "</h1>\n                    </header>\n\n                    <div class=\"helptext\">\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:completenessInfo",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":59}}}))
    + "\n                    </div>\n                </div>\n            </article>\n        </div>\n    </div>\n</div>\n\n<div class=\"grid-row margin-top\">\n    <div class=\"grid-sm-1 grid-md-9-12 grid-md-centered grid-lg-8-12 grid-lg-centered profile-sections-list\">\n        <div id=\"contact-info-card-feedback\"></div>\n        <address class=\"contact-card action-icon fadeIn\">\n            <header>\n                <h1 class=\"gamma\">"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-contact-info\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n                    </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":61},"end":{"line":41,"column":84}}}))
    + "</span></a>\n                </div>\n            </header>\n            <div class=\"content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"city") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":45,"column":16},"end":{"line":49,"column":23}}})) != null ? stack1 : "")
    + "                "
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + " <br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":55,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </address>\n\n        <div id=\"demographics-card-feedback\"></div>\n        <section class=\"profile-section-card wow\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.demographics.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":62,"column":34},"end":{"line":62,"column":86}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-demographics\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n                    </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":65,"column":61},"end":{"line":65,"column":84}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.demographics.gender.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":70,"column":40},"end":{"line":70,"column":97}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"gender") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":71,"column":20},"end":{"line":75,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPregnancyField") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":77,"column":16},"end":{"line":92,"column":23}}})) != null ? stack1 : "")
    + "                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.demographics.dob.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":94,"column":40},"end":{"line":94,"column":94}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dateOfBirth") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":95,"column":20},"end":{"line":99,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.demographics.race.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":102,"column":40},"end":{"line":102,"column":95}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"race") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":103,"column":20},"end":{"line":107,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.demographics.fluency.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":110,"column":40},"end":{"line":110,"column":98}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"englishFluency") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":111,"column":20},"end":{"line":115,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"parentAccount") : stack1),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":16},"end":{"line":130,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </section>\n\n        <div id=\"genderIdentity-card-feedback\"></div>\n\n        <section class=\"profile-section-card wow\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":138,"column":34},"end":{"line":138,"column":88}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-genderIdentity\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n                    </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":141,"column":61},"end":{"line":141,"column":84}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.identity.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":146,"column":40},"end":{"line":146,"column":101}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"genderIdentity") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":147,"column":20},"end":{"line":151,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.pronoun.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":154,"column":40},"end":{"line":154,"column":100}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"genderPronoun") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":155,"column":20},"end":{"line":159,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n            </ul>\n        </section>\n\n\n        <div id=\"health-info-card-feedback\"></div>\n        <section class=\"profile-section-card wow\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.healthInfo.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":168,"column":34},"end":{"line":168,"column":84}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-health-info\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n                    </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":171,"column":61},"end":{"line":171,"column":84}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.healthInfo.weight.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":176,"column":40},"end":{"line":176,"column":95}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"weight") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":177,"column":20},"end":{"line":181,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.healthInfo.height.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":184,"column":40},"end":{"line":184,"column":95}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"height") : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":185,"column":20},"end":{"line":189,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.healthInfo.smokingStatus.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":192,"column":40},"end":{"line":192,"column":102}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"smokingStatus") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":193,"column":20},"end":{"line":197,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n            </ul>\n        </section>\n\n        <div id=\"medical-conditions-card-feedback\"></div>\n        <section class=\"profile-section-card wow\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":205,"column":34},"end":{"line":205,"column":91}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-med-conditions\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n          </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":208,"column":51},"end":{"line":208,"column":74}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.currentConditions.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":213,"column":40},"end":{"line":213,"column":113}}}))
    + "</span>\n                    <span class=\"value\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"currentConditions") : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":215,"column":24},"end":{"line":217,"column":33}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noCurrentCondition") : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data,"loc":{"start":{"line":218,"column":24},"end":{"line":222,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.pastConditions.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":226,"column":40},"end":{"line":226,"column":110}}}))
    + "</span>\n                    <span class=\"value\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pastConditions") : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":228,"column":24},"end":{"line":230,"column":33}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noPastCondition") : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.program(53, data, 0),"data":data,"loc":{"start":{"line":231,"column":24},"end":{"line":235,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.metalObjects.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":239,"column":40},"end":{"line":239,"column":108}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMetalImplants") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.program(57, data, 0),"data":data,"loc":{"start":{"line":240,"column":20},"end":{"line":246,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n            </ul>\n        </section>\n\n        <div id=\"medications-card-feedback\"></div>\n        <section class=\"profile-section-card wow\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medications.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":254,"column":34},"end":{"line":254,"column":85}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-medications\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n          </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":257,"column":51},"end":{"line":257,"column":74}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medications.medications.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":262,"column":40},"end":{"line":262,"column":101}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"willingToChangeMeds") : depth0),{"name":"if","hash":{},"fn":container.program(62, data, 0),"inverse":container.program(64, data, 0),"data":data,"loc":{"start":{"line":263,"column":20},"end":{"line":269,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medications.experimentalDrugs.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":272,"column":40},"end":{"line":272,"column":107}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"willingTakeExpDrugs") : depth0),{"name":"if","hash":{},"fn":container.program(67, data, 0),"inverse":container.program(69, data, 0),"data":data,"loc":{"start":{"line":273,"column":20},"end":{"line":279,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n            </ul>\n        </section>\n\n        <div id=\"study-interests-card-feedback\"></div>\n        <section class=\"profile-section-card wow\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":287,"column":34},"end":{"line":287,"column":88}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-study-interests\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n          </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":290,"column":51},"end":{"line":290,"column":74}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.topics.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":295,"column":40},"end":{"line":295,"column":99}}}))
    + "</span>\n                    <span class=\"value\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"studyCriterionMedicalConditions") : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":297,"column":24},"end":{"line":301,"column":33}}})) != null ? stack1 : "")
    + "                    </span>\n                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.locations.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":305,"column":40},"end":{"line":305,"column":102}}}))
    + "</span>\n                    <span class=\"value\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"studyCriterionStudyLocations") : depth0),{"name":"each","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":307,"column":24},"end":{"line":311,"column":33}}})) != null ? stack1 : "")
    + "                    </span>\n                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.compensation.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":315,"column":40},"end":{"line":315,"column":105}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"OffersCompensation") : depth0),{"name":"if","hash":{},"fn":container.program(72, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":316,"column":20},"end":{"line":320,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.healthyParticipants.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":323,"column":40},"end":{"line":323,"column":112}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"typeOfResearchPatient") : depth0),{"name":"if","hash":{},"fn":container.program(74, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":324,"column":20},"end":{"line":328,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n            </ul>\n        </section>\n\n        <div id=\"email-notification-card-feedback\"></div>\n        <section class=\"profile-section-card wow special-section\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":336,"column":34},"end":{"line":336,"column":92}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-email-notifications\"><span class=\"fa fa-pencil fa-margin-right\"\n                                                                 aria-hidden=\"true\">\n          </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":340,"column":51},"end":{"line":340,"column":74}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.messageNotificationFrequency.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":345,"column":40},"end":{"line":345,"column":125}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"newMessageNotification") : depth0),{"name":"if","hash":{},"fn":container.program(76, data, 0),"inverse":container.program(78, data, 0),"data":data,"loc":{"start":{"line":346,"column":20},"end":{"line":350,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":353,"column":40},"end":{"line":353,"column":132}}}))
    + "</span>\n                    <span class=\"value\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.answer",{"name":"i18n","hash":{"recommendationNotificationFrequency":(depth0 != null ? lookupProperty(depth0,"recommendationNotificationFrequency") : depth0)},"data":data,"loc":{"start":{"line":354,"column":40},"end":{"line":354,"column":205}}}))
    + "</span>\n                </li>\n            </ul>\n        </section>\n\n        <div id=\"visibility-card-feedback\"></div>\n        <section class=\"profile-section-card wow special-section\">\n            <header>\n                <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.profileVisibility.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":362,"column":34},"end":{"line":362,"column":91}}}))
    + "</h1>\n                <div class=\"edit-button\">\n                    <a data-history-navigate href=\"/volunteers/profile/profile-visibility\"><span class=\"fa fa-pencil fa-margin-right\" aria-hidden=\"true\">\n          </span><span class=\"hide-for-small-only\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:edit",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":365,"column":51},"end":{"line":365,"column":74}}}))
    + "</span></a>\n                </div>\n            </header>\n            <ul class=\"content\">\n                <li>\n                    <span class=\"label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.profileVisibility.visibility.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":370,"column":40},"end":{"line":370,"column":106}}}))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"visibleToStudyTeams") : depth0),{"name":"if","hash":{},"fn":container.program(80, data, 0),"inverse":container.program(82, data, 0),"data":data,"loc":{"start":{"line":371,"column":20},"end":{"line":375,"column":27}}})) != null ? stack1 : "")
    + "                </li>\n            </ul>\n        </section>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"multipleLangaugeAvailable") : depth0),{"name":"if","hash":{},"fn":container.program(84, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":380,"column":9},"end":{"line":401,"column":16}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});