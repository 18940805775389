/**
 * Represents an age range.
 * (Note: In many places we simply use plain objects {min, max}
 * so you may consider eliminating this constructor if not needed.)
 */
function AgeRange(min, max) {
	this.min = min;
	this.max = max;
}

AgeRange.prototype.isValid = function () {
	return this.min <= this.max;
};

/**
 * The AgeRangeParser is responsible for parsing eligibility criteria
 * into one or more age ranges.
 */
function AgeRangeParser() {}

/**
 * Parses an array of eligibility criteria objects and returns
 * an array of age ranges.
 */
/**
 * Parses an array of eligibility criteria objects and returns
 * an array of age ranges.
 */
AgeRangeParser.prototype.parseAgeRangeFromCriteria = function (eligibilityCriteria) {
	var ageExpressionCounter = 0;
	var ageRanges = [];

	if (!Array.isArray(eligibilityCriteria)) {
		return ageRanges;
	}

	for (var i = 0; i < eligibilityCriteria.length; i++) {
		var criterion = eligibilityCriteria[i];
		if (!criterion || !Array.isArray(criterion.clauses)) {
			continue;
		}

		for (var j = 0; j < criterion.clauses.length; j++) {
			var clause = criterion.clauses[j];
			if (!clause || !Array.isArray(clause.expressions)) {
				continue;
			}

			var ageRangeExpressionsInClause = { inclusions: [], exclusions: [] };

			for (var k = 0; k < clause.expressions.length; k++) {
				var expression = clause.expressions[k];
				if (!expression || !expression.variable || !expression.value || expression.variable.name !== 'AGE') {
					continue;
				}
				ageExpressionCounter++;

				var current = this.parseAgeRange(expression.value.savedValue, expression.operator);

				if (expression.operator.indexOf('NOT_') > -1) {
					this.handleExclusion(ageRangeExpressionsInClause, current);
				} else {
					this.handleInclusion(ageRangeExpressionsInClause, current);
				}
			} // end expressions loop

			this.processClauseAgeRanges(ageRangeExpressionsInClause, ageRanges);

		} // end clauses loop
	} // end criteria loop

	if (ageExpressionCounter === 0) {
		return [{ min: -Infinity, max: Infinity }];
	}

	return this.combineAgeRanges(ageRanges);
};

AgeRangeParser.prototype.handleExclusion = function (ageRangeExpressionsInClause, current) {
	var previousInclusion = ageRangeExpressionsInClause.inclusions.pop();
	if (previousInclusion) {
		var subtracted = this.subtractAgeRange(previousInclusion, current);
		for (var m = 0; m < subtracted.length; m++) {
			ageRangeExpressionsInClause.inclusions.push(subtracted[m]);
		}
	} else {
		ageRangeExpressionsInClause.exclusions.push(current);
	}
};

AgeRangeParser.prototype.handleInclusion = function (ageRangeExpressionsInClause, current) {
	var previousExclusion = ageRangeExpressionsInClause.exclusions.pop();
	if (previousExclusion) {
		var subtracted = this.subtractAgeRange(current, previousExclusion);
		for (var m = 0; m < subtracted.length; m++) {
			ageRangeExpressionsInClause.inclusions.push(subtracted[m]);
		}
	} else {
		ageRangeExpressionsInClause.inclusions.push(current);
	}
};

AgeRangeParser.prototype.processClauseAgeRanges = function (ageRangeExpressionsInClause, ageRanges) {
	if (ageRangeExpressionsInClause.inclusions.length > 0 || ageRangeExpressionsInClause.exclusions.length > 0) {
		if (ageRangeExpressionsInClause.exclusions.length === 0 && ageRangeExpressionsInClause.inclusions.length > 0) {
			ageRanges.push.apply(ageRanges, ageRangeExpressionsInClause.inclusions); // Use apply for array push
		} else if (ageRangeExpressionsInClause.inclusions.length === 0 && ageRangeExpressionsInClause.exclusions.length > 0) {
			var inclusions = this.convertExclusionsToInclusions(ageRangeExpressionsInClause.exclusions);
			ageRanges.push.apply(ageRanges, inclusions); // Use apply for array push
		} else {
			throw new Error(
				'Unexpected error. Each criterion expression clause age ranges should be reduced ' +
				'either to only exclusions or inclusions but cannot have both.'
			);
		}
	}
};

AgeRangeParser.prototype.convertExclusionsToInclusions = function (exclusions) {
	var allInclusions = [];
	for (var i = 0; i < exclusions.length; i++) {
		var inclusions = this.convertExclusionToInclusion(exclusions[i]);
		allInclusions = allInclusions.concat(inclusions); // Use concat to combine arrays
	}
	return allInclusions;
};

/**
 * Converts an exclusion range into one or two inclusion ranges.
 * For example, if the exclusion is between 10 and 20, then the inclusions
 * become (-Infinity, 9) and (21, Infinity) where applicable.
 */
AgeRangeParser.prototype.convertExclusionToInclusion = function (exclusion) {
	var inclusions = [];

	if (exclusion.min > -Infinity) {
		inclusions.push({ min: -Infinity, max: exclusion.min - 1 });
	}
	if (exclusion.max < Infinity) {
		inclusions.push({ min: exclusion.max + 1, max: Infinity });
	}

	return inclusions;
};

/**
 * Combines overlapping or adjacent age ranges into a single range.
 */
AgeRangeParser.prototype.combineAgeRanges = function (ranges) {
	var unique = removeDuplicateAgeRanges(ranges);
	var sortedUnique = sortAgeRanges(unique);
	var merged = [];

	for (var i = 0; i < sortedUnique.length; i++) {
		var current = sortedUnique[i];

		if (merged.length === 0) {
			merged.push({ min: current.min, max: current.max });
		} else {
			var last = merged[merged.length - 1];
			// Merge ranges that overlap or are adjacent (assuming integer ages).
			if (current.min <= last.max + 1) {
				last.max = Math.max(current.max, last.max);
			} else {
				merged.push({ min: current.min, max: current.max });
			}
		}
	}
	return merged;
};

function removeDuplicateAgeRanges(ranges) {
	var unique = [];
	for (var i = 0; i < ranges.length; i++) {
		var r = ranges[i];
		var duplicate = false;
		for (var j = 0; j < unique.length; j++) {
			if (unique[j].min === r.min && unique[j].max === r.max) {
				duplicate = true;
				break;
			}
		}
		if (!duplicate) {
			unique.push({ min: r.min, max: r.max });
		}
	}
	return unique;
}

/**
 * Sorts age ranges in ascending order by min (then by max).
 */
function sortAgeRanges(ranges) {
	return ranges.sort(function (a, b) {
		if (a.min !== b.min) {
			return a.min - b.min;
		}
		return a.max - b.max;
	});
}

/**
 * Parses a string value and an operator into an age range.
 * For example, for BETWEEN the value is expected in the form "min:max".
 */
AgeRangeParser.prototype.parseAgeRange = function (value, operator) {
	var parts, num;
	if (operator === 'BETWEEN' || operator === 'NOT_BETWEEN') {
		parts = value.split(':');
		return { min: parseInt(parts[0], 10), max: parseInt(parts[1], 10) };
	}

	num = parseInt(value, 10);
	switch (operator) { // Use a switch statement for cleaner logic
	case 'LESS_THAN':
	case 'NOT_LESS_THAN':
		return { min: -Infinity, max: num - 1 };
	case 'LESS_THAN_OR_EQUAL':
	case 'NOT_LESS_THAN_OR_EQUAL':
		return { min: -Infinity, max: num };
	case 'GREATER_THAN':
	case 'NOT_GREATER_THAN':
		return { min: num + 1, max: Infinity };
	case 'GREATER_THAN_OR_EQUAL':
	case 'NOT_GREATER_THAN_OR_EQUAL':
		return { min: num, max: Infinity };
	default:
		return null;
	}
};

AgeRangeParser.prototype.subtractAgeRange = function (inclusionRange, exclusionRange) {
	validateAgeRanges(inclusionRange, exclusionRange);

	if (areRangesIdentical(inclusionRange, exclusionRange)) {
		return [];
	}

	// If there is no overlap, return the original inclusion.
	if (areRangesDisjoint(inclusionRange, exclusionRange)) {
		return [inclusionRange];
	}

	if (exclusionOverlapsStartOfInclusion(inclusionRange, exclusionRange)) {
		return [{ min: exclusionRange.max + 1, max: inclusionRange.max }];
	}

	if (exclusionOverlapsEndOfInclusion(inclusionRange, exclusionRange)) {
		return [{ min: inclusionRange.min, max: exclusionRange.min - 1 }];
	}

	if (inclusionContainsExclusion(inclusionRange, exclusionRange)) {
		return [
			{ min: inclusionRange.min, max: exclusionRange.min - 1 },
			{ min: exclusionRange.max + 1, max: inclusionRange.max }
		];
	}

	return [];
};

function validateAgeRanges(inclusion, exclusion) {
	if (!validAgeRange(inclusion)) {
		throw new Error('Inclusion age range is invalid: ' + JSON.stringify(inclusion));
	}
	if (!validAgeRange(exclusion)) {
		throw new Error('Exclusion age range is invalid: ' + JSON.stringify(exclusion));
	}
}

function validAgeRange(ageRange) {
	return ageRange.min <= ageRange.max;
}

function areRangesIdentical(range1, range2) {
	return range1.min === range2.min && range1.max === range2.max;
}

function areRangesDisjoint(range1, range2) {
	return range1.min > range2.max || range1.max < range2.min;
}

/**
 * Tests if range overlap is such that
 * inclusion: _____***************_____
 * exclusion: ***************__________
 */
function exclusionOverlapsStartOfInclusion(inclusion, exclusion) {
	return exclusion.min <= inclusion.min && exclusion.max >= inclusion.min && exclusion.max < inclusion.max;
}
/**
 * Tests if range overlap is such that
 * inclusion: _____***************_____
 * exclusion: ________*********________
 */
function inclusionContainsExclusion(inclusion, exclusion) {
	return exclusion.min > inclusion.min && exclusion.min < inclusion.max &&
		exclusion.max > inclusion.min && exclusion.max < inclusion.max;
}
/**
 * Tests if range overlap is such that
 * inclusion: _____***************_______
 * exclusion: _____________***************
 */
function exclusionOverlapsEndOfInclusion(inclusion, exclusion) {
	return exclusion.min > inclusion.min && exclusion.min <= inclusion.max && exclusion.max >= inclusion.max;
}

module.exports = new AgeRangeParser();
