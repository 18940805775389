var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"study-details-overview inactive-study\">\n  <section class=\"grid-row study-header\">\n    <div class=\"grid-sm-1\">\n            <div class=\"grid-row\">\n              <section class=\"grid-sm-1 grid-md-10-12 grid-lg-8-12 grid-centered text-center\">\n                  <section class=\"media extra-large-font-size\">\n                    <span class=\"fa fa-exclamation-triangle\"></span>\n                  </section>\n                  <section class=\"content\">\n                    <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInactive.notRecruiting",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":75}}}))
    + "</h1>\n                    <div class=\"gamma\">\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInactive.reason",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":68}}}))
    + "\n                    </div>\n                  </section>\n              </section>\n            </div>\n\n            <div class=\"stylized-divider\" aria-hidden=\"true\">\n            </div>\n            \n      			<section class=\"grid-row text-center margin-top\">\n      				<div class=\"grid-sm-1\">\n                <a data-history-navigate href=\"/\" class=\"\"><span class=\"fa fa-home fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyDetails:studyInactive.backToHome.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":126},"end":{"line":23,"column":184}}}))
    + "</a>\n        			</div>\n      		</section>\n    </div>\n  </section>\n</div>\n";
},"useData":true});