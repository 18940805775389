(function() {
	'use strict';
	var Backbone = require('backbone');
	var MessageModel= require('../models/model.message.js');
	module.exports = Backbone.Collection.extend({

		initialize: function (models, options) {
			if (options) {
				this.studyVolunteerId = options.studyVolunteerId;
			}
		},
		url: function () {
			return '/backend/secure/conversations/' + this.studyVolunteerId;
		},

		model: MessageModel
	});
})();

