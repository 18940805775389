var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parent") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <label for=\"radioLovedOne"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":19,"column":49},"end":{"line":19,"column":59}}}) : helper)))
    + "\" class=\" full-line-radio secondary-font-color\">\n                            <input id=\"radioLovedOne"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":20,"column":52},"end":{"line":20,"column":62}}}) : helper)))
    + "\" type=\"radio\" name=\"userId\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":20,"column":98},"end":{"line":20,"column":108}}}) : helper)))
    + "\" checked=\"checked\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.form.field.deactivationList.allPeople",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":128},"end":{"line":20,"column":210}}}))
    + "\n                        </label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <label for=\"radioLovedOne"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":23,"column":49},"end":{"line":23,"column":59}}}) : helper)))
    + "\" class=\" full-line-radio secondary-font-color\">\n                            <input id=\"radioLovedOne"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":24,"column":52},"end":{"line":24,"column":62}}}) : helper)))
    + "\" type=\"radio\" name=\"userId\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":24,"column":98},"end":{"line":24,"column":108}}}) : helper)))
    + "\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"fullName") || (depth0 != null ? lookupProperty(depth0,"fullName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"fullName","hash":{},"data":data,"loc":{"start":{"line":24,"column":111},"end":{"line":24,"column":123}}}) : helper)))
    + "\n                        </label>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":35,"column":39},"end":{"line":35,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"displayText") || (depth0 != null ? lookupProperty(depth0,"displayText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayText","hash":{},"data":data,"loc":{"start":{"line":35,"column":47},"end":{"line":35,"column":62}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n    <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <nav class=\"text-center\">\n                <a data-history-navigate href=\"/volunteers/account-settings\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":77},"end":{"line":6,"column":109}}}))
    + "</a> <span class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span> <span\n                    class=\"show-for-sr\">is parent of</span>\n            </nav>\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":71}}}))
    + "</h1>\n            <p class=\"secondary-font-color text-center\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.form.desc",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":119}}}))
    + "\n            </p>\n        </header>\n        <form class=\"form-stacked\" id=\"formDeactivateAccount\">\n            <div class=\"field-container\" id=\"divLovedOnes\" style=\"display: none;\">\n                <label id=\"labelDeactivateWho\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.form.field.deactivationList.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":47},"end":{"line":16,"column":125}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"lovedOnes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":27,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"field-container\">\n                <label id=\"labelDeactivationReason\" for=\"selectDeactivationReason\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.form.field.deactivationReason.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":83},"end":{"line":31,"column":163}}}))
    + "</label>\n                <select id=\"selectDeactivationReason\" name=\"reason\" class=\"input-1\">\n                    <option></option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"reasons") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":20},"end":{"line":36,"column":29}}})) != null ? stack1 : "")
    + "                </select>\n            </div>\n\n            <button type=\"submit\" class=\"primary-button full-width-button\"\n                    value=\"EMAIL RESET LINK\"><span class=\"fa fa-power-off fa-fw fa-margin-right\"\n                                                   aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.deactivate.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":77},"end":{"line":42,"column":144}}}))
    + "\n            </button>\n\n            <div class=\"secondary-font-style text-center margin-top-small\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.deactivate.desc",{"name":"i18n","hash":{"supportPhoneNumber":((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportPhoneNumber") : stack1)},"data":data,"loc":{"start":{"line":46,"column":16},"end":{"line":46,"column":126}}}))
    + "\n            </div>\n        </form>\n    </section>\n</div>\n\n<aside class=\"\" id=\"asideConfirmAccountDeletion\">\n    <header>\n        <h1 class=\"grid-sm-1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.popup.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":54,"column":30},"end":{"line":54,"column":86}}}))
    + "</h1>\n        <div class=\"top-close-button\">\n            <a data-history-navigate href=\"javascript:void(0);\" class=\"asideConfirmAccountDeletion_close\"><span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span><span\n                    class=\"hide show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:close.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":57,"column":45},"end":{"line":57,"column":78}}}))
    + "</span></a>\n        </div>\n    </header>\n    <section class=\"popup-main\">\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.popup.info",{"name":"i18n","hash":{"supportPhoneNumber":((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportPhoneNumber") : stack1),"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":62,"column":166}}}))
    + "<br/><br/>\n            <strong>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.popup.confirmationQuestion",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":63,"column":20},"end":{"line":63,"column":91}}}))
    + "</strong>\n        </p>\n        <div class=\"button-container text-center\">\n            <a data-history-navigate href=\"javascript:void(0)\" class=\"error-font-color margin-right-small asideConfirmAccountDeletion_close\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.popup.noOption.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":66,"column":141},"end":{"line":66,"column":210}}}))
    + "</a>\n            <a data-history-navigate id=\"aConfirmDeactivateAccount\" class=\"primary-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.popup.yesOption.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":91},"end":{"line":67,"column":163}}}))
    + "</a>\n        </div>\n    </section>\n</aside>\n";
},"useData":true});