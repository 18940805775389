(function () {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		url: function () {
			return '/backend/public/study-interest-stats?study-number='+this.get('studyNumber');
		}
	});
	module.exports = Model;
})();

