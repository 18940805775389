(function() {
	'use strict';
	var template = require('../../templates/hbs/study.card.user.rec.hbs');
	var StudyCardView = require('./view.study.card.js');
	var StudyCardModel = require('../models/model.study.card.js');
	var html = require('../../../../shared-scripts/app-util/html');

	module.exports = Backbone.View.extend({
		tagName: 'li',

		initialize: function() {
			this.subViews = {};
			var studyCardModel = new StudyCardModel(this.model.get('study'));
			studyCardModel.set('new', this.model.get('isNew'));
			this.subViews.studyCardView = new StudyCardView({model: studyCardModel, onStudiesForYouPage: true});
		},

		generateTemplateData: function() {
			var templateData = {};

			var messages = [];

			$.each(this.model.get('messages'), function(index, message) {
				var recommendingUserFullName = message.recommendingUserFullName;
				var messageBody = ' ';
				if(message.message){
					messageBody = html.asSimpleMarkup(message.message);
				}
				messages.push({recommendingUserFullName: recommendingUserFullName, messageBody: messageBody});
			});

			templateData.messages = messages;
			return templateData;
		},
		render: function() {
			this.$el.html(template(this.generateTemplateData()));
			this.renderSubviewAppend(this.$el.find('#studyCardContainer'), 'studyCardContainer', this.subViews.studyCardView);

			return this;
		}
	}
	);
})();
