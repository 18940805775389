
(function() {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		getLokupValue: function(lookupValueName){
			return _.find(this.get('lookupValues'), function(lookupValue){
				return lookupValue.name===lookupValueName;
			});
		}
	});
	module.exports = Model;
})();

