var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"show-for-sr\">Main Navigation</h1>\n\n<div class=\"account-nav desktop-only\">\n    <nav class=\"loved-one-nav\">\n        <span class=\"logged-in-as-info\">Logged in as a study team member</span>\n    </nav>\n    <nav class=\"logout\">\n        <a data-history-navigate id=\"aLogout\" href=\"javascript:void(0);\">\n            <span class=\"fa fa-sign-out fa-margin-right\" aria-hidden=\"true\"></span>\n            Log out\n        </a>\n    </nav>\n</div>\n\n<div class=\"main-menu-nav\">\n    <nav class=\"grid-row grid-centered\">\n        <div class=\"grid-md-1-3 desktop-only\">\n            <a data-history-navigate href=\"/\" class=\"logo-small\">\n                <span class=\"show-for-sr\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1), depth0))
    + " logo</span>\n            </a>\n        </div>\n        <div class=\"grid-md-1-6 desktop-only\">\n        </div>\n        <div class=\"grid-md-1-6 desktop-only\">\n        </div>\n        <div class=\"grid-md-1-6 desktop-only\">\n        </div>\n        <div class=\"grid-sm-1-2 grid-md-1-6 nav-tile\">\n            <a data-bypass href=\"/study-team/studies/current\">\n            <span class=\"fa fa-list-ol\" aria-hidden=\"true\"></span>\n            Manage Studies\n            </a>\n        </div>\n        <div class=\"grid-sm-1-2 grid-md-1-6 nav-tile mobile-only\">\n        <a data-history-navigate id=\"aLogout\" href=\"javascript:void(0);\">\n        <span class=\"fa fa-sign-out fa-margin-right\" aria-hidden=\"true\"></span>\n        Log out\n        </a>\n        </div>\n    </nav>\n</div>\n";
},"useData":true});