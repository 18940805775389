(function () {
	'use strict';

	var template = require('~institution-specific-config/footer.hbs');
	var userAgreementModule = require('../modules/module.user.agreement');
	var appConfig = require('~institution-specific-config/app.config.json');

	module.exports = Backbone.View.extend({
		el: '#sectionFooter',
		events: {
			'click #aUserAgreementFooter': 'openUserAgreement'
		},

		render: function () {
			var templateData = {
				currentYear: new Date().getFullYear(),
				organizationInfo: appConfig.organizationInfo,
				supportInfo: appConfig.supportInfo
			};

			this.$el.html(template(templateData));
			return this;
		},

		openUserAgreement: function () {
			userAgreementModule.showUserAgreement({userAgreementType: 'VOL'});
		}
	});
})();
