(function() {
	'use strict';

	var template = require('../../templates/hbs/forgot.password.hbs');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var csrfModule = require('../../../../shared-scripts/modules/module.csrf');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	var View = Backbone.View.extend({
		el: 'main',
		events: {
			'submit #formForgotPassword': 'emailPasswordResetLink'
		},

		initialize: function() {
			this.subViews = {};
		},

		emailPasswordResetLink: function(event) {
			var thisView = this;
			event.preventDefault();

			var resetPasswordToken = {
				'userName': $('#emailUsername').val()
			};
			csrfModule(function () {
				$.ajax({
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					type: 'POST',
					url: '/backend/volunteers/forgot-password-confirmations',
					data: JSON.stringify(resetPasswordToken),
					success: function () {
						$.ajax({
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json'
							},
							type: 'GET',
							url: '/backend/application-settings?name=RESET_PASSWORD_TOKEN_GRACE_PERIOD',
							success: function(data) {
								feedbackModule.showFeedback(thisView,'EMAIL_PASSWORD_RESET_LINK_SUCCESS',
									[{name: 'RESET_PASSWORD_TOKEN_GRACE_PERIOD', value: data[0].value}]);
							}
						});
					},
					error: function (jqxhr) {
						if (jqxhr.status === 400) {
							feedbackModule.showFeedback(thisView,'EMAIL_PASSWORD_RESET_LINK_FAILURE');
						}
					}
				});
			});
		},

		render: function() {
			this.$el.html(template());
			this.$el.find('#formForgotPassword').parsley(parsleyConfig);
			return this;
		}
	}
	);
	module.exports = View;
})();
