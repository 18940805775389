(function () {

	'use strict';

	var PopupModule = function () {
		var containers = {};
		this.initializePopup = function (popupContentContainer, popupConfig) {
			containers[popupContentContainer.attr('id')] = popupContentContainer;

			var defaultConfig = {
				scrolllock: true,
				blur: false,
				escape: false
			};

			return popupContentContainer.popup(_.extend(defaultConfig, popupConfig));
		};

		this.showPopup = function (popupContentContainer, confirmButton, confirmCallback, callbackParam) {
			if (confirmButton) {
				confirmButton.off('click');
			}
			var $popup = popupContentContainer.popup ? popupContentContainer : containers[popupContentContainer];
			$popup.popup('show');
			if (confirmButton) {
				confirmButton.on('click', callbackParam, confirmCallback);
			}
		};

		this.closePopup = function (popupContentContainer) {
			popupContentContainer.popup('hide');
		};

		function reEnableScrolling(popupContainerId) {
			if (popupContainerId === 'asideFiltersContainer') {
				var $body =$('body');
				setTimeout(function () {
					$body.css({
						overflow: 'visible',
						'margin-right': ''
					});
				}, 10); // 10ms added for CSS transition in Firefox which doesn't like overflow:auto
			}
		}

		this.destroyPopup = function (popupContainerId) {
			reEnableScrolling(popupContainerId);
			$('#' + popupContainerId).popup('hide'); //trigger jquery popup hide to clear the data
			$('#' + popupContainerId + '_wrapper').remove(); // remove the popup wrapper
			$('#' + popupContainerId + '_background').remove(); //remove the popup overlay
			$(document).off('click.jqp', '.' + popupContainerId + '_open');
		};
	};

	module.exports = new PopupModule();
})();
