var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n  <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n    <header class=\"page-header\">\n        <nav class=\"text-center\">\n          <a data-history-navigate href=\"/volunteers/account-settings\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":71},"end":{"line":5,"column":103}}}))
    + "</a> <span class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">is parent of</span>\n        </nav>\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changePassword.form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":64}}}))
    + "</h1>\n    </header>\n        <div id=\"divFeedback\"></div>\n        <form class=\"form-stacked\" id=\"formChangePassword\">\n        <input type=\"hidden\" id=\"hiddenParentUserId\" name=\"parentUserId\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"PARENT_USER_ID") || (depth0 != null ? lookupProperty(depth0,"PARENT_USER_ID") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"PARENT_USER_ID","hash":{},"data":data,"loc":{"start":{"line":11,"column":80},"end":{"line":11,"column":98}}}) : helper)))
    + "\">\n            <div class=\"field-container validation-error-container\">\n                <label id=\"labelOriginalPassword\" for=\"passwordOriginalPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changePassword.form.field.currentPassword.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":81},"end":{"line":13,"column":155}}}))
    + "</label>\n                <input class=\"input-1\" id=\"passwordOriginalPassword\" type=\"password\" name=\"oldPassword\" required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:requiredField",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":141},"end":{"line":14,"column":179}}}))
    + "\">\n            </div>\n\n            <div class=\"field-container validation-error-container\">\n                <label id=\"labelNewPassword\" for=\"passwordNewPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changePassword.form.field.newPassword.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":71},"end":{"line":18,"column":141}}}))
    + "</label>\n                <div class=\"field-help-text\">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"passwordDescription",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":50}}}))
    + "\n                </div>\n                <input class=\"input-1\" id=\"passwordNewPassword\" type=\"password\" name=\"newPassword\" required\n                       data-parsley-pattern=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"PASSWORD_REGEX") || (depth0 != null ? lookupProperty(depth0,"PASSWORD_REGEX") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"PASSWORD_REGEX","hash":{},"data":data,"loc":{"start":{"line":23,"column":45},"end":{"line":23,"column":63}}}) : helper)))
    + "\"\n                       data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:passwordDoesNotMeetCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":51},"end":{"line":24,"column":103}}}))
    + "\">\n                <label class=\"field-help-text\">\n                    <input type=\"checkbox\" id=\"checkboxShowPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changePassword.form.field.showPassword.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":69},"end":{"line":26,"column":140}}}))
    + "\n                </label>\n            </div>\n\n            <button type=\"submit\" class=\"primary-button full-width-button\" id=\"btnPasswordResetLink\"\n                    value=\"EMAIL RESET LINK\"><span class=\"fa fa-key fa-fw fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changePassword.changePassword.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":117},"end":{"line":31,"column":185}}}))
    + "\n            </button>\n\n            <div class=\"secondary-font-style text-center margin-top-small\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changePassword.changePassword.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":77}}}))
    + "\n            </div>\n\n        </form>\n\n    </section>\n</div>\n";
},"useData":true});