require('cookieconsent');
var i18n = require('../../../shared-scripts/app-util/i18n');
var cookieName = 'cookie_consent';

var loadCookieConsent = function () {
	window.cookieconsent.initialise({
		palette: {
		},
		cookie: {
			name: cookieName,
		},
		type: 'info',
		content: {
			href: '/privacy',
			target: '',
			policy: '',
			message: i18n.t('cookieConsent:banner.message'),
			link: i18n.t('cookieConsent:banner.link.label'),
			dismiss: i18n.t('cookieConsent:banner.button.dismiss.label'),
		},
		onPopupOpen: function() {
			enableCookies();
		},
		onInitialise: function () {
			enableCookies();
		},
		onStatusChange: function () {
			enableCookies();
		}
	});
};

function enableCookies() {
	var tagManagerHead = typeof TAG_HEAD === 'undefined' ? '' : TAG_HEAD;
	var tagManagerBody = typeof TAG_BODY === 'undefined' ? '' : TAG_BODY;

	if (tagManagerHead && tagManagerBody) {
		writeNewTag('head', 'script', 'scriptGTM', tagManagerHead);
		writeNewTag('body', 'noscript', 'noscriptGTM', tagManagerBody);
	}
}

function writeNewTag(targetTag, newTag, id, content) {
	if (!document.getElementById(id)) {
		var target = document.querySelector(targetTag);
		var newElement = document.createElement(newTag);
		newElement.setAttribute('id', id);
		var inlineText = document.createTextNode(content);
		newElement.appendChild(inlineText);
		target.appendChild(newElement);
	}
}

module.exports = {
	loadCookieConsent: loadCookieConsent
};
