(function () {
	'use strict';
	var Backbone = require('backbone');
	var _ = require('underscore');
	var template = require('../../shared-templates/hbs/feedback.hbs');
	module.exports = Backbone.View.extend({
		tagName: 'div',
		id: 'divFeedbackContent',

		initialize: function () {
			_.bindAll(this, 'render');
			this.model.bind('change', this.render);
		},

		render: function () {
			var thisView = this;
			thisView.$el.html(template(this.model.attributes));
			return thisView;
		}
	});
})();
