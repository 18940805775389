(function () {
	'use strict';

	var appConfig = require('~institution-specific-config/app.config.json');
	var logoutModule = require('../../../../shared-scripts/modules/module.logout');
	var userRoleModule = require('../../../../shared-scripts/modules/module.user.role');
	var i18next = require('../../../../shared-scripts/app-util/i18n');

	var getMenuPopupTemplate = function (userRole) {
		if (!userRole) {
			return require('../../templates/hbs/unauthenticated.menu.popup.hbs');
		}

		if (userRole === 'VOL') {
			return require('../../templates/hbs/volunteer.logged.in.menu.popup.hbs');
		}
	};

	var View = Backbone.View.extend({
		el: '#asideMobileMenuPopup',
		events: {
			'click #aLogout': 'logout',
			'click a': 'closeMenu',
			'change #selectLanguage': 'changeLanguage',
			'click #aLanguage': 'changeLanguage',
		},

		logout: function () {
			logoutModule.logout(userRoleModule.getUserRole(this.model.loggedInUser));
		},

		changeLanguage: function(event){
			var $selectLanguage = $('#selectLanguage');
			var language = $selectLanguage.length===1 ? event.target.value : $(event.target).attr('data-language');

			i18next.changeLanguage(language).then(function(){
				$.ajaxSetup({
					headers: {
						'Accept-Language': language
					}
				});
				window.location.reload();
			});
		},

		closeMenu:function(){
			$('#asideMobileMenuPopup').popup('hide');
			$('#asideMobileMenuPopup, #asideMobileMenuPopup *').off();
		},

		render: function () {
			var loggedInUser = this.model.loggedInUser;
			var template = getMenuPopupTemplate(userRoleModule.getUserRole(loggedInUser));

			this.$el.html(template(this.generateTemplateData()));
			return this;
		},

		generateTemplateData: function () {
			var templateData = {};
			var loggedInUser = this.model.loggedInUser;
			var lovedOnes = this.model.lovedOnes;
			var availableLanguages = i18next.getSupportedLanguages().map(function(language){
				language.highlighted = language.key === i18next.resolvedLanguage;
				return language;
			});

			templateData.userFullName = loggedInUser ? loggedInUser.fullName : undefined;
			templateData.lovedOneExists = (lovedOnes && lovedOnes.length > 1);
			var recommendedStudyCounts = this.model.recommendedStudyCounts;
			var totalNewStudyCount = 0;
			if (recommendedStudyCounts) {
				var newUserRecStudyCount = recommendedStudyCounts.newUserRecStudyCount;
				var newSysRecStudyCount = recommendedStudyCounts.newSysRecStudyCount;
				totalNewStudyCount = newUserRecStudyCount + newSysRecStudyCount;
			}
			templateData.totalNewStudyCount = totalNewStudyCount;
			templateData.totalUnreadMessageCount = this.model.totalUnreadMessageCount;
			templateData.supportInfo = appConfig.supportInfo;
			templateData.organizationInfo = appConfig.organizationInfo;
			templateData.dropDownLanguageSelector= availableLanguages.length > 2;
			templateData.availableLanguages= availableLanguages;
			templateData.notSelectedLanguages= availableLanguages.filter(function(language){return language.key !== i18next.resolvedLanguage;});
			return templateData;
		}
	});
	module.exports = View;
})();
