var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n  <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n    <div id=\"divFeedback\"></div>\n    <header class=\"page-header\">\n        <nav class=\"text-center\">\n          <a data-history-navigate id=\"aAccountSettings\" href=\"/volunteers/account-settings\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":93},"end":{"line":6,"column":125}}}))
    + "</a> <span class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.screenReader.isParent",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":217},"end":{"line":6,"column":282}}}))
    + "</span>\n        </nav>\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":66}}}))
    + "</h1>\n    </header>\n    <div class=\"field-container\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.form.field.currentEmail.label",{"name":"i18n","hash":{"email":((stack1 = (depth0 != null ? lookupProperty(depth0,"loggedInUser") : depth0)) != null ? lookupProperty(stack1,"email") : stack1)},"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":11,"column":106}}}))
    + "\n    </div>\n\n    <form class=\"form-stacked margin-top\" id=\"formChangeEmail\">\n\n      <div class=\"field-container validation-error-container\">\n        <label id=\"lblNewEmail\" for=\"emailNewEmail\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.form.field.email.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":52},"end":{"line":17,"column":118}}}))
    + "</label>\n        <input class=\"input-1\" id=\"emailNewEmail\" type=\"email\" name=\"new-email\" data-parsley-type=\"email\" required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:invalidEmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":143},"end":{"line":18,"column":180}}}))
    + "\">\n      </div>\n\n      <div class=\"field-container validation-error-container\">\n        <label id=\"labelConfirmNewEmail\" for=\"emailConfirmNewEmail\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.form.field.confirmEmail.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":68},"end":{"line":22,"column":141}}}))
    + "</label>\n        <input class=\"input-1\" id=\"emailConfirmNewEmail\" type=\"email\" name=\"confirm-new-email\" required data-parsley-equalto=\"#emailNewEmail\" data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:emailsDoNotMatch",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":170},"end":{"line":23,"column":211}}}))
    + "\">\n      </div>\n\n      <div class=\"secondary-font-style text-center margin-top-small\">\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.form.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":10},"end":{"line":27,"column":63}}}))
    + "\n      </div>\n\n      <button type=\"submit\"  class=\"primary-button full-width-button\" id=\"submitChangeEmailLink\" value=\"EMAIL RESET LINK\"><span class=\"fa fa-envelope fa-fw fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.changeLoginEmail.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":199},"end":{"line":30,"column":271}}}))
    + "\n      </button>\n    </form>\n  </section>\n</div>\n";
},"useData":true});