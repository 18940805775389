var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"top-close-button\">\n        <a data-history-navigate href=\"javascript:void(0);\" class=\"asideUserAgreement_close\">\n            <span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span>\n            <span class=\"hide show-for-sr\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"screenReader.closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":83}}}))
    + "</span>\n        </a>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,__default(require("../../shared-scripts/app-util/handlebars-helpers/eq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"userAgreementType") : depth0),"VOL",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":40}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":19,"column":13}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p class=\"error-font-color\">\n            "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"userAgreement:warning.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":17,"column":49}}}))
    + "\n        </p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasLovedOnes") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":63,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <footer>\n            <div class=\"margin-top form-stacked\">\n                <div class=\"field-container white-background border\">\n                    <input type=\"checkbox\" id=\"checkboxReadAndAccept\"/>\n                    <label for=\"checkboxReadAndAccept\" class=\"other-label\">\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"userAgreement:agree.checkboxLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":68}}}))
    + "\n                    </label>\n                </div>\n                <div class=\"field-container white-background border\">\n                    <input type=\"checkbox\" id=\"checkboxDiscussed\"/>\n                    <label for=\"checkboxDiscussed\" class=\"other-label\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"userAgreement:discussed.checkboxLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":71},"end":{"line":36,"column":119}}}))
    + "</label>\n                </div>\n                <div class=\"button-container text-center\">\n                    <a data-history-navigate href=\"javascript:void(0);\" id=\"aDecline\" class=\"error-font-color margin-right-small\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"needsDeactivate") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":44,"column":31}}})) != null ? stack1 : "")
    + "                    </a>\n                    <button type=\"submit\" class=\"primary-button\" disabled=\"disabled\" id=\"buttonIagree\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"userAgreement:agree.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":46,"column":103},"end":{"line":46,"column":145}}}))
    + "</button>\n                </div>\n            </div>\n        </footer>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"userAgreement:decline.deactivateButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":28},"end":{"line":41,"column":82}}}))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"userAgreement:decline.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":43,"column":72}}}))
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <footer>\n            <div class=\"button-container text-center center-container\">\n                <a data-history-navigate href=\"javascript:void(0);\" id=\"aDecline\" class=\"error-font-color margin-right-small\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"needsDeactivate") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":54,"column":20},"end":{"line":58,"column":27}}})) != null ? stack1 : "")
    + "                </a>\n                <button class=\"primary-button\" id=\"buttonIagree\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"userAgreement:agree.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":60,"column":65},"end":{"line":60,"column":107}}}))
    + "</button>\n            </div>\n        </footer>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"userAgreement:decline.deactivateButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":55,"column":24},"end":{"line":55,"column":78}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"userAgreement:decline.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":57,"column":24},"end":{"line":57,"column":68}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header>\n    <h1 class=\"grid-sm-1\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"userAgreement:popup.volunteer.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":74}}}))
    + "</h1>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"needsConfirm") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "</header>\n<section class=\"popup-main\" id=\"sectionUserAgreementMain\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deactivationReason") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <div id=\"divUserAgreement\"></div>\n</section>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"needsConfirm") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":0},"end":{"line":64,"column":7}}})) != null ? stack1 : "");
},"useData":true});