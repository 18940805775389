var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-9-12 grid-lg-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:tab.lovedOne.addLovedOne",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":58}}}))
    + "</h1>\n            <p class=\"secondary-font-color text-center\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:tab.lovedOne.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":51}}}))
    + "\n            </p>\n        </header>\n\n        <form class=\"form-stacked\" id=\"formAddLovedOne\">\n            <div class=\"form-section-heading\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.group.aboutSelf.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":70}}}))
    + "\n            </div>\n            <fieldset id=\"fieldsetRelationship\">\n                <legend>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.signingUpFor.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":20},"end":{"line":18,"column":71}}}))
    + "\n                </legend>\n                <div class=\"field-container\">\n                    <label for=\"selectRelationshipCategory\">\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.signingUpFor.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":22,"column":71}}}))
    + "\n                    </label>\n                    <select id=\"selectRelationshipCategory\" class=\"input-1 cascading-dropdown\">\n                    </select>\n                </div>\n                <div class=\"field-container\">\n                    <label for=\"selectRelationship\">\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.relationWithLovedOne.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":24},"end":{"line":29,"column":79}}}))
    + "\n                    </label>\n                    <select id=\"selectRelationship\" name=\"relationship\" class=\"input-1 cascading-dropdown\">\n                    </select>\n                    <div class=\"field-help-text\">\n                        <a data-history-navigate href=\"javascript:void(0);\" class=\"asideTellMeMore_open\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"tellMeMore.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":105},"end":{"line":34,"column":136}}}))
    + "</a>\n                    </div>\n                </div>\n            </fieldset>\n\n            <div class=\"form-section-heading\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.group.aboutLovedOne.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":40,"column":74}}}))
    + "\n            </div>\n"
    + ((stack1 = container.invokePartial(require("./partials/basicInfo.section.hbs"),depth0,{"name":"basicInfo.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/raceAndEthnicity.section.hbs"),depth0,{"name":"raceAndEthnicity.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/healthInfo.section.hbs"),depth0,{"name":"healthInfo.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/smokingStatus.section.hbs"),depth0,{"name":"smokingStatus.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/profileVisibility.section.hbs"),depth0,{"name":"profileVisibility.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"center-button-container\">\n                <button type=\"submit\" class=\"primary-button\">\n                    <span class=\"fa fa-user-plus fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.addLovedOne.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":92},"end":{"line":49,"column":127}}}))
    + "\n                </button>\n            </div>\n        </form>\n    </section>\n</div>\n"
    + ((stack1 = container.invokePartial(require("./partials/lovedOne.tellMeMore.popup.hbs"),depth0,{"name":"lovedOne.tellMeMore.popup","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});