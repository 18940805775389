var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n  <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n    <header class=\"page-header\">\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"changeEmailPassword:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":48}}}))
    + "</h1>\n        <div class=\"secondary-font-style text-center margin-top-small\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"changeEmailPassword:desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":6,"column":47}}}))
    + "\n        </div>\n    </header>\n    <div id=\"divFeedback\"></div>\n    <form class=\"form-stacked\" id=\"formChangeEmailPassword\">\n      <div class=\"field-container validation-error-container\">\n        <label id=\"labelPassword\" for=\"passwordPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"changeEmailPassword:form.field.password.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":57},"end":{"line":12,"column":113}}}))
    + "</label>\n        <input class=\"input-1\" id=\"passwordPassword\" type=\"password\" name=\"password\" required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:requiredField",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":122},"end":{"line":13,"column":160}}}))
    + "\">\n      </div>\n\n      <button type=\"submit\"  class=\"primary-button full-width-button\" value=\"EMAIL RESET LINK\"><span class=\"fa fa-check fa-fw fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"changeEmailPassword:form.field.changeEmailPassword.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":169},"end":{"line":16,"column":242}}}))
    + "\n      </button>\n    </form>\n  </section>\n</div>\n";
},"useData":true});