(function () {
	'use strict';
	var Backbone = require('backbone');
	module.exports = Backbone.Model.extend({
		url: function () {
			return '/backend/secure/volunteer/study-info-for-conversation/' + this.get('studyVolunteerId');
		}
	});
})();

