var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n  <section class=\"grid-sm-1 grid-md-9-12 grid-md-centered\">\n      <div id=\"divFeedback\"></div>\n      <header class=\"page-header\">\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:heading",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":97}}}))
    + "</h1>\n    </header>\n  </section>\n</div>\n\n<div class=\"grid-row grid-no-padding\">\n  <section class=\"grid-sm-1 grid-lg-9-12 grid-centered\">\n    <nav class=\"secondary-nav horizontal\">\n      <a data-history-navigate href=\"/about/volunteers\" class=\"active\"><span class=\"fa fa-check-circle fa-margin-right\"><span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.youAreHere",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":146},"end":{"line":13,"column":180}}}))
    + "</span></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":194},"end":{"line":13,"column":240}}}))
    + "</a>\n      <a data-history-navigate href=\"/about/study-teams\"><span class=\"fa fa-check-circle fa-margin-right\"><span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.youAreHere",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":132},"end":{"line":14,"column":166}}}))
    + "</span></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:studyTeams.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":180},"end":{"line":14,"column":226}}}))
    + "</a>\n    </nav>\n  </section>\n</div>\n\n<section class=\"grid-row fadeIn\">\n  <div class=\"grid-sm-1 grid-lg-9-12 grid-centered\">\n    <div class=\"grid-row margin-top vertical-center-container\">\n      <div class=\"grid-sm-1 grid-md-1-4 four-steps vertical-center\" aria-hidden=\"true\">\n        <div class=\"step three\">\n          <div class=\"media center-container\">\n            <span class=\"fa fa-search\"></span>\n          </div>\n        </div>\n      </div>\n      <div class=\"grid-sm-1 grid-md-3-4 vertical-center text-left\">\n        <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.first.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":26},"end":{"line":30,"column":90}}}))
    + "</h2>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.first.text",{"name":"i18n","hash":{"searchStudiesLink":"/"},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":95}}}))
    + "\n        </p>\n      </div>\n    </div>\n\n    <div class=\"stylized-divider\" aria-hidden=\"true\"></div>\n\n    <div class=\"grid-row margin-top\">\n      <div class=\"grid-sm-1 grid-md-1-4 four-steps vertical-center\" aria-hidden=\"true\">\n        <div class=\"step two\">\n          <div class=\"media center-container\">\n              <span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.second.bubbleText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":43,"column":68}}}))
    + "</span>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"grid-sm-1 grid-md-3-4 vertical-center text-left\">\n        <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.second.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":26},"end":{"line":49,"column":91}}}))
    + "</h2>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.second.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":51,"column":74}}}))
    + "\n        </p>\n      </div>\n    </div>\n\n    <div class=\"stylized-divider\" aria-hidden=\"true\"></div>\n\n    <div class=\"grid-row margin-top\">\n      <div class=\"grid-sm-1 grid-md-1-4 four-steps vertical-center\" aria-hidden=\"true\">\n        <div class=\"step three\">\n          <div class=\"media center-container\" aria-hidden=\"true\">\n            <span class=\"fa fa-comments-o\"></span>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"grid-sm-1 grid-md-3-4 vertical-center text-left\">\n        <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.third.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":68,"column":26},"end":{"line":68,"column":90}}}))
    + "</h2>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.third.text",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":70,"column":122}}}))
    + "\n        </p>\n      </div>\n    </div>\n\n    <div class=\"stylized-divider\" aria-hidden=\"true\"></div>\n\n    <div class=\"grid-row margin-top\">\n      <div class=\"grid-sm-1 grid-md-1-4 four-steps vertical-center\" aria-hidden=\"true\">\n        <div class=\"step three\">\n          <div class=\"media center-container\" aria-hidden=\"true\">\n            <span class=\"fa fa-thumbs-up\"></span>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"grid-sm-1 grid-md-3-4 vertical-center text-left\">\n        <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.fourth.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":87,"column":26},"end":{"line":87,"column":91}}}))
    + "</h2>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.fourth.text",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1),"signUpLink":"/signup"},"data":data,"loc":{"start":{"line":89,"column":12},"end":{"line":89,"column":144}}}))
    + "\n        </p>\n      </div>\n    </div>\n\n    <div class=\"stylized-divider\" aria-hidden=\"true\"></div>\n\n    <div class=\"grid-row margin-top\">\n      <div class=\"grid-sm-1 grid-md-1-4 four-steps vertical-center\" aria-hidden=\"true\">\n        <div class=\"step three\">\n          <div class=\"media center-container\" aria-hidden=\"true\">\n            <span class=\"fa fa-users\"></span>\n          </div>\n        </div>\n      </div>\n\n      <div class=\"grid-sm-1 grid-md-3-4 vertical-center text-left\">\n        <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.fifth.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":106,"column":26},"end":{"line":106,"column":90}}}))
    + "</h2>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.simpleSteps.fifth.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":108,"column":12},"end":{"line":108,"column":73}}}))
    + "\n        </p>\n      </div>\n    </div>\n  </div>\n</section>\n\n<section class=\"grid-row special-background\">\n  <div class=\"grid-1\">\n    <h1 class=\"special-header-with-top-border center_container gamma\"><em>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.privacyHeading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":117,"column":74},"end":{"line":117,"column":127}}}))
    + "</em></h1>\n    <p class=\"text-center\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.privacyBody",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1),"privacyNoticeUrl":"/privacy"},"data":data,"loc":{"start":{"line":119,"column":8},"end":{"line":119,"column":135}}}))
    + "\n    </p>\n  </div>\n  <div class=\"grid-1\">\n    <h1 class=\"special-header-with-top-border center_container gamma\"><em>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.consentHeading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":123,"column":74},"end":{"line":123,"column":127}}}))
    + "</em></h1>\n    <p class=\"text-center\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.consentBody",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":125,"column":8},"end":{"line":125,"column":107}}}))
    + "\n    </p>\n  </div>\n</section>\n\n<section class=\"grid-row margin-top margin-bottom\">\n    <div class=\"grid-1 margin-top margin-bottom\">\n      <h1 class=\"text-center margin-top\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.start.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":132,"column":41},"end":{"line":132,"column":93}}}))
    + "</h1>\n      <div class=\"text-center margin-bottom\">\n        <a data-history-navigate href=\"/\" class=\"primary-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanYHRHelpYou:volunteers.start.findStudies.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":134,"column":65},"end":{"line":134,"column":133}}}))
    + "</a>\n        <a data-history-navigate href=\"/signup\" class=\"special-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.signUp.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":135,"column":71},"end":{"line":135,"column":101}}}))
    + "</a>\n      </div>\n    </div>\n</section>\n";
},"useData":true});