(function() {

	'use strict';

	var DeactivateAccountModule = function () {
		this.deactivateAccount = function (params, successCallback) {
			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: 'POST',
				url: '/backend/secure/volunteer/user-deactivation',
				data: JSON.stringify(params),
				success: successCallback
			});
		};
	};

	module.exports = new DeactivateAccountModule();
})();