var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n  <section class=\"grid-sm-1 grid-lg-9-12 grid-lg-centered\">\n    <div class=\"feedback-block success\">\n				<div class=\"feedback-block-media\">\n					<span class=\"fa pe-7s-check\" id=\"spnFeedbackBlockMediaIcon\"></span>\n				</div>\n				<div class=\"feedback-block-body\">\n					<h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:sigUpVerification.thankYou",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":23},"end":{"line":8,"column":76}}}))
    + "</h1>\n					<p>\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:sigUpVerification.youAreParticipant",{"name":"i18n","hash":{"interestedParticipantLabel":((stack1 = (depth0 != null ? lookupProperty(depth0,"marketingContent") : depth0)) != null ? lookupProperty(stack1,"interestedParticipantLabel") : stack1)},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":157}}}))
    + "\n                    </p>\n				</div>\n			</div>\n			<div class=\"feedback-block info\">\n      	<div class=\"feedback-block-media\">\n      		<span class=\"fa pe-7s-info\" id=\"spnFeedbackBlockMediaIcon\"></span>\n      	</div>\n      	<div class=\"feedback-block-body\">\n      		<h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:sigUpVerification.confirmEmailAddress.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":26},"end":{"line":19,"column":96}}}))
    + "</h1>\n					<p>\n                        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:sigUpVerification.confirmEmailAddress.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":24},"end":{"line":21,"column":93}}}))
    + "\n					</p>\n      	</div>\n      </div>\n    </section>\n</div>\n\n<div class=grid-row>\n  <div class=\"grid-sm-1 grid-md-9-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n    <section class=\"faq\">\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.why.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":87}}}))
    + "</h2>\n      <p>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.why.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":10},"end":{"line":33,"column":72}}}))
    + "\n      </p>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whichEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":35,"column":94}}}))
    + "</h2>\n      <p>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whichEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":37,"column":10},"end":{"line":37,"column":79}}}))
    + "\n        <strong id=\"strongNewEmail\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":38,"column":36},"end":{"line":38,"column":45}}}) : helper)))
    + "</strong>\n      </p>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfIDontClick.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":40,"column":100}}}))
    + "</h2>\n      <p>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:sigUpVerification.confirmEmailAddress.notClicked",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":10},"end":{"line":42,"column":85}}}))
    + "\n      </p>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":44,"column":97}}}))
    + "</h2>\n      <p>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":46,"column":10},"end":{"line":46,"column":82}}}))
    + "<br/>\n        <a data-history-navigate href=\"https://www.gmail.com\" class=\"small-outline-button margin-right-small\" target=\"_blank\"><span class=\"fa fa-google fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.gmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":195},"end":{"line":47,"column":268}}}))
    + "</a>\n\n        <a data-history-navigate href=\"https://mail.yahoo.com/\" target=\"_blank\" class=\"small-outline-button\"><span class=\"fa fa-yahoo fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.yahoo",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":177},"end":{"line":49,"column":250}}}))
    + "</a>\n      </p>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":106}}}))
    + "</h2>\n      <div>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":53,"column":10},"end":{"line":53,"column":91}}}))
    + ":\n      </div>\n      <ol>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.verifyEmailInfo.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":57,"column":28},"end":{"line":57,"column":126}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.verifyEmailInfo.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":14},"end":{"line":59,"column":111}}}))
    + "\n          </p>\n        </li>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.checkJunkEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":63,"column":28},"end":{"line":63,"column":125}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.checkJunkEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":65,"column":14},"end":{"line":65,"column":110}}}))
    + "\n          </p>\n        </li>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":69,"column":28},"end":{"line":69,"column":122}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":71,"column":14},"end":{"line":71,"column":107}}}))
    + "\n            <span id=\"spanResendResult\" class=\"display-block\"></span>\n            </p>\n        </li>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.contactUs.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":76,"column":28},"end":{"line":76,"column":120}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.contactUs.info",{"name":"i18n","hash":{"supportEmailAddress":((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1)},"data":data,"loc":{"start":{"line":78,"column":14},"end":{"line":78,"column":157}}}))
    + "\n          </p>\n        </li>\n      </ol>\n    </section>\n  </div>\n</div>\n";
},"useData":true});