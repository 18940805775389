var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"text-center margin-top white-background\">\n                "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"noMessages",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":37}}}))
    + "\n            </p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <section class=\"grid-row\">\n        <div class=\"grid-1\">\n            <section class=\"pagination\">\n                <div class=\"pagination-label\">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:heading",{"name":"i18n","hash":{"entity":"conversations","totalStudyCount":(depth0 != null ? lookupProperty(depth0,"totalConversationCount") : depth0),"endStudyCount":(depth0 != null ? lookupProperty(depth0,"endConversationCount") : depth0),"startStudyCount":(depth0 != null ? lookupProperty(depth0,"startConversationCount") : depth0)},"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":186}}}))
    + "\n                </div>\n                <a data-history-navigate id=\"aPrev\" href=\"javascript:void(0);\" class=\"small-outline-button\"><span class=\"fa fa-chevron-left\"\n                                                                                            aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:previous",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":118},"end":{"line":34,"column":148}}}))
    + "</a>\n                <a data-history-navigate id=\"aNext\" href=\"javascript:void(0);\" class=\"small-outline-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":108},"end":{"line":35,"column":134}}}))
    + "<span\n                        class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span></a>\n                <section class=\"paginate-to-specific-page\">\n                    <a href=\"#divPaginatePageNum\" data-bypass data-bs-toggle=\"collapse\" aria-expanded=\"false\"\n                       aria-controls=\"divPaginatePageNum\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:open",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":39,"column":58},"end":{"line":39,"column":84}}}))
    + "</a>\n                    <div class=\"collapse\" id=\"divPaginatePageNum\">\n                        <form>\n                            <label for=\"selectPage\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:page",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":52},"end":{"line":42,"column":74}}}))
    + "</label>\n                            <select id=\"selectPage\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pageNumbers") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":32},"end":{"line":46,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </form>\n                        <a href=\"#divPaginatePageNum\" data-bypass  data-bs-toggle=\"collapse\" aria-expanded=\"false\"\n                           aria-controls=\"divPaginatePageNum\"><span class=\"fa fa-chevron-up\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.close.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":50,"column":119},"end":{"line":50,"column":148}}}))
    + "</a>\n                    </div>\n                </section>\n            </section>\n        </div>\n    </section>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pageNumber") || (depth0 != null ? lookupProperty(depth0,"pageNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data,"loc":{"start":{"line":45,"column":51},"end":{"line":45,"column":65}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"pageNumber") || (depth0 != null ? lookupProperty(depth0,"pageNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data,"loc":{"start":{"line":45,"column":67},"end":{"line":45,"column":81}}}) : helper)))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messages",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":35}}}))
    + "</h1>\n            <p class=\"secondary-font-color text-center\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"messageDescription",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":45}}}))
    + "\n            </p>\n        </header>\n    </section>\n</div>\n\n<div class=\"grid-row grid-no-padding\">\n    <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalConversationCount") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "        <div id=\"divMessageCardList\">\n\n        </div>\n\n    </section>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalConversationCount") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":0},"end":{"line":56,"column":7}}})) != null ? stack1 : "");
},"useData":true});