(function() {
	'use strict';

	var ProgressBar = require('progressbar.js');

	var ProfileCompletenessModule = function () {
		this.getMissingRequiredFields = function (model) {
			return model.get('missingRequiredFields');
		};

		this.getSections = function(model){
			return {
				'userInfo': model.get('userInfo'),
				'demographics': model.get('demographics'),
				'genderIdentity': model.get('genderIdentity'),
				'basicHealthInfo': model.get('basicHealthInfo'),
				'medicalConditions': model.get('medicalConditions'),
				'medications': model.get('medications'),
			};
		};

		this.getCommonFieldsNeededForCompletion = function() {
			return {
				'userInfo': ['firstName', 'lastName', 'email', 'city', 'state', 'zip', 'country', 'phone'],
				'demographics': ['gender', 'race', 'otherRace', 'dateOfBirth', 'englishFluency'],
				'genderIdentity': ['genderIdentity', 'otherGenderIdentity', 'genderPronoun', 'otherGenderPronoun'],
				'basicHealthInfo': ['smokingStatus', 'height', 'weight'],
				'medicalConditions': ['currentConditions', 'pastConditions', 'hasMetalImplants'],
				'medications': ['willingTakeExpDrugs', 'willingToChangeMeds'],
			};
		};

		this.getRelatedFields = function(){
			return {
				'race': 'otherRace',
				'otherRace': 'race',
				'genderIdentity': 'otherGenderIdentity',
				'otherGenderIdentity': 'genderIdentity',
				'genderPronoun': 'otherGenderPronoun',
				'otherGenderPronoun': 'genderPronoun'
			};
		};

		this.showProfileCompleteness = function (model) {
			var bar = new ProgressBar.Circle('#progress', {
				color: '#228B22',
				strokeWidth: 4,
				trailWidth: 1,
				easing: 'easeInOut',
				duration: 1400,
				text: {autoStyleContainer: false},
				from: {color: '#99c199', width: 4},
				to: {color: '#228B22', width: 4},
				step: function (state, circle) {
					circle.path.setAttribute('stroke', state.color);
					circle.path.setAttribute('stroke-width', state.width);
					var value = Math.round(circle.value() * 100);
					circle.setText(value === 0 ? '' : value + '%');
				}
			});

			bar.animate(this.calculateProfileCompleteness(model));  // Number from 0.0 to 1.0
		};

		this.calculateProfileCompleteness = function (model, formatAsPercent) {
			var sections = this.getSections(model);
			var allFieldsNeededForCompletion = this.getCommonFieldsNeededForCompletion();

			if (sections.userInfo.parentAccount){
				// Fields needed to be completed for parent account only
				allFieldsNeededForCompletion.demographics.push('parentOrGuardianOfAChild');
			}
			var totalCompleted = 0;
			var totalFields = 0;

			for (var section in sections) {
				if (sections.hasOwnProperty(section)) {
					var fieldsFromModel = sections[section];
					var fieldsNeededForCompletion = allFieldsNeededForCompletion[section];
					var result;

					if (Array.isArray(fieldsFromModel)) {
						result = this.processArraySectionFields(fieldsFromModel, fieldsNeededForCompletion);
					} else if (typeof fieldsFromModel === 'object') {
						result = this.processObjectSectionFields(fieldsFromModel, fieldsNeededForCompletion);
					}

					totalCompleted += result.completed;
					totalFields += result.total;
				}
			}

			// if showPregnancyField is true, then the pregnancy radio button is shown with default value of false.
			// if the value of radio button is true, then the estimated date is mandatory, so the field is completed no matter what
			if (model.get('showPregnancyField')) {
				totalFields += 1;
				totalCompleted += 1;
			}

			var profileCompleteness = totalCompleted / totalFields;
			return formatAsPercent ? Math.round(profileCompleteness * 100) : profileCompleteness;
		};

		this.processArraySectionFields = function (fieldsFromModel, fieldsNeededForCompletion) {
			var relatedFields = this.getRelatedFields();
			var completed = this.getCompletedFieldsCountForArraySection(fieldsFromModel, fieldsNeededForCompletion, relatedFields);
			var total = this.getTotalFieldsCountForArraySection(fieldsNeededForCompletion, relatedFields);

			return {completed: completed, total: total};
		};

		this.processObjectSectionFields = function (fieldsFromModel, fieldsNeededForCompletion) {
			var completed = 0;
			var total = 0;

			fieldsNeededForCompletion.forEach(function (fieldName) {
				total++;
				if (fieldsFromModel.hasOwnProperty(fieldName) && fieldsFromModel[fieldName]) {
					completed++;
				}
			});
			return {completed: completed, total: total};
		};

		this.getTotalFieldsCountForArraySection = function(fieldsNeededForCompletion, relatedFields){
			var processedFields = new Set();
			var totalCount = 0;

			fieldsNeededForCompletion.forEach(function (fieldName) {
				totalCount++;
				processedFields.add(fieldName);
				var relatedField = relatedFields[fieldName];
				// if the related field is already processed, then we don't need to count the current field
				if(relatedField && processedFields.has(relatedField)){
					totalCount--;
				}
			});
			return totalCount;
		};

		this.getCompletedFieldsCountForArraySection = function(fieldsFromModel, fieldsNeededForCompletion, relatedFields){
			var completedFields = new Set();
			var completedCount = 0;

			var isComplete = function(fieldName){
				var field = fieldsFromModel.find(function(field){return field.property.name === fieldName;});
				return field && (field.savedValue || (field.lookupValues && field.lookupValues.length > 0));
			};

			fieldsNeededForCompletion.forEach(function (fieldName) {
				var relatedField = relatedFields[fieldName];
				if(isComplete(fieldName)){
					completedFields.add(fieldName);
					completedCount++;
					// if the related field is already marked as complete, we don't need to count the completion of current field
					if(relatedField && completedFields.has(relatedField)){
						completedCount--;
					}
				}
			});

			return completedCount;
		};
	};
	module.exports = new ProfileCompletenessModule();
})();
