(function () {
	'use strict';
	var fileDownload = require('js-file-download');
	var template = require('../../templates/hbs/message.list.hbs');
	var messageTemplate = require('../../templates/hbs/message.hbs');
	var appConfig = require('~institution-specific-config/app.config.json');
	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var html = require('../../../../shared-scripts/app-util/html.js');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aShowPreviousMessages': 'showPreviousMessages',
			'keyup #textareaMessage': 'changeSendButtonStatus',
			'change #textareaMessage': 'changeSendButtonStatus',
			'paste #textareaMessage': 'changeSendButtonStatus',
			'submit #formSendMessage': 'sendMessage',
			'click .attachment': 'downloadAttachment'
		},

		initialize: function (options) {
			if (options) {
				this.messages = options.messages;
				this.studyInfo = options.studyInfo;
			}
			this.pageSize = appConfig.pagination.defaultPageSize;

			this.subViews = {};
		},

		// Define a wrapper function for window.location.reload to let us mock in test
		reloadPage: function() {
			window.location.reload();
		},

		downloadAttachment: function (event) {
			event.preventDefault();
			var filename = event.currentTarget.getAttribute('data-filename');
			var title = event.currentTarget.getAttribute('data-title');
			var studyId = this.studyInfo.get('studyId');
			var thisView = this;

			$.ajax({
				method: 'GET',
				url: '/backend/secure/studies/' + studyId + '/attachments/' + filename,
				xhr: function () {// Seems like the only way to get access to the xhr object
					var xhr = new XMLHttpRequest();
					xhr.onreadystatechange = function () {
						if (xhr.readyState === 2) {
							if (xhr.status === 200) {
								xhr.responseType = 'blob';
							}
						}
					};
					return xhr;
				},
				success: function (response) {
					var fileExtension = util.getFileExtension(filename);
					fileDownload(response, title + (fileExtension ? '.' + fileExtension : ''));
				},
				error: function (error) {
					if (error.status === 404) {
						// change status to 204 to prevent the 404 error from reaching the global handler
						error.status = 204;
						localStorage.setItem('feedbackCode', '404_ATTACHMENT_NOT_FOUND');
						thisView.reloadPage();
					}
				}
			});
		},

		changeSendButtonStatus: function () {
			var shouldBeDisabled = (this.$el.find('#textareaMessage').val().trim() === '');
			this.$el.find('#buttonSendMessage').prop('disabled', shouldBeDisabled);
		},

		sendMessage: function (event) {
			event.preventDefault();
			var $textAreaMessage = this.$el.find('#textareaMessage');
			var messageBody = $textAreaMessage.val();
			var fromUserId = this.studyInfo.get('volunteerUserId');
			var message = {
				body: messageBody,
				fromUserId: fromUserId,
				studyVolunteer: {
					id: this.studyInfo.get('studyVolunteerId'),
					studyId: this.studyInfo.get('studyId'),
					userId: this.studyInfo.get('volunteerUserId')
				},
				attachments: []
			};

			return $.post({
				url: '/backend/secure/messages',
				data: JSON.stringify(message),
				contentType: 'application/json',
				success: function () {
					Backbone.history.loadUrl();
				}
			});
		}
		,

		addMessagesToList: function (messages, visible) {
			var $olMessageList = this.$el.find('#olMessageList');
			$.each(messages.models, function (messageIndex, messageModel) {
				var messageBody = messageModel.get('body');
				var messageTemplateData = {};
				if (messageBody) {
					messageTemplateData.body = html.asSimpleMarkup(messageBody);
				}

				var formatDateHumanRelative = util.formatDateHumanRelative(messageModel.get('sentDate'));

				messageTemplateData.sentDateToDisplay = formatDateHumanRelative[0];
				messageTemplateData.time = formatDateHumanRelative[1];
				messageTemplateData.recipientVolunteer = messageModel.get('recipientVolunteer');
				messageTemplateData.isNewMessage = messageTemplateData.recipientVolunteer && messageModel.get('status') === 'UNREAD';
				messageTemplateData.senderFirstName = messageModel.get('senderFirstName') && messageModel.get('senderLastName') ?
					messageModel.get('senderFirstName') : messageModel.get('senderEmail');
				var attachments = messageModel.get('attachments');

				messageTemplateData.hasAttachments = attachments.length > 0;
				if (attachments.length) {
					messageTemplateData.attachments = attachments.map(function (attachment) {
						var attachmentFileName = attachment.filename;

						return {
							attachmentTitle: attachment.title,
							attachmentFilename: attachmentFileName,
							attachmentFileExtension: util.getFileExtension(attachmentFileName)
						};
					});
				}
				var $messageHtml = $(messageTemplate(messageTemplateData));
				if (visible) {
					$messageHtml.removeClass('hide');
				}
				$olMessageList.append($messageHtml);
			});
		}
		,

		showPreviousMessages: function (isInitialLoading) {
			var hiddenMessages = this.$el.find('#olMessageList li.hide');

			var numberOfMessagesToDisplay = this.pageSize;

			if (isInitialLoading) {
				var firstNewMessageIndex = _.findIndex(this.messages.models, function (messageModel) {
					return messageModel.get('recipientVolunteer') && messageModel.get('status') === 'UNREAD';
				});
				if (firstNewMessageIndex !== -1) {

					numberOfMessagesToDisplay = Math.max(this.pageSize, this.messages.models.length - firstNewMessageIndex);
				}
			}
			if (hiddenMessages.length <= numberOfMessagesToDisplay) {
				this.$el.find('#aShowPreviousMessages').addClass('hide');
			}
			hiddenMessages.slice(-numberOfMessagesToDisplay).removeClass('hide');
		}
		,

		setupTextAreaCharacterCount: function () {
			this.$el.find('textarea#textareaMessage').maxlength({
				alwaysShow: true,
				separator: ' of ',
				message: i18n.t('messageList:remainingCharacters', {charsRemaining:'%charsRemaining%', charsTotal:'%charsTotal%'}),
				validate: true,
				utf8: true
			});
		}
		,

		scrollToLastNewMessageOrWriteMessage: function () {
			if ($('li.new').length === 0) {
				this.scrollTo(this.$el.find('.write-message'));
			} else {
				this.scrollTo(this.$el.find('li.new').first());
			}
		}
		,

		scrollTo: function ($container) {
			if ($container && $container.length) {
				var elOffset = $container.offset().top;
				var elHeight = $container.height();
				var windowHeight = window.innerHeight;
				var offset;

				if (elHeight < windowHeight) {
					offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
				} else {
					offset = elOffset;
				}
				$('html, body').scrollTop(offset);
			}
		}
		,

		generateTemplateData: function () {
			var templateData = {};
			templateData.studyTitle = this.studyInfo.get('studyTitle');
			templateData.isStudyPublishable = this.studyInfo.get('studyStatus') !== 'NOT_PUBLISHABLE';

			templateData.studyTeamMemberNames = this.generateStudyTeamMemberNames(this.messages);
			return templateData;
		}
		,

		generateStudyTeamMemberNames: function (messages) {
			var messageModels = messages.models;
			var fromUserIdSenderNameMap = {};

			messageModels.filter(function (messageModel) {
				return messageModel.get('recipientVolunteer');
			}).forEach(function (messageModel) {
				var firstName = messageModel.get('senderFirstName');
				var lastName = messageModel.get('senderLastName');
				if (firstName && lastName) {
					fromUserIdSenderNameMap[messageModel.get('fromUserId')] = firstName + ' ' + lastName;
				} else {
					fromUserIdSenderNameMap[messageModel.get('fromUserId')] = messageModel.get('senderEmail');
				}
			});

			return _.values(fromUserIdSenderNameMap).join(', ');
		}
		,

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			this.addMessagesToList(this.messages, false);

			this.$el.find('.page-header').stickEmUp();

			this.showPreviousMessages(true);

			this.scrollToLastNewMessageOrWriteMessage();

			popupModule.initializePopup(this.$el.find('#asideWhichStudyTeamMember'));

			this.setupTextAreaCharacterCount();

			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				window.scrollTo({top: 0, behavior: 'smooth'});
				localStorage.setItem('feedbackCode', '');
			}

			return this;
		}
	})
	;
})
();
