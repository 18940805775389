(function () {
	var Handlebars = require('handlebars/dist/handlebars.min');

	module.exports = {
		escapeHtml: function(string) {
			return string && Handlebars.escapeExpression(string);
		},

		markAsSafe: function(string) {
			return string && new Handlebars.SafeString(string);
		},

		asSimpleMarkup: function (string) {
			// escape the string, but replace new lines with <br>'s and make plain-text urls actual anchor tags.
			if (!string) {
				return string;
			}

			string = Handlebars.escapeExpression(string);
			string = string.replace(/(\r\n|\n|\r)/gm, '<br>');
			string = linkifyHtml(string);
			return new Handlebars.SafeString(string);
		},
	};
})();
