(function() {
	'use strict';
	var Backbone = require('backbone');
	module.exports = Backbone.Model.extend({
		defaults: {
			types: [
				'MEDICAL_CONDITION',
				'RACE',
				'GENDER',
				'GENDER_IDENTITY',
				'GENDER_PRONOUN',
				'SMOKING_STATUS',
				'BOOLEAN',
				'LEARNED_ABOUT_WEBSITE_FROM',
				'STUDY_LOCATION',
				'PARTICIPANT_TYPE'
			]
		},

		initialize: function () {
			for (var i = 0; i < this.attributes.types.length; i++) {
				this.urlRoot += 'type=' + this.attributes.types[i];
				if (i !== this.attributes.types.length - 1) {
					this.urlRoot += '&';
				}
			}
		},

		urlRoot: '/backend/lookup-values?'
	});
})();

