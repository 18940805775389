var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header>\n    <h1 class=\"grid-sm-1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:missingRequiredFields.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":26},"end":{"line":2,"column":80}}}))
    + "</h1>\n    <div class=\"top-close-button\">\n        <a data-history-navigate href=\"javascript:void(0);\" class=\"divAlert_close\">\n            <span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span>\n            <span class=\"hide show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":43},"end":{"line":6,"column":83}}}))
    + "</span>\n        </a>\n    </div>\n</header>\n<section class=\"popup-main\" id=\"sectionMain\">\n    <div id=\"divContent\">\n        "
    + alias3((lookupProperty(helpers,"i18nWithPartial")||(depth0 && lookupProperty(depth0,"i18nWithPartial"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"messageKey") : depth0),{"name":"i18nWithPartial","hash":{"partialData":(depth0 != null ? lookupProperty(depth0,"data") : depth0),"partialName":(depth0 != null ? lookupProperty(depth0,"partialTemplate") : depth0)},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":83}}}))
    + "\n    </div>\n</section>\n<footer>\n    <div class=\"button-container text-center center-container\">\n        <button class=\"primary-button\" id=\"buttonClose\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.close.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":56},"end":{"line":17,"column":85}}}))
    + "</button>\n    </div>\n</footer>\n";
},"useData":true});