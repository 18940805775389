(function () {
	'use strict';
	var template = require('../../templates/hbs/not.authorized.hbs');
	var View = Backbone.View.extend({
		el: 'main',

		initialize: function () {
			this.subViews = {};
		},

		generateTemplateData: function () {
			var modalAttributes = this.model;
			if (modalAttributes.error === 'not-authz-study') {
				modalAttributes.notAuthStudy = true;
			}
			return modalAttributes;
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			return this;
		}
	}
	);

	module.exports = View;
})();
