(function() {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		url: function() {
			return '/backend/public/study-team-member-names?role=principal_investigator';
		},
		parse: function(response) {
			return {piNames: response};
		}
	});
	module.exports = Model;
})();

