(function () {
	'use strict';
	var template = require('../../shared-templates/hbs/alert.popup.hbs');
	var popupModule = require('../modules/module.popup');

	module.exports = Backbone.View.extend({
		el: '#divAlert',
		events: {
			'click #buttonClose': 'closePopup',
		},

		initialize: function (options) {
			this.templateData = {
				data: this.model.attributes.data,
				messageKey: options.messageKey,
				partialTemplate: options.partialTemplate
			};
		},

		closePopup: function () {
			popupModule.closePopup($('#divAlert'));
		},

		render: function () {
			this.$el.html(template(this.templateData));
			return this;
		}
	});
})();
