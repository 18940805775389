(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.medications.hbs');

	var util = require('../../../../shared-scripts/app-util/util.js');

	var volunteerProfileModule = require('../modules/module.volunteer.profile.js');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	var View = Backbone.View.extend({
		el: 'main',
		initialize: function(options) {
			if(options) {
				this.seedModel = options.seedModel;
			}
			this.subViews = {};
		},

		events: {
			'click #aClearChangeMeds': 'clearChangeMeds',
			'click #aClearExpDrugs': 'clearExpDrugs',
			'submit form': 'saveSection',
			'click #aCancel': 'cancel'
		},

		cancel: function() {
			localStorage.setItem('feedback-card-cancel-container', 'medications-card-feedback');
			util.redirect('/volunteers/profile');
		},

		saveSection: function(event) {
			event.preventDefault();
			var requestData = volunteerProfileModule.getPropertyValues('#formMedications');
			this.parseEmptyValues(requestData);
			var userId = this.model.get('userId');
			volunteerProfileModule.sendPropertyValuesRequest(userId, requestData, 'medications-card-feedback');
		},

		parseEmptyValues: function(requestData) {
			if(!$('input[name=\'willingToChangeMeds\']:checked').val()) {
				requestData.push({property: {name: 'willingToChangeMeds'}, lookupValues: []});
			}

			if(!$('input[name=\'willingTakeExpDrugs\']:checked').val()) {
				requestData.push({property: {name: 'willingTakeExpDrugs'}, lookupValues: []});
			}
		},

		populateForm: function() {
			var medications = this.model.get('medications');

			for(var i=0; i<medications.length; i++) {
				var medication = medications[i];

				switch(medication.property.name) {
				case 'willingToChangeMeds':
					if(medication.lookupValues.length) {
						this.$el.find('input[name=willingToChangeMeds][value=' + medication.lookupValues[0].id + ']').prop('checked', 'checked');
					}
					break;
				case 'willingTakeExpDrugs':
					if(medication.lookupValues.length) {
						this.$el.find('input[name=willingTakeExpDrugs][value=' + medication.lookupValues[0].id + ']').prop('checked', 'checked');
					}
					break;
				}
			}
		},

		clearChangeMeds: function() {
			this.$el.find('input[name=willingToChangeMeds]').prop('checked', false);
		},

		clearExpDrugs: function() {
			this.$el.find('input[name=willingTakeExpDrugs]').prop('checked', false);
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded='[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		generateTemplateData: function() {
			util.transformTemplateDataForBooleanLookUpValues(this.seedModel.attributes);
			return this.seedModel.attributes;
		},

		render: function() {
			this.$el.html(template(this.generateTemplateData()));
			this.populateForm();
			this.setupValidationSummaryHandler();
			return this;
		}
	});
	module.exports = View;
})();
