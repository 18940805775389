(function() {
	'use strict';
	var Backbone = require('backbone');
	var html = require('../../../../shared-scripts/app-util/html');
	var AnnouncementModel= require('../models/model.announcement.js');
	var Collection = Backbone.Collection.extend({

		url: function() {
			return '/backend/public/application-announcements';
		},

		model: AnnouncementModel,

		parse: function (response) {
			return response.filter(function (announcement) {
				return announcement.startTime <= Date.now();
			}).map(function (announcement) {
				return {
					title: html.markAsSafe(announcement.title),
					message: html.markAsSafe(announcement.message)
				};
			});
		}
	});
	module.exports = Collection;
})();

