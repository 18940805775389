(function () {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		url: function () {
			var url = '/backend/secure/staff/studies?study-number=' + this.get('studyNumber');

			if (this.get('status')) {
				url += '&status=' + this.get('status');
			}

			return url;
		}
	});
	module.exports = Model;
})();

