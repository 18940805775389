(function () {
	'use strict';
	var popupCssTransitionTime = 300;
	var template = require('../../templates/hbs/study.results.hbs');
	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var StudyCardListView = require('./view.study.card.list.js');
	var StudyCollection = require('../collections/collection.study.js');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values');
	var i18n = require('../../../../shared-scripts/app-util/i18n');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aPrev': 'goToPrevPage',
			'click #aNext': 'goToNextPage',
			'change #selectPage': 'jumpToPage',
			'click #aOpenPopupFilter': 'openPopupFilter',
			'click #aAdjustFilter': 'openPopupFilter'
		},

		initialize: function (options) {
			this.subViews = {};
			var studyCollection = new StudyCollection(this.model.get('page'));

			if (options) {
				this.seedModel = options.seedModel;
				this.piNames = options.studyTeamMemberNameModel.get('piNames');
				this.constantLookupValues = new ConstantLookupValues(options.seedModel.attributes);
				this.subViews.studyCardListView = new StudyCardListView({model: studyCollection, seedModel: options.seedModel});
			}
		},
		openPopupFilter: function (event) {
			event.preventDefault();
			event.stopPropagation();
			var thisView = this;

			popupModule.showPopup($('#asideFiltersContainer'), $('#buttonApplyFilter'), thisView.applyFilter, {view: thisView});
		},

		applyFilter: function (event) {
			event.preventDefault();
			var thisView = event.data.view;

			var $textAge = $('#textAge');
			$textAge.parsley(parsleyConfig).validate();
			if ($textAge.parsley(parsleyConfig).isValid()) {
				var formArray = $('#formPopupFilter').serializeArray();
				var newSearchQuery = thisView.generateNewSearchQuery(formArray);
				thisView.model.set('searchQuery', newSearchQuery);
				popupModule.closePopup($('#asideFiltersContainer'));

				setTimeout(
					function () {
						if (!newSearchQuery.trim()) {
							thisView.goTo('/study-results');
						} else {
							thisView.goTo('/study-results?' + encodeURI(thisView.model.get('searchQuery')));
						}
					},
					popupCssTransitionTime  // css transition set in the popup initialization takes 300ms to complete. Wait for it otherwise upon popup close scroll freezes. The problem is that because we are waiting the transition to finish before we trigger the loading of the new view (route switch) the screen flickers (spinner shows briefly after fade out transition).
				);
			} else {
				$('#divAge')[0].scrollIntoView();
			}
		},

		generateNewSearchQuery: function (formArray) {
			var newSearchQuery = '';
			var formNameValueMap = util.buildFormNameValueMap(formArray);

			$.each(formNameValueMap, function (key, value) {
				newSearchQuery += key + '=' + value.join(',') + '&';
			});

			return newSearchQuery.substring(0, newSearchQuery.length - 1);
		},

		goToNextPage: function (event) {
			event.preventDefault();

			if (this.isLastPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') + 1;
			this.model.set('pageNum', newPageNum);
			var newSearchQuery = util.setUrlParam('page', newPageNum);
			newSearchQuery = util.removeParamFromQuery('scroll-to', newSearchQuery);
			this.model.set('searchQuery', newSearchQuery);
			this.goTo('/study-results?' + encodeURI(this.model.get('searchQuery')));
		},

		isLastPage: function () {
			return Number(this.model.get('pageNum')) === Math.ceil(this.model.get('totalCount') / this.model.get('pageSize'));
		},

		goToPrevPage: function (event) {
			event.preventDefault();

			if (this.isFirstPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') - 1;
			this.model.set('pageNum', newPageNum);
			var newSearchQuery = util.setUrlParam('page', newPageNum);
			newSearchQuery = util.removeParamFromQuery('scroll-to', newSearchQuery);
			this.model.set('searchQuery', newSearchQuery);
			this.goTo('/study-results?' + encodeURI(this.model.get('searchQuery')));
		},

		isFirstPage: function () {
			return (Number(this.model.get('pageNum')) === 1);
		},

		jumpToPage: function () {
			var newPageNum = $('#selectPage').val();
			this.model.set('pageNum', newPageNum);
			var newSearchQuery = util.setUrlParam('page', newPageNum);
			newSearchQuery = util.removeParamFromQuery('scroll-to', newSearchQuery);
			this.model.set('searchQuery', newSearchQuery);
			this.goTo('/study-results?' + encodeURI(this.model.get('searchQuery')));
		},

		genderSelected: function () {
			var divPregnancy = $('#divPregnancy');
			var inputPregnancy = $('input[name=pregnant]');
			var selectedGenderCheckbox = $('input[name=gender]:checked');
			var selectedGenderIds = selectedGenderCheckbox.map(function () {
				return Number($(this).val());
			}).get();

			var hasMale = selectedGenderIds.includes(this.constantLookupValues.get('GENDER', 'MALE'));
			var hasFemaleOrIntersex = selectedGenderIds.includes(this.constantLookupValues.get('GENDER', 'FEMALE')) || selectedGenderIds.includes(this.constantLookupValues.get('GENDER', 'INTERSEX'));

			if (!hasMale && hasFemaleOrIntersex) {
				divPregnancy.removeClass('hide');
				inputPregnancy.prop('disabled', false);
			}else{
				divPregnancy.addClass('hide');
				inputPregnancy.prop('disabled', true);
				inputPregnancy.prop('checked', false);
			}
		},

		setupFilterPopupEvents: function () {
			var thisView = this;
			var $radiosPregnant = $('input[name=pregnant]');

			$('input[name=gender]').on('click', function () {
				thisView.genderSelected();
			});

			$('#aClearPregnancy').on('click', function () {
				$radiosPregnant.prop('checked', false);
				$('input').trigger('change');
			});

			$('input').on('change', function () {
				thisView.checkFilterAppliedToGenderAgePregnancy();
				thisView.checkFilterAppliedToHealthyVol();
				thisView.checkFilterAppliedToOnlyComp();
				thisView.checkFilterAppliedToLocations();
			});

			$('#textAge').on('keyup', function () {
				thisView.checkFilterAppliedToGenderAgePregnancy();
			});

			$('#textTopics').on('typeahead:select', function () {
				$('#sectionResearchTopics').addClass('filter-applied');
			});

			$('#textPIName').on('typeahead:select', function () {
				$('#sectionPIandDept').addClass('filter-applied');
			});

			$('#divTopics').on('click', '.typeahead-remove-selected-term', function () {
				if ($('select[name=topics] option').length === 1) {
					$('#sectionResearchTopics').removeClass('filter-applied');
				}
			});

			$('#divPIName').on('click', '.typeahead-remove-selected-term', function () {
				if ($('select[name=pi] option').length === 1) {
					$('#sectionPIandDept').removeClass('filter-applied');
				}
			});
		},

		checkFilterAppliedToGenderAgePregnancy: function () {
			if ($('input[name=gender]').is(':checked') ||
				$('input[name=pregnant]').is(':checked') ||
				$('#textAge').val()) {
				$('#sectionGenderAgePregnancy').addClass('filter-applied');
			} else {
				$('#sectionGenderAgePregnancy').removeClass('filter-applied');
			}
		},

		checkFilterAppliedToHealthyVol: function () {
			if ($('input[name=onlyHealthy]').is(':checked')) {
				$('#sectionHealthyVolStudies').addClass('filter-applied');
			} else {
				$('#sectionHealthyVolStudies').removeClass('filter-applied');
			}
		},

		checkFilterAppliedToOnlyComp: function () {
			if ($('input[name=onlyComp]').is(':checked')) {
				$('#sectionCompensationPreference').addClass('filter-applied');
			} else {
				$('#sectionCompensationPreference').removeClass('filter-applied');
			}
		},

		checkFilterAppliedToLocations: function () {
			if ($('input[name=locations]').is(':checked')) {
				$('#sectionCanBeDoneFromHome').addClass('filter-applied');
			} else {
				$('#sectionCanBeDoneFromHome').removeClass('filter-applied');
			}
		},

		isOptionValueValid: function (optionValue, lookupValueType) {
			var thisView = this;

			if (!optionValue) {
				return false;
			}

			var optionValueArray = optionValue.split(',');

			return optionValueArray.some(function (value) {
				if (!isNaN(value)) {
					var lookupValueIds = thisView.seedModel.get(lookupValueType).map(function (lookupValue) {
						return Number(lookupValue.id);
					});

					return _.contains(lookupValueIds, Number(value));
				}
			});
		},

		populateFilterPopup: function () {
			var thisView = this;
			var searchQuery = this.model.get('searchQuery');
			var options = searchQuery.split('&');
			var initialTopics = [];
			var initialPIId = '';
			var $radiosPregnant = $('input[name=pregnant]');
			$.each(options, function (optionIndex, option) {
				var optionName = option.split('=')[0];
				var optionValue = option.split('=')[1];

				if (!optionValue) {
					return true;
				}

				switch (optionName) {
				case 'gender':
					var validGenderCount = 0;
					var genderIds = optionValue.split(',');
					$.each(genderIds, function (index, genderId) {
						if (!isNaN(genderId) && _.findWhere(thisView.seedModel.get('GENDER'), {id: Number(genderId)})) {
							$('#checkbox-gender-'+genderId).prop('checked', true);
							validGenderCount++;
						}
					});
					if (validGenderCount > 0) {
						thisView.genderSelected();
						$('#sectionGenderAgePregnancy').addClass('filter-applied');
					}
					break;
				case 'age':
					var agePattern = /^\d{0,3}$/g;
					if (agePattern.test(optionValue)) {
						$('#textAge').val(optionValue);
						$('#sectionGenderAgePregnancy').addClass('filter-applied');
					}
					break;
				case 'pregnant':
					if (thisView.isOptionValueValid(optionValue, 'BOOLEAN')) {
						$('input[name=pregnant][value=' + optionValue + ']').prop('checked', true);
						$('#sectionGenderAgePregnancy').addClass('filter-applied');
					}
					break;
				case 'locations':
					if (!isNaN(optionValue) && Number(optionValue) === Number(thisView.constantLookupValues.get('STUDY_LOCATION','CAN_BE_DONE_FROM_HOME'))) {
						$('input[name=locations][value=' + optionValue + ']').prop('checked', true);
						$('#sectionCanBeDoneFromHome').addClass('filter-applied');
					}
					break;
				case 'onlyHealthy':
					if (!isNaN(optionValue) && Number(optionValue) === Number(thisView.constantLookupValues.get('PARTICIPANT_TYPE', 'HEALTHY'))) {
						$('input[name=onlyHealthy][value=' + optionValue + ']').prop('checked', true);
						$('#sectionHealthyVolStudies').addClass('filter-applied');
					}
					break;
				case 'onlyComp':
					if (!isNaN(optionValue) && Number(optionValue) === Number(thisView.constantLookupValues.get('BOOLEAN','TRUE'))) {
						$('input[name=onlyComp][value=' + optionValue + ']').prop('checked', true);
						$('#sectionCompensationPreference').addClass('filter-applied');
					}
					break;
				case 'topics':
					var initialTopicIds = optionValue.split(',');
					$.each(initialTopicIds, function (index, initialTopicId) {
						var initialTopic = _.findWhere(thisView.seedModel.get('MEDICAL_CONDITION'), {id: Number(initialTopicId)});
						if (initialTopic) {
							initialTopics.push(initialTopic);
						}
					});
					if (initialTopics.length) {
						$('#sectionResearchTopics').addClass('filter-applied');
					}
					break;
				case 'pi':
					initialPIId = decodeURI(optionValue);
					if (initialPIId) {
						$('#sectionPIandDept').addClass('filter-applied');
					}
					break;
				}
			});

			if ($radiosPregnant.prop('disabled')) {
				$radiosPregnant.prop('checked', false);
			}
			this.setupTypeIO('textTopics', this.transformTopicsForTypeIO(initialTopics), 'topics', 'divTopics', this.transformTopicsForTypeIO(this.seedModel.get('MEDICAL_CONDITION')), 'multi-select', i18n.t('multiSelectRemoveLabel'));
			this.setupTypeIO('textPIName', this.transformInitialPiNameForTypeIo(initialPIId, this.piNames), 'pi', 'divPIName', this.transformPINamesForTypeIO(this.piNames), 'single-select', i18n.t('singleSelectRemoveLabel'));
		},

		setupTypeIO: function (inputId, initialResults, name, resultsContainerId, source, mode, removeText) {
			$('#' + inputId).typeIO({
				hint: true,
				highlight: true,
				minLength: 1,
				resultsContainer: '#' + resultsContainerId,
				initialResults: initialResults,
				name: name,
				mode: mode,
				removeText: removeText
			},
			{
				limit: 10,
				display: 'text',
				source: source,
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					empty: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		transformTopicsForTypeIO: function (topics) {
			var result = [];
			$.each(topics, function (index, topic) {
				if (topic.visible) {
					result.push({value: topic.id, text: topic.displayText});
				}
			});
			return result;
		},

		transformInitialPiNameForTypeIo: function (initialPIId, piNames) {
			var result = [];
			for (var i = 0; i < piNames.length; i++) {
				if (piNames[i].id === Number(initialPIId)) {
					result.push({
						value: piNames[i].id,
						text: util.formatNameLastFirstMiddle(piNames[i].lastName, piNames[i].firstName, piNames[i].middleName)
					});
				}
			}
			return result;
		},

		transformPINamesForTypeIO: function (piNames) {
			var result = [];
			for (var i = 0; i < piNames.length; i++) {
				result.push({
					value: piNames[i].id,
					text: util.formatNameLastFirstMiddle(piNames[i].lastName, piNames[i].firstName, piNames[i].middleName)
				});

			}
			return result;
		},

		scrollToStudy: function (studyNum) {
			var studyCardView = this.$el.find('#li-' + studyNum);
			if (studyCardView && studyCardView.length) {
				var elOffset = studyCardView.offset().top;
				var elHeight = studyCardView.height();
				var windowHeight = window.innerHeight;
				var offset;

				if (elHeight < windowHeight) {
					offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
				} else {
					offset = elOffset;
				}
				$('html, body').scrollTop(offset);
			}
		},

		generateTemplateData: function () {
			var thisView = this;
			var templateData = {};

			util.sortLookupValuesById(this.seedModel.attributes.GENDER);

			templateData.GENDER = this.seedModel.attributes.GENDER;
			templateData.yesLookupValueId = thisView.constantLookupValues.get('BOOLEAN','TRUE');
			templateData.noLookupValueId = thisView.constantLookupValues.get('BOOLEAN','FALSE');
			templateData.canBeDoneFromHomeLookupValueId = thisView.constantLookupValues.get('STUDY_LOCATION','CAN_BE_DONE_FROM_HOME');
			templateData.healthyVolunteerLookupValueId = thisView.constantLookupValues.get('PARTICIPANT_TYPE','HEALTHY');

			var searchQuery = thisView.model.get('searchQuery');
			var options = searchQuery.split('&');
			var searchOptions = [];
			$.each(options, function (optionIndex, option) {
				var optionName = option.split('=')[0];
				var optionValue = option.split('=')[1];

				if (!optionValue) {
					return true;
				}
				switch (optionName) {
				case 'topics':
					if (thisView.isOptionValueValid(optionValue, 'MEDICAL_CONDITION')) {
						searchOptions.push(i18n.t('studyResults:form.field.researchTopics.shortLabel'));
					}
					break;
				case 'gender':
					if (thisView.isOptionValueValid(optionValue, 'GENDER')) {
						searchOptions.push(i18n.t('studyResults:form.field.genderAgeAndPregnancy.gender.desc'));
					}
					break;
				case 'age':
					var agePattern = /^\d{0,3}$/g;
					if (agePattern.test(optionValue)) {
						searchOptions.push(i18n.t('studyResults:form.field.genderAgeAndPregnancy.age.label'));
					}
					break;
				case 'pregnant':
					if (thisView.isOptionValueValid(optionValue, 'BOOLEAN')) {
						searchOptions.push(i18n.t('studyResults:form.field.genderAgeAndPregnancy.pregnancy.label'));
					}
					break;
				case 'locations':
					if (!isNaN(optionValue) && Number(optionValue) === Number(thisView.constantLookupValues.get('STUDY_LOCATION','CAN_BE_DONE_FROM_HOME'))) {
						searchOptions.push(i18n.t('studyResults:form.field.studiesThatCanBeDoneFromHome.shortLabel'));
					}
					break;
				case 'onlyHealthy':
					if (!isNaN(optionValue) && Number(optionValue) === Number(thisView.constantLookupValues.get('PARTICIPANT_TYPE','HEALTHY'))) {
						searchOptions.push(i18n.t('studyResults:form.field.healthyVolunteer.shortLabel'));
					}
					break;
				case 'onlyComp':
					if (!isNaN(optionValue) && Number(optionValue) === Number(thisView.constantLookupValues.get('BOOLEAN','TRUE'))) {
						searchOptions.push(i18n.t('studyResults:form.field.compensation.label'));
					}
					break;
				case 'pi':
					for (var i = 0; i < thisView.piNames.length; i++) {
						if (thisView.piNames[i].id === Number(decodeURI(optionValue))) {
							searchOptions.push(i18n.t('studyResults:form.field.principalInvestigator.label'));
						}
					}
					break;
				}
			});

			templateData.searchOptions = searchOptions;
			templateData.isNoFilter = (searchOptions.length === 0);
			templateData.totalStudyCount = thisView.model.get('totalCount');
			templateData.startStudyCount = (thisView.model.get('pageNum') - 1) * thisView.model.get('pageSize') + 1;
			templateData.endStudyCount = templateData.startStudyCount + thisView.model.get('pageSize') - 1;

			if (templateData.endStudyCount > thisView.model.get('totalCount')) {
				templateData.endStudyCount = thisView.model.get('totalCount');
			}

			templateData.pageNumbers = [];
			for (var i = 1; i <= Math.ceil(thisView.model.get('totalCount') / thisView.model.get('pageSize')); i++) {
				templateData.pageNumbers.push({pageNumber: i});
			}
			return templateData;
		},

		render: function () {
			// noinspection JSValidateTypes
			this.$el.html(template(this.generateTemplateData()));
			//popup creates a wrapper around the markup within the view and puts it outside the view markup within the body of document. After that destroying view doesn't remove popup and the next time when filter gets rendered by view the popup plugin gets a hold of the one that's wrapped up outside the view markup which is emptied by controller.base.js destroyAllViews method upon re-rendering of view.
			$('#asideFiltersContainer_wrapper').remove();
			var cssTransition = 'ease ' + popupCssTransitionTime + 'ms';
			popupModule.initializePopup(this.$el.find('#asideFiltersContainer'), {
				scrolllock: true,
				pagecontainer: 'main',
				transition: cssTransition,
				blur: false
			});
			this.setupFilterPopupEvents();
			this.populateFilterPopup();
			this.renderSubviewAppend(this.$el.find('#divStudyCardList'), 'studyCardList', this.subViews.studyCardListView);

			if (this.isFirstPage()) {
				this.$el.find('#aPrev').attr('disabled', 'disabled');
			}
			if (this.isLastPage()) {
				this.$el.find('#aNext').attr('disabled', 'disabled');
			}
			this.$el.find('#selectPage').val(this.model.get('pageNum'));
			this.$el.find('.sticky-header').stickEmUp({
				stickOffset: -200
			});
			this.scrollToStudy(util.getUrlParam('scroll-to'));
			return this;
		}
	});
})();
