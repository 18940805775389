var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header>\n  <h1 class=\"grid-sm-1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"menuText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":43}}}))
    + "</h1>\n  <div class=\"top-close-button\">\n      <a data-history-navigate href=\"javascript:void(0);\" class=\"asideMobileMenuPopup_close\"><span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span><span class=\"hide show-for-sr\">Close</span></a>\n  </div>\n</header>\n<section class=\"popup-main\">\n  <nav class=\"main-menu-nav\">\n    <ul class=\"nav-list\">\n      <li>\n          <a data-bypass href=\"/login/volunteer\">\n            <span class=\"fa fa-user fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"userRoles.volunteer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":85},"end":{"line":12,"column":115}}}))
    + " "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"loginText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":116},"end":{"line":12,"column":136}}}))
    + "\n          </a>\n      </li>\n      <li>\n          <a data-bypass href=\"/login/study-team\"><span class=\"fa fa-user fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"userRoles.studyTeam",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":123},"end":{"line":16,"column":153}}}))
    + " "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"loginText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":154},"end":{"line":16,"column":174}}}))
    + "</a>\n      </li>\n    </ul>\n  </nav>\n  <nav class=\"sign-up-nav\">\n    <ul class=\"nav-list\">\n      <li class=\"text-center\">\n          <a data-history-navigate href=\"/signup\" class=\"small-special-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.signUp.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":79},"end":{"line":23,"column":109}}}))
    + "</a>\n          <a data-history-navigate href=\"/about/volunteers\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"whySignUp.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":60},"end":{"line":24,"column":90}}}))
    + "</a>\n"
    + ((stack1 = container.invokePartial(require("./partials/language.picker.hbs"),depth0,{"name":"language.picker","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </li>\n    </ul>\n  </nav>\n\n  <nav>\n    <ul class=\"nav-list\">\n      <li>\n          <a data-history-navigate href=\"/about/volunteers\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"howCanAppHelpMe",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":33,"column":67},"end":{"line":33,"column":142}}}))
    + "</a>\n      </li>\n      <li>\n          <a data-history-navigate href=\"mailto:"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1), depth0))
    + "\" class=\"asideMobileMenuPopup_close\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"contactUs.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":120},"end":{"line":36,"column":150}}}))
    + "</a>\n      </li>\n  </nav>\n</section>\n";
},"usePartial":true,"useData":true});