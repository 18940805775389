(function () {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({

		url: function () {
			var url = '/backend/';

			url = url + 'secure/volunteer/' + this.get('volunteerUserId') + '/conversations?page=' + this.get('pageNum') + '&page-size=' + this.get('pageSize');
			return url;

		}
	});
	module.exports = Model;
})();

