var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<aside id=\"asideTellMeMore\">\n    <header>\n        <h1 class=\"grid-sm-1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.lovedOne.relationship.main.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":88}}}))
    + "</h1>\n        <div class=\"top-close-button\">\n            <a data-history-navigate href=\"javascript:void(0);\" class=\"asideTellMeMore_close\">\n                <span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span>\n                <span class=\"hide show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":47},"end":{"line":7,"column":87}}}))
    + "</span>\n            </a>\n        </div>\n    </header>\n    <section class=\"popup-main\">\n        <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.lovedOne.relationship.adultGuardian.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":26},"end":{"line":12,"column":93}}}))
    + "</h1>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.lovedOne.relationship.adultGuardian.body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":76}}}))
    + "\n        </p>\n        <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.lovedOne.relationship.powerOfAttorney.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":26},"end":{"line":16,"column":95}}}))
    + "</h1>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.lovedOne.relationship.powerOfAttorney.body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":78}}}))
    + "\n        </p>\n        <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.lovedOne.relationship.nextOfKin.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":26},"end":{"line":20,"column":89}}}))
    + "</h1>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:popup.lovedOne.relationship.nextOfKin.body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":72}}}))
    + "\n        </p>\n        <div class=\"button-container text-center\">\n            <a data-history-navigate class=\"primary-button asideTellMeMore_close\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.ok.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":82},"end":{"line":25,"column":108}}}))
    + "</a>\n        </div>\n    </section>\n</aside>\n";
},"useData":true});