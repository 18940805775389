var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"grid-row\">\n    <div class=\"grid-sm-1 grid-lg-9-12 grid-centered filter-studies "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNoFilter") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":68},"end":{"line":5,"column":116}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"status-container\">\n            <div class=\"count\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyCount",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),"totalStudyCount":(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0)},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":106}}}))
    + "</div>\n            <div class=\"applied-filters\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNoFilter") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"button-container\">\n            <a data-bypass id=\"aOpenPopupFilter\" class=\"small-primary-button\" role=\"button\"\n               aria-controls=\"asideFiltersContainer\" aria-expanded=\"false\">\n                <span class=\"fa single-icon fa-sliders\" aria-hidden=\"true\"></span>\n                <span class=\"fa-stack\" aria-hidden=\"true\">\n                    <i class=\"fa fa-circle fa-stack-2x\"></i>\n                    <i class=\"fa fa-sliders fa-stack-1x\"></i>\n                </span>\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:filter.name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":51}}}))
    + "\n            </a>\n        </div>\n    </div>\n</section>\n\n<!-- Sticky header -->\n<div class=\"sticky-header\" aria-hidden=\"true\">\n    <div class=\"count\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:heading",{"name":"i18n","hash":{"entity":"studies","totalStudyCount":(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),"endStudyCount":(depth0 != null ? lookupProperty(depth0,"endStudyCount") : depth0),"startStudyCount":(depth0 != null ? lookupProperty(depth0,"startStudyCount") : depth0)},"data":data,"loc":{"start":{"line":36,"column":23},"end":{"line":36,"column":162}}}))
    + "</div>\n    <div class=\"button-container\">\n        <a data-bypass id=\"aOpenPopupFilter\" class=\"small-primary-button\" role=\"button\"\n           aria-controls=\"asideFiltersContainer\" aria-expanded=\"false\"><span class=\"fa fa-sliders show-for-medium-up\"\n                                                                             aria-hidden=\"true\"></span> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:filter.name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":104},"end":{"line":40,"column":139}}}))
    + "</a>\n    </div>\n</div>\n\n<section class=\"grid-row study-list\">\n    <div class=\"grid-sm-1 grid-lg-8-12 grid-centered\" id=\"divStudyCardList\"></div>\n</section>\n\n<!-- Pagination -->\n<section class=\"grid-row\">\n    <div class=\"grid-1\">\n        <section class=\"pagination\">\n            <div class=\"pagination-label\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:heading",{"name":"i18n","hash":{"entity":"studies","totalStudyCount":(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),"endStudyCount":(depth0 != null ? lookupProperty(depth0,"endStudyCount") : depth0),"startStudyCount":(depth0 != null ? lookupProperty(depth0,"startStudyCount") : depth0)},"data":data,"loc":{"start":{"line":53,"column":16},"end":{"line":53,"column":155}}}))
    + "\n            </div>\n            <a data-bypass id=\"aPrev\" class=\"small-outline-button\"><span class=\"fa fa-chevron-left\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:previous",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":55,"column":126},"end":{"line":55,"column":156}}}))
    + "</a>\n            <a data-bypass id=\"aNext\" class=\"small-outline-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":56,"column":67},"end":{"line":56,"column":93}}}))
    + "<span class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span></a>\n            <section class=\"paginate-to-specific-page\">\n                <a href=\"#divPaginatePageNum\" data-bypass data-bs-toggle=\"collapse\" aria-expanded=\"false\"\n                   aria-controls=\"divPaginatePageNum\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:open",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":54},"end":{"line":59,"column":80}}}))
    + "</a>\n                <div class=\"collapse\" id=\"divPaginatePageNum\">\n                    <form>\n                        <label for=\"selectPage\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:page",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":62,"column":48},"end":{"line":62,"column":70}}}))
    + "</label>\n                        <select id=\"selectPage\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pageNumbers") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":28},"end":{"line":66,"column":37}}})) != null ? stack1 : "")
    + "                        </select>\n                    </form>\n                    <a href=\"#divPaginatePageNum\" data-bypass data-bs-toggle=\"collapse\" aria-expanded=\"false\"\n                       aria-controls=\"divPaginatePageNum\"><span class=\"fa fa-chevron-up\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.close.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":70,"column":115},"end":{"line":70,"column":144}}}))
    + "</a>\n                </div>\n            </section>\n        </section>\n    </div>\n</section>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "filters-applied";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <strong>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyResults:filter.notApplied",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":69}}}))
    + "</strong>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <strong>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studyResults:filter.applied",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":28},"end":{"line":12,"column":66}}}))
    + "</strong>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"searchOptions") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":15,"column":29}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "                        <span>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pageNumber") || (depth0 != null ? lookupProperty(depth0,"pageNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data,"loc":{"start":{"line":65,"column":47},"end":{"line":65,"column":61}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"pageNumber") || (depth0 != null ? lookupProperty(depth0,"pageNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data,"loc":{"start":{"line":65,"column":63},"end":{"line":65,"column":77}}}) : helper)))
    + "</option>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!----- SHOW THE FOLLOWING IF NO STUDIES ARE FOUND ------>\n<div class=\"grid-row margin-top\">\n    <section class=\"grid-sm-1 grid-md-9-12 grid-lg-6-12 grid-centered no-studies-found\">\n        <div class=\"media\">\n            <span class=\"fa fa-frown-o\"></span>\n        </div>\n        <div class=\"content\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:noResults.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":85,"column":57}}}))
    + "</h1>\n            <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:noResults.body",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":86,"column":15},"end":{"line":86,"column":53}}}))
    + "<br/><br/>\n                <a data-history-navigate href=\"/\"><span class=\"fa fa-refresh fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:noResults.searchAgain",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":87,"column":120},"end":{"line":87,"column":165}}}))
    + "</a> <span\n                    class=\"secondary-font-color\">|</span>\n                <a data-bypass id=\"aAdjustFilter\" class=\"asideFiltersContainer_open\"><span class=\"fa fa-sliders fa-margin-right\"\n                                                                     aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:noResults.adjustFilter",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":90,"column":95},"end":{"line":90,"column":141}}}))
    + "</a> <span\n                    class=\"secondary-font-color\">|</span>\n                <a data-history-navigate href=\"/signup\" class=\"special-font-color\"><span class=\"fa fa-user-plus fa-margin-right\"\n                                                                   aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:noResults.signup",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":93,"column":93},"end":{"line":93,"column":133}}}))
    + "</a>\n            </p>\n        </div>\n    </section>\n</div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"checkbox-gender-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":140,"column":56},"end":{"line":140,"column":62}}}) : helper)))
    + "\" class=\"block-label\">\n                                <input id=\"checkbox-gender-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":141,"column":59},"end":{"line":141,"column":65}}}) : helper)))
    + "\" type=\"checkbox\" name=\"gender\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":141,"column":104},"end":{"line":141,"column":110}}}) : helper)))
    + "\"/>\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"displayText") || (depth0 != null ? lookupProperty(depth0,"displayText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayText","hash":{},"data":data,"loc":{"start":{"line":142,"column":32},"end":{"line":142,"column":47}}}) : helper)))
    + "\n                            </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.studyResults",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":60}}}))
    + "</h1>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":3,"column":0},"end":{"line":98,"column":7}}})) != null ? stack1 : "")
    + "\n<!---------- POPUP CONTENT FOR FILTERS ---------->\n<aside class=\"filters\" id=\"asideFiltersContainer\">\n    <header>\n        <h1 class=\"grid-sm-1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":103,"column":30},"end":{"line":103,"column":64}}}))
    + "</h1>\n        <div class=\"top-close-button\">\n            <a data-bypass class=\"asideFiltersContainer_close\"><span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span><span\n                    class=\"hide show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":106,"column":45},"end":{"line":106,"column":85}}}))
    + "</span></a>\n        </div>\n    </header>\n    <section class=\"popup-main\">\n        <form class=\"form-stacked accordion\" id=\"formPopupFilter\">\n            <section id=\"sectionResearchTopics\">\n                <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divResearchTopics\" aria-expanded=\"false\"\n                   aria-controls=\"divResearchTopics\" class=\"collapse-handler\">\n                    <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.researchTopics.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":114,"column":100},"end":{"line":114,"column":155}}}))
    + "</h2>\n                </a>\n\n                <div class=\"collapse \" id=\"divResearchTopics\">\n                    <!-- Topics -->\n                    <div class=\"field-container typeahead-container\">\n                        <label for=\"textTopics\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.researchTopics.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":120,"column":48},"end":{"line":120,"column":102}}}))
    + "</label>\n\n                        <div id=\"divTopics\" role=\"list\"></div>\n\n                        <input id=\"textTopics\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.typeToAdd",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":124,"column":90},"end":{"line":124,"column":128}}}))
    + "\">\n                    </div>\n                </div>\n            </section>\n\n            <section id=\"sectionGenderAgePregnancy\">\n                <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divGenderAgePregnancy\" aria-expanded=\"false\"\n                   aria-controls=\"divGenderAgePregnancy\" class=\"collapse-handler\">\n                    <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":132,"column":100},"end":{"line":132,"column":162}}}))
    + "</h2>\n                </a>\n\n                <div class=\"collapse \" id=\"divGenderAgePregnancy\">\n                    <!-- Gender -->\n                    <div class=\"field-container\">\n                        <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.gender.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":138,"column":31},"end":{"line":138,"column":99}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"GENDER") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":24},"end":{"line":144,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n\n                    <!-- Pregnancy -->\n                    <div class=\"field-container hide\" id=\"divPregnancy\">\n                        <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.pregnancy.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":149,"column":31},"end":{"line":149,"column":102}}}))
    + "</label>\n                        <div>\n                            <label for=\"radioPregnancyYes\" class=\"block-label\">\n                                <input id=\"radioPregnancyYes\" type=\"radio\" name=\"pregnant\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"yesLookupValueId") || (depth0 != null ? lookupProperty(depth0,"yesLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"yesLookupValueId","hash":{},"data":data,"loc":{"start":{"line":152,"column":98},"end":{"line":152,"column":118}}}) : helper)))
    + "\" disabled>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.pregnancy.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":152,"column":129},"end":{"line":152,"column":199}}}))
    + "\n                            </label>\n\n                            <label for=\"radioPregnancyNo\" class=\"block-label\">\n                                <input id=\"radioPregnancyNo\" type=\"radio\" name=\"pregnant\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noLookupValueId") || (depth0 != null ? lookupProperty(depth0,"noLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noLookupValueId","hash":{},"data":data,"loc":{"start":{"line":156,"column":97},"end":{"line":156,"column":116}}}) : helper)))
    + "\" disabled>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.pregnancy.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":156,"column":127},"end":{"line":156,"column":196}}}))
    + "\n                            </label>\n                        </div>\n                        <div class=\"field-help-text\">\n                            <a data-bypass id=\"aClearPregnancy\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.clear",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":160,"column":64},"end":{"line":160,"column":126}}}))
    + "</a>\n                        </div>\n                    </div>\n\n                    <!-- age -->\n                    <div id=\"divAge\" class=\"field-container validation-error-container\">\n                        <label for=\"textAge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.genderAgeAndPregnancy.age.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":166,"column":45},"end":{"line":166,"column":110}}}))
    + "</label>\n                        <input type=\"text\" id=\"textAge\" maxlength=\"3\" size=\"3\" name=\"age\" value=\"\" data-parsley-pattern=\"^\\d{0,3}$\" data-parsley-validate-if-empty data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:invalidAge",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":167,"column":191},"end":{"line":167,"column":226}}}))
    + "\"/>\n                    </div>\n                </div>\n            </section>\n\n            <section id=\"sectionHealthyVolStudies\">\n                <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divHealthyVolStudies\" aria-expanded=\"false\"\n                   aria-controls=\"divHealthyVolStudies\" class=\"collapse-handler\">\n                    <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.healthyVolunteer.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":175,"column":100},"end":{"line":175,"column":157}}}))
    + "</h2>\n                </a>\n\n                <div class=\"collapse \" id=\"divHealthyVolStudies\">\n                    <!-- Healthy -->\n                    <div class=\"field-container\">\n                        <label for=\"checkboxOnlyShowHealthy\" class=\"block-label\">\n                            <input id=\"checkboxOnlyShowHealthy\" type=\"checkbox\" name=\"onlyHealthy\"\n                                   value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"healthyVolunteerLookupValueId") || (depth0 != null ? lookupProperty(depth0,"healthyVolunteerLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"healthyVolunteerLookupValueId","hash":{},"data":data,"loc":{"start":{"line":183,"column":42},"end":{"line":183,"column":75}}}) : helper)))
    + "\"/>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.healthyVolunteer.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":183,"column":78},"end":{"line":183,"column":134}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </section>\n\n            <section id=\"sectionCompensationPreference\">\n                <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divCompensationPreference\" aria-expanded=\"false\"\n                   aria-controls=\"divCompensationPreference\" class=\"collapse-handler\">\n                    <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.compensation.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":192,"column":100},"end":{"line":192,"column":153}}}))
    + "</h2>\n                </a>\n\n                <div class=\"collapse \" id=\"divCompensationPreference\">\n                    <div class=\"field-container\">\n                        <!-- Compensation -->\n                        <label for=\"checkboxOffersCompensation\" class=\"block-label\">\n                            <input id=\"checkboxOffersCompensation\" type=\"checkbox\" name=\"onlyComp\"\n                                   value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"yesLookupValueId") || (depth0 != null ? lookupProperty(depth0,"yesLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"yesLookupValueId","hash":{},"data":data,"loc":{"start":{"line":200,"column":42},"end":{"line":200,"column":62}}}) : helper)))
    + "\"/>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.compensation.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":200,"column":65},"end":{"line":200,"column":117}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </section>\n\n            <section id=\"sectionCanBeDoneFromHome\">\n                <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divCanBeDoneFromHome\" aria-expanded=\"false\"\n                   aria-controls=\"divCanBeDoneFromHome\" class=\"collapse-handler\">\n                    <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.studiesThatCanBeDoneFromHome.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":209,"column":100},"end":{"line":209,"column":169}}}))
    + "</h2>\n                </a>\n\n                <div class=\"collapse \" id=\"divCanBeDoneFromHome\">\n                    <!-- Can be done from home -->\n                    <div class=\"field-container\">\n                        <label for=\"checkboxCanBeDoneFromHome\" class=\"block-label\">\n                            <input id=\"checkboxCanBeDoneFromHome\" type=\"checkbox\" name=\"locations\"\n                                   value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"canBeDoneFromHomeLookupValueId") || (depth0 != null ? lookupProperty(depth0,"canBeDoneFromHomeLookupValueId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"canBeDoneFromHomeLookupValueId","hash":{},"data":data,"loc":{"start":{"line":217,"column":42},"end":{"line":217,"column":76}}}) : helper)))
    + "\"/>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.studiesThatCanBeDoneFromHome.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":217,"column":79},"end":{"line":217,"column":147}}}))
    + "\n                        </label>\n                    </div>\n                </div>\n            </section>\n\n            <section id=\"sectionPIandDept\">\n                <a role=\"button\" data-bypass data-bs-toggle=\"collapse\" href=\"#divPIandDept\" aria-expanded=\"false\" aria-controls=\"divPIandDept\" class=\"collapse-handler\">\n                  <h2><span class=\"fa fa-plus-square-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.principalInvestigator.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":225,"column":98},"end":{"line":225,"column":160}}}))
    + "</h2>\n                </a>\n\n                <div class=\"collapse \" id=\"divPIandDept\">\n                  <!-- PI -->\n                  <div class=\"field-container typeahead-container\">\n                      <label for=\"textPIName\">\n                         "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.field.principalInvestigator.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":232,"column":25},"end":{"line":232,"column":86}}}))
    + "\n                      </label>\n                      <div id=\"divPIName\" role=\"list\"></div>\n\n                      <input id=\"textPIName\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.typeToAdd",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":236,"column":88},"end":{"line":236,"column":126}}}))
    + "\">\n                  </div>\n                </div>\n              </section>\n\n            <div class=\"center-button-container\">\n                <button  class=\"asideFiltersContainer_close outline-button margin-right-5\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.close.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":242,"column":91},"end":{"line":242,"column":120}}}))
    + "</button>\n                <button class=\"primary-button\" id=\"buttonApplyFilter\">\n                    <span class=\"fa fa-check\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyResults:form.submit.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":244,"column":72},"end":{"line":244,"column":113}}}))
    + "\n                </button>\n            </div>\n        </form>\n    </section>\n</aside>\n";
},"useData":true});