var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n  <div class=\"grid-sm-1 grid-md-1-2\">\n    <nav>\n      <ul>\n        <li>\n          <a data-history-navigate href=\"https://www.bethenewnormal.org\" target=\"_blank\">How "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"institutionName") : stack1), depth0))
    + " Movement Helps Humanity</a>\n        </li>\n        <li>\n          <a data-history-navigate href=\"https://bethenewnormal.org/newsletter/\" target=\"_blank\">The New Normal Latest in Your Inbox! Become an Insider Here</a>\n        </li>\n        <li>\n          <a data-history-navigate href=\"javascript:void(0);\" id=\"aUserAgreementFooter\">User Agreement - The Technical Who, What, Where, and Why</a>\n        </li>\n        <li>\n          <a  data-bypass href=\"/privacy\">Privacy Practices - Spoiler Alert: You're the Boss of Your Info!</a>\n        </li>\n      </ul>\n    </nav>\n  </div>\n  <section class=\"grid-sm-1 grid-md-1-2\">\n      <div class=\"contact-card\">\n        <h1 class=\"gamma\">Connect With Us</h1>\n        <address>\n            <span class=\"tel\"><span class=\"fa fa-phone fa-fw\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">Telephone:</span>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportPhoneNumber") : stack1), depth0))
    + "</span>\n            <span class=\"email\"><span class=\"fa fa-envelope fa-fw\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">E-mail:</span><a data-history-navigate href=\"mailto:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1), depth0))
    + "</a></span>\n        </address>\n      </div>\n  </section>\n</div>\n\n<div class=\"grid-row\">\n  <section class=\"grid-1 social\">\n    <a data-history-navigate href=\"https://www.facebook.com/bethenewnorm\"><span class=\"fa fa-facebook-square fa-margin-right\" aria-hidden=\"true\"></span><span class=\"show-for-sr\">Follow us on Facebook</span></a>\n    <a data-history-navigate href=\"https://twitter.com/bethenewnorm\"><span class=\"fa fa-twitter-square fa-margin-right\" aria-hidden=\"true\"></span><span class=\"show-for-sr\">Follow us on Twitter</span></a>\n    <a data-history-navigate href=\"https://www.instagram.com/bethenewnorm/\"><span class=\"fa fa-instagram fa-margin-right\" aria-hidden=\"true\"></span><span class=\"show-for-sr\">Follow us on Instagram</span></a>\n    <a data-history-navigate href=\"https://www.linkedin.com/company/bethenewnorm/\"><span class=\"fa fa-linkedin-square fa-margin-right\" aria-hidden=\"true\"></span><span class=\"show-for-sr\">Follow us on LinkedIn</span></a>\n    <a data-history-navigate href=\"https://www.youtube.com/channel/UCwBjGJO8rKyCtynq_tO2WVw/featured?view_as=subscriber\"><span class=\"fa fa-youtube-square fa-margin-right\" aria-hidden=\"true\"></span><span class=\"show-for-sr\">Follow us on Youtube</span></a>\n  </section>\n</div>\n\n<div class=\"grid-row logos\">\n  <div class=\"grid-sm-1 grid-md-1-2\">\n      <a data-history-navigate href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"institutionWebSite") : stack1), depth0))
    + "\" class=\"department-logo\"></a>\n  </div>\n  <div class=\"grid-sm-1 grid-md-1-2\">\n      <a data-history-navigate href=\"https://yourhealthresearch.org\" class=\"powered-by\"></a>\n      <div class=\"admin-login margin-top-small\">\n          <a data-bypass href=\"../Shibboleth.sso/Login?target=/study-team/studies/current&entityID=https://shibboleth.umich.edu/idp/shibboleth\">\n              YourHealthResearch Support Login</a>\n          <span class=\"fa fa-question-circle\" title=\"This platform is provided by University of Michigan's YourHealthResearch initiative. YourHealthResearch team members use this link to log into the application to provide your institution with the support it needs.\"></span>\n      </div>\n  </div>\n</div>\n\n<div class=\"grid-row\">\n  <section class=\"grid-1 copyright\">\n    <div>Copyright © "
    + alias2(((helper = (helper = lookupProperty(helpers,"currentYear") || (depth0 != null ? lookupProperty(depth0,"currentYear") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"currentYear","hash":{},"data":data,"loc":{"start":{"line":57,"column":21},"end":{"line":57,"column":36}}}) : helper)))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"institutionName") : stack1), depth0))
    + ". All Rights Reserved.</div>\n  </section>\n</div>\n\n";
},"useData":true});