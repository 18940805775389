

(function () {
	'use strict';
	var template = require('../../templates/hbs/interest.failure.hbs');
	var eligibilityCriteriaUtil = require('../../../../shared-scripts/app-util/eligibility.criteria.helper');

	var View = Backbone.View.extend({
		el: 'main',

		initialize: function () {
			this.subViews = {};
		},

		generateTemplateData: function () {
			var templateData = {};

			var page = this.model.get('page');
			if (!page) {
				return templateData;
			}

			var study = page[0];

			templateData.hasGroups = study.eligibilityCriteria.length>0;
			templateData.eligibilityCriteriaGroupNumber = study.eligibilityCriteria.length;
			templateData.moreThanOneGroup = study.eligibilityCriteria.length > 1;

			templateData.eligibilityCriteria = eligibilityCriteriaUtil.generateEligibilityCriteriaTemplateData(study.eligibilityCriteria);

			templateData.contactName = study.contactName;
			templateData.contactPhone = study.contactPhone;
			templateData.contactEmail = study.contactEmail;
			templateData.contactWebsite = study.contactWebsite;

			return templateData;
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			return this;
		}
	});

	module.exports = View;
})();
