(function () {
	'use strict';

	var cookieConsentModule = require('~institution-specific-config/modules/module.cookie.consent');
	var userAgreementModule = require('../../../../shared-scripts/modules/module.user.agreement');
	// var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var cookieName = cookieConsentModule.cookieName;
	var enableCookies = cookieConsentModule.enableCookies;
	var deleteCookies = cookieConsentModule.deleteCookies;
	var allowValue = cookieConsentModule.allowValue;
	var denyValue = cookieConsentModule.denyValue;
	var appConfig = require('~institution-specific-config/app.config.json');
	var i18n = require('../../../../shared-scripts/app-util/i18n');
	var template = i18n.getLanguageInstitutionSpecificFile('PRIVACY_STATEMENT');

	var View = Backbone.View.extend({
		el: 'main',
		events: {
			'click #checkboxCookieConsent': 'toggleCookieConsent',
			'click #asideUserAgreement': 'openUserAgreement'
		},

		toggleCookieConsent: function () {
			if ($('#checkboxCookieConsent').is(':checked')) {
				window.cookieconsent.utils.setCookie(cookieName, allowValue, 365);
				enableCookies();
			} else {
				window.cookieconsent.utils.setCookie(cookieName, denyValue, 365);
				deleteCookies();
			}
		},
		render: function () {
			var cookieValue = window.cookieconsent.utils.getCookie(cookieName);
			var supportInfo = appConfig.supportInfo;

			this.$el.html(template({
				applicationName: appConfig.organizationInfo.applicationName,
				supportPhoneNumber: supportInfo.supportPhoneNumber,
				supportEmailAddress: supportInfo.supportEmailAddress
			}));

			$('#checkboxCookieConsent').prop('checked', cookieValue === allowValue);
			return this;
		},
		openUserAgreement: function () {
			userAgreementModule.showUserAgreement({userAgreementType: 'VOL'});
		}
	}
	);
	module.exports = View;
})();
