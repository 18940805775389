(function () {
	'use strict';
	var template = require('../../templates/hbs/interest.success.hbs');

	var View = Backbone.View.extend({
		el: 'main',

		initialize: function () {
			this.subViews = {};
		},

		render: function () {
			var page = this.model.get('page');
			this.$el.html(template(page?page[0]:{}));
			return this;
		}
	}
	);

	module.exports = View;
})();
