var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row grid-no-padding\">\n  <section class=\"grid-sm-1\">\n    <nav class=\"secondary-nav horizontal\">\n      <a data-history-navigate href=\"signup"
    + alias4(((helper = (helper = lookupProperty(helpers,"interestedStudy") || (depth0 != null ? lookupProperty(depth0,"interestedStudy") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"interestedStudy","hash":{},"data":data,"loc":{"start":{"line":4,"column":43},"end":{"line":4,"column":62}}}) : helper)))
    + "\"><span class=\"fa fa-check-circle fa-margin-right\"><span class=\"show-for-sr\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.youAreHere",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":139},"end":{"line":4,"column":173}}}))
    + "</span></span>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:tab.self.name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":187},"end":{"line":4,"column":218}}}))
    + "</a>\n      <a data-history-navigate href=\"signup-loved-one"
    + alias4(((helper = (helper = lookupProperty(helpers,"interestedStudy") || (depth0 != null ? lookupProperty(depth0,"interestedStudy") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"interestedStudy","hash":{},"data":data,"loc":{"start":{"line":5,"column":53},"end":{"line":5,"column":72}}}) : helper)))
    + "\" class=\"active\"><span class=\"fa fa-check-circle fa-margin-right\"><span class=\"show-for-sr\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.youAreHere",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":164},"end":{"line":5,"column":198}}}))
    + "</span></span>\n          "
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:tab.lovedOne.name",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":45}}}))
    + "</a>\n    </nav>\n  </section>\n</div>\n<div class=\"grid-row margin-top\">\n    <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-9-12\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <h1>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:tab.lovedOne.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":54}}}))
    + "</h1>\n            <p class=\"secondary-font-color text-center\">\n                "
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:tab.lovedOne.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":51}}}))
    + "\n            </p>\n        </header>\n      </section>\n</div>\n\n<div class=\"grid-row\">\n      <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-9-12\">\n        <form class=\"form-stacked\" id=\"formSignupLovedOne\">\n"
    + ((stack1 = container.invokePartial(require("./partials/accountInfo.section.hbs"),depth0,{"name":"accountInfo.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"form-section-heading\">\n                "
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.group.aboutSelf.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":70}}}))
    + "\n            </div>\n"
    + ((stack1 = container.invokePartial(require("./partials/lovedOne.relationship.section.hbs"),depth0,{"name":"lovedOne.relationship.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"form-section-heading\">\n                "
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.group.aboutLovedOne.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":31,"column":74}}}))
    + "\n            </div>\n"
    + ((stack1 = container.invokePartial(require("./partials/basicInfo.section.hbs"),depth0,{"name":"basicInfo.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/raceAndEthnicity.section.hbs"),depth0,{"name":"raceAndEthnicity.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/healthInfo.section.hbs"),depth0,{"name":"healthInfo.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/smokingStatus.section.hbs"),depth0,{"name":"smokingStatus.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/profileVisibility.section.hbs"),depth0,{"name":"profileVisibility.section","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/signup.submit.button.hbs"),depth0,{"name":"signup.submit.button","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </form>\n    </section>\n</div>\n\n"
    + ((stack1 = container.invokePartial(require("./partials/signup.no.email.popup.hbs"),depth0,{"name":"signup.no.email.popup","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(require("./partials/lovedOne.tellMeMore.popup.hbs"),depth0,{"name":"lovedOne.tellMeMore.popup","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n";
},"usePartial":true,"useData":true});