var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <form class=\"form-stacked\"\n                  id=\"frmLogin\"\n                  action=\"/Shibboleth.sso/Login\"\n                  method=\"GET\">\n                <div class=\"field-container validation-error-container\">\n                    <label for=\"selectInstitution\">Institution</label>\n                    <input id=\"hiddenTargetUrl\" type=\"hidden\" value=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"target") || (depth0 != null ? lookupProperty(depth0,"target") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"target","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":18,"column":69},"end":{"line":18,"column":79}}}) : helper)))
    + "\" name=\"target\"/>\n                    <select id=\"selectInstitution\" class=\"input-1\" name=\"entityID\">\n                        <option value=\"\">Please select an institution</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"identityProviders") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":21,"column":24},"end":{"line":25,"column":33}}})) != null ? stack1 : "")
    + "                    </select>\n                </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedIdP") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":29,"column":16},"end":{"line":31,"column":23}}})) != null ? stack1 : "")
    + "            </form>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <option value=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"entityID") : stack1), depth0))
    + "\" "
    + alias2(__default(require("../../../../shared-scripts/app-util/handlebars-helpers/cond.js")).call(alias3,__default(require("../../../../shared-scripts/app-util/handlebars-helpers/eq.js")).call(alias3,((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"entityID") : stack1),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"selectedIdP") : depths[1])) != null ? lookupProperty(stack1,"entityID") : stack1),{"name":"eq","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":22,"column":68},"end":{"line":22,"column":109}}}),"selected",{"name":"cond","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":22,"column":61},"end":{"line":22,"column":122}}}))
    + ">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"displayName") : stack1), depth0))
    + "</option>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                    <button type=\"submit\" class=\"primary-button\" id=\"submitLogin\" value=\"SIGN IN\">Log in</button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "            <div id=\"divWait\" class=\"overlay\" tabindex=\"-1\" aria-hidden=\"true\">\n                <div class=\"spinner\">\n                    <div class=\"double-bounce1\"></div>\n                    <div class=\"double-bounce2\"></div>\n                </div>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./stripped.header.hbs"),depth0,{"name":"stripped.header","data":data,"blockParams":blockParams,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<!-- Study Team Login box -->\n<section class=\"grid-row login-content\" id=\"divLoginBox\">\n    <div class=\"grid-sm-11-12 grid-sm-centered grid-md-7-12 grid-md-centered grid-lg-5-12 grid-lg-centered\">\n        <header>\n            <h1>Study Team Log in</h1>\n        </header>\n\n        <div id=\"divFeedback\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"multipleIdentityProviders") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"blockParams":blockParams,"loc":{"start":{"line":11,"column":8},"end":{"line":40,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</section>\n";
},"usePartial":true,"useData":true,"useDepths":true,"useBlockParams":true});