window.jQuery = window.$ = require('jquery');
window._ = require('underscore');
window.Backbone = require('backbone');

Backbone.View.prototype.destroy = function () {
	// COMPLETELY UNBIND THE VIEW
	this.undelegateEvents();
	//this.$el.removeData().unbind();
	this.$el.empty();
};

Backbone.View.prototype.close = function () {
	_.each(this.subViews, function (view) {
		view.close();
	});
	this.destroy();
};

Backbone.View.prototype.closeSubViews = function () {
	var thisView = this;
	_.each(thisView.subViews, function (view) {
		view.close();
	});
};

/**
 * @description Renders the subview of a backbone view in the given html element as specified by css id selector
 * @function renderSubView
 * @param {String} $container -  jquery object of the html element that will contain the subview markup.
 * @param {String} subviewName - The name of the subview to be referenced by the parent view in subViews hash
 * @param {Backbone.View} subview - The Backbone subview object that will be rendered inside parent view
 * @param {boolean} - Whether to replace the container html or append to it
 */
Backbone.View.prototype.renderSubview = function ($container, subviewName, subview, replace) {
	this.subViews[subviewName] = subview;
	if (replace) {
		$container.html(subview.render().el);
	} else {
		$container.append(subview.render().el);
	}
};

Backbone.View.prototype.renderSubviewAppend = function ($container, subviewName, subview) {
	this.renderSubview($container, subviewName, subview, false);
};

Backbone.View.prototype.renderSubviewReplace = function ($container, subviewName, subview) {
	this.renderSubview($container, subviewName, subview, true);
};

Backbone.View.prototype.goTo = function (loc) {
	if (Backbone.history.fragment === loc.slice(loc.indexOf('/') + 1)) {
		Backbone.history.loadUrl(loc);
	} else {
		Backbone.history.navigate(loc, {trigger: true});
	}
};

Backbone.View.prototype.goToSilent = function (loc) {
	Backbone.history.navigate(loc, {trigger: false});
};

Backbone.View.prototype.goToForceRefresh = function (loc) {
	Backbone.history.fragment = null;
	Backbone.history.navigate(loc, {trigger: true});
};


Backbone.Collection = (function (Collection) {
	return Collection.extend({
		constructor: function (options) {
			this.options = options || {};
			Collection.apply(this, arguments);
		}
	});
})(Backbone.Collection);

Backbone.Router.prototype.destroyAllViews = function () {
	if (this.view) {
		if (this.view.close) {
			this.view.close();
		} else {
			this.view.remove();
		}
	}
	this.view = null;
};

Backbone.Router.prototype.loadView = function (viewConstructor, viewConstructorArguments) {
	this.destroyAllViews();
	this.view = new viewConstructor(viewConstructorArguments).render();
};
