var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "not-enrolling";
},"3":function(container,depth0,helpers,partials,data) {
    return "new";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"banner\">\n                "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:notRecruiting",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":51}}}))
    + "\n            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"banner\">\n                <span class=\"fa fa-star\" aria-hidden=\"true\"></span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:newStudy",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":97}}}))
    + "\n            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span id='spanMoreLocations' class=\"link-font-color text-hover-underline\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:showMore",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":119},"end":{"line":22,"column":149}}}))
    + "</span>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasAge2") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":37,"column":27}}})) != null ? stack1 : "")
    + "                    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:age.range",{"name":"i18n","hash":{"age2":(depth0 != null ? lookupProperty(depth0,"age2") : depth0),"age1":(depth0 != null ? lookupProperty(depth0,"age1") : depth0)},"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":28,"column":75}}}))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasAge1") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":37,"column":20}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:age.single",{"name":"i18n","hash":{"ageOp":(depth0 != null ? lookupProperty(depth0,"ageOp") : depth0),"age1":(depth0 != null ? lookupProperty(depth0,"age1") : depth0)},"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":30,"column":79}}}))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"multiple") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":37,"column":20}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:age.multiple",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":24},"end":{"line":32,"column":58}}}))
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"allAge") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":37,"column":20}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:age.all",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":53}}}))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"ageError") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":37,"column":20}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studyCards:age.error",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":24},"end":{"line":36,"column":55}}}))
    + "\n                    ";
},"26":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a data-history-navigate href=\"javascript: void(0);\" class=\"excludeStudy\" id=\"aNotInterested-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":51,"column":101},"end":{"line":51,"column":107}}}) : helper)))
    + "\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyCards:enrollmentStatus.notInterested",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":51,"column":109},"end":{"line":51,"column":161}}}))
    + "</a>\n        <a data-history-navigate href=\"javascript: void(0);\" class=\"excludeStudy\" id=\"aEnrolled-"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":52,"column":96},"end":{"line":52,"column":102}}}) : helper)))
    + "\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyCards:enrollmentStatus.enrolled",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":52,"column":104},"end":{"line":52,"column":151}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<article class=\"study-snippet wow "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":76}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"new") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":77},"end":{"line":2,"column":98}}})) != null ? stack1 : "")
    + "\">\n    <a data-history-navigate href=\"/studies/"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyNum") || (depth0 != null ? lookupProperty(depth0,"studyNum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyNum","hash":{},"data":data,"loc":{"start":{"line":3,"column":44},"end":{"line":3,"column":56}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"new") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + "        <h1>"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyTitle") || (depth0 != null ? lookupProperty(depth0,"studyTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyTitle","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":26}}}) : helper)))
    + "</h1>\n        <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyPurpose") || (depth0 != null ? lookupProperty(depth0,"studyPurpose") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyPurpose","hash":{},"data":data,"loc":{"start":{"line":15,"column":11},"end":{"line":15,"column":27}}}) : helper)))
    + "</p>\n\n        <!-- Medium up devices -->\n        <section class=\"study-overview\">\n            <div class=\"info-block\">\n                <div><strong>"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyCards:locations",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":29},"end":{"line":20,"column":60}}}))
    + "</strong></div>\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"locations") || (depth0 != null ? lookupProperty(depth0,"locations") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locations","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":29}}}) : helper)))
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showMoreLocationsLink") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":163}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"info-block \">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"ages") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":39,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"info-block\">\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"participantTypes") || (depth0 != null ? lookupProperty(depth0,"participantTypes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"participantTypes","hash":{},"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":42,"column":36}}}) : helper)))
    + "\n            </div>\n        </section>\n    </a>\n\n    <!-- For study snippets that need action buttons -->\n    <div class=\"actions\">\n        <a data-history-navigate href=\"/studies/"
    + alias4(((helper = (helper = lookupProperty(helpers,"studyNum") || (depth0 != null ? lookupProperty(depth0,"studyNum") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"studyNum","hash":{},"data":data,"loc":{"start":{"line":49,"column":48},"end":{"line":49,"column":60}}}) : helper)))
    + "\" class=\"more\">"
    + alias4((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studyCards:learnMore",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":75},"end":{"line":49,"column":106}}}))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"onStudiesForYouPage") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":53,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</article>\n";
},"useData":true});