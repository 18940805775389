(function() {
	'use strict';
	var Backbone = require('backbone');
	var ConversationCardModel= require('../models/model.conversation.card.js');
	var Collection = Backbone.Collection.extend({

		model: ConversationCardModel
	});
	module.exports = Collection;
})();

