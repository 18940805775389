(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.visibility.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var appConfig = require('~institution-specific-config/app.config.json');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'submit #formVisibility': 'saveSection',
			'click #aCancel': 'cancel'
		},

		cancel: function() {
			localStorage.setItem('feedback-card-cancel-container', 'visibility-card-feedback');
			util.redirect('/volunteers/profile');
		},

		saveSection: function(event) {
			event.preventDefault();

			var requestData = this.getRequestData();

			var userId = this.model.get('userId');

			$.ajax({
				headers : {
					'Accept' : 'application/json',
					'Content-Type' : 'application/json-patch+json'
				},
				type:'PATCH',
				url: '/backend/secure/volunteer/users/' + userId,
				data:JSON.stringify(requestData),

				success: function() {
					localStorage.setItem('feedback-card-success-container', 'visibility-card-feedback');
					util.redirect('/volunteers/profile');
				}
			});
		},

		getRequestData: function() {
			var requestData = [];
			var visibility = this.$el.find('input[name=visibleToStudyTeams]:checked').val();

			requestData.push(
				{
					'op': 'replace',
					'path': '/profile/visibleToStudyTeams',
					'value':visibility
				}
			);
			return requestData;
		},

		render: function() {
			this.$el.html(template({organizationInfo: appConfig.organizationInfo}));

			var visibleToStudyTeams = this.model.get('visibleToStudyTeams');
			this.$el.find('input[value=' + visibleToStudyTeams + ']').prop('checked', 'checked');
			return this;
		}
	});
	module.exports = View;
})();
