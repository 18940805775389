var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset>\n    <legend>\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.accountInfo.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":58}}}))
    + "\n    </legend>\n    <div class=\"field-container email validation-error-container\">\n        <label for=\"inputEmailUsername\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.email.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":40},"end":{"line":6,"column":80}}}))
    + "</label>\n        <div class=\"field-help-text\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.email.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":51}}}))
    + "\n        </div>\n        <input class=\"input-1\" id=\"inputEmailUsername\" type=\"email\" name=\"email\" required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:invalidEmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":118},"end":{"line":10,"column":155}}}))
    + "\">\n        <div class=\"field-help-text\">\n            <a data-history-navigate href=\"javascript:void(0);\" class=\"asideNoEmailAccount_open\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.email.noemail.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":97},"end":{"line":12,"column":149}}}))
    + "</a>\n        </div>\n    </div>\n\n    <div class=\"field-container password validation-error-container\">\n        <label id=\"labelPassword\" for=\"passwordNewPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.password.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":60},"end":{"line":17,"column":103}}}))
    + "</label>\n        <div class=\"field-help-text\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"passwordDescription",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":19,"column":42}}}))
    + "\n        </div>\n        <input class=\"input-1\" id=\"passwordNewPassword\" type=\"password\" name=\"password\" required\n               data-parsley-pattern=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"PASSWORD_REGEX") || (depth0 != null ? lookupProperty(depth0,"PASSWORD_REGEX") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"PASSWORD_REGEX","hash":{},"data":data,"loc":{"start":{"line":22,"column":37},"end":{"line":22,"column":55}}}) : helper)))
    + "\"\n               data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:passwordDoesNotMeetCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":43},"end":{"line":23,"column":95}}}))
    + "\">\n        <label class=\"password-show other-label\">\n            <input type=\"checkbox\" id=\"checkboxShowPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.password.show.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":61},"end":{"line":25,"column":109}}}))
    + "\n        </label>\n    </div>\n</fieldset>";
},"useData":true});