(function() {
	'use strict';
	var util = require('../app-util/util.js');
	var html = require('../app-util/html.js');
	var FeedbackModel = require('../models/model.feedback.js');
	var feedbackMessages = require('../config/feedback.messages.json');
	var i18n = require('../../shared-scripts/app-util/i18n');
	var FeedbackModelFactory = function() {
		this.getModelForFeedbackNamed = function (code, params) {
			return new FeedbackModel(getRawModel(code, params));
		};

		var getRawModel = function(code, params) {
			var convertedParamsObject = {};
			params.forEach(function(item) {
				convertedParamsObject[item.name] = item.value;
			});
			if (feedbackMessages[code]) {
				var feedbackMessage = {};
				$.extend(true, feedbackMessage, feedbackMessages[code]);
				feedbackMessage.title = html.markAsSafe(i18n.t('feedbackMessages:'+feedbackMessage.title));
				feedbackMessage.message = html.markAsSafe(i18n.t('feedbackMessages:'+feedbackMessage.message,convertedParamsObject));
				return feedbackMessage;
			} else {
				throw new Error('unknown feedback name ' + code + ' passed to feedbackModelFactory');
			}
		};
	};

	module.exports = new FeedbackModelFactory();
})();
