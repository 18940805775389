(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.email.notifications.hbs');

	var util = require('../../../../shared-scripts/app-util/util.js');

	var View = Backbone.View.extend({
		el: 'main',
		events: {
			'submit #formEmailNotifications': 'saveSection',
			'click #aCancel': 'cancel'
		},

		cancel: function() {
			localStorage.setItem('feedback-card-cancel-container', 'email-notification-card-feedback');
			util.redirect('/volunteers/profile');
		},

		saveSection: function(event) {
			event.preventDefault();

			var requestData = this.getRequestData();

			var userId = this.model.get('userId');

			$.ajax({
				headers : {
					'Accept' : 'application/json',
					'Content-Type' : 'application/json-patch+json'
				},
				type:'PATCH',
				url: '/backend/secure/volunteer/users/' + userId,
				data:JSON.stringify(requestData),

				success: function() {
					localStorage.setItem('feedback-card-success-container', 'email-notification-card-feedback');
					util.redirect('/volunteers/profile');
				}
			});
		},

		getRequestData: function() {
			var requestData = [];
			var messageNotification = this.$el.find('#selectMessageNotification').val();
			var recommendedStudiesNotification = this.$el.find('#selectRecommendedStudiesNotification').val();

			requestData.push(
				{
					'op': 'replace',
					'path': '/profile/messageNotificationFrequency',
					'value':messageNotification
				}
			);

			requestData.push(
				{
					'op': 'replace',
					'path': '/profile/recommendationNotificationFrequency',
					'value':recommendedStudiesNotification
				}
			);

			return requestData;
		},

		render: function() {
			var emailNotifications = this.model.get('emailNotifications');

			this.$el.html(template(emailNotifications));

			this.$el.find('#selectMessageNotification').val(emailNotifications.messageNotificationFrequency);
			this.$el.find('#selectRecommendedStudiesNotification').val(emailNotifications.recommendationNotificationFrequency);
			return this;
		}
	});
	module.exports = View;
})();
