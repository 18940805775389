(function () {
	'use strict';
	var FeedbackView = require('../../../../shared-scripts/views/view.feedback');
	var template = require('../../templates/hbs/announcements.hbs');

	var View = Backbone.View.extend({
		el: '#sectionAnnouncements',

		initialize: function () {
			this.subViews = {};
			this.model.on('reset', this.render, this);
		},

		render: function () {
			this.closeSubViews();
			if (this.model.length) {
				this.$el.html(template());
			}
			_.each(this.model.models, function (model) {
				model.set('isShowFeedback', true);
				model.set('type', 'info');
				var feedbackView = new FeedbackView({model: model});
				this.renderSubviewAppend(this.$el.find('#divMainPageFeedback'), model.cid, feedbackView);
			}, this);
			return this;
		},
	});
	module.exports = View;
})();
