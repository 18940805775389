(function () {
	'use strict';
	var template = require('../../templates/hbs/app.error.hbs');
	var View = Backbone.View.extend({
		el: 'main',

		initialize: function () {
			this.subViews = {};
		},

		render: function () {
			this.$el.html(template(this.model));
			return this;
		}
	});

	module.exports = View;
})();
