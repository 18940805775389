(function () {
	'use strict';

	var BaseController = require('../../../shared-scripts/controllers/controller.base.js');
	var util = require('../../../shared-scripts/app-util/util.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var profileCompletenessModule = require('./modules/module.profile.completeness.js');
	var csrfModule = require('../../../shared-scripts/modules/module.csrf');
	var BasicFooterView = require('../../../shared-scripts/views/view.footer.basic');

	function Controller() {
		BaseController.call(this);
		var thisController = this;
		var ProfileModel = require('./models/model.profile.js');
		var profileModel = new ProfileModel();

		var SearchFilterSeedModel = require('./models/model.profile.seed.js');
		var searchFilterSeedModel = new SearchFilterSeedModel({types: ['MEDICAL_CONDITION', 'BOOLEAN', 'STUDY_LOCATION', 'PARTICIPANT_TYPE', 'GENDER']});

		var StudyTeamMemberNameModel = require('./models/model.study.team.member.name.js');
		var studyTeamMemberNameModel = new StudyTeamMemberNameModel();

		var studiesForYou = function (options) {
			var MainView = require('./views/view.studies.for.you.js');

			var pageSize = appConfig.pagination.defaultPageSize;
			var pageNum = (Number(options.pageNum) > 0) ? Number(options.pageNum) : 1;

			var userId = util.getLoggedInUserId();
			var PaginatedStudyModel = require('./models/model.paginated.study.js');
			var paginatedStudyModel = new PaginatedStudyModel({
				action: options.action,
				pageNum: pageNum,
				pageSize: pageSize,
				userId: userId
			});
			profileModel.set('userId', userId);
			var authDetails = util.getGlobalVar('authDetails');
			var action = options.action;
			var matchingFinished = authDetails.matchingFinished;

			if (authDetails.loggedInUser) {
				var recommendedStudyCounts = authDetails.recommendedStudyCounts;
				var totalStudies;
				if (action === 'suggested-by-study-teams') {
					totalStudies = recommendedStudyCounts ? recommendedStudyCounts.userRecStudyCount : 0;
				} else if (action === 'matched-by-system') {
					totalStudies = recommendedStudyCounts ? recommendedStudyCounts.sysRecStudyCount : 0;
				}
				var lastPage = Math.ceil(totalStudies / pageSize);
				if (lastPage === 0) {
					lastPage = 1;
				}
				pageNum = (pageNum <= lastPage) ? pageNum : lastPage;
				paginatedStudyModel.set('pageNum', pageNum);
			}

			if (action === 'matched-by-system' && !matchingFinished) {
				fetchProfileAndLoadView();
			} else {
				paginatedStudyModel.fetch({
					success: function () {
						fetchProfileAndLoadView();
					}
				});
			}

			function fetchProfileAndLoadView() {
				profileModel.fetch({
					success: function () {
						var profileCompleteness = profileCompletenessModule.calculateProfileCompleteness(profileModel, true);
						var missingRequiredFields = profileCompletenessModule.getMissingRequiredFields(profileModel);
						thisController.loadView({
							mainView: new MainView({
								model: paginatedStudyModel,
								profileCompleteness: profileCompleteness,
								matchingFinished: matchingFinished,
								missingRequiredFields: missingRequiredFields
							}),
							securityLevel: 'volunteer',
							language: profileModel.get('language')
						});
					}
				});
			}
		};

		var accountSettings = function () {
			var MainView = require('./views/view.account.settings.js');
			this.loadView({mainView: new MainView(), securityLevel: 'volunteer'});
		};

		var changeEmail = function () {
			var Model = require('./models/model.change.email.js');
			var model = new Model();
			model.fetch({
				success: function () {
					var MainView = require('./views/view.change.email.js');
					thisController.loadView({mainView: new MainView({model: model}), securityLevel: 'volunteer'});
				}
			});
		};

		var changeEmailVerification = function (options) {
			var Model = require('./models/model.verification.js');
			var MainView = require('./views/view.change.email.verification.js');
			var model = new Model(options);
			var mainView = new MainView({model: model});
			this.loadView({mainView: mainView, securityLevel: 'volunteer'});
		};

		var changeEmailPassword = function (options) {
			var Model = require('./models/model.change.email.password.js');
			var MainView = require('./views/view.change.email.password.js');
			var model = new Model(options);
			var mainView = new MainView({model: model});
			this.loadView({mainView: mainView, securityLevel: 'public'});
		};

		var changePassword = function () {
			var AppSettingsCollection = require('../../../shared-scripts/collections/collection.app.setting.js');
			var appSettingsCollection = new AppSettingsCollection();
			var appSettingsUrlParamValues = appSettingsCollection.model.urlParams.name;

			appSettingsCollection.fetch({
				success: function (collection) {
					var MainView = require('./views/view.change.password.js');

					var mainView = new MainView({collection: collection});
					thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
				}
			}, [appSettingsUrlParamValues.passwordRegex, appSettingsUrlParamValues.passwordRegexDescription]
			);
		};

		var deactivateAccount = function () {
			var MainView = require('./views/view.deactivate.account.js');
			var LookupValueModel = require('./models/model.lookup.value.js');
			var AuthDetailsModel = require('../../../shared-scripts/models/model.auth.details.js');
			var seedModel = new LookupValueModel('DEACTIVATION_REASON');
			var authDetailsModel = new AuthDetailsModel();
			seedModel.fetch({
				success: function (seedModel) {
					authDetailsModel.fetch({
						success: function (authDetailsModel) {
							seedModel.set('lovedOnes', authDetailsModel.get('lovedOnes'));
							seedModel.set('supportPhone', appConfig.supportInfo.supportPhoneNumber);
							seedModel.set('reasons', seedModel.get('DEACTIVATION_REASON').filter(function (lv) {
								return lv.visible;
							}));
							seedModel.unset('DEACTIVATION_REASON');
							var DeactivateModel = require('./models/model.deactivate.account.js');
							var deactivateModel = new DeactivateModel();
							var mainView = new MainView({
								model: deactivateModel,
								seedModel: seedModel
							});
							thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
						}
					});
				}
			});
		};

		var selectLovedOne = function () {
			var AuthDetailsModel = require('../../../shared-scripts/models/model.auth.details.js');
			var authDetailsModel = new AuthDetailsModel();
			authDetailsModel.fetch({
				success: function (model) {
					var MainView = require('./views/view.select.loved.one.js');
					var mainView = new MainView({model: model});
					thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
				}
			});
		};

		var addLovedOne = function () {
			var MainView = require('./views/view.add.loved.one.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel();
			var RelationshipsModel = require('./models/model.relationships.js');
			var relationshipsModel = new RelationshipsModel();
			var AppSettingsCollection = require('../../../shared-scripts/collections/collection.app.setting.js');
			var appSettingsCollection = new AppSettingsCollection();
			var appSettingsUrlParamValues = appSettingsCollection.model.urlParams.name;

			seedModel.fetch({
				success: function () {
					appSettingsCollection.fetch({
						success: function () {
							relationshipsModel.fetch({
								success: function () {
									var AddLovedOneModel = require('./models/model.add.loved.one.js');
									var addLovedOneModel = new AddLovedOneModel();
									var mainView = new MainView({
										model: addLovedOneModel,
										seedModel: seedModel,
										appSettingsCollection: appSettingsCollection,
										relationshipsModel: relationshipsModel
									}
									);
									thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
								}
							});
						}
					}, [appSettingsUrlParamValues.yearsToBeConsideredMature]);
				}
			});
		};

		var signup = function (options) {
			var studyId = options.studyId;
			var MainView = require('./views/view.signup.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel();
			var AppSettingsCollection = require('../../../shared-scripts/collections/collection.app.setting.js');
			var appSettingsCollection = new AppSettingsCollection();
			var appSettingsUrlParamValues = appSettingsCollection.model.urlParams.name;

			seedModel.fetch({
				success: function () {
					appSettingsCollection.fetch({
						success: function () {
							var SignupModel = require('./models/model.sign.up.js');
							var signupModel = new SignupModel();
							var mainView = new MainView({
								model: signupModel,
								seedModel: seedModel,
								appSettingsCollection: appSettingsCollection,
								studyId: studyId
							});
							thisController.loadView({mainView: mainView, securityLevel: 'public'});
						}
					}, [appSettingsUrlParamValues.passwordRegex, appSettingsUrlParamValues.passwordRegexDescription, appSettingsUrlParamValues.yearsToBeConsideredMature]);
				}
			});
		};

		var signupVerification = function (options) {
			var Model = require('./models/model.verification.js');
			var MainView = require('./views/view.signup.verification.js');
			var model = new Model(options);
			var mainView = new MainView({model: model});
			this.loadView({mainView: mainView, securityLevel: 'public'});
		};

		var activateUser = function (options) {
			var studyId = options.studyId;
			var userActivationToken = {
				metadata: studyId,
				userId: options.userId,
				token: options.token
			};
			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: 'POST',
				url: '/backend/public/volunteer/user-activation-token',
				data: JSON.stringify(userActivationToken),
				success: function (data) {
					csrfModule(function () {
						if (Number(studyId)) {
							var studyNumber = data.value;
							util.redirect('/volunteers/studies/' + studyNumber + '/screening-questions');
						} else {
							localStorage.setItem('feedbackCode', 'SIGNUP_SUCCESS');
							util.redirect('/volunteers/profile');
						}
					});
				},
				error: function (jqxhr) {
					if (jqxhr.status === 400 && jqxhr.responseJSON) {
						localStorage.setItem('feedbackCode', jqxhr.responseJSON.code);
						var details = jqxhr.responseJSON.details;
						if (details.length > 0) {
							// this is a flatMap, we wrote this for IE and Edge support
							var params = Array.prototype.concat.apply([], details.map(function (detail) {
								return detail.parameters;
							}));
							localStorage.setItem('feedbackParams', JSON.stringify(params));
						}
						util.redirect('signup');
					}
				}
			});
		};

		var signupLovedOne = function (options) {
			var studyId = options.studyId;
			var MainView = require('./views/view.signup.loved.one.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel();
			var AppSettingsCollection = require('../../../shared-scripts/collections/collection.app.setting.js');
			var appSettingsCollection = new AppSettingsCollection();
			var appSettingsUrlParamValues = appSettingsCollection.model.urlParams.name;
			var RelationshipsModel = require('./models/model.relationships.js');
			var relationshipsModel = new RelationshipsModel();

			seedModel.fetch({
				success: function () {
					relationshipsModel.fetch({
						success: function () {
							appSettingsCollection.fetch({
								success: function () {
									var SignupModel = require('./models/model.sign.up.js');
									var signupModel = new SignupModel();
									var mainView = new MainView({
										model: signupModel,
										seedModel: seedModel,
										relationshipsModel: relationshipsModel,
										appSettingsCollection: appSettingsCollection,
										studyId: studyId
									});
									thisController.loadView({mainView: mainView, securityLevel: 'public'});
								}
							}, [appSettingsUrlParamValues.passwordRegex, appSettingsUrlParamValues.passwordRegexDescription, appSettingsUrlParamValues.yearsToBeConsideredMature]);
						}
					});
				}
			});
		};

		var history = function (options) {
			var MainView = require('./views/view.history.js');
			var pageSize = Number(appConfig.pagination.defaultPageSize);
			var pageNum = (Number(options.pageNum) > 0) ? Number(options.pageNum) : 1;
			var userId = util.getLoggedInUserId();
			var PaginatedStudyModel = require('./models/model.paginated.study.js');
			var paginatedStudyModel = new PaginatedStudyModel({
				action: options.action,
				pageNum: pageNum,
				pageSize: pageSize,
				userId: userId
			});

			paginatedStudyModel.fetch({
				success: function (model) {
					var mainView = new MainView({model: model});
					thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
				}
			});
		};

		var profile = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.js');
			profileModel.set('userId', util.getLoggedInUserId());
			profileModel.fetch({
				success: function () {
					var mainView = new MainView({model: profileModel});
					thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
				}
			});
		};

		var conversations = function (options) {
			var thisController = this;
			var MainView = require('./views/view.conversations.js');
			var pageNum = (Number(options.pageNum) > 0) ? Number(options.pageNum) : 1;
			var pageSize = appConfig.pagination.defaultPageSize;
			var volunteerUserId = util.getLoggedInUserId();

 			var PaginatedConversationModel = require('./models/model.paginated.conversation.js');
			var paginatedConversationModel = new PaginatedConversationModel({
				pageNum: pageNum,
				pageSize: pageSize,
				volunteerUserId: volunteerUserId
			});


			paginatedConversationModel.fetch({
				success: function (paginatedConversationModel) {
					var page = paginatedConversationModel.get('page');
					var totalCount = paginatedConversationModel.get('totalCount');

					if (page.length === 0 && totalCount !== 0) {
						util.redirect('/messages');
					} else {
						var mainView = new MainView({
							model: paginatedConversationModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
					}
				}
			});
		};

		var messages = function (options) {
			var thisController = this;
			var MainView = require('./views/view.message.list.js');

			var StudyInfoForConversation = require('./models/model.study.info.for.conversation');
			var studyInfoForConversation = new StudyInfoForConversation({studyVolunteerId: options.studyVolunteerId});
			var MessageCollection = require('./collections/collection.message');
			var messageCollection = new MessageCollection([], {studyVolunteerId: options.studyVolunteerId});

			studyInfoForConversation.fetch({
				success: function (studyInfo) {
					messageCollection.fetch({
						success: function (messages) {
							thisController.loadView({
								mainView: new MainView({
									messages: messages,
									studyInfo: studyInfo,
								}),
								securityLevel: 'volunteer'
							});
						}
					});
				}
			});

		};

		var index = function (options) {
			var MainView = require('./views/view.index.js');

			var StatModel = require('./models/model.stat.js');
			var statModel = new StatModel(['ACTIVE_VOLUNTEER']);

			var LookupValueModel = require('./models/model.lookup.value.js');
			var seeModel = new LookupValueModel('MEDICAL_CONDITION');

			statModel.fetch({
				success: function () {
					seeModel.fetch({
						success: function () {
							var layoutViewConstructorArguments = {
								mainView: new MainView({model: statModel, seedModel: seeModel}),
								securityLevel: 'public'
							};
							if (options && options.feedbackCode) {
								localStorage.setItem('feedbackCode', options.feedbackCode);
							}
							thisController.loadView(layoutViewConstructorArguments);
						}
					});
				}
			});
		};

		var profileContactInfo = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.contact.info.js');
			if (profileModel.get('userId')) {
				var mainView = new MainView({model: profileModel});
				thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
			} else {
				profileModel.set('userId', util.getLoggedInUserId());
				profileModel.fetch({
					success: function () {
						var mainView = new MainView({model: profileModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
					}
				});
			}
		};

		var profileLanguage = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.language.js');
			if (profileModel.get('userId')) {
				var mainView = new MainView({model: profileModel});
				thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
			} else {
				profileModel.set('userId', util.getLoggedInUserId());
				profileModel.fetch({
					success: function () {
						var mainView = new MainView({model: profileModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
					}
				});
			}
		};

		var profileDemographics = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.demographics.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel({types: ['RACE', 'GENDER', 'FLUENT_ENGLISH', 'BOOLEAN']});
			var AppSettingsCollection = require('../../../shared-scripts/collections/collection.app.setting.js');
			var appSettingsCollection = new AppSettingsCollection();
			var appSettingsUrlParamValues = appSettingsCollection.model.urlParams.name;

			seedModel.fetch({
				success: function () {
					appSettingsCollection.fetch({
						success: function () {
							if (profileModel.get('userId')) {
								var mainView = new MainView({
									model: profileModel,
									seedModel: seedModel,
									appSettingsCollection: appSettingsCollection
								});
								thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
							} else {
								profileModel.set('userId', util.getLoggedInUserId());
								profileModel.fetch({
									success: function () {
										var mainView = new MainView({
											model: profileModel,
											seedModel: seedModel,
											appSettingsCollection: appSettingsCollection
										});
										thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
									}
								});
							}
						}
					}, [appSettingsUrlParamValues.yearsToBeConsideredMature]);
				}
			});
		};

		var profileGenderIdentity = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.genderIdentity.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel({types: ['GENDER_IDENTITY', 'GENDER_PRONOUN', 'BOOLEAN']});
			seedModel.fetch({
				success: function () {
					if (profileModel.get('userId')) {
						var mainView = new MainView({model: profileModel, seedModel: seedModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
					} else {
						profileModel.set('userId', util.getLoggedInUserId());
						profileModel.fetch({
							success: function () {
								var mainView = new MainView({model: profileModel, seedModel: seedModel});
								thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
							}
						});
					}
				}
			});
		};

		var profileHealthInfo = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.health.info.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel({types: ['SMOKING_STATUS']});
			seedModel.fetch({
				success: function () {
					if (profileModel.get('userId')) {
						var mainView = new MainView({model: profileModel, seedModel: seedModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
					} else {
						profileModel.set('userId', util.getLoggedInUserId());
						profileModel.fetch({
							success: function () {
								var mainView = new MainView({model: profileModel, seedModel: seedModel});
								thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
							}
						});
					}
				}
			});
		};

		var profileEmailNotifications = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.email.notifications.js');
			if (profileModel.get('userId')) {
				var mainView = new MainView({model: profileModel});
				thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
			} else {
				profileModel.set('userId', util.getLoggedInUserId());
				profileModel.fetch({
					success: function () {
						var mainView = new MainView({model: profileModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
					}
				});
			}
		};

		var profileVisibility = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.visibility.js');
			if (profileModel.get('userId')) {
				var mainView = new MainView({model: profileModel});
				thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
			} else {
				profileModel.set('userId', util.getLoggedInUserId());
				profileModel.fetch({
					success: function () {
						var mainView = new MainView({model: profileModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
					}
				});
			}
		};

		var profileStudyInterests = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.study.interests.js');
			var LookupValueModel = require('./models/model.profile.seed');
			var lookupValueModel = new LookupValueModel({types: ['MEDICAL_CONDITION', 'BOOLEAN', 'STUDY_LOCATION', 'PARTICIPANT_TYPE']});
			var CriterionVariableCollection = require('./collections/collection.criterion.variable.js');
			var criterionVariablesCollection = new CriterionVariableCollection();
			/** Maps the names used by view to the criterion varible types stored in db */
			var criterionVariableNamesForView = {
				STUDY_TOPICS: 'PRESENT_MEDICAL_CONDITION',
				STUDY_LOCATIONS: 'LOCATIONS',
				COMPENSATION_PREFERENCE: 'OFFERS_COMPENSATION',
				PARTICIPANT_TYPE_STUDY_SEEKING: 'TYPE_OF_RESEARCH_PATIENTS_THIS_STUDY_IS_SEEKING'
			};

			var modelParamArray = [];
			for (var critVarKey in criterionVariableNamesForView) {
				modelParamArray.push({
					name: 'criterion-variable-name',
					value: criterionVariableNamesForView[critVarKey]
				});
			}
			var criterionVariablesFetchPromise = criterionVariablesCollection.fetch({data: $.param(modelParamArray)});
			var lookupValuePromise = lookupValueModel.fetch();

			var promises = [lookupValuePromise, criterionVariablesFetchPromise];
			if (!profileModel.get('userId')) {
				profileModel.set('userId', util.getLoggedInUserId());
				promises.push(profileModel.fetch());
			}

			$.when.apply(null, promises).done(function () {
				loadProfileView.apply(thisController, [MainView, profileModel, criterionVariablesCollection, lookupValueModel, criterionVariableNamesForView]);
			});
		};

		var loadProfileView = function (mainViewConstructor, profileModel, seedModel, lookupValueModel, criterionVariableNameMap) {
			var mainView = new mainViewConstructor({
				model: profileModel,
				seedModel: seedModel,
				lookupValueModel: lookupValueModel,
				criterionVariableNameMap: criterionVariableNameMap
			});
			this.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
		};

		var profileMedConditions = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.med.conditions.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel({types: ['MEDICAL_CONDITION', 'BOOLEAN']});

			seedModel.fetch({
				success: function () {
					if (profileModel.get('userId')) {
						var mainView = new MainView({model: profileModel, seedModel: seedModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
					} else {
						profileModel.set('userId', util.getLoggedInUserId());
						profileModel.fetch({
							success: function () {
								var mainView = new MainView({model: profileModel, seedModel: seedModel});
								thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
							}
						});
					}
				}
			});
		};

		var profileMedications = function () {
			var thisController = this;
			var MainView = require('./views/view.profile.medications.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel({types: ['BOOLEAN']});

			seedModel.fetch({
				success: function () {
					if (profileModel.get('userId')) {
						var mainView = new MainView({model: profileModel, seedModel: seedModel});
						thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
					} else {
						profileModel.set('userId', util.getLoggedInUserId());
						profileModel.fetch({
							success: function () {
								var mainView = new MainView({model: profileModel, seedModel: seedModel});
								thisController.loadView({mainView: mainView, securityLevel: 'volunteer', language: profileModel.get('language')});
							}
						});
					}
				}
			});
		};

		var studyDetails = function (options) {
			var userRoles = util.getLoggedInUserRoles();
			var studyNumber = options.studyNumber;
			localStorage.setItem('scrollToStudy', studyNumber);
			var StudyInterestStatsModel = require('./models/model.study.interest.stats');
			var studyInterestStatsModel = new StudyInterestStatsModel({studyNumber: studyNumber});

			studyInterestStatsModel.fetch({
				success: function (studyDetailsInterestStatsModel) {
					var StudyModel = require('./models/model.study');
					var studyModel = new StudyModel({studyNumber: studyNumber, status: 'any'});
					studyModel.fetch({
						success: function (studyModel) {
							if (studyModel.attributes.page.length === 0) {
								util.redirect('/404-page-not-found');
							}
							var MainView = require('./views/view.study.details.js');
							var mainView = new MainView({
								model: studyModel,
								studyInterestStats: studyDetailsInterestStatsModel,
								userRoles: userRoles
							});
							thisController.loadView({mainView: mainView, securityLevel: 'public'});
						},
						error: function () {
							util.redirect('/404-page-not-found');
						}
					});
				}
			});
		};

		var screeningQuestions = function (options) {
			var studyNumber = options.studyNumber;
			var userId = util.getLoggedInUserId();
			var ScreeningQuestionsModel = require('./models/model.screening.questions.js');
			var MainView = require('./views/view.screening.questions.js');
			var SeedModel = require('./models/model.profile.seed.js');
			var seedModel = new SeedModel({types: ['MEDICAL_CONDITION', 'BOOLEAN', 'GENDER']});

			var screeningQuestionModel = new ScreeningQuestionsModel({studyNum: studyNumber, userId: userId});

			var volunteerEligibilityMap = {
				studyNum: studyNumber
			};
			seedModel.fetch({
				success: function () {
					screeningQuestionModel.fetch({
						success: function (model) {
							var mainView = new MainView({
								model: model,
								seedModel: seedModel,
								volunteerEligibilityMap: volunteerEligibilityMap
							});
							thisController.loadView({
								mainView: mainView,
								securityLevel: 'volunteer'
							});
						}
					});
				}
			});
		};

		var interestSuccess = function (options) {
			var studyNumber = options.studyNumber;

			var StudyModel = require('./models/model.study');
			var studyModel = new StudyModel({studyNumber: studyNumber});
			studyModel.fetch({
				success: function (model) {
					var MainView = require('./views/view.interest.success.js');
					var mainView = new MainView({model: model});
					thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
				},
				error: function (model, response) {
					if (response.status === 404) {
						util.redirect('/404-page-not-found');
					}
				}
			});
		};

		function fetchPaginatedStudy(searchQuery, pageNum, pageSize) {
			var PaginatedStudyModel = require('./models/model.paginated.study.js');
			var paginatedStudyModel = new PaginatedStudyModel({
				searchQuery: searchQuery,
				pageNum: pageNum,
				pageSize: pageSize
			});
			paginatedStudyModel.fetch({
				success: function () {
					var MainView = require('./views/view.study.results.js');
					thisController.loadView({
						mainView: new MainView({
							model: paginatedStudyModel,
							seedModel: searchFilterSeedModel,
							studyTeamMemberNameModel: studyTeamMemberNameModel
						}), securityLevel: 'public'
					});
				}
			});
		}

		var studyResults = function (options) {
			var searchQuery = options.searchQuery ? options.searchQuery : '';
			var pageSize = appConfig.pagination.defaultPageSize;
			var pageNum = util.getUrlParam('page') ? Number(util.getUrlParam('page')) : 1;

			if (localStorage.getItem('scrollToStudy')) {
				searchQuery = util.setUrlParam('scroll-to', localStorage.getItem('scrollToStudy'));
				localStorage.removeItem('scrollToStudy');
				if (searchQuery !== options.searchQuery) {
					util.redirect('/study-results?' + searchQuery);
					return;
				}
			}
			searchQuery = util.setUrlParam('page', pageNum);

			if (searchFilterSeedModel.get('BOOLEAN') && studyTeamMemberNameModel.get('piNames')) {
				fetchPaginatedStudy(searchQuery, pageNum, pageSize);
			} else {
				searchFilterSeedModel.fetch({
					success: function () {
						studyTeamMemberNameModel.fetch({
							success: function () {
								fetchPaginatedStudy(searchQuery, pageNum, pageSize);
							}
						});
					}
				});
			}
		};

		var advancedSearch = function () {
			searchFilterSeedModel.fetch({
				success: function () {
					studyTeamMemberNameModel.fetch({
						success: function () {
							var MainView = require('./views/view.advanced.search.js');
							var mainView = new MainView({
								seedModel: searchFilterSeedModel,
								studyTeamMemberNameModel: studyTeamMemberNameModel
							});
							thisController.loadView({mainView: mainView, securityLevel: 'public'});
						}
					});
				}
			});
		};

		var about = function (action) {
			var MainView = require('./views/view.about.js');
			var mainView = new MainView(action);
			thisController.loadView({mainView: mainView, securityLevel: 'public'});
		};

		var interestFailure = function (options) {
			var studyNumber = options.studyNumber;

			var StudyModel = require('./models/model.study');
			var studyModel = new StudyModel({studyNumber: studyNumber});
			studyModel.fetch({
				success: function (model) {
					var MainView = require('./views/view.interest.failure.js');
					var mainView = new MainView({model: model});
					thisController.loadView({mainView: mainView, securityLevel: 'volunteer'});
				},
				error: function (model, response) {
					if (response.status === 404) {
						util.redirect('/404-page-not-found');
					}
				}
			});
		};

		var notAuthorized = function (queryString) {
			var MainView = require('./views/view.not.authorized.js');

			var error = util.getUrlParam('error', queryString);
			var studyIdentifier = util.getUrlParam('study-identifier', queryString);

			this.destroyAllViews();
			this.view = new MainView({
				model: {
					supportEmail: appConfig.supportInfo.supportEmailAddress,
					supportPhone: appConfig.supportInfo.supportPhoneNumber,
					error: error,
					studyIdentifier: studyIdentifier
				}
			}).render();
			renderMiscFooterView();
		};

		var appError = function (queryString) {
			var errorId = util.getUrlParam('error-id', queryString);
			var MainView = require('./views/view.app.error.js');
			this.destroyAllViews();
			this.view = new MainView({
				model: {
					supportEmail: appConfig.supportInfo.supportEmailAddress,
					supportPhone: appConfig.supportInfo.supportPhoneNumber,
					errorNotificationEmailSubject: appConfig.supportInfo.errorNotificationEmailSubject,
					errorNotificationEmailBody: appConfig.supportInfo.errorNotificationEmailBody,
					errorId: errorId
				}
			}).render();
			renderMiscFooterView();
		};

		var studyTeamUserAgreement = function () {
			var needConfirmButtons = util.getUrlParam('need-confirm-buttons');
			var username = util.getUrlParam('username');
			var redirectUrl = util.getUrlParam('redirect-url');

			var Model = require('./models/model.stm.user.agreement');
			var View = require('./views/view.stm.user.agreement');

			var model = new Model(
				{needConfirmButtons: needConfirmButtons, username: username, redirectUrl: redirectUrl});
			var view = new View({model: model});

			this.destroyAllViews();
			this.view = view.render();
			renderMiscFooterView();
		};

		var privacy = function () {
			var MainView = require('./views/view.privacy');
			var mainView = new MainView();
			this.destroyAllViews();
			this.view = mainView.render();
			renderMiscFooterView();
		};

		var widget = function () {
			var StudyTeamMemberNameModel = require('./models/model.study.team.member.name');
			var studyTeamMemberNameModel = new StudyTeamMemberNameModel();
			var LookupValueModel = require('./models/model.lookup.value');
			var lookupValueModel = new LookupValueModel('MEDICAL_CONDITION,STUDY_DEPARTMENT,GENDER');
			var AppSettingsCollection = require('../../../shared-scripts/collections/collection.app.setting.js');
			var appSettingsCollection = new AppSettingsCollection();
			var appSettingsUrlParamValues = appSettingsCollection.model.urlParams.name;

			var MainView = require('./views/view.widget.js');

			appSettingsCollection.fetch({
				success: function(appSettingsCollection) {
					studyTeamMemberNameModel.fetch({
						success: function(studyTeamMemberNameModel) {
							lookupValueModel.fetch({
								success: function (lookupValueModel) {
									new MainView({
										appSettingsCollection: appSettingsCollection,
										studyTeamMemberNameModel: studyTeamMemberNameModel,
										lookupValueModel: lookupValueModel
									}).render();
								}
							});
						}
					});
				}
			}, [appSettingsUrlParamValues.applicationUrl]);
		};

		function renderMiscFooterView() {
			var footer = $('footer');
			$(footer).attr('class','stripped-footer');
			$(footer).html(new BasicFooterView().render().el);
		}
		this.handlers = {
			'index': index,
			'studies-for-you': studiesForYou,
			'account-settings': accountSettings,
			'change-email': changeEmail,
			'change-email-verification': changeEmailVerification,
			'change-email-password': changeEmailPassword,
			'deactivate-account': deactivateAccount,
			'conversations': conversations,
			'messages': messages,
			'profile': profile,
			'signup': signup,
			'signup-verification': signupVerification,
			'activate-user': activateUser,
			'change-password': changePassword,
			'select-loved-one': selectLovedOne,
			'add-loved-one': addLovedOne,
			'signup-loved-one': signupLovedOne,
			'profile-contact-info': profileContactInfo,
			'profile-language': profileLanguage,
			'profile-demographics': profileDemographics,
			'profile-genderIdentity': profileGenderIdentity,
			'profile-health-info': profileHealthInfo,
			'profile-email-notifications': profileEmailNotifications,
			'profile-visibility': profileVisibility,
			'profile-study-interests': profileStudyInterests,
			'profile-med-conditions': profileMedConditions,
			'profile-medications': profileMedications,
			'history': history,
			'study-details': studyDetails,
			'screening-questions': screeningQuestions,
			'interest-success': interestSuccess,
			'interest-failure': interestFailure,
			'advanced-search': advancedSearch,
			'study-results': studyResults,
			'about': about,
			'notAuthorized': notAuthorized,
			'appError': appError,
			'studyTeamUserAgreement': studyTeamUserAgreement,
			'privacy': privacy,
			'widget': widget
		};
	}

	Controller.prototype = new BaseController();
	Controller.prototype.constructor = Controller;

	module.exports = new Controller();
}
)();
