(function() {
	'use strict';
	var Backbone = require('backbone');
	var StudyCardModel= require('../models/model.study.card.js');
	var Collection = Backbone.Collection.extend({
		initialize: function(models, options) {
			if(options) {
				this.userId = options.userId;
				this.action = options.action;
				this.page = options.page;
				this.pageSize = options.pageSize;

				switch(this.action) {
				case 'showed-interest':
					this.backendActionEnum = 'SHOWED_INTEREST';
					break;
				case 'dismissed':
					this.backendActionEnum = 'DISMISSED';
					break;
				case 'suggested-by-study-teams':
					this.backendActionEnum = 'INVESTIGATOR_RECOMMENDED';
					break;
				case 'matched-by-system':
					this.backendActionEnum = 'SYSTEM_RECOMMENDED';
					break;
				}

				this.studyCountUrl = '/backend/secure/volunteer/studies/count?action=' + this.backendActionEnum + '&user-id=' + this.userId;
			}
		},

		url: function() {
			return '/backend/secure/volunteer/studies?action=' + this.backendActionEnum + '&page='+this.page+'&page-size='+this.pageSize+ '&user-id=' + this.userId;
		},

		model: StudyCardModel
	});
	module.exports = Collection;
})();

