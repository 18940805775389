var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"screeningQuestions:form.question.required",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":45},"end":{"line":2,"column":97}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"field-container\">\n    <label>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":104}}})) != null ? stack1 : "")
    + "</label>\n    <div class=\"field-help-text\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? lookupProperty(stack1,"helpText") : stack1), depth0))
    + "</div>\n    <div id=\"divDueDate\">\n        <div class=\"inline-fields validation-error-container\">\n            <div class=\"field-container\">\n                <label for=\"selectDueMonth\" class=\"show-for-sr\">Due month</label>\n                <select id=\"selectDueMonth\">\n                    <option value=\"\"></option>\n                    <option value=\"0\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.jan",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":38},"end":{"line":10,"column":59}}}))
    + "</option>\n                    <option value=\"1\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.feb",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":38},"end":{"line":11,"column":59}}}))
    + "</option>\n                    <option value=\"2\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.mar",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":38},"end":{"line":12,"column":59}}}))
    + "</option>\n                    <option value=\"3\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.apr",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":13,"column":59}}}))
    + "</option>\n                    <option value=\"4\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.may",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":38},"end":{"line":14,"column":59}}}))
    + "</option>\n                    <option value=\"5\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.jun",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":38},"end":{"line":15,"column":59}}}))
    + "</option>\n                    <option value=\"6\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.jul",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":38},"end":{"line":16,"column":59}}}))
    + "</option>\n                    <option value=\"7\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.aug",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":59}}}))
    + "</option>\n                    <option value=\"8\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.sep",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":38},"end":{"line":18,"column":59}}}))
    + "</option>\n                    <option value=\"9\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.oct",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":38},"end":{"line":19,"column":59}}}))
    + "</option>\n                    <option value=\"10\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.nov",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":39},"end":{"line":20,"column":60}}}))
    + "</option>\n                    <option value=\"11\">"
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"months.dec",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":39},"end":{"line":21,"column":60}}}))
    + "</option>\n                </select>\n            </div>\n            <div class=\"field-container\">\n                <label for=\"textDueYear\" class=\"show-for-sr\">Due year</label>\n                <input type=\"text\" id=\"textDueYear\" maxlength=\"4\" size=\"4\" placeholder=\""
    + alias2((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias4).call(alias3,"placeHolder.year.yyyy",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":88},"end":{"line":26,"column":120}}}))
    + "\" data-parsley-check-not-empty-due-date data-parsley-check-valid-due-date data-parsley-validate-if-empty>\n            </div>\n        </div>\n    </div>\n    <input type=\"hidden\" name=\"study-specific-datemmyy-"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":30,"column":55},"end":{"line":30,"column":61}}}) : helper)))
    + "\" id=\"hiddenDueDate\" />\n</div>\n";
},"useData":true});