var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"show-for-sr\">Main Navigation</h1>\n\n<div class=\"main-menu-nav\">\n  <section class=\"global-nav desktop-only\">\n    <div class=\"grid-row\">\n      <div class=\"grid-sm-1\">\n          <h1 class=\"show-for-sr\">Sign up navigation</h1>\n          <nav>\n"
    + ((stack1 = container.invokePartial(require("./partials/language.picker.hbs"),depth0,{"name":"language.picker","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              <a data-history-navigate href=\"/signup\" class=\"small-special-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.signUp.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":83},"end":{"line":10,"column":113}}}))
    + "</a>\n              <a data-history-navigate href=\"/about/volunteers\" class=\"\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"whySignUp.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":73},"end":{"line":11,"column":103}}}))
    + "</a>\n          </nav>\n      </div>\n    </div>\n  </section>\n  <nav class=\"grid-row\">\n    <div class=\"grid-md-1-2 desktop-only\">\n      <a data-history-navigate href=\"/\" class=\"logo\">\n        <span class=\"show-for-sr\">"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1), depth0))
    + " logo</span>\n      </a>\n    </div>\n    <div class=\"grid-sm-1-3 grid-md-1-4 nav-tile\">\n        <a data-bypass href=\"/login/volunteer\"><span class=\"fa fa-user fa-margin-right desktop-only\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"userRoles.volunteer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":127},"end":{"line":23,"column":157}}}))
    + " <br class=\"mobile-only\"> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"loginText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":183},"end":{"line":23,"column":203}}}))
    + "</a>\n    </div>\n      <div class=\"grid-sm-1-3 grid-md-1-4 nav-tile\">\n          <a data-bypass href=\"/login/study-team\"><span class=\"fa fa-user fa-margin-right desktop-only\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"userRoles.studyTeam",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":130},"end":{"line":26,"column":160}}}))
    + "<br class=\"mobile-only\"> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"loginText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":185},"end":{"line":26,"column":205}}}))
    + "</a>\n    </div>\n    <div class=\"grid-sm-1-3 nav-tile mobile-only\">\n      <a data-history-navigate id=\"aMenuMore\"><span class=\"fa fa-bars\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:more.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":97},"end":{"line":29,"column":129}}}))
    + "</a>\n    </div>\n  </nav>\n</div>\n";
},"usePartial":true,"useData":true});