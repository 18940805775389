(function() {

	'use strict';
	var popupModule = require('../modules/module.popup');
	var deactivateAccountModule = require('../modules/module.deactivate.account');

	var UserAgreementView = require('../views/view.user.agreement');
	var UserAgreementModel = require('../models/model.user.agreement');

	var UserAgreementModule = function () {

		var userAgreementModel;
		var userAgreementView;

		this.showUserAgreement = function (params) {
			userAgreementModel = new UserAgreementModel(params);
			popupModule.showPopup($('#asideUserAgreement'));
			userAgreementView = new UserAgreementView({model: userAgreementModel});
			userAgreementView.render();
		};

		this.onUserAgreementClosed = function() {
			userAgreementView.close();
		};

		this.agree = function (userAgreement, successCallback) {
			if (!successCallback) {
				successCallback = function () {
					window.location.reload();
				};
			}
			popupModule.closePopup($('#asideUserAgreement'));

			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				url: '/backend/secure/user-agreement',
				type: 'POST',
				data: JSON.stringify(userAgreement),
				success: successCallback
			});
		};

		this.declineAndDeactivateAccount = function (params) {
			deactivateAccountModule.deactivateAccount(
				{reason: params.deactivationReason, userId: params.userId},
				function() {
					localStorage.setItem('feedbackCode', 'DEACTIVATE_ACCOUNT_SUCCESS');
					window.location.reload();
				}
			);
		};
	};

	module.exports = new UserAgreementModule();
})();
