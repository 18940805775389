(function () {
	'use strict';
	var template = require('../../templates/hbs/widget.hbs');
	var appConfig = require('~institution-specific-config/app.config.json');
	var View = Backbone.View.extend({
		el: 'main',

		events: {
		  'submit form': 'generateWidget'
		},
    
		initialize: function (options) {
			this.appUrl = options.appSettingsCollection.models[0].get('value');
			this.applicationName = appConfig.organizationInfo.applicationName;
			this.studyTeamMemberNameModel = options.studyTeamMemberNameModel;
			this.lookupValueModel = options.lookupValueModel;
		},
 
		generateWidget: function(event) {
			event.preventDefault();
			event.stopPropagation();
			var innerText = 'Find a health research study that\'s right for you.';
			var scriptTag = '<script type="text/javascript"' +
          ' src="' + window.location.origin + '/widget/smart.widget.js"></script>';
			var attributes = this.generateAttributes();
			var anchorTag = '<a data-history-navigate ' + attributes.join('\n   ') + '>\n      ' + innerText + '\n</a>';
			document.querySelector('#textareaOutput').value = anchorTag + '\n' + scriptTag;
			var widgetContainer = document.querySelector('#widget');
			widgetContainer.innerHTML = anchorTag;
			window.injectJsonp();
			$('.preview-and-code')[0].scrollIntoView();
		},
    
		generateAttributes: function() {
			var map = {
				'data-widget-appurl': [this.appUrl],
				'data-widget-appname': [this.applicationName]
			};
			var array = $('form').serializeArray();
			var key;
			for (key in array) {
				if (!array.hasOwnProperty(key)) continue;
				var entry = array[key];
				var slashIndex = entry.name.indexOf('/');
				var name = slashIndex === -1 ? entry.name : entry.name.substring(0, slashIndex);
				var value = slashIndex === -1 ? entry.value : entry.name.substring(slashIndex + 1);
				value = value === 'on' ? 'true' : value === 'off' ? 'false' : value;
				map[name] = map[name] || [];
				map[name].push(value);
			}
			var attributes = ['id="widget-link"'];
			for (key in map) {
				attributes.push(key + '=' + '"' + map[key].join(',') + '"');
			}
		  return attributes;
		},
    
		setupTypeIO: function(inputTextId, resultsContainer, name, data, id,mode) {
			var LIMIT = 200;
			var source = [];
			for(var i=0; i<data.length;i++) {
				var datum = data[i];
				source.push({text: datum.displayText, value: datum.id});
			}
			$(inputTextId).typeIO({
				minLength: 1,
				hint: true,
				highlight: true,
				resultsContainer: resultsContainer,
				name: name,
				mode: mode

			},
			{
				display:'text',
				source: source,
				limit: LIMIT,
				templates: {
					suggestion: function(data) {
						return '<div>' + data.text + '</div>';
					},
					notFound: [
						'<div class="empty-message">',
						'No results found',
						'</div>'
					].join('\n')
				}
			});
		},

		transformPINameForTypeIO: function(piNames) {
			var result = [];
			for (var i=0; i < piNames.length; i++) {
				result.push({id: piNames[i].id, displayText: this.formatNameLastFirstMiddle(piNames[i].lastName,piNames[i].firstName,piNames[i].middleName)});
			}
			return result;
		},

		formatNameLastFirstMiddle: function(last, first, middle) {
			var lastSeparator = !first ? (!middle ? '' : ',') : ', ';
			var middleSeparator = !first ? (!last ? '' : ' ') : ' ';

			first = !first ? '' : first;
			last = !last ? '' : (last + lastSeparator);
			middle = !middle ? '' : (middleSeparator + middle);

			return last + first + middle;
		},

		render: function () {
			var medicalCondition = this.lookupValueModel.get('MEDICAL_CONDITION');
			var department = this.lookupValueModel.get('STUDY_DEPARTMENT');
			var gender = this.lookupValueModel.get('GENDER');
			var transformedPINamedWithId = this.transformPINameForTypeIO(this.studyTeamMemberNameModel.get('piNames'));
			this.$el.html(template({appUrl: this.appUrl, applicationName: this.applicationName, gender: gender}));
			this.setupTypeIO('#textKeyword', '#divKeyword', 'data-widget-keyword',medicalCondition, 'displayText', 'multi-select');
			this.setupTypeIO('#textDepartment', '#divDepartment', 'data-widget-department',department, 'id', 'multi-select');
			this.setupTypeIO('#textPI', '#divPI', 'data-widget-pi',transformedPINamedWithId, 'id', 'inline-single-select');
			window.injectJsonp();
			return this;
		}
	});

	module.exports = View;
})();
