(function() {
	'use strict';
	var template = require('../../templates/hbs/studies.for.you.hbs');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var StudyCollection = require('../collections/collection.study.js');
	var StudyCardListView = require('./view.study.card.list.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var alertMissingProfileFieldsPopupModule = require('../../../../shared-scripts/modules/module.alert.missing.profile.fields.popup');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aPrev': 'goToPrevPage',
			'click #aNext': 'goToNextPage',
			'change #selectPage': 'jumpToPage',
			'click .excludeStudy': 'excludeStudy'
		},

		initialize: function (options) {
			this.subViews = {};
			var studyCollection = new StudyCollection(this.model.get('page'));
			this.subViews.studyCardListView = new StudyCardListView(
				{
					model: studyCollection,
					isSuggestedByStudyTeams: (this.model.get('action') === 'suggested-by-study-teams'),
					isMatchedBySystem: (this.model.get('action') === 'matched-by-system')
				});

			if (options) {
				this.profileCompleteness = options.profileCompleteness;
				this.matchingFinished = options.matchingFinished;
				this.missingRequiredFields = options.missingRequiredFields;
			}
		},

		excludeStudy: function (event) {
			var anchorId = event.target.id;
			var recommendedStudyId = anchorId.substring(anchorId.indexOf('-') + 1);
			if (anchorId.indexOf('NotInterested') !== -1) {
				this.sendExcludeStudyRequest('NOT_INTERESTED', recommendedStudyId);
			} else {
				this.sendExcludeStudyRequest('ENROLLED_IN_STUDY', recommendedStudyId);
			}
		},

		sendExcludeStudyRequest: function (exclusionReason, recommendedStudyId) {
			var thisView = this;
			var userId = util.getLoggedInUserId();

			var excludedRecommendedStudy = {
				userId: userId,
				studyId: recommendedStudyId,
				reason: exclusionReason
			};

			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: 'POST',
				data: JSON.stringify(excludedRecommendedStudy),
				url: '/backend/secure/volunteer/excludedRecommendedStudy',
				success: function () {
					thisView.refreshPageAfterStudyExclusion();
				}
			});
		},

		refreshPageAfterStudyExclusion: function () {
			if (this.isLastPage() && this.model.get('page').length === 1) {
				if (this.isFirstPage()) {
					this.goToForceRefresh('/volunteers/studies-for-you/' + this.model.get('action'));
				} else {
					this.goTo('/volunteers/studies-for-you/' + this.model.get('action') + '/page/' + (this.model.get('pageNum') - 1));
				}
			} else {
				this.goToForceRefresh('/volunteers/studies-for-you/' + this.model.get('action') + '/page/' + this.model.get('pageNum'));
			}
		},

		getTotalStudyCount: function () {
			var recommendedStudyCounts = util.getGlobalVar('authDetails').recommendedStudyCounts;
			if (this.model.get('action') === 'suggested-by-study-teams') {
				return recommendedStudyCounts.userRecStudyCount;
			} else if (this.model.get('action') === 'matched-by-system') {
				return recommendedStudyCounts.sysRecStudyCount;
			}
		},

		goToNextPage: function (event) {
			event.preventDefault();

			if (this.isLastPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') + 1;
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/studies-for-you/' + this.model.get('action') + '/page/' + this.model.get('pageNum'));
		},

		isLastPage: function () {
			return Number(this.model.get('pageNum')) === Math.ceil(this.getTotalStudyCount() / this.model.get('pageSize'));
		},

		goToPrevPage: function (event) {
			event.preventDefault();

			if (this.isFirstPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') - 1;
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/studies-for-you/' + this.model.get('action') + '/page/' + this.model.get('pageNum'));
		},

		isFirstPage: function () {
			return (Number(this.model.get('pageNum')) === 1);
		},

		jumpToPage: function () {
			var newPageNum = $('#selectPage').val();
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/studies-for-you/' + this.model.get('action') + '/page/' + this.model.get('pageNum'));
		},

		generateTemplateData: function () {
			var templateData = {};
			if (this.model.get('action') === 'matched-by-system') {
				templateData.matchedBySystem = true;
			}
			templateData.recommendedStudyCounts = util.getGlobalVar('authDetails').recommendedStudyCounts;

			templateData.totalStudyCount = this.getTotalStudyCount();
			templateData.startStudyCount = (this.model.get('pageNum') - 1) * this.model.get('pageSize') + 1;
			templateData.endStudyCount = templateData.startStudyCount + this.model.get('pageSize') - 1;

			if (templateData.endStudyCount > templateData.totalStudyCount) {
				templateData.endStudyCount = templateData.totalStudyCount;
			}

			templateData.pageNumbers = [];
			for (var i = 1; i <= Math.ceil(templateData.totalStudyCount / this.model.get('pageSize')); i++) {
				templateData.pageNumbers.push({pageNumber: i});
			}

			templateData.profileCompleteness = this.profileCompleteness;
			templateData.organizationInfo = appConfig.organizationInfo;
			templateData.matchingFinished = this.matchingFinished;
			templateData.showFindStudies = !templateData.matchedBySystem || templateData.matchingFinished;
			return templateData;
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			if(this.missingRequiredFields.length>0 && localStorage.getItem('missingRequiredFieldsDismissed')!=='true'){
				alertMissingProfileFieldsPopupModule.showPopup({
					data: this.missingRequiredFields
				});
			}
			this.renderSubviewAppend(this.$el.find('#divStudyCardList'), 'studyCardList', this.subViews.studyCardListView);

			if (this.isFirstPage()) {
				this.$el.find('#aPrev').attr('disabled', 'disabled');
			}
			if (this.isLastPage()) {
				this.$el.find('#aNext').attr('disabled', 'disabled');
			}
			this.$el.find('#selectPage').val(this.model.get('pageNum'));

			if (localStorage.getItem('feedbackCode')) {
				if (localStorage.getItem('userFullName')) {
					var userFullName = localStorage.getItem('userFullName');
					feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'), [{name: 'USER_FULL_NAME', value: userFullName}]);
				} else {
					feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				}
				localStorage.setItem('feedbackCode', '');
				localStorage.setItem('userFullName', '');
			}
			return this;
		}
	});
})();
