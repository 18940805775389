var AuthHelper = function () {
	var qs = require('qs');
	var _ = require('underscore');
	var util = require('./util.js');
	var userAgreementModule = require('../modules/module.user.agreement');
	var logoutModule = require('../../shared-scripts/modules/module.logout');
	/**
   * Depending on the security level (the required user role being one of the securityLevel options)
   * redirects the user to the login page with the correct redirect url upon successful login
   * @param securityLevel - volunteer|staff|admin|public
   */
	this.notAuthenticatedHandler = function(securityLevel) {
		var absoluteUrl = window.location.href;
		var relativeUrl = absoluteUrl.substring(absoluteUrl.indexOf(window.location.origin) + window.location.origin.length);
		localStorage.setItem('feedbackCode', '401_PROTECTED_VIEW');
		if (securityLevel.toLowerCase() === 'volunteer') {
			util.redirect('/login/volunteer?redirectUrl=' + relativeUrl);
		} else {
			util.redirect('/login/study-team?redirectUrl=' + relativeUrl);
		}
	};

	this.notAuthorizedHandler = function(responseJSON) {
		var convertedResponse = _.object(_.map(_.keys(responseJSON), function (key) {
			return util.camelToDash(key);
		}), _.values(responseJSON));

		var queryString = qs.stringify(convertedResponse, {addQueryPrefix: true});
		queryString = util.camelToDash(queryString);
		util.redirect('/not-authorized' + queryString);
	};

	this.notAgreedToUserAgreementHandler = function(responseJSON) {
		var arguments = responseJSON.arguments;
		var userAgreementType = arguments.userAgreementType;
		var agreementSettings = {
			username: arguments.username,
			userAgreementType: userAgreementType,
			needsConfirm: true,
			needsDeactivate: true,
			isParent: arguments.isParent,
			hasLovedOnes: arguments.hasLovedOnes,
			deactivationReason: arguments.deactivationReason,
			agreeCallback: userAgreementModule.agree,
			declineCallback: userAgreementModule.declineAndDeactivateAccount,
			declineCallbackParams: arguments,
		};

		// TODO: remove redundant configuration for agreementSettings in volunteer and study team
		if (userAgreementType === 'STM') {
			agreementSettings.needsDeactivate=false;
			agreementSettings.needsConfirm=true;
			agreementSettings.isParent=arguments.isParent;
			agreementSettings.declineCallback = logoutModule.logout;
			agreementSettings.declineCallbackParams = userAgreementType;
		}

		var redirectUrl =util.getUrlParam('redirect-url');
    
		// if there is a redirectUrl, we are passing a call back function to agreementSettings.agreeCallback so that when user agrees to user agreement, they will be redirected to the value in redirectUrl.
		if(redirectUrl){
			agreementSettings.agreeCallback = function(userAgreement) {
				userAgreementModule.agree(userAgreement, function(){
					util.redirect(redirectUrl);
				});
			};
		}
		userAgreementModule.showUserAgreement(agreementSettings);
	};
};

module.exports = new AuthHelper();
