(function () {
	'use strict';
	var template = require('~institution-specific-config/footer.basic.hbs');
	var appConfig = require('~institution-specific-config/app.config.json');
	var View = Backbone.View.extend({
		render: function () {
			var footerData = {
				currentYear: new Date().getFullYear(),
				organizationInfo: appConfig.organizationInfo
			};
			this.$el.html(template(footerData));
			return this;
		}
	}
	);
	module.exports = View;
})();