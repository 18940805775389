(function() {
	'use strict';
	var template = require('../../templates/hbs/change.password.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');

	require('parsleyjs');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	var View = Backbone.View.extend({
		el: 'main',
		initialize: function(options) {
			this.subViews = {};

			if(options) {
				this.collection = options.collection;
			}
		},

		events: {
			'submit #formChangePassword': 'changePassword',
			'click #checkboxShowPassword' : 'togglePasswordVisibility'
		},

		changePassword: function(event) {
			event.preventDefault();
			var thisView = this;

			var $frmChangePassword = $('#formChangePassword');

			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
				},
				type: 'POST',
				url: '/backend/public/change-password',
				data: $frmChangePassword.serialize(),
				success: function () {
					localStorage.setItem('feedbackCode', 'CHANGE_PASSWORD_SUCCESS');
					util.redirect('account-settings');
				},
				error: function (jqxhr) {
					if (jqxhr.status === 400) {
						feedbackModule.showFeedback(thisView, jqxhr.responseJSON.code);
					}
				}
			});
		},

		togglePasswordVisibility: function() {
			var $password = $('#passwordNewPassword');
			if ($password.attr('type') === 'password') {
				$password.attr('type', 'text');
			} else {
				$password.attr('type', 'password');
			}
		},

		parseTemplateData: function() {
			var templateData = {};
			var authDetails = util.getGlobalVar('authDetails');
			templateData.PARENT_USER_ID = authDetails.lovedOnes[0].parentId;

			this.collection.each(function(applicationSetting) {
				templateData[applicationSetting.get('name')] = applicationSetting.get('value');
			});

			return templateData;
		},

		render: function() {
			this.$el.html(template(this.parseTemplateData()));
			this.$el.find('#formChangePassword').parsley(parsleyConfig);
			return this;
		}
	}
	);
	module.exports = View;
})();
