(function() {
	'use strict';
	var Backbone = require('backbone');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values.js');
	var DEMOGRAPHICS_PROPERTIES = ['gender','race','otherRace', 'englishFluency','dateOfBirth','parentOrGuardianOfAChild'];
	var i18next = require('../../../../shared-scripts/app-util/i18n');
	var validateMissingRequiredFields = require('../modules/module.required.volunteer.profile.fields.js');

	module.exports = Backbone.Model.extend({
		url: function () {
			return '/backend/secure/volunteers/' + this.get('userId');
		},

		parse: function (data) {
			var volunteerProfile = {
				'userId': data.userId,
				'demographics': [],
				'genderIdentity':[],
				'basicHealthInfo': [],
				'medicalConditions': [],
				'medications': [],
				'userInfo': {
					firstName: data.user.firstName,
					lastName: data.user.lastName,
					email: data.user.email,
					achildAccount: data.user.achildAccount, //Does not indicate a parent account, can be adult loved one or parent
					parentAccount: data.user.parentAccount
				},
				'studyInterestsCriterion': data.studyInterestsCriterion,
				'visibleToStudyTeams': data.visibleToStudyTeams,
				'emailNotifications': {
					recommendationNotificationFrequency: data.recommendationNotificationFrequency,
					messageNotificationFrequency: data.messageNotificationFrequency
				},
				'language': data.language ? data.language.toLowerCase() : i18next.resolvedLanguage,
			};

			if (data.contactInfo) {
				volunteerProfile.userInfo.city = data.contactInfo.city;
				volunteerProfile.userInfo.state = data.contactInfo.state;
				volunteerProfile.userInfo.country = data.contactInfo.country;
				volunteerProfile.userInfo.zip = data.contactInfo.zip;
				volunteerProfile.userInfo.phone = data.contactInfo.phone;
			}

			var propertyValues = data.propertyValues;

			for (var i = 0; i < propertyValues.length; i++) {
				var name = propertyValues[i].property.name;

				if (DEMOGRAPHICS_PROPERTIES.indexOf(name) >= 0) {
					if (name === 'gender') {
						var constantLookupValues = new ConstantLookupValues(null, true);
						var femaleLookupValueId = constantLookupValues.get('GENDER', 'FEMALE');
						var intersexLookupValueId = constantLookupValues.get('GENDER', 'INTERSEX');
						var volunteerGenderId = propertyValues[i].lookupValues[0].id;
						volunteerProfile.showPregnancyField = volunteerGenderId === femaleLookupValueId || volunteerGenderId === intersexLookupValueId;
					}
					volunteerProfile.demographics.push(propertyValues[i]);
				}  else if (name === 'estimatedDueDate' && propertyValues[i].savedValue) {
					var dueDate = new Date(propertyValues[i].savedValue);
					var currentDate = new Date();

					if (new Date(dueDate.getUTCFullYear(), dueDate.getUTCMonth(), dueDate.getUTCDate()).getTime() >= new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1).getTime()) {
						volunteerProfile.demographics.push(propertyValues[i]);
					}
				} else if(name === 'genderIdentity' || name === 'otherGenderIdentity' || name === 'genderPronoun' || name === 'otherGenderPronoun') {
					volunteerProfile.genderIdentity.push(propertyValues[i]);
				}
				else if (name === 'smokingStatus' || name === 'height' || name === 'weight') {
					volunteerProfile.basicHealthInfo.push(propertyValues[i]);
				}else if (name === 'currentConditions' || name === 'pastConditions' || name === 'hasMetalImplants') {
					volunteerProfile.medicalConditions.push(propertyValues[i]);
				} else if (name === 'willingTakeExpDrugs' || name === 'willingToChangeMeds') {
					volunteerProfile.medications.push(propertyValues[i]);
				}else if (name === 'parentOrGuardianOfAChild') {
					volunteerProfile.demographics.push(propertyValues[i]);
				}
			}
			volunteerProfile.missingRequiredFields = validateMissingRequiredFields(data);
			return volunteerProfile;
		},
		getStudyInterestExpression: function (criterionVariableName) {
			var studyInterestsClauses = this.get('studyInterestsCriterion') ? this.get('studyInterestsCriterion').clauses : undefined;
			var studyInterestsExpressions = studyInterestsClauses ? studyInterestsClauses[0].expressions : undefined;
			if (studyInterestsExpressions) {
				for (var expressionIdx = 0; expressionIdx < studyInterestsExpressions.length; expressionIdx++) {
					var expression = studyInterestsExpressions[expressionIdx];
					if (expression.variable.name.toUpperCase() === criterionVariableName.toUpperCase()) {
						return expression;
					}
				}
			}
			return null;
		}
	});
})();

