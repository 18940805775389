(function () {
	'use strict';
	var template = require('../../templates/hbs/study.details.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var html = require('../../../../shared-scripts/app-util/html.js');
	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var screeningQuestionsModule = require('../modules/module.screening.questions.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var eligibilityCriteriaUtil = require('../../../../shared-scripts/app-util/eligibility.criteria.helper');

	function getPI(teamMembers) {
		for (var i = 0; i < teamMembers.length; i++) {
			if (teamMembers[i].role === 'PRINCIPAL_INVESTIGATOR') {
				return util.formatNameLastFirstMiddle(teamMembers[i].studyTeamMemberUser.lastName, teamMembers[i].studyTeamMemberUser.firstName, teamMembers[i].studyTeamMemberUser.middleName);
			}
		}
	}

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aPrintStudy': 'printStudy',
			'click #aIamInterested': 'showInterest'
		},

		initialize: function (options) {
			if (options) {
				this.userRoles = options.userRoles;
				this.studyInterestStats = options.studyInterestStats;
			}
			this.study = this.model.get('page')[0];
			this.subViews = {};
		},

		printStudy: function () {
			window.print();
		},

		showInterest: function () {
			var loggedInUserId = util.getLoggedInUserId();
			var studyNum = this.study.studyNum;
			var volunteerEligibilityMap = {
				'study-num': studyNum
			};

			if (loggedInUserId) {
				var successCallBack = function () {
					util.redirect('/volunteers/studies/' + studyNum + '/screening-questions');
				};
				screeningQuestionsModule.checkVolunteerEligibility(volunteerEligibilityMap, false, successCallBack);
			} else {
				popupModule.showPopup($('#asideLoginOrCreateAccount'), $('#aSignup'), this.closePopup, {studyId: this.study.id});
			}
		},

		closePopup: function (event) {
			var studyId = event.data.studyId;
			popupModule.closePopup($('#asideLoginOrCreateAccount'));
			util.redirect('/signup?study-id=' + studyId);
		},

		parseLocation: function () {
			var locations = this.study.locations;
			util.moveCanBeDoneFromHomeToStart(locations);
			return locations.map(function (location) {
				return html.markAsSafe(location.displayText);
			}).join(' | ');
		},

		generateTemplateData: function () {
			var templateData = {};

			var studyPropertyValues = this.study.propertyValues;

			var savedValueStudyProperties = ['title', 'purpose', 'studyDescription', 'compensation', 'aboutStudy'];

			templateData.pi = getPI(this.study.teamMembers);

			_.forEach(savedValueStudyProperties, function (savedValueStudyProperty) {
				var savedValuePropertyValue = _.find(studyPropertyValues, function (studyPropertyValue) {
					return studyPropertyValue.property.name === savedValueStudyProperty;
				});

				var savedValue = savedValuePropertyValue ? savedValuePropertyValue.savedValue : null;
				templateData[savedValueStudyProperty] = html.asSimpleMarkup(savedValue) || '';
			});
			templateData.studyNum = this.study.studyNum;
			templateData.department = this.study.department.displayText;
			templateData.locations = this.parseLocation();

			templateData.postingActivationDate = util.formatDate(new Date(this.study.postingActivationDate), true);
			templateData.postingDeActivationDate = util.formatDate(new Date(this.study.postingDeactivationDate), true);

			templateData.contactName = this.study.contactName;
			templateData.contactPhone = this.study.contactPhone;
			templateData.contactEmail = this.study.contactEmail;
			templateData.contactWebsite = util.formatUrlProtocol(this.study.contactWebsite);

			if (this.studyInterestStats.get('interestedParticipantCount') < appConfig.studyDetails.showInterestedCountIfGreaterThan) {
				templateData.victorsNeeded = true;
			} else {
				templateData.victorCount = this.studyInterestStats.get('interestedParticipantCount');
			}

			templateData.showedInterestDate = this.studyInterestStats.get('loggedInUserStatus').showedInterestDate;

			if (this.userRoles && this.userRoles.indexOf('VOLUNTEER', 0) === -1) {
				templateData.isStudyTeamMember = true;
			}

			templateData.hasGroups = this.study.eligibilityCriteria.length > 0;
			templateData.eligibilityCriteriaGroupNumber = this.study.eligibilityCriteria.length;
			templateData.moreThanOneGroup = this.study.eligibilityCriteria.length > 1;

			templateData.eligibilityCriteria = eligibilityCriteriaUtil.generateEligibilityCriteriaTemplateData(this.study.eligibilityCriteria);

			templateData.organizationInfo = appConfig.organizationInfo;
			templateData.marketingContent = appConfig.marketingContent;

			return templateData;
		},

		render: function () {
			if (this.study && this.study.active) {
				template = require('../../templates/hbs/study.details.hbs');
				this.$el.html(template(this.generateTemplateData()));
				popupModule.initializePopup(this.$el.find('#asideLoginOrCreateAccount'));
				this.$el.find('.study-details').stickEmUp();

				var showedInterestDate = this.studyInterestStats.get('loggedInUserStatus').showedInterestDate;
				if (showedInterestDate) {
					var params = [{name: 'SHOWED_INTEREST_DATE', value: util.formatDate(new Date(showedInterestDate))}];
					feedbackModule.showFeedback(this, 'STUDY_ALREADY_SHOWED_INTEREST', params);
				}
			} else {
				template = require('../../templates/hbs/study.details.inactive.hbs');
				this.$el.html(template());
			}
			return this;
		}
	});
})();
