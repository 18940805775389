(function() {

	'use strict';
	var util = require('../app-util/util');

	var LogoutModule = function () {

		this.logout = function (role) {
			$.ajax({
				type: 'POST',
				url: '/backend/logout',
				success: function() {
					var origin = window.location.origin;
					if (role === 'VOL') {
						util.redirect(origin);
					} else if(role === 'STM') {
						util.redirect('/Shibboleth.sso/Logout?return=' + origin);
					}
				}
			});
		};
	};

	module.exports = new LogoutModule();
})();
