(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.contact.info.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');
	var countries = require('../../../../shared-scripts/config/countries.json');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'submit form': 'saveSection',
			'click #aCancel': 'cancel',
			'keyup #textPhone': 'validatePhone'
		},

		cancel: function () {
			localStorage.setItem('feedback-card-cancel-container', 'contact-info-card-feedback');
			util.redirect('/volunteers/profile');
		},

		validatePhone: function () {
			$('#textPhone').parsley(parsleyConfig).validate();
		},

		validateZip: function () {
			$('#textZip').parsley(parsleyConfig).validate();
		},

		saveSection: function (event) {
			event.preventDefault();
			this.validateZip();
			var userId = this.model.get('userId');

			var requestData = this.getRequestData();

			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json-patch+json'
				},
				type: 'PATCH',
				url: '/backend/secure/volunteer/users/' + userId,
				data: JSON.stringify(requestData),

				success: function () {
					localStorage.setItem('feedback-card-success-container', 'contact-info-card-feedback');
					util.redirect('/volunteers/profile');
				}
			});
		},

		getRequestData: function () {
			var requestData = [];
			var firstName = $('#textFirstName').val().trim();
			var lastName = $('#textLastName').val().trim();
			var city = $('#textCity').val().trim();
			var state = $('#selectState').val().trim();
			var zip = $('#textZip').val().trim();
			var country = $('#select_country_Selected').val()[0];
			var phone = $('#textPhone').val().trim();

			requestData.push({
				op: 'replace',
				path: '/firstName',
				value: firstName
			});

			requestData.push({
				op: 'replace',
				path: '/lastName',
				value: lastName
			});

			requestData.push({
				op: 'replace',
				path: '/profile/contactInfo/city',
				value: city
			});

			requestData.push({
				op: 'replace',
				path: '/profile/contactInfo/state',
				value: state
			});

			requestData.push({
				op: 'replace',
				path: '/profile/contactInfo/zip',
				value: zip
			});

			requestData.push({
				op: 'replace',
				path: '/profile/contactInfo/country',
				value: country
			});

			requestData.push({
				op: 'replace',
				path: '/profile/contactInfo/phone',
				value: phone
			});

			return requestData;
		},

		setupTypeaheadCountry: function () {
			this.$el.find('#textCountry').typeIO({
				hint: true,
				highlight: true,
				mode: 'single-select',
				minLength: 1,
				name: 'country',
				removeText: i18n.t('multiSelectRemoveLabel'),
				resultsContainer: '#divCountry'
			},
			{
				limit: 10,
				display: 'text',
				source: countries,
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					notFound: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
			// Because of TypeIO generated markup interfering with the parsley validation we need to manually trigger the validation
			// when a selection is picked from single select type io the input textbox disappears (loses focus) but parsley is not triggered therefore the validation
			// error message is not cleared.
			$('#textCountry').blur(function() {
				// Code to execute when the textbox loses focus
				$('#textCountry').parsley().validate();
			});
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		render: function () {
			this.$el.html(template(this.model.get('userInfo')));
			this.setupTypeaheadCountry();
			var countryCode = this.model.get('userInfo').country;
			if(countryCode) {
				var selectedCountry = countries.filter(function (country) {
					return country.value === countryCode;
				})[0].text;
				this.$el.find('#textCountry').selectItem(selectedCountry);
			}
			this.$el.find('#selectState').val(this.model.get('userInfo').state);
			this.$el.find('form').parsley(parsleyConfig);

			if (!util.isAndroid()) {
				new Cleave('#textPhone', {
					numericOnly: true,
					blocks: [3, 3, 4],
					delimiters: ['-', '-']
				});
				//this.$el.find('#textPhone').mask('999-999-9999', {autoclear: false});
			}
			this.setupValidationSummaryHandler();
			return this;
		}
	});
})();
