(function () {
	'use strict';
	var Backbone = require('backbone');
	/* eslint-disable backbone/model-defaults */
	module.exports = Backbone.Model.extend({

		initialize: function () {
			var identityProviders = this.get('identityProviders');
			if (identityProviders && identityProviders.length === 1) {
				this.set('selectedIdP', identityProviders[0]);
			}
			this.set('multipleIdentityProviders', identityProviders.length > 1);
		}
	});
})();

