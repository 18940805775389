(function() {
	'use strict';
	var template = require('../../templates/hbs/change.email.verification.hbs');
	var appConfig = require('~institution-specific-config/app.config.json');
	var i18next = require('../../../../shared-scripts/app-util/i18n');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aResendEmail': 'resendEmail'
		},

		resendEmail: function(event) {
			event.preventDefault();
			var thisView = this;
			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: 'POST',
				url: '/backend/volunteers/'+thisView.model.get('userId')+'/change-email-confirmation/email',
				success: function () {
					thisView.$el.find('#divResendResult').addClass('success-font-color');
					$('#spanResendResult').html(i18next.t('accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.success', {newEmail:thisView.model.get('email')}));
				},
				error: function () {
					thisView.$el.find('#divResendResult').addClass('error-font-color');
					$('#spanResendResult').html(i18next.t('accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.failure'));
				}
			});
		},

		generateTemplateData: function() {
			var templateData = $.extend(true, {}, this.model.attributes);
			templateData.supportInfo = appConfig.supportInfo;
			return templateData;
		},

		render: function() {
			this.$el.html(template(this.generateTemplateData()));
			return this;
		}
	});

	module.exports = View;
})();
