(function () {
	'use strict';
	var template = require('../../templates/hbs/index.hbs');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var userAgreementModule = require('../../../../shared-scripts/modules/module.user.agreement');
	var appConfig = require('~institution-specific-config/app.config.json');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'submit #formTopics': 'search',
			'click #aUserAgreement': 'openUserAgreement',
		},

		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
			}
			this.subViews = {};
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			this.setupTypeIO();
			this.showOnLoadFeedback();
			return this;
		},

		search: function (event) {
			event.preventDefault();
			var formDataArray = $('#formTopics').serializeArray();
			var redirectUrl = '/study-results';
			if (formDataArray.length > 0) {
				redirectUrl += '?topics=' + formDataArray[0].value;
			}
			this.goTo(redirectUrl);
		},

		openUserAgreement: function () {
			userAgreementModule.showUserAgreement({userAgreementType: 'VOL'});
		},

		generateTemplateData: function () {
			var templateData = {};
			var totalbackgrounds = i18n.countKeys('landing:victorImage.quote');
			var randomInt = util.randomInt(1, totalbackgrounds+1);
			templateData.victorCount = this.model.get('ACTIVE_VOLUNTEER');
			templateData.backgroundClass = 'banner-'+randomInt;
			templateData.quote = 'landing:victorImage.quote.'+randomInt;
			templateData.organizationInfo = appConfig.organizationInfo;
			templateData.marketingContent = appConfig.marketingContent;
			return templateData;
		},

		setupTypeIO: function () {
			this.$el.find('#textTopics').typeIO({
				hint: true,
				highlight: true,
				mode: 'inline-single-select',
				minLength: 1,
				name: 'topics'
			},
			{
				limit: 10,
				display: 'text',
				source: this.transformDataForTypeIO(this.seedModel.get('MEDICAL_CONDITION')),
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					notFound: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		transformDataForTypeIO: function (dataArray) {
			var result = [];
			$.each(dataArray, function (index, data) {
				if (data.visible) {
					result.push({value: data.id, text: data.displayText});
				}
			});
			return result;
		},

		showOnLoadFeedback: function () {
			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'), [], '#divMainPageFeedback');
				localStorage.setItem('feedbackCode', '');
			}
		}
	});
	module.exports = View;
})();

