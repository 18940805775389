var Util = function () {
	var moment = require('moment');
	var _ = require('underscore');
	var i18n = require('./i18n');
	var ConstantLookupValues = require('../../shared-scripts/app-util/constant.lookup.values');

	this.camelToDash = function (str) {
		return str.replace(/(^[A-Z])/, function (first) {
			return first.toLowerCase();
		})
			.replace(/([A-Z])/g, function (letter) {
				return '-' + letter.toLowerCase();
			});
	};

	this.navigate = function (url, options){
		if (url) {
			if(options){
				Backbone.history.navigate(url, options);
			}
			Backbone.history.navigate(url);
		}
		else {
			throw new Error('No url specified for navigate');
		}
	};
	
	this.redirect = function (url, openInNewWindow) {
		if (url) {
			if (openInNewWindow) {
				window.open(url);
			} else if(window.location.href.replace(/^.*\/\/[^\/]+/, '') === url){
				// If redirecting to the same url, just reload the page.
				// Setting window.location.href to the url with hashes does not work
			  window.location.reload();
			} else {
				window.location.href = url;
			}
		} else {
			throw new Error('No url specified for redirect');
		}
	};

	/**
	 * formats a message by replacing {param.name} placeholders with array of params.
	 * @param message - the message to be formatted
	 * @param params - the array of params to be injected into message
	 * @returns formattedMessage - the formatted message
	 */
	this.format = function (message, params) {
		var formattedMessage;
		if (message) {
			formattedMessage = message;
			if(_.isArray(params)) {
				for (var i = 0; i < params.length; i++) {
					formattedMessage = formattedMessage.split('{' + params[i].name + '}').join(params[i].value);
				}
			}
		} else {
			throw new Error('Please specify message to be formatted');
		}
		return formattedMessage;
	};

	/**
	 * formats a number by adding commas. e.g. 10000 -> 10,000
	 * @param number - the unformatted number without commas
	 * @returns formatted number with commas
	 */
	this.numberWithCommas = function (number) {
		if(!number) {
			return 0;
		}
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	/**
	 * returns a random integer between min (included) and max (excluded)
	 * @param min - the minimum range (included)
	 * @param max - the maximum range (excluded)
	 * @returns random number in range [min, max)
	 */
	this.randomInt = function (min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min)) + min;
	};

	var NUM_REGEX = /[0-9]+/;

	function parseWholeInt(str) {
		// ensure the entire string makes up a positive integer number
		// return NaN if not
		if (typeof str === 'number') {
			return str;
		}

		var match = NUM_REGEX.exec(str);
		if (match && match[0].length === str.length) {
			return parseInt(str, 10);
		} else {
			return NaN;
		}
	}

	this.parseAndValidateDate = function (year, month, day) {
		var inputYear = parseWholeInt(year);
		var inputMonth = parseWholeInt(month);
		var inputDay = parseWholeInt(day);

		if (isNaN(inputYear) || isNaN(inputDay) || inputMonth < 0 || inputYear <= 1900) {
			return {valid: false};
		} else {
			var date = new Date(inputYear, inputMonth, inputDay);
			var valid = date.getDate() === inputDay &&
				// getDate is the day of the month.  getDay is the day of the week!
				date.getFullYear() === inputYear &&
				date.getMonth() === inputMonth;
			return {valid: valid, date: date};
		}
	};

	this.newDate = function (year, month, day) {
		return this.parseAndValidateDate(year, month, day).date;
	};

	this.formatDateForProperty = function (date) {
		// property.savedValue is as tring, but we're putting a date in it.  Be sure to
		// get the date in the local timezone, so the date is correct.
		var month = date.getMonth() + 1;
		month = ('00' + month).slice(-2);// zero padding
		var year = date.getFullYear();
		var day = date.getDate();
		day = ('00' + day).slice(-2);
		return year + '-' + month + '-' + day;
	};

	this.formatDate = function (inputDate, showDay) {
		var date = inputDate;
		if (typeof showDay === 'undefined' || showDay===null) {
			showDay = true;
		}
		if (typeof name === 'string') {
			date = new Date(date);
		}
		if (isNaN(date.getTime())) {
			console.log('Invalid date');
			return null;
		}
		var monthIndex = date.getUTCMonth();
		var monthNames = [i18n.t('months.jan'),
			i18n.t('months.feb'),
			i18n.t('months.mar'),
			i18n.t('months.apr'),
			i18n.t('months.may'),
			i18n.t('months.jun'),
			i18n.t('months.jul'),
			i18n.t('months.aug'),
			i18n.t('months.sep'),
			i18n.t('months.oct'),
			i18n.t('months.nov'),
			i18n.t('months.dec')];
		var month = monthNames[monthIndex];
		var day = date.getUTCDate();
		var year = date.getUTCFullYear();

		if (showDay) {
			return month + ' ' + day + ', ' + year;

		} else {
			return monthNames[date.getUTCMonth()] + ' ' + date.getUTCFullYear();
		}
	};

	this.setGlobalVar = function (variableName, value) {
		window.YHR = window.YHR ? window.YHR : {};
		window.YHR[variableName] = value;
	};

	this.getGlobalVar = function (variableName) {
		return window.YHR?window.YHR[variableName]:undefined;
	};

	this.getLoggedInUserId = function () {
		var loggedInUser = this.getGlobalVar('authDetails').loggedInUser;
		if (loggedInUser) {
			return loggedInUser.userId;
		}
	};

	this.isLoggedInUserParent = function() {
		var authDetails = this.getGlobalVar('authDetails');
		if (authDetails.loggedInUser) {
			var loggedInUser = _.findWhere(authDetails.lovedOnes, {userId: authDetails.loggedInUser.userId});
			return loggedInUser && loggedInUser.parent;
		}
		return false;
	};

	this.getLoggedInUserRoles = function () {
		var loggedInUser = this.getGlobalVar('authDetails').loggedInUser;
		if (loggedInUser) {
			return loggedInUser.roles;
		}
	};

	/*
	 * @description Extracts a query string parameter/variable's value from an url encoded query string.
	 * @see Courtesy of http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript#answer-2480180
	 * @function getUrlParam
	 * @param {string} param - The name of the query string parameter to extract.
	 * @url {string} url - The Url from which query string parameter will be extracted
	 * @returns {string} - The query string parameter value. (url decoded)
	 */
	this.getUrlParam = function (param, url) {
		if (!url) {
			url = window.location.href;
		}
		var results = new RegExp('[\\?&]' + param + '=([^&#]*)').exec(url);
		if (!results) {
			return undefined;
		}
		return decodeURIComponent(results[1]) || undefined;
	};

	/**
	 * util.setUrlParam(page, 2, 'https://yhr.org/study-results?gender=100&page=1')
	 * returns gender=100&page=2
	 *
	 * util.setUrlParam(page-size, 2, 'https://yhr.org/study-results?gender=100')
	 * returns gender=100&page-size=2
	 */
	this.setUrlParam = function (param, paramVal, url) {
		if (!url) {
			url = window.location.href;
		}
		var newAdditionalURL = '';
		var tempArray = url.split('?');
		var additionalURL = tempArray[1];
		var temp = '';
		if (additionalURL) {
			tempArray = additionalURL.split('&');
			for (var i = 0; i < tempArray.length; i++) {
				if (tempArray[i].split('=')[0] !== param) {
					newAdditionalURL += temp + tempArray[i];
					temp = '&';
				}
			}
		}

		var rows_txt = temp + '' + param + '=' + paramVal;
		return newAdditionalURL + rows_txt;
	};

	this.removeParamFromQuery = function (parameter, query) {

		var prefix = encodeURIComponent(parameter) + '=';
		var pars = query.split(/[&;]/g);

		//reverse iteration as may be destructive
		for (var i = pars.length; i-- > 0;) {
			if (pars[i].lastIndexOf(prefix, 0) !== -1) {
				pars.splice(i, 1);
			}
		}

		return (pars.length > 0 ? pars.join('&') : '');

	};

	this.aggregateArrayValues = function (array) {
		var aggregatedArray = [];
		$.each(array, function (index, element) {
			var existingAnswer = _.findWhere(aggregatedArray, {name: element.name});
			if (existingAnswer) {
				if (existingAnswer.value.constructor === Array) {
					existingAnswer.value.push(element.value);
				} else {
					existingAnswer.value = [existingAnswer.value, element.value];
				}
			} else {
				aggregatedArray.push(element);
			}
		});
		return aggregatedArray;
	};

	this.isAndroid = function () {
		return (navigator.userAgent.toLowerCase().indexOf('android') > -1);
	};


	this.formatDateHumanRelative = function (millis) {
		if (!_.isFinite(millis)) {
			return '';
		}
		// const format = 'MMMM D, YYYY';
		var m = moment(millis);
		var str = m.calendar();
		return str.split(' at ');
	};
	this.joinWithLast = function (inputArray, all, last) {
		var arr = inputArray.slice();                   // make a copy so we don't mess with the original
		var lastItem = arr.splice(-1);            // strip out the last element
		arr = arr.length ? [arr.join(all)] : [];  // make an array with the non-last elements joined with our 'all' string, or make an empty array
		arr.push(lastItem);                       // add last item back so we should have ["some string with first stuff split by 'all'", last item]; or we'll just have [lastItem] if there was only one item, or we'll have [] if there was nothing in the original array
		return arr.join(last);                    // now we join the array with 'last'
	};
	this.calculateAge = function getAge(dob) {
		var today = new Date();
		var birthDate = new Date(dob);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	};

	this.formatNameLastFirstMiddle = function (last, first, middle) {
		var lastSeparator = !first ? (!middle ? '' : ',') : ', ';
		var middleSeparator = !first ? (!last ? '' : ' ') : ' ';

		first = !first ? '' : first;
		last = !last ? '' : (last + lastSeparator);
		middle = !middle ? '' : (middleSeparator + middle);

		return last + first + middle;
	};

	 this.transformTemplateDataForBooleanLookUpValues =  function(attributes) {
		var booleanLookUpValues = attributes.BOOLEAN;
		if(booleanLookUpValues){
			for(var booleanLookUpValIndex in booleanLookUpValues) {
				booleanLookUpValues[booleanLookUpValIndex].name = (booleanLookUpValues[booleanLookUpValIndex].name === 'TRUE');
			}
		}
	};

	this.getFileExtension = function (filename) {
		var filenameSplitByDot = filename.split('.');
		if(filenameSplitByDot.length>1) {
			return filenameSplitByDot.pop();
		}
		return '';
	};

	this.buildFormNameValueMap = function(formArray){
		var formNameValueMap = {};
		for(var i = 0; i<formArray.length; i++){
			var field = formArray[i];

			if (!field.value) {
				continue;
			} else {
				field.value = field.value.trim();
			}
			if (!formNameValueMap[field.name]) {
				formNameValueMap[field.name] = [field.value];
			} else {
				formNameValueMap[field.name].push(field.value);
			}
		}
		return formNameValueMap;
	};

	this.formatUrlProtocol = function(url) {
		if(!url) return null;
		else if (!url.startsWith('http://') && !url.startsWith('https://')) {
			return 'https://' + url;
		}
		return url;
	};

	this.sortLookupValuesById = function (lookupValues, direction){
		if(lookupValues){
			switch(direction && direction.toLowerCase()){
			case 'asc':
				lookupValues.sort(function(aLookupValue, bLookupValue){
					return aLookupValue.id - bLookupValue.id;
				});
				break;
			case 'desc':
				lookupValues.sort(function(aLookupValue, bLookupValue){
					return bLookupValue.id - aLookupValue.id;
				});
				break;
			default:
				lookupValues.sort(function(aLookupValue, bLookupValue){
					return aLookupValue.id - bLookupValue.id;
				});
				break;
			}
		}
	};

	this.moveCanBeDoneFromHomeToStart = function (locations){
		var constantLookupValues = new ConstantLookupValues(null, true);
		if(!locations){
			return;
		}

		var canBeDoneFromHomeId = constantLookupValues.get('STUDY_LOCATION', 'CAN_BE_DONE_FROM_HOME');
		var indexOfCanBeDoneFromHome = locations.findIndex(function (location) {
			return location.id === canBeDoneFromHomeId;
		});

		if (indexOfCanBeDoneFromHome !== -1) {
			//remove the object from its current position
			var canBeDoneFromHome = locations.splice(indexOfCanBeDoneFromHome, 1)[0];
			// Insert the object at the beginning of the array
			locations.unshift(canBeDoneFromHome);
		}
	};
};

module.exports = new Util();
