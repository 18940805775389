(function() {
	'use strict';
	var template = require('../../templates/hbs/change.email.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	require('parsleyjs');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'submit #formChangeEmail': 'changeEmail'
		},

		initialize: function() {
			this.subViews = {};
		},

		changeEmail: function(event) {
			var thisView = this;
			event.preventDefault();
			var newEmail = $('#emailNewEmail').val();
			var changeEmailToken = {
				'newEmail': newEmail
			};

			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: 'POST',
				url: '/backend/volunteers/change-email-confirmations',
				data: JSON.stringify(changeEmailToken),
				success: function (data) {
					util.redirect('change-email-verification?new-email='+encodeURIComponent(newEmail)+'&user-id=' + data.value);
				},
				error: function (jqxhr) {
					if (jqxhr.status === 400) {
						feedbackModule.showFeedback(thisView, 'CHANGE_EMAIL_VERIFICATION_LINK_EMAIL_IN_USE',
							[{name: 'NEW_EMAIL', value: newEmail}]);
					}
				}
			});
		},

		render: function() {
			this.$el.html(template(this.model.attributes));
			this.$el.find('#formChangeEmail').parsley(parsleyConfig);
			return this;
		}
	}
	);
	module.exports = View;
})();
