(function () {
	'use strict';
	var template = require('../../templates/hbs/profile.demographics.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var volunteerProfileModule = require('../modules/module.volunteer.profile.js');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values.js');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'change input[name=gender]': 'genderChanged',
			'change input[name=pregnancy]': 'pregnancyChanged',
			'keyup #textDueYear': 'validateDueDate',
			'submit #formDemographics': 'saveSection',
			'click [name="race"]': 'validateRace',
			'keyup #textBirthDay': 'validateDateOfBirth',
			'keyup #textBirthYear': 'validateDateOfBirth',
			'change #selectBirthMonth': 'validateDateOfBirth',
			'click #aCancel': 'cancel'
		},

		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
				this.appSettingsCollection = options.appSettingsCollection;
				this.constantLookupValues = new ConstantLookupValues(options.seedModel.attributes);
			}
			this.subViews = {};
		},

		cancel: function () {
			localStorage.setItem('feedback-card-cancel-container', 'demographics-card-feedback');
			util.redirect('/volunteers/profile');
		},

		validateRace: function () {
			$('#textOtherRace').parsley(parsleyConfig).validate();
		},

		validateDateOfBirth: function (event) {
			if (!event.isTrigger) {
				$('#textBirthYear').parsley(parsleyConfig).validate();
			}
		},

		validateDueDate: function () {
			$('#textDueYear').parsley(parsleyConfig).validate();
		},

		saveSection: function (event) {
			event.preventDefault();
			var requestData = volunteerProfileModule.getPropertyValues('#formDemographics');
			this.processBirthDate(requestData);
			this.processDueDate(requestData);
			var userId = this.model.get('userId');
			volunteerProfileModule.sendPropertyValuesRequest(userId, requestData, 'demographics-card-feedback');
		},

		processBirthDate: function (requestData) {
			var birthDate = util.newDate(
				$('#textBirthYear').val(),
				$('#selectBirthMonth').val(),
				$('#textBirthDay').val());
			birthDate = util.formatDateForProperty(birthDate);

			requestData.push({savedValue: birthDate, property: {name: 'dateOfBirth'}});
		},

		processDueDate: function (requestData) {
			var dueDate = '';
			if (!$('#divDueDate').hasClass('hide')) {
				dueDate = util.newDate($('#textDueYear').val(), $('#selectDueMonth').val(), 1);
				dueDate = util.formatDateForProperty(dueDate);
			}
			requestData.push({savedValue: dueDate, property: {name: 'estimatedDueDate'}});
		},

		genderChanged: function (event) {
			if (event.currentTarget.id === 'radio-gender-'+this.constantLookupValues.get('GENDER', 'MALE')) {
				this.$el.find('#divPregnancy').addClass('hide');
				this.$el.find('#radioNoPregnant').prop('checked', true);
				this.$el.find('#divDueDate').addClass('hide');
				this.$el.find('#selectDueMonth').val('0');
				this.$el.find('#textDueYear').val('');
			} else {
				this.$el.find('#divPregnancy').removeClass('hide');
			}
		},

		pregnancyChanged: function (event) {
			if (event.currentTarget.id === 'radioYesPregnant') {
				this.$el.find('#divDueDate').removeClass('hide');
			} else {
				this.$el.find('#divDueDate').addClass('hide');
				this.$el.find('#selectDueMonth').val('0');
				this.$el.find('#textDueYear').val('');
			}
		},

		populateForm: function () {
			var parsleyCheckProfileDateAttr = this.model.get('userInfo').achildAccount ? 'data-parsley-check-profile-date-child' : 'data-parsley-check-profile-date-adult';
			this.$el.find('#textBirthYear').attr(parsleyCheckProfileDateAttr, this.appSettingsCollection.models[0].get('value'));

			var demographics = this.model.get('demographics');

			for (var i = 0; i < demographics.length; i++) {
				var demographic = demographics[i];
				switch (demographic.property.name) {
				case 'gender':
					var gender = demographic.lookupValues[0].id;
					this.$el.find('input[name=gender][value=' + gender + ']').prop('checked', true);
					break;
				case 'estimatedDueDate':
					this.$el.find('#radioYesPregnant').prop('checked', true);
					this.$el.find('#divDueDate').removeClass('hide');

					var dueDate = new Date(demographic.savedValue);
					this.$el.find('#selectDueMonth').val(dueDate.getUTCMonth());
					this.$el.find('#textDueYear').val(dueDate.getUTCFullYear());
					break;
				case 'englishFluency':
					if (demographic.lookupValues.length) {
						var englishFluency = demographic.lookupValues[0].id;
						this.$el.find('#selectFluency').val(englishFluency);
					}
					break;
				case 'otherRace':
					var otherRace = demographic.savedValue;
					this.$el.find('#textOtherRace').val(otherRace);
					break;
				case 'race':
					for (var j = 0; j < demographic.lookupValues.length; j++) {
						var lookupValue = demographic.lookupValues[j];
						this.$el.find('input[name=race][value=' + lookupValue.id + ']').prop('checked', true);
					}
					break;
				case 'dateOfBirth':
					var dateOfBirth = new Date(demographic.savedValue);
					var birthMonth = dateOfBirth.getUTCMonth();
					var birthDay = dateOfBirth.getUTCDate();
					var birthYear = dateOfBirth.getUTCFullYear();
					this.$el.find('#selectBirthMonth').val(birthMonth);
					this.$el.find('#textBirthDay').val(birthDay);
					this.$el.find('#textBirthYear').val(birthYear);
					break;
				case 'parentOrGuardianOfAChild':
					var parentOrGuardianOfAChild = demographic.lookupValues[0].id;
					this.$el.find('input[name=parentOrGuardianOfAChild][value=' + parentOrGuardianOfAChild + ']').prop('checked', true);
					break;
				}
			}
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		generateTemplateData: function () {
			util.sortLookupValuesById(this.seedModel.attributes.GENDER);
			util.transformTemplateDataForBooleanLookUpValues(this.seedModel.attributes);
			var templateData =  $.extend(true, {}, this.seedModel.attributes);
			templateData.isParent = this.model.get('userInfo').parentAccount;
			return templateData;
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			if(this.model.get('showPregnancyField')){
				this.$el.find('#divPregnancy').removeClass('hide');
			}
			this.populateForm();
			this.$el.find('form').parsley(parsleyConfig);
			this.setupValidationSummaryHandler();
			return this;
		}
	});
})();
