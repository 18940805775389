var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"margin-top margin-bottom center-button-container text-center\">\n        <a data-history-navigate href=\"javascript:void(0);\" id=\"aDecline\" role=\"button\" class=\"error-font-color margin-left margin-right\">Decline</a>\n        <a data-history-navigate href=\"javascript:void(0);\" id=\"buttonIagree\" role=\"button\" class=\"primary-button\">Accept</a>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./stripped.header.hbs"),depth0,{"name":"stripped.header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div class=\"grid-row\">\n  <div id=\"divUserAgreement\" class=\"grid-sm-1 grid-lg-8-12 grid-centered\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"needConfirmButtons") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>";
},"usePartial":true,"useData":true});