(function () {
	'use strict';
	var template = require('../../templates/hbs/account.settings.hbs');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');

	var View = Backbone.View.extend({
		el: 'main',
		initialize: function (options) {
			this.subViews = {};
		},

		render: function () {
			this.$el.html(template());

			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				localStorage.setItem('feedbackCode', '');
			}

			return this;
		}
	}
	);
	module.exports = View;
})();
