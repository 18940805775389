(function() {
	'use strict';

	require('parsleyjs');
	var util = require('../app-util/util');
	var i18n = require('../../shared-scripts/app-util/i18n');
	var zipValidator = require('postcode-validator');

	var parsleyConfig = {
		errorsContainer: function (field) {
			var parent = field.$element.parent();

			while(parent) {
				var parentClass = parent.attr('class');
				if(parentClass && parentClass.indexOf('validation-error-container') !== -1) {
					break;
				}
				parent = parent.parent();
			}
			return parent;
		},
		errorsWrapper: '<div class="field-error-text"></div>',
		errorTemplate: '<span></span>'
	};

	Parsley.addValidator('checkRace', {
		messages: {en: i18n.t('errorMessages:requiredField')},
		requirementType: 'integer',
		validate: function() {
			return !!($('#textOtherRace').val().trim() || $('input[name="race"]:checked').length > 0);
		}
	});

	Parsley.addValidator('checkPhoneNumber', {
		messages: {en: i18n.t('errorMessages:invalidPhone')},
		requirementType: 'integer',
		validate: function(_value, requirement, parsleyInstance) {
			var phoneNumber = parsleyInstance.$element.val();
			var regEx = new RegExp('^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$');
			var emptyPhoneNumber = '___-___-____';

			if(phoneNumber && phoneNumber !== emptyPhoneNumber) {
				return regEx.test(phoneNumber);
			} else {
				return true;
			}
		}
	});

	//TODO: 12-05-2024 - Mel Gunal: I couldn't find the usage for this validator (data-parsley-check-multi-selection), remove if unused
	Parsley.addValidator('checkMultiSelection', {
		messages: {en: i18n.t('errorMessages:requiredField')},
		requirementType: 'integer',
		validate: function(_value, requirement, parsleyInstance) {
			if(parsleyInstance.$element.is(':disabled')) {
				return true;
			} else {
			  return !!parsleyInstance.$element.val();
			}
		}
	});

	Parsley.addValidator('checkTypeaheadSelection', {
		messages: {en: i18n.t('errorMessages:requiredField')},
		requirementType: 'integer',
		validate: function(_value, requirement, parsleyInstance) {
			var $checkbox = parsleyInstance.$element;
			var checkboxName = $checkbox.prop('name');
			checkboxName = checkboxName.substring(0, checkboxName.indexOf('-'));
			var $select = $('select[name='+checkboxName+']');
			if($select.is(':disabled')) {
				return true;
			} else {
			  return $select.val() && $select.val().length !== 0;
			}
		}
	});

	Parsley.addValidator('checkNotEmptyDate', {
		messages: {
			en : i18n.t('errorMessages:requiredField'),
		},
		requirementType: 'integer',
		priority: 33,
		validate: function() {
			return !!($('#textBirthDay').val() && $('#textBirthYear').val());
		}
	});

	Parsley.addValidator('checkValidDate', {
		messages: {en: i18n.t('errorMessages:invalidDob')},
		requirementType: 'integer',
		priority: 32,
		validate: function() {
			var dateValidation = util.parseAndValidateDate(
				$('#textBirthYear').val(),
				$('#selectBirthMonth').val(),
				$('#textBirthDay').val());
			return dateValidation.valid && dateValidation.date.getTime() < Date.now();
		}
	});

	Parsley.addValidator('checkDateChild', {
		messages: {en: i18n.t('errorMessages:invalidChildDob')},
		requirementType: 'integer',
		priority: 31,
		validate: function(value, requirement) {
			var dateValidation = util.parseAndValidateDate(
				$('#textBirthYear').val(),
				$('#selectBirthMonth').val(),
				$('#textBirthDay').val());
			var dateOfBirth = dateValidation.date;
			var eighteenYearsAgo = new Date();
			eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - requirement);

			var relationshipCategory = $('#selectRelationshipCategory').val();

			return dateValidation.valid &&
            dateValidation.date.getTime() < Date.now() &&
            // Recall (A implies B) is equivalent to (!A or B).
            // if child, then born after 18 years ago
            (relationshipCategory !== 'CHILD' || dateOfBirth.getTime() > eighteenYearsAgo.getTime()) &&
            // if adult, then born before 18 years ago
            (relationshipCategory !== 'ADULT' || dateOfBirth.getTime() <= eighteenYearsAgo.getTime());
		}
	});

	Parsley.addValidator('checkDateAdult', {
		messages: {en: i18n.t('errorMessages:invalidAdultDob')},
		requirementType: 'integer',
		priority: 31,
		validate: function(value, requirement) {
			var dateValidation = util.parseAndValidateDate(
				$('#textBirthYear').val(),
				$('#selectBirthMonth').val(),
				$('#textBirthDay').val());
			var dateOfBirth = dateValidation.date;
			var eighteenYearsAgo = new Date();
			eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - requirement);

			return dateValidation.valid &&
            dateValidation.date.getTime() < Date.now() &&
            dateOfBirth.getTime() <= eighteenYearsAgo.getTime();
		}
	});

	Parsley.addValidator('checkProfileDateAdult', {
		messages: {en: i18n.t('errorMessages:minAge', {age:'%s'})},
		requirementType: 'integer',
		priority: 31,
		validate: function(value, requirement) {
			var dateValidation = util.parseAndValidateDate(
				$('#textBirthYear').val(),
				$('#selectBirthMonth').val(),
				$('#textBirthDay').val());
			var dateOfBirth = dateValidation.date;
			var eighteenYearsAgo = new Date();
			eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - requirement);

			return dateValidation.valid &&
						dateValidation.date.getTime() < Date.now() &&
						dateOfBirth.getTime() <= eighteenYearsAgo.getTime();
		}
	});

	Parsley.addValidator('checkProfileDateChild', {
		messages: {en: i18n.t('errorMessages:maxAge', {age:'%s'})},
		requirementType: 'integer',
		priority: 31,
		validate: function(value, requirement) {
			var dateValidation = util.parseAndValidateDate(
				$('#textBirthYear').val(),
				$('#selectBirthMonth').val(),
				$('#textBirthDay').val());
			var dateOfBirth = dateValidation.date;
			var eighteenYearsAgo = new Date();
			eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - requirement);

			return dateValidation.valid &&
						dateValidation.date.getTime() < Date.now() &&
						dateOfBirth.getTime() > eighteenYearsAgo.getTime();
		}
	});

	Parsley.addValidator('checkWeight', {
		messages: {en: i18n.t('errorMessages:enterValidWeight')},
		requirementType: 'integer',
		priority: 31,
		validate: function() {
			var weightStr = $('#textWeight').val();
			var weight = ~~Number(weightStr);

			if(String(weight) !== weightStr || weight <= 0) {
				return false;
			} else {
			  return weight <= 1000;
			}
		}
	});

	Parsley.addValidator('checkHeight', {
		messages: {en: i18n.t('errorMessages:enterValidHeight')},
		requirementType: 'integer',
		priority: 31,
		validate: function() {
			var feetStr = $('#textFeet').val();
			var feet = ~~Number(feetStr);
			var inchesStr = $('#textInches').val();
			var inches = ~~Number(inchesStr);

			if(!feetStr && !inchesStr) {
			  return true;
			} else if(feetStr && (String(feet) !== feetStr || feet < 0)) {
				return false;
			} else if(inchesStr && (String(inches) !== inchesStr || inches < 0)) {
				return false;
			} else {
				var height = 12 * feet + inches;
				return (height>=12) && (height<120);
			}
		}
	});

	Parsley.addValidator('checkNotEmptyDueDate', {
		messages: {en: i18n.t('errorMessages:requiredField')},
		requirementType: 'integer',
		priority: 32,
		validate: function() {
			if($('#divDueDate').hasClass('hide')) {
				return true;
			}

			if($('#textDueYear').val()) {
				return true;
			} else {
				return false;
			}
		}
	});

	Parsley.addValidator('checkValidDueDate', {
		messages: {en: i18n.t('errorMessages:enterDueDate')},
		requirementType: 'integer',
		priority: 31,
		validate: function() {

			if($('#divDueDate').hasClass('hide')) {
				return true;
			}

			var year = $('#textDueYear').val();
			var month = $('#selectDueMonth').val();
			var dateValidation = util.parseAndValidateDate(year, month, 1);

			var currentDate = new Date();
			var dateFirstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).getTime();
			var date10MonthsFromNow = new Date(currentDate.getFullYear(), currentDate.getMonth() + 10, 1).getTime();
			var dueDate = dateValidation.valid && dateValidation.date.getTime();

			// the date is valid, and between the first of this month and the first of ten months from now.
			return dateValidation.valid &&
              dateFirstDayOfCurrentMonth <= dueDate &&
              dueDate <= date10MonthsFromNow;
		}
	});

	Parsley.addValidator('checkCountry', {
		messages: {en: i18n.t('errorMessages:selectCountry')},
		requirementType: 'string',
		validate: function(_value, requirement, parsleyInstance) {
			//Because of TypeIO interaction with parsley the validation is called twice (hidden text element)
			if(parsleyInstance.$element[0].className.indexOf('tt-hint')>-1){
				return true;
			}
			var selectedValue = $('#select_country_Selected').val()[0];
			return !!selectedValue;
		}
	});

	Parsley.addValidator('checkZip', {
		messages: {en: i18n.t('errorMessages:enterZip')},
		requirementType: 'string',
		priority: 31,
		validate: function(_value, requirement, parsleyInstance) {
			var selectedCountry = $('#select_country_Selected').val()[0];
			if(zipValidator.postcodeValidatorExistsForCountry(selectedCountry)){
				return zipValidator.postcodeValidator(_value, selectedCountry);
			}
			return zipValidator.postcodeValidator(_value, 'INTL');
		}
	});

	module.exports = parsleyConfig;
})();
