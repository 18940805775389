var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row fadeInRight\">\n    <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <nav class=\"text-center\">\n                <a data-history-navigate href=\"/volunteers/profile\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":68},"end":{"line":6,"column":94}}}))
    + "</a> <span class=\"fa fa-chevron-right\"\n                                                                                                         aria-hidden=\"true\"></span> <span\n                    class=\"show-for-sr\">is parent of</span>\n            </nav>\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:editProfile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":46}}}))
    + "</h1>\n        </header>\n\n        <form class=\"form-stacked margin-top\" id=\"formGenderIdentity\">\n            <fieldset>\n                <legend>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.genderIdentity.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":74}}}))
    + "\n                </legend>\n"
    + ((stack1 = container.invokePartial(require("./partials/genderIdentity.hbs"),depth0,{"name":"genderIdentity","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                <div class=\"center-button-container\">\n                    <button type=\"submit\" class=\"primary-button\"><span class=\"fa fa-floppy-o fa-margin-right\"\n                                                                       aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.save.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":97},"end":{"line":22,"column":125}}}))
    + "\n                    </button>\n                    <a data-history-navigate class=\"outline-button\" href=\"javascript:void(0);\" id=\"aCancel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.cancel.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":108},"end":{"line":24,"column":138}}}))
    + "</a>\n                </div>\n            </fieldset>\n        </form>\n    </section>\n</div>\n";
},"usePartial":true,"useData":true});