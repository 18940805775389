(function () {
	'use strict';
	var template = require('../../templates/hbs/signup.loved.one.hbs');

	var util = require('../../../../shared-scripts/app-util/util.js');
	var relationshipCascadingDropdownModule = require('../../../../shared-scripts/modules/module.relationship.cascading.dropdown.js');
	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var learnedThroughModule = require('../../../../shared-scripts/modules/module.learned.through.js');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var userAgreementModule = require('../../../../shared-scripts/modules/module.user.agreement');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');
	var csrfModule = require('../../../../shared-scripts/modules/module.csrf');
	var i18n = require('../../../../shared-scripts/app-util/i18n');
	var countries = require('../../../../shared-scripts/config/countries.json');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'typeahead:select #textCurrentConditions': 'validateTypeahead',
			'typeahead:select #textPastConditions': 'validateTypeahead',
			'click #checkboxNoCurrentConditions': 'toggleTypeahead',
			'click #checkboxNoPastConditions': 'toggleTypeahead',
			'click #checkboxShowPassword': 'togglePasswordVisibility',
			'click [name="race"]': 'validateRace',
			'keyup #textBirthDay': 'validateDateOfBirth',
			'change #selectBirthMonth': 'validateDateOfBirth',
			'keyup #textBirthYear': 'validateDateOfBirth',
			'change #selectRelationshipCategory': 'validateDateOfBirth',
			'submit #formSignupLovedOne': 'submitSignupLovedOneForm',
			'change input[name=gender]': 'genderChanged',
			'change input[name=pregnancy]': 'pregnancyChanged',
			'keyup #textDueYear': 'validateDueDate',
		},

		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
				this.relationshipsModel = options.relationshipsModel;
				this.appSettingsCollection = options.appSettingsCollection;
				this.studyId = options.studyId;
				this.constantLookupValues = new ConstantLookupValues(options.seedModel.attributes);
			}
			this.subViews = {};
		},

		submitSignupLovedOneForm: function (event) {
			event.preventDefault();
			this.openUserAgreement();
		},

		openUserAgreement: function () {

			userAgreementModule.showUserAgreement(
				{
					userAgreementType: 'VOL',
					hasLovedOnes: true,
					needsConfirm: true,
					agreeCallback: this.signupLovedOne,
					agreeCallbackParams: {view: this}
				}
			);
		},

		signupLovedOne: function (params) {
			var thisView = params.view;

			var formArray = $('#formSignupLovedOne').serializeArray();
			var newVolunteer = thisView.generateNewVolunteerLovedOne(formArray);
			thisView.setNoConditionToParent(newVolunteer);
			thisView.processDueDate(newVolunteer);

			var signupDto = {
				dbUser: newVolunteer,
				interestedStudyId: thisView.studyId,
			};

			csrfModule(function () {
				$.ajax({
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					type: 'POST',
					url: '/backend/public/volunteer/db-user',
					data: JSON.stringify(signupDto),
					success: function (data) {
						util.redirect('signup-verification?email=' + encodeURIComponent(newVolunteer.user.parentLovedOne.parent.email) + '&user-id=' + data.value);
					},
					error: function (jqxhr) {
						if (jqxhr.status === 400) {
							feedbackModule.showFeedback(thisView, 'SIGNUP_USERNAME_IN_USE');
							$('html, body').scrollTop(0);
						}
					}
				});
			});
			popupModule.closePopup($('#asideUserAgreement'));
		},

		setNoConditionToParent: function (newVolunteer) {
			var noCurrentConditionId = $('#checkboxNoCurrentConditions').val();
			var noPastConditionId = $('#checkboxNoPastConditions').val();

			newVolunteer.user.parentLovedOne.parent.profile.propertyValues.push({
				property: {name: 'currentConditions'},
				lookupValues: [{id: noCurrentConditionId}]
			});
			newVolunteer.user.parentLovedOne.parent.profile.propertyValues.push({
				property: {name: 'pastConditions'},
				lookupValues: [{id: noPastConditionId}]
			});
		},

		generateNewVolunteerLovedOne: function (formArray) {
			var newVolunteer = {
				user: {
					parentLovedOne: {
						parent: {},
						child: {},
						relationship: {}
					}
				}
			};

			//By default, the parent profile is not visible to all study team members unless he showed interest in a study/v
			newVolunteer.user.parentLovedOne.parent.profile = {visibleToStudyTeams: false};
			newVolunteer.user.parentLovedOne.parent.profile.propertyValues = [];
			newVolunteer.user.parentLovedOne.parent.profile.contactInfo = {};
			newVolunteer.user.parentLovedOne.child.profile = {};
			newVolunteer.user.parentLovedOne.child.profile.propertyValues = [];
			newVolunteer.user.parentLovedOne.child.profile.contactInfo = {};

			var lookupValuePropertyValueMap = {};

			for (var i = 0; i < formArray.length; i++) {
				var field = formArray[i];

				if(field.name === 'pregnancy') {
					continue;
				}

				if (field.value) {
					field.value = field.value.trim();
				}

				if (field.name.indexOf('-checkbox') !== -1) {
					field.name = field.name.substring(0, field.name.indexOf('-'));
				}

				if (field.name === 'relationship') {
					newVolunteer.user.parentLovedOne.relationship = field.value;
				} else if (field.name === 'password') {
					newVolunteer.password = field.value;
				} else if (field.name === 'email' || field.name === 'firstName' || field.name === 'lastName') {
					newVolunteer.user.parentLovedOne.parent[field.name] = field.value;
				} else if (field.name === 'otherLearnedFrom') {
					var otherLearnedFromPropertyValue = {};
					otherLearnedFromPropertyValue.savedValue = field.value;
					otherLearnedFromPropertyValue.property = {name: field.name};
					newVolunteer.user.parentLovedOne.parent.profile.propertyValues.push(otherLearnedFromPropertyValue);
				} else if (field.name === 'lovedOneFirstName') {
					newVolunteer.user.firstName = field.value;
				} else if (field.name === 'lovedOneLastName') {
					newVolunteer.user.lastName = field.value;
				} else if (field.name === 'zip' && field.value) {
					newVolunteer.user.parentLovedOne.child.profile.contactInfo[field.name] = field.value;
				} else if (field.name === 'country' && field.value) {
					newVolunteer.user.parentLovedOne.child.profile.contactInfo[field.name] = field.value;
				} else if (field.name === 'birthMonth') {
					var dateOfBirth = util.newDate(
						$('#textBirthYear').val(),
						field.value,
						$('#textBirthDay').val());
					dateOfBirth = util.formatDateForProperty(dateOfBirth);

					var dateOfBirthPropertyValue = {};
					dateOfBirthPropertyValue.savedValue = dateOfBirth;
					dateOfBirthPropertyValue.property = {name: 'dateOfBirth'};
					newVolunteer.user.parentLovedOne.child.profile.propertyValues.push(dateOfBirthPropertyValue);
				} else if (field.name === 'otherRace') {
					var otherRacePropertyValue = {};
					otherRacePropertyValue.savedValue = $('#textOtherRace').val();
					otherRacePropertyValue.property = {name: field.name};
					newVolunteer.user.parentLovedOne.child.profile.propertyValues.push(otherRacePropertyValue);
				} else if (field.name === 'visibleToStudyTeams') {
					newVolunteer.user.parentLovedOne.child.profile[field.name] = field.value;
				} else if (field.name === 'otherGenderIdentity') {
					var otherGenderIdentityPropertyValue = {};
					otherGenderIdentityPropertyValue.savedValue = $('#textOtherGenderIdentity').val();
					otherGenderIdentityPropertyValue.property = {name: field.name};
					newVolunteer.user.parentLovedOne.child.profile.propertyValues.push(otherGenderIdentityPropertyValue);
				} else if (field.name === 'otherGenderPronoun') {
					var otherGenderPronounPropertyValue = {};
					otherGenderPronounPropertyValue.savedValue = $('#textOtherGenderPronoun').val();
					otherGenderPronounPropertyValue.property = {name: field.name};
					newVolunteer.user.parentLovedOne.child.profile.propertyValues.push(otherGenderPronounPropertyValue);
				}else {
					if (field.value) {
						if (!lookupValuePropertyValueMap[field.name]) {
							lookupValuePropertyValueMap[field.name] = {
								property: {name: field.name},
								lookupValues: []
							};
						}
						lookupValuePropertyValueMap[field.name].lookupValues.push({id: field.value});
					}
				}
			}
			for (var lookupValuePropertyName in lookupValuePropertyValueMap) {
				if (lookupValuePropertyName === 'learnedFrom') {
					newVolunteer.user.parentLovedOne.parent.profile.propertyValues.push(lookupValuePropertyValueMap[lookupValuePropertyName]);
				} else {
					newVolunteer.user.parentLovedOne.child.profile.propertyValues.push(lookupValuePropertyValueMap[lookupValuePropertyName]);

				}
			}
			newVolunteer.user.parentLovedOne.parent.profile.language = i18n.language;
			newVolunteer.user.parentLovedOne.child.profile.language = i18n.language;
			return newVolunteer;
		},

		processDueDate: function (newVolunteer) {
			var dueDate = '';
			if (!$('#divDueDate').hasClass('hide')) {
				dueDate = util.newDate($('#textDueYear').val(), $('#selectDueMonth').val(), 1);
				dueDate = util.formatDateForProperty(dueDate);
				newVolunteer.user.parentLovedOne.child.profile.propertyValues.push({savedValue: dueDate, property: {name: 'estimatedDueDate'}});
			}
		},

		genderChanged: function (event) {
			if (event.currentTarget.id === 'radio-gender-'+this.constantLookupValues.get('GENDER', 'MALE')) {
				this.$el.find('#divPregnancy').addClass('hide');
				this.$el.find('#radioNoPregnant').prop('checked', true);
				this.$el.find('#divDueDate').addClass('hide');
				this.$el.find('#textDueYear').val('');
			} else {
				this.$el.find('#divPregnancy').removeClass('hide');
			}
		},

		pregnancyChanged: function (event) {
			if (event.currentTarget.id === 'radioYesPregnant') {
				this.$el.find('#divDueDate').removeClass('hide');
			} else {
				this.$el.find('#divDueDate').addClass('hide');
				this.$el.find('#textDueYear').val('');
			}
		},

		validateDueDate: function () {
			$('#textDueYear').parsley(parsleyConfig).validate();
		},

		validateRace: function () {
			$('#textOtherRace').parsley(parsleyConfig).validate();
		},

		validateDateOfBirth: function (event) {
			if (!event.isTrigger) {
				$('#textBirthYear').parsley(parsleyConfig).validate();
			}
		},

		toggleTypeahead: function (event) {
			var checkboxId = event.currentTarget.id;

			var $textTypeahead;

			if (checkboxId.indexOf('Current') !== -1) {
				$textTypeahead = this.$el.find('#textCurrentConditions');
			} else if (checkboxId.indexOf('Past') !== -1) {
				$textTypeahead = this.$el.find('#textPastConditions');
			}

			$textTypeahead.typeIO('toggleResultsContainerVisibility');
			$textTypeahead.typeIO('toggleDisabledResultsContainer');

			if ($textTypeahead.is(':disabled')) {
				$textTypeahead.prop('disabled', false);
			} else {
				$textTypeahead.prop('disabled', true);
			}
			this.validateTypeahead();
		},

		validateTypeahead: function () {
			this.$el.find('#checkboxNoCurrentConditions').parsley(parsleyConfig).validate();
			this.$el.find('#checkboxNoPastConditions').parsley(parsleyConfig).validate();
		},

		togglePasswordVisibility: function () {
			var $password = $('#passwordNewPassword');
			if ($password.attr('type') === 'password') {
				$password.attr('type', 'text');
			} else {
				$password.attr('type', 'password');
			}
		},

		populateRelationshipDropdown: function () {
			var dropdownContainer = this.$el.find('#fieldsetRelationship');

			relationshipCascadingDropdownModule.populateCascadingDropdown(dropdownContainer, this.relationshipsModel.attributes);
		},

		parseTemplateData: function () {
			util.transformTemplateDataForBooleanLookUpValues(this.seedModel.attributes);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER_IDENTITY);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER_PRONOUN);

			var templateData = $.extend(true, {}, this.seedModel.attributes);

			this.appSettingsCollection.each(function (applicationSetting) {
				templateData[applicationSetting.get('name')] = applicationSetting.get('value');
			});

			templateData.noConditionId = this.constantLookupValues.get('MEDICAL_CONDITION', 'NO_CONDITION');

			if (this.studyId) {
				templateData.interestedStudy = '?study-id=' + this.studyId;
			}
			templateData.organizationInfo = appConfig.organizationInfo;
			templateData.supportInfo = appConfig.supportInfo;
			templateData.marketingContent = appConfig.marketingContent;

			templateData.isLovedOne = true;
			return templateData;
		},

		setupPopup: function () {
			popupModule.initializePopup(this.$el.find('#asideNoEmailAccount'));
			popupModule.initializePopup(this.$el.find('#asideTellMeMore'));
		},

		setupTypeaheadCountry: function () {
			this.$el.find('#textCountry').typeIO({
				hint: true,
				highlight: true,
				mode: 'single-select',
				minLength: 1,
				name: 'country',
				removeText: i18n.t('multiSelectRemoveLabel'),
				resultsContainer: '#divCountry'
			},
			{
				limit: 10,
				display: 'text',
				source: countries,
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					notFound: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		setupTypeahead: function (inputId, name, resultsContainerId, sourceAttributeName, removeText) {
			this.$el.find('#' + inputId).typeIO({
				hint: true,
				highlight: true,
				resultsContainer: '#' + resultsContainerId,
				name: name,
				minLength: 1,
				removeText:removeText
			},
			{
				limit: 10,
				display: 'text',
				source: this.transformDataForTypeahead(this.seedModel.get(sourceAttributeName)),
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					empty: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		transformDataForTypeahead: function (dataArray) {
			var result = [];
			$.each(dataArray, function (index, data) {
				if (data.visible) {
					result.push({value: data.id, text: data.displayText});
				}
			});
			return result;
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},


		render: function () {
			this.$el.html(template(this.parseTemplateData()));

			this.setupTypeaheadCountry();
			this.$el.find('#textCountry').selectItem('United States of America');
			this.setupTypeahead('textCurrentConditions', 'currentConditions', 'divCurrentConditionsResults', 'MEDICAL_CONDITION',i18n.t('multiSelectRemoveLabel'));
			this.setupTypeahead('textPastConditions', 'pastConditions', 'divPastConditionsResults', 'MEDICAL_CONDITION',i18n.t('multiSelectRemoveLabel'));

			this.setupPopup();
			this.populateRelationshipDropdown();

			learnedThroughModule.setupLearnedThrough(this);

			this.setupValidationSummaryHandler();
			this.$el.find('#formSignupLovedOne').parsley(parsleyConfig);
			return this;
		}
	});
})();
