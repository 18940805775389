/**
 * The keys for the lookup value ids should be the same as LOOKUP_VALUE.NAME column in the db unless it's null,
 * otherwise validation will fail.
 */
var constants = {
	BOOLEAN: {
		TRUE: 260001,
		FALSE: 260000
	},
	GENDER: {
		MALE: 272001,
		FEMALE: 272000,
		INTERSEX: 272002,
	},
	MEDICAL_CONDITION:{
		NO_CONDITION: 285369
	},
	PARTICIPANT_TYPE: {
		HEALTHY: 395000 //Healthy participants
	},
	STUDY_LOCATION: {
		CAN_BE_DONE_FROM_HOME: 420002 //Can Be Done From Home
	}
};

function validateLookupValueTypeConstants(constantNameIdMap, lookupVal){
	if (!constantNameIdMap){
		return undefined;
	}
	var hardCodedIdByName = constantNameIdMap[lookupVal.name];
	//If hardcoded value exists with the same name and the same lookup value id as the one returned from the server mark
	//that hard coded lookup value as valid
	if(lookupVal.name && hardCodedIdByName){
		return {name: lookupVal.name, valid: hardCodedIdByName===lookupVal.id};
	}
	else if(!lookupVal.name) {
		//If lookup value returned by the server doesn't have a name then hard coded value name is not used for
		//validation. Then check if for the given type any hard coded id matches the lookup value id. If so then mark it
		//as valid.
		var constantNames = Object.keys(constantNameIdMap);
		for(var constIdx=0; constIdx<constantNames.length; constIdx++) {
			var constantName = constantNames[constIdx];
			var constantId = constantNameIdMap[constantName];
			if(constantId===lookupVal.id){
		  	  return {name: constantName, valid: true};
			}
		}
	}
}
/**
 * A primitive validation to check if the hardcoded lookup value id belongs to any lookup value for lookup values where
 * a unique name is not assigned, in other cases where unique lookup value is assigned in the database that should match
 * the constant name hardcoded for the hardcoded lookup value id.
 * @param lookupValueMapToValidate Map of types to lookup values as captured from the backend
 */
function validateConstants(lookupValueMapToValidate) {
	var validationMap ={};
	var types = Object.keys(lookupValueMapToValidate);

	for (var idx = 0; idx < types.length; idx++) {
		var type = types[idx];
		if(type==='types'){
			continue;
		}
		var lookupValues = lookupValueMapToValidate[type];

		for (var valIdx = 0; valIdx < lookupValues.length; valIdx++) {
			var lookupVal = lookupValues[valIdx];
			var constantNameIdMap = constants[type];
			var validationResult = validateLookupValueTypeConstants(constantNameIdMap, lookupVal, validationMap);
			if (validationResult) {
				if(!validationMap[type]){
					validationMap[type]={};
				}
				validationMap[type][validationResult.name]=validationResult.valid;
			}
		}
	}

	return validationMap;
}

module.exports = function (lookupValueMapToValidate, withoutValidation) {
	var validationMap ={};
	if(!withoutValidation) {
		validationMap = validateConstants(lookupValueMapToValidate,{});
	}
	this.get = function (type, name) {
		if (!constants[type]) {
			throw Error('Lookup value constants for ' + type + ' are not defined.');
		}
		if (!constants[type][name]) {
			throw Error('Lookup value id is not defined for ' + type + '.' + name);
		}
		if (!withoutValidation && !validationMap[type][name]) {
			throw Error('Id or name of the lookup value returned by the server does not match the hard coded lookup value constant for '+type+'.'+name);
		}
		return constants[type][name];
	};
};
