var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a data-history-navigate id=\"aSelectLovedOne\" href=\"/volunteers/select-loved-one\"><span class=\"fa fa-users fa-margin-right\" aria-hidden=\"true\"></span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"volunteerHeader:switchUser",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":158},"end":{"line":10,"column":195}}}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"menu-badge\"><span class=\"fa fa-spinner fa-pulse fa-fw\"></span></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"totalNewStudiesCount") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":12},"end":{"line":43,"column":19}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"menu-badge\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"totalNewStudiesCount") || (depth0 != null ? lookupProperty(depth0,"totalNewStudiesCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"totalNewStudiesCount","hash":{},"data":data,"loc":{"start":{"line":42,"column":41},"end":{"line":42,"column":65}}}) : helper)))
    + "<span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:screenReader.newStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":42,"column":91},"end":{"line":42,"column":141}}}))
    + "</span></span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"menu-badge\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"totalUnreadMessageCount") || (depth0 != null ? lookupProperty(depth0,"totalUnreadMessageCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"totalUnreadMessageCount","hash":{},"data":data,"loc":{"start":{"line":49,"column":35},"end":{"line":49,"column":62}}}) : helper)))
    + "<span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:screenReader.newMessages",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":88},"end":{"line":49,"column":139}}}))
    + "</span></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:screenReader.mainMenu",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":1,"column":24},"end":{"line":1,"column":72}}}))
    + "</h1>\n\n<div class=\"account-nav desktop-only\">\n  <nav class=\"loved-one-nav\">\n    <span class=\"logged-in-as-info\">\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:loggedInUser",{"name":"i18n","hash":{"userFullName":(depth0 != null ? lookupProperty(depth0,"userFullName") : depth0)},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":73}}}))
    + "\n    </span>\n    <a data-history-navigate id=\"aAddLovedOne\" href=\"/volunteers/add-loved-one\"><span class=\"fa fa-user-plus fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:addLovedOne",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":152},"end":{"line":8,"column":190}}}))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lovedOneExists") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "  </nav>\n\n  <nav class=\"account-settings-nav desktop-only\">\n    <a data-history-navigate id=\"aAccountSettings\" href=\"/volunteers/account-settings\"><span class=\"fa fa-cogs fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:accountSettings",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":15,"column":154},"end":{"line":15,"column":196}}}))
    + "</a>\n  </nav>\n\n  <nav class=\"logout desktop-only\">\n    <a data-history-navigate id=\"aLogout\" href=\"javascript:void(0);\">\n        <span class=\"fa fa-sign-out fa-margin-right\" aria-hidden=\"true\"></span>\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:logout",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":41}}}))
    + "\n    </a>\n  </nav>\n</div>\n\n<div class=\"main-menu-nav\">\n  <nav class=\"grid-row\">\n    <div class=\"grid-md-1-3 desktop-only\">\n      <a data-history-navigate id=\"aLogo\" href=\"/\" class=\"logo-small\">\n        <span class=\"show-for-sr\">"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1), depth0))
    + " logo</span>\n      </a>\n    </div>\n    <div class=\"grid-sm-1-3 grid-md-1-6 nav-tile\">\n      <a data-history-navigate id=\"aStudiesForYou\" href=\"/volunteers/studies-for-you/suggested-by-study-teams\">\n        <span class=\"fa fa-thumbs-up\" aria-hidden=\"true\"></span>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:studies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":10},"end":{"line":36,"column":44}}}))
    + "\n      </a>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"matchingFinished") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":44,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"grid-sm-1-3 grid-md-1-6 nav-tile\">\n      <a data-history-navigate id=\"aMessages\" href=\"/volunteers/messages\"><span class=\"fa fa-comment\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:messages",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":128},"end":{"line":47,"column":163}}}))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalUnreadMessageCount") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":6},"end":{"line":50,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"grid-sm-1-3 grid-md-1-6 nav-tile desktop-only\">\n      <a data-history-navigate id=\"aProfile\" href=\"/volunteers/profile\"><span class=\"fa fa-user\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:profile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":53,"column":123},"end":{"line":53,"column":157}}}))
    + "</a>\n    </div>\n    <div class=\"grid-sm-1-3 grid-md-1-6 nav-tile desktop-only\">\n      <a data-history-navigate id=\"aHistory\" href=\"/volunteers/history/showed-interest\"><span class=\"fa fa-history\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:history",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":56,"column":142},"end":{"line":56,"column":176}}}))
    + "</a>\n    </div>\n    <div class=\"grid-sm-1-3 grid-md-1-6 nav-tile mobile-only\">\n      <a data-history-navigate id=\"aMenuMore\"><span class=\"fa fa-bars\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:more.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":97},"end":{"line":59,"column":129}}}))
    + "</a>\n    </div>\n  </nav>\n</div>\n";
},"useData":true});