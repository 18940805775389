(function() {
	'use strict';
	var template = require('../../templates/hbs/select.loved.one.hbs');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'click li': 'switchVolunteerAccount'
		},

		switchVolunteerAccount: function (event) {
			event.preventDefault();
			var index = $(event.currentTarget).index();
			var userToSwitchTo = this.model.get('lovedOnes')[index];
			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: 'POST',
				url: '/backend/secure/volunteer/change-account',
				data: JSON.stringify({id: userToSwitchTo.userId}),
				success: function () {
					localStorage.removeItem('missingRequiredFieldsDismissed');
					localStorage.setItem('userFullName', userToSwitchTo.fullName);
					localStorage.setItem('feedbackCode', 'SWITCH_USER_SUCCESS');
					Backbone.history.navigate('/volunteers/studies-for-you/suggested-by-study-teams', {trigger: true});
				},
				error: function () {
				}
			});
		},

		parseTemplateData: function () {
			var loggedInUserId = this.model.get('loggedInUser').userId;
			var lovedOnes = this.model.get('lovedOnes');

			for (var i = 0; i < lovedOnes.length; i++) {
				var lovedOne = lovedOnes[i];
				if (lovedOne.userId === loggedInUserId) {
					lovedOnes.splice(i, 1);
					break;
				}
			}
		},

		render: function () {
			this.parseTemplateData();
			this.$el.html(template(this.model.attributes));
			return this;
		}
	}
	);
})();
