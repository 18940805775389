(function() {
	'use strict';
	var template = require('../../templates/hbs/study.card.hbs');
	var html = require('../../../../shared-scripts/app-util/html.js');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var defaultLocationSize = 3;

	module.exports = Backbone.View.extend({
		tagName: 'li',
		events: {
			'click #spanMoreLocations': 'showMoreLocations',
		},

		initialize: function (options) {
			if (options) {
				if (options.onStudiesForYouPage) {
					this.onStudiesForYouPage = options.onStudiesForYouPage;
				}
			}
		},

		parseLocation: function (showAllLocations) {
			var locations = this.model.getLocations();
			util.moveCanBeDoneFromHomeToStart(locations);
			if(!showAllLocations){
				locations = locations.slice(0,defaultLocationSize);
			}
			return locations.map(function (location) {
				return html.markAsSafe(location.displayText);
			}).join(' | ');
		},

		generateTemplateData: function (showAllLocations) {
			var templateData = {};

			templateData.studyTitle = this.model.getStudyTitle();
			templateData.studyPurpose = this.model.getStudyPurpose();
			templateData.participantTypes = this.model.getParticipantTypes();
			templateData.active = this.model.getActive();
			templateData.ages = this.model.getAges();
			templateData.onStudiesForYouPage = this.onStudiesForYouPage;
			templateData.new = this.model.get('new');
			templateData.id = this.model.get('id');
			templateData.studyNum = this.model.get('studyNum');
			templateData.locations = this.parseLocation(showAllLocations);
			templateData.showMoreLocationsLink = this.model.getLocations().length > defaultLocationSize && !showAllLocations;

			return templateData;
		},

		showMoreLocations: function (e){
			e.preventDefault();
			e.stopPropagation();
			this.render(true);
		},

		render: function (showAllLocations) {
			this.$el.html(template(this.generateTemplateData(showAllLocations)));
			this.el.id = 'li-' + this.model.get('studyNum');
			return this;
		}
	});
})();
