(function () {
	'use strict';

	var thisRouter;
	var controller = require('./controller.index.js');
	var util = require('../../../shared-scripts/app-util/util.js');
	var AppSettingsCollection = require('../../../shared-scripts/collections/collection.app.setting.js');
	var appSettingsCollection = new AppSettingsCollection();
	var appSettingsUrlParamValues = appSettingsCollection.model.urlParams.name;

	var Router = Backbone.Router.extend({
		initialize: function () {
			this.view = null;
			thisRouter = this;
		},

		routes: {
			'volunteers/studies-for-you/*action/page/*pageNum': 'studiesForYou',
			'volunteers/studies-for-you/*action': 'studiesForYou',
			'volunteers/messages?*queryString': 'conversations',
			'volunteers/messages': 'conversations',
			'volunteers/messages/for/*studyVolunteerId': 'messages',
			'volunteers/profile': 'profile',
			'volunteers/history/*action/page/*pageNum': 'history',
			'volunteers/history/*action': 'history',
			'volunteers/profile/profile-contact-info': 'profileContactInfo',
			'volunteers/profile/profile-language': 'profileLanguage',
			'volunteers/profile/profile-demographics': 'profileDemographics',
			'volunteers/profile/profile-genderIdentity': 'profileGenderIdentity',
			'volunteers/profile/profile-health-info': 'profileHealthInfo',
			'volunteers/profile/profile-study-interests': 'profileStudyInterests',
			'volunteers/profile/profile-med-conditions': 'profileMedConditions',
			'volunteers/profile/profile-medications': 'profileMedications',
			'volunteers/profile/profile-email-notifications': 'profileEmailNotifications',
			'volunteers/profile/profile-visibility': 'profileVisibility',
			'volunteers/select-loved-one': 'selectLovedOne',
			'volunteers/add-loved-one': 'addLovedOne',
			'volunteers/account-settings': 'accountSettings',
			'volunteers/change-email': 'changeEmail',
			'volunteers/change-email-verification?*queryString': 'changeEmailVerification',
			'volunteers/change-email-password?*queryString': 'changeEmailPassword',
			'volunteers/change-password': 'changePassword',
			'volunteers/deactivate-account': 'deactivateAccount',
			'volunteers/studies/*studyNumber/screening-questions': 'screeningQuestions',
			'volunteers/interest-success/*studyNumber': 'interestSuccess',
			'volunteers/interest-failure/*studyNumber': 'interestFailure',

			'signup?*queryString': 'signup',
			'signup': 'signup',
			'signup-verification?*queryString': 'signupVerification',
			'signup-loved-one?*queryString': 'signupLovedOne',
			'signup-loved-one': 'signupLovedOne',

			'activate-user?*queryString': 'activateUser',
			'studies/*studyNumber': 'studyDetails',

			'advanced-search': 'advancedSearch',
			'study-results?*searchQuery': 'studyResults',
			'study-results': 'studyResults',
			'about/*action': 'about',
			'404-attachment-not-found': 'attachmentNotFound',
			'404-page-not-found': 'pageNotFound',
			'login/volunteer?redirectUrl=*redirectUrl':'volunteerLogin',
			'login/volunteer': 'volunteerLogin',
			'login/study-team?redirectUrl=*redirectUrl':'studyTeamLogin',
			'login/study-team': 'studyTeamLogin',
			'login/forgot-password': 'forgotPassword',
			'login/reset-password?*queryString': 'resetPassword',
			'app-error*queryString': 'appError',
			'not-authorized*queryString': 'notAuthorized',
			'user-agreement': 'studyTeamUserAgreement',
			'privacy': 'privacy',
			'widget':'widget',
			'*path': 'default'
		},

		default: function (path) {
			if(path){
				var url = Backbone.history.location.pathname+Backbone.history.location.hash;
				if(url.includes('index.html#'))
				{
					Backbone.history.navigate(url.replace('index.html#',''),true);
				}else {
					util.redirect('/404-page-not-found');
				}
			}else{
				controller.handle('index', undefined);
			}
		},

		pageNotFound: function () {
			controller.handle('index', {feedbackCode: '404_PAGE_NOT_FOUND'});
		},

		attachmentNotFound: function () {
			controller.handle('index', {feedbackCode: '404_ATTACHMENT_NOT_FOUND'});
		},

		studyDetails: function (studyNumber) {
			controller.handle('study-details', {studyNumber: studyNumber});
		},

		interestSuccess: function (studyNumber) {
			controller.handle('interest-success', {studyNumber: studyNumber});
		},

		interestFailure: function (studyNumber) {
			controller.handle('interest-failure', {studyNumber: studyNumber});
		},

		screeningQuestions: function (studyNumber) {
			controller.handle('screening-questions', {studyNumber: studyNumber});
		},

		studiesForYou: function (action, pageNum) {
			switch (action) {
			case 'suggested-by-study-teams':
			case 'matched-by-system':
				controller.handle('studies-for-you', {action: action, pageNum: pageNum});
				break;
			default:
				thisRouter.default('404-page-not-found');
				break;

			}
		},

		about: function (action) {
			switch (action) {
			case 'volunteers':
			case 'study-teams':
				controller.handle('about', {action: action});
				break;
			default:
				thisRouter.default('404-page-not-found');
				break;
			}
		},

		conversations: function () {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);

			controller.handle('conversations', {pageNum: searchParams.get('page')});
		},

		messages: function (studyVolunteerId) {
			if (isNaN(studyVolunteerId)) {
				thisRouter.default('404-page-not-found');
			}
			else {
				controller.handle('messages', {studyVolunteerId: studyVolunteerId});
			}
		},

		profile: function () {
			controller.handle('profile');
		},

		accountSettings: function () {
			controller.handle('account-settings');
		},

		history: function (action, pageNum) {
			switch (action) {
			case 'showed-interest':
			case 'dismissed':
				controller.handle('history', {action: action, pageNum: pageNum});
				break;
			default:
				thisRouter.default('404-page-not-found');
				break;

			}
		},

		signup: function () {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);
			controller.handle('signup', {studyId: searchParams.get('study-id')});
		},

		signupLovedOne: function () {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);
			controller.handle('signup-loved-one', {studyId: searchParams.get('study-id')});
		},


		signupVerification: function () {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);
			controller.handle('signup-verification', {email: searchParams.get('email'), userId: searchParams.get('user-id')});
		},

		activateUser: function () {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);

			var userId = searchParams.get('user-id');
			var token = searchParams.get('token');
			var studyId = searchParams.get('study-id');

			controller.handle('activate-user', {userId: userId, token: token, studyId: studyId});
		},

		changeEmail: function () {
			controller.handle('change-email');
		},

		changeEmailVerification: function () {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);
			controller.handle('change-email-verification', {newEmail: searchParams.get('new-email'), userId: searchParams.get('user-id')});
		},

		changeEmailPassword: function () {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);

			controller.handle('change-email-password', {userId: searchParams.get('user-id'), token: searchParams.get('token'), newEmail: searchParams.get('new-email')});
		},

		changePassword: function () {
			controller.handle('change-password');
		},

		deactivateAccount: function () {
			controller.handle('deactivate-account');
		},

		selectLovedOne: function () {
			controller.handle('select-loved-one');
		},

		addLovedOne: function () {
			controller.handle('add-loved-one');
		},

		profileContactInfo: function () {
			controller.handle('profile-contact-info');
		},

		profileLanguage: function () {
			controller.handle('profile-language');
		},

		profileDemographics: function () {
			controller.handle('profile-demographics');
		},

		profileGenderIdentity: function () {
			controller.handle('profile-genderIdentity');
		},

		profileHealthInfo: function () {
			controller.handle('profile-health-info');
		},

		profileEmailNotifications: function () {
			controller.handle('profile-email-notifications');
		},

		profileVisibility: function () {
			controller.handle('profile-visibility');
		},

		profileStudyInterests: function () {
			controller.handle('profile-study-interests');
		},

		profileMedConditions: function () {
			controller.handle('profile-med-conditions');
		},

		profileMedications: function () {
			controller.handle('profile-medications');
		},

		advancedSearch: function () {
			controller.handle('advanced-search');
		},

		studyResults: function (searchQuery) {
			controller.handle('study-results', {searchQuery: searchQuery});
		},
		volunteerLogin: function(redirectUrl) {
			var View = require('./views/view.volunteer.login.js');
			thisRouter.loadView(View, {'redirectUrl':redirectUrl});
			renderLoginFooterView();
		},

		studyTeamLogin: function(redirectUrl) {
			appSettingsCollection.fetch(
				{
					success: function(collection) {
						var View = require('./views/view.study.team.login.js');
						thisRouter.loadView(View, {
							redirectUrl: redirectUrl,
							'collection': collection,
						});
					}
				},[appSettingsUrlParamValues.applicationUrl]
			);
			renderLoginFooterView();
		},

		forgotPassword: function() {
			var View = require('./views/view.forgot.password.js');
			thisRouter.loadView(View);
			renderLoginFooterView();
		},

		resetPassword: function() {
			var queryString = Backbone.history.getFragment().split('?')[1];
			var searchParams = new URLSearchParams(queryString);

			appSettingsCollection.fetch(
				{
					success: function(collection) {
						var View = require('./views/view.reset.password.js');
						thisRouter.loadView(View, {'userId':searchParams.get('user-id'), 'token':searchParams.get('token'), 'collection': collection});
					}
				}, [appSettingsUrlParamValues.passwordRegex, appSettingsUrlParamValues.passwordRegexDescription]
			);
			renderLoginFooterView();
		},

		appError: function (queryString) {
			controller.handle('appError', queryString);
		},
		notAuthorized: function (queryString) {
			controller.handle('notAuthorized', queryString);
		},
		studyTeamUserAgreement: function () {
			controller.handle('studyTeamUserAgreement');
		},
		privacy: function () {
			controller.handle('privacy');
		},
		widget: function () {
			controller.handle('widget');
		},
	}
	);
	function renderLoginFooterView() {
		$('footer').attr('class','stripped-footer');
	}
	module.exports = Router;
})();
