(function () {
	'use strict';
	var template = require('../../templates/hbs/conversation.card.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');

	var View = Backbone.View.extend({

		tagName: 'li',
		className: 'wow',

		convertLastReceivedDate: function() {
			var lastReceivedDate = this.model.get('lastReceivedDate');
			var dateParts = util.formatDateHumanRelative(lastReceivedDate);

			this.model.set('lastReceivedDateString', dateParts[0]);
			if (dateParts.length === 2) {
				this.model.set('time', dateParts[1]);
			}
		},

		convertStudyTeamMemberNames: function() {
			var studyTeamMemberFullNamesString = 'No specific study team member is available';
			var StudyTeamMemberNames = this.model.get('studyTeamMemberNames');
			var studyTeamMemberFullNames = StudyTeamMemberNames.filter(function(stmName) {
				return stmName.firstName && stmName.lastName;
			}).map(function(stmName) {
				return stmName.firstName + ' ' + stmName.lastName;
			});

			if (studyTeamMemberFullNames.length) {
				studyTeamMemberFullNamesString = studyTeamMemberFullNames.join(', ');
			}
			this.model.set('studyTeamMemberFullNamesString', studyTeamMemberFullNamesString);
		},

		render: function () {
			this.convertLastReceivedDate();
			this.convertStudyTeamMemberNames();
			this.$el.html(template(this.model.attributes));
			return this;
		}
	});
	module.exports = View;
})();
