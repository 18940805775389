(function() {
	'use strict';
	var Backbone = require('backbone');
	var AppSettingCollection = Backbone.Collection.extend({
		url:'/backend/application-settings',

		model: require('../models/model.app.setting.js'),

		fetch: function( options, queryParamValues ){
			this.url = '/backend/application-settings';

			if(queryParamValues && queryParamValues.length>0) {

				var queryParamString = '?';
				_.map(queryParamValues, function (urlParamValue) {
					queryParamString += 'name=' + urlParamValue + '&';
				});

				this.url = this.url + queryParamString.slice(0, -1);
			}

			return Backbone.Collection.prototype.fetch.call(this, options);
		}
	});

	module.exports = AppSettingCollection;
})();
