(function() {

	'use strict';

	require('jquery-cascading-dropdown');
	var i18n = require('../../shared-scripts/app-util/i18n.js');

	var RelationshipCascadingDropdownModule = function () {

		this.populateCascadingDropdown = function(dropdownContainer, dropdownData) {
			var dropdownIds = getDropdownIds();
			dropdownContainer.cascadingDropdown({
				selectBoxes: [
					{
						selector: '#' + dropdownIds[0],
						source: function(request, response) {
							response(getRelationshipCategoriesForDropDown(dropdownData));
						},
						onChange: function(event, value) {
							if(value === 'CHILD') {
								$('.asideTellMeMore_open').hide();
							} else {
								$('.asideTellMeMore_open').show();
							}
						}
					},
					{
						selector: '#' + dropdownIds[1],
						requires: ['#' + dropdownIds[0]],
						source: function(request, response) {
							response(getRelationshipsForDropDown(dropdownData, '#' + dropdownIds[0]));
						}
					}
				]
			});
		};

		var getDropdownIds = function() {
			var dropdownIds = [];

			var cascadingDropdowns = $('.cascading-dropdown');

			for(var i=0; i<cascadingDropdowns.length;i++) {
				dropdownIds.push(cascadingDropdowns[i].id);
			}

			return dropdownIds;

		};

		var getRelationshipCategoriesForDropDown = function(dropdownData) {
			var relationshipCategoriesForDropDown = [];

			for(var key in dropdownData) {
				relationshipCategoriesForDropDown.push({label: i18n.t('signUp:form.field.signingUpFor.'+key.toLowerCase()), value: key});
			}

			return relationshipCategoriesForDropDown;
		};

		var getRelationshipsForDropDown = function(dropdownData, relationshipCategoryId) {
			var relationshipsForDropDown = [];

			var relationships = dropdownData[$(relationshipCategoryId).val()];

			for(var i = 0; i<relationships.length; i++) {
				var relationship = relationships[i];
				relationshipsForDropDown.push({label: relationship.display, value :relationship.value});
			}

			return relationshipsForDropDown;
		};
	};

	module.exports = new RelationshipCascadingDropdownModule();
})();