(function () {
	'use strict';
	var MenuView = require('../../apps/index/scripts/views/view.menu.js');
	var AnnouncementView = require('../../apps/index/scripts/views/view.announcement');
	var FooterView = require('../../shared-scripts/views/view.footer');
	var View = Backbone.View.extend({
		el: 'main',

		initialize: function (options) {
			if (options) {
				this.initViews(options.mainView, options.authDetails, options.announcements);
			} else {
				throw new Error('No options found in layout view');
			}
		},
		renderMainView: function(){
			this.subViews.mainView.render();
			return this;
		},
		renderMenuView: function(){
			this.subViews.menuView.render();
			return this;
		},
		renderAnnouncementView: function () {
			this.subViews.announcementView.render();
			return this;
		},
		renderFooterView: function() {
			this.subViews.footerView.render();
			return this;
		},

		render: function () {
			this.renderMenuView();
			this.renderFooterView();
			this.renderAnnouncementView();
			this.renderMainView();
			return this;
		},

		initViews: function(mainView, authDetails, announcements) {
			var menuView = new MenuView({model: authDetails});
			var announcementView = new AnnouncementView({model: announcements});
			var footerView = new FooterView();
			this.subViews = {
				mainView: mainView,
				menuView: menuView,
				announcementView: announcementView,
				footerView: footerView
			};
		}
	}
	);
	module.exports = View;
})();
