var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"appError:teamNotified.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":50}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./stripped.header.hbs"),depth0,{"name":"stripped.header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div class=grid-row>\n  <section class=\"grid-sm-1  grid-lg-centered\">\n    <div class=\"feedback-block error\">\n      <div class=\"feedback-block-media\">\n        <span class=\"fa pe-7s-close-circle\" id=\"spnFeedbackBlockMediaIcon\"></span>\n      </div>\n      <div class=\"feedback-block-body\">\n        <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"appError:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":53}}}))
    + "</h1>\n        <p>\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"appError:sorry.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":43}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorId") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":13,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n      </div>\n    </div>\n  </section>\n</div>\n<div class=\"grid-row\">\n  <section class=\"grid-1 text-center\">\n    <h4>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"appError:meanTime.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":42}}}))
    + "</h4>\n  </section>\n</div>\n<div class=grid-row>\n  <section class=\"grid-sm-1 grid-md-5-12 grid-centered\">\n    <nav>\n      <div>\n        <a data-history-navigate href=\"/\"><span class=\"fa fa-home fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"appError:goBack.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":109},"end":{"line":28,"column":145}}}))
    + "</a>\n      </div>\n      <div>\n        <a href=\"mailto:"
    + alias3(((helper = (helper = lookupProperty(helpers,"supportEmail") || (depth0 != null ? lookupProperty(depth0,"supportEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"supportEmail","hash":{},"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":31,"column":40}}}) : helper)))
    + "?subject="
    + alias3(((helper = (helper = lookupProperty(helpers,"errorNotificationEmailSubject") || (depth0 != null ? lookupProperty(depth0,"errorNotificationEmailSubject") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"errorNotificationEmailSubject","hash":{},"data":data,"loc":{"start":{"line":31,"column":49},"end":{"line":31,"column":82}}}) : helper)))
    + "\n        &body="
    + alias3(((helper = (helper = lookupProperty(helpers,"errorNotificationEmailBody") || (depth0 != null ? lookupProperty(depth0,"errorNotificationEmailBody") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"errorNotificationEmailBody","hash":{},"data":data,"loc":{"start":{"line":32,"column":14},"end":{"line":32,"column":44}}}) : helper)))
    + "\n        %0A%0AError%20Id:%20"
    + alias3(((helper = (helper = lookupProperty(helpers,"errorId") || (depth0 != null ? lookupProperty(depth0,"errorId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"errorId","hash":{},"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":33,"column":39}}}) : helper)))
    + "\"><span class=\"fa fa-envelope fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"appError:emailUs.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":112},"end":{"line":33,"column":149}}}))
    + "</a>\n      </div>\n      <div>\n        <span class=\"fa fa-phone fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"appError:callUS.label",{"name":"i18n","hash":{"supportPhone":(depth0 != null ? lookupProperty(depth0,"supportPhone") : depth0)},"data":data,"loc":{"start":{"line":36,"column":76},"end":{"line":36,"column":134}}}))
    + "\n      </div>\n    </nav>\n  </section>\n</div>";
},"usePartial":true,"useData":true});