var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row fadeInRight\">\n  <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n      <div id=\"divFeedback\"></div>\n      <header class=\"page-header\">\n        <nav class=\"text-center\">\n          <a data-history-navigate id=\"aAccountSettings\" href=\"/volunteers/profile\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":84},"end":{"line":6,"column":110}}}))
    + "</a> <span class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">is parent of</span>\n        </nav>\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:editProfile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":42}}}))
    + "</h1>\n    </header>\n\n      <form class=\"form-stacked margin-top\" id=\"formVisibility\">\n        <fieldset class=\"special-fieldset\">\n              <legend>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.profileVisibility.visibility.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":22},"end":{"line":13,"column":88}}}))
    + "</legend>\n              <div class=\"grid-row\">\n                  <div class=\"grid-sm-1 grid-md-1-2 field-container\">\n                      <label for=\"radioProfileVisibilityAll\" class=\"other-label\">\n                          <input type=\"radio\" id=\"radioProfileVisibilityAll\" name=\"visibleToStudyTeams\" value=\"true\">\n                          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.profileVisibility.visibility.edit.option.yes",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":18,"column":26},"end":{"line":18,"column":151}}}))
    + "\n                      </label>\n                      <div class=\"field-help-text\">\n                          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.profileVisibility.visible.selfDesc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":21,"column":26},"end":{"line":21,"column":89}}}))
    + "\n                      </div>\n                  </div>\n                  <div class=\"grid-sm-1 grid-md-1-2 field-container\">\n\n                      <label for=\"radioProfileVisibilitySpecificStudies\" class=\"other-label\">\n                          <input type=\"radio\" id=\"radioProfileVisibilitySpecificStudies\" name=\"visibleToStudyTeams\" value=\"false\">\n                          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.profileVisibility.visibility.edit.option.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":26},"end":{"line":28,"column":101}}}))
    + "\n                      </label>\n                  </div>\n              </div>\n          </fieldset>\n        <div class=\"center-button-container\">\n          <button type=\"submit\" class=\"primary-button\"><span class=\"fa fa-floppy-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.save.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":126},"end":{"line":34,"column":154}}}))
    + "</button>\n          <a data-history-navigate class=\"outline-button\" href=\"javascript:void(0);\" id=\"aCancel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.cancel.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":98},"end":{"line":35,"column":128}}}))
    + "</a>\n				</div>\n\n    </form>\n  </section>\n</div>\n";
},"useData":true});