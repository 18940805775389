var Handlebars = require('handlebars/runtime');

//webpack handlebars-loader compiles and registers all partials, however they are not available to code using Handlebars.partials,
//thus they have t be manually registered to make them programmatically available to build handlebars helpers
var partialsContext = require.context('../../apps/index/templates/hbs/partials', true, /\.hbs$/);//partials = require('!!handlebars-loader!partials');
partialsContext.keys().forEach(function (key) {
	var partialName = key.replace('./', '').replace('.hbs', '');
	var partialContent = partialsContext(key);
	Handlebars.registerPartial(partialName, partialContent);
});

module.exports = {
	registeri18n: function (translate) {
		if (!('i18n' in Handlebars.helpers)) {
			Handlebars.registerHelper('i18n', function (key, options) {
				return new Handlebars.SafeString(translate(key, options.hash));
			});
		}
	},

	registeri18nWithPartial: function (translate) {
		if (!('i18nWithPartial' in Handlebars.helpers)) {
			//If you are using this helper then your i18n template should have a placeholder {{partial}} where the partial will be inserted
			// usage example {{{i18nWithPartial 'errorMessages:missingRequiredFields' partialName='missingFields.list' partialData=missingFields}}}
			Handlebars.registerHelper('i18nWithPartial', function (key, options) {
				var partial = Handlebars.partials[options.hash.partialName];
				if (partial) {
					var html = new Handlebars.SafeString(partial(options.hash.partialData));
					return new Handlebars.SafeString(translate(key, {partial: html}));
				}
				return '';
			});
		}
	}
};
