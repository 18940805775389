(function () {
	'use strict';
	var template = require('../../templates/hbs/conversations.hbs');
	var ConversationCardList = require('./view.conversation.card.list.js');
	var ConversationCollection = require('../collections/collection.conversation');
	var util = require('../../../../shared-scripts/app-util/util');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aPrev': 'goToPrevPage',
			'click #aNext': 'goToNextPage',
			'change #selectPage': 'jumpToPage'
		},

		initialize: function (options) {
			this.subViews = {};

			var conversationCollection = new ConversationCollection(this.model.get('page'));
			this.subViews.messageCardListView = new ConversationCardList({model: conversationCollection});
		},

		goToNextPage: function(event) {
			event.preventDefault();

			if(this.isLastPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') + 1;
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/messages?page=' + this.model.get('pageNum'));
		},

		isLastPage: function() {
			return Number(this.model.get('pageNum')) === Math.ceil(this.model.get('totalCount')/this.model.get('pageSize'));
		},

		goToPrevPage: function(event) {
			event.preventDefault();

			if(this.isFirstPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') - 1;
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/messages?page=' + this.model.get('pageNum'));
		},

		isFirstPage: function() {
			return (Number(this.model.get('pageNum')) === 1);
		},

		jumpToPage: function() {
			var newPageNum = $('#selectPage').val();
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/messages?page=' + this.model.get('pageNum'));
		},

		generateTemplateData: function() {
			var templateData = {};
			templateData.totalConversationCount = this.model.get('totalCount');
			templateData.startConversationCount = (this.model.get('pageNum')-1)*this.model.get('pageSize') + 1;
			templateData.endConversationCount = templateData.startConversationCount + this.model.get('pageSize') - 1;

			if(templateData.endConversationCount > this.model.get('totalCount')) {
				templateData.endConversationCount = this.model.get('totalCount');
			}

			templateData.pageNumbers = [];
			for(var i=1; i<=Math.ceil(this.model.get('totalCount')/this.model.get('pageSize')); i++) {
				templateData.pageNumbers.push({pageNumber: i});
			}
			return templateData;
		},
		render: function () {
			this.closeSubViews();
			this.$el.html(template(this.generateTemplateData()));
			this.renderSubviewAppend(this.$el.find('#divMessageCardList'), 'messageCardList', this.subViews.messageCardListView);

			if(this.isFirstPage()) {
				this.$el.find('#aPrev').attr('disabled', 'disabled');
			}
			if(this.isLastPage()) {
				this.$el.find('#aNext').attr('disabled', 'disabled');
			}
			this.$el.find('#selectPage').val(this.model.get('pageNum'));

			$(window).scrollTop(0);
			return this;
		}
	});
	module.exports = View;
})();
