var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./stripped.header.hbs"),depth0,{"name":"stripped.header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"grid-row login-content\" id=\"sectionForgotPassword\">\n    <div class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n        <header>\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"forgotPassword:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":49}}}))
    + "</h1>\n            <p class=\"secondary-font-color text-center\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"forgotPassword:desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":46}}}))
    + "\n            </p>\n        </header>\n        <div id=\"divFeedback\"></div>\n        <form class=\"form-stacked\" id=\"formForgotPassword\">\n            <div class=\"field-container email validation-error-container\">\n                <label id=\"labelEmail\" for=\"emailUsername\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"forgotPassword:field.username.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":13,"column":59},"end":{"line":13,"column":105}}}))
    + "</label>\n                <input class=\"input-1\" id=\"emailUsername\" type=\"email\" name=\"userName\" data-parsley-type=\"email\" required data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:invalidEmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":150},"end":{"line":14,"column":187}}}))
    + "\">\n            </div>\n            <button type=\"submit\" class=\"primary-button\" id=\"btnPasswordResetLink\" value=\"EMAIL RESET LINK\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"forgotPassword:action.emailResetLink.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":108},"end":{"line":16,"column":161}}}))
    + "\n            </button>\n        </form>\n    </div>\n</section>\n";
},"usePartial":true,"useData":true});