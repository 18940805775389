(function() {
	'use strict';

	var template = require('../../templates/hbs/reset.password.hbs');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var csrfModule = require('../../../../shared-scripts/modules/module.csrf');

	require('parsleyjs');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'submit #formResetPassword': 'resetPassword',
			'click #checkboxShowPassword' : 'togglePasswordVisibility'
		},

		initialize: function(options) {
			if (options) {
				this.userId = options.userId;
				this.token = options.token;
				this.collection = options.collection;
			}

			this.subViews = {};
		},

		resetPassword: function(event) {
			var thisView = this;
			event.preventDefault();

			var patchRequestBody = {
				'op': 'replace',
				'path': '/password',
				'value': $('#passwordPassword').val()
			};

			csrfModule(function () {
				$.ajax({
					type: 'PATCH',
					headers: {
						'Accept': 'application/json, text/plain, */*',
						'Content-Type': 'application/json;charset=UTF-8'
					},
					dataType: 'json',
					url: '/backend/public/user-account/' + this.userId + '?token=' + this.token,
					data: JSON.stringify([patchRequestBody]),
					success: function () {
						localStorage.setItem('feedbackCode', 'RESET_PASSWORD_SUCCESS');
						util.redirect('/volunteers/studies-for-you/suggested-by-study-teams');
					},
					error: function (jqxhr) {
						if(jqxhr.status === 400 && jqxhr.responseJSON && jqxhr.responseJSON.code){
							feedbackModule.showFeedback(thisView, jqxhr.responseJSON.code, jqxhr.responseJSON.parameters);
						}
					}
				});
			}.bind(this));
		},

		togglePasswordVisibility: function() {
			var $password = $('#passwordPassword');
			if ($password.attr('type') === 'password') {
				$password.attr('type', 'text');
			} else {
				$password.attr('type', 'password');
			}
		},

		parseTemplateData: function() {
			var templateData = {};

			this.collection.each(function(applicationSetting) {
				templateData[applicationSetting.get('name')] = applicationSetting.get('value');
			});

			return templateData;
		},

		render: function() {
			this.$el.html(template(this.parseTemplateData()));
			this.$el.find('#formResetPassword').parsley(parsleyConfig);
			return this;
		}
	}
	);
	module.exports = View;
})();
