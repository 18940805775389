
(function() {
	'use strict';

	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var template = require('../../templates/hbs/profile.med.conditions.hbs');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var volunteerProfileModule = require('../modules/module.volunteer.profile.js');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	module.exports = Backbone.View.extend({
		el: 'main',

		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
				this.constantLookupValues = new ConstantLookupValues(options.seedModel.attributes);
			}
			this.subViews = {};
		},

		events: {
			'typeahead:select #textCurrentConditions': 'validateConditions',
			'typeahead:select #textPastConditions': 'validateConditions',
			'click #checkboxNoCurrentConditions': 'toggleConditions',
			'click #checkboxNoPastConditions': 'toggleConditions',
			'click #aClearMyAnswer': 'clearMyAnswer',
			'submit form': 'saveSection',
			'click #aCancel': 'cancel'
		},

		cancel: function () {
			localStorage.setItem('feedback-card-cancel-container', 'medical-conditions-card-feedback');
			util.redirect('/volunteers/profile');
		},

		saveSection: function (event) {
			event.preventDefault();
			var requestData = volunteerProfileModule.getPropertyValues('#formMedicalConditions');
			this.parseEmptyValues(requestData);
			var userId = this.model.get('userId');
			volunteerProfileModule.sendPropertyValuesRequest(userId, requestData, 'medical-conditions-card-feedback');
		},

		parseEmptyValues: function (requestData) {
			if (!$('input[name=\'hasMetalImplants\']:checked').val()) {
				requestData.push({property: {name: 'hasMetalImplants'}, lookupValues: []});
			}
		},

		populateForm: function () {
			var medicalConditions = this.model.get('medicalConditions');
			var initialCurrentConditions = [];
			var initialPastConditions = [];

			for (var i = 0; i < medicalConditions.length; i++) {
				var medicalCondition = medicalConditions[i];

				switch (medicalCondition.property.name) {
				case 'currentConditions':
					initialCurrentConditions = medicalCondition.lookupValues;
					break;
				case 'pastConditions':
					initialPastConditions = medicalCondition.lookupValues;
					break;
				case 'hasMetalImplants':
					if (medicalCondition.lookupValues.length) {
						this.$el.find('input[type=radio][value=' + medicalCondition.lookupValues[0].id + ']').prop('checked', 'checked');
					}
					break;
				}
			}

			this.setupTypeahead('textCurrentConditions', initialCurrentConditions, 'currentConditions', 'divCurrentConditionsResults', 'MEDICAL_CONDITION',i18n.t('multiSelectRemoveLabel'));
			if (initialCurrentConditions && initialCurrentConditions.length > 0 && initialCurrentConditions[0].id === this.constantLookupValues.get('MEDICAL_CONDITION', 'NO_CONDITION')) {
				this.$el.find('#checkboxNoCurrentConditions').trigger('click');
			}
			this.setupTypeahead('textPastConditions', initialPastConditions, 'pastConditions', 'divPastConditionsResults', 'MEDICAL_CONDITION', i18n.t('multiSelectRemoveLabel'));
			if (initialPastConditions && initialPastConditions.length > 0 && initialPastConditions[0].id === this.constantLookupValues.get('MEDICAL_CONDITION', 'NO_CONDITION')) {
				this.$el.find('#checkboxNoPastConditions').trigger('click');
			}
		},

		toggleConditions: function (event) {
			var checkboxId = event.currentTarget.id;

			var $textConditions;

			if (checkboxId.indexOf('Current') !== -1) {
				$textConditions = this.$el.find('#textCurrentConditions');
			} else {
				$textConditions = this.$el.find('#textPastConditions');
			}

			$textConditions.typeIO('toggleResultsContainerVisibility');
			$textConditions.typeIO('toggleDisabledResultsContainer');

			if ($textConditions.is(':disabled')) {
				$textConditions.prop('disabled', false);
			} else {
				$textConditions.prop('disabled', true);
			}
			this.validateConditions();
		},

		validateConditions: function () {
			this.$el.find('#checkboxNoCurrentConditions').parsley(parsleyConfig).validate();
			this.$el.find('#checkboxNoPastConditions').parsley(parsleyConfig).validate();
		},

		clearMyAnswer: function () {
			this.$el.find('input[type=radio]').prop('checked', false);
		},

		setupTypeahead: function (inputId, initialConditions, name, resultsContainerId, sourceAttributeName, removeText) {
			this.$el.find('#' + inputId).typeIO({
				hint: true,
				highlight: true,
				resultsContainer: '#' + resultsContainerId,
				initialResults: this.transformDataForTypeahead(initialConditions),
				name: name,
				minLength: 1,
				removeText: removeText
			},
			{
				limit: 10,
				display: 'text',
				source: this.transformDataForTypeahead(this.seedModel.get(sourceAttributeName)),
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					empty: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		transformDataForTypeahead: function (dataArray) {
			var result = [];
			$.each(dataArray, function (index, data) {
				if (data.visible) {
					result.push({value: data.id, text: data.displayText});
				}
			});
			return result;
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		generateTemplateData: function () {
			util.transformTemplateDataForBooleanLookUpValues(this.seedModel.attributes);
			var templateData = this.seedModel.attributes;
			templateData.noConditionId = this.constantLookupValues.get('MEDICAL_CONDITION', 'NO_CONDITION');

			return templateData;
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));
			this.populateForm();
			this.setupValidationSummaryHandler();
			return this;
		}
	});
})();
