require('popper.js');
var csrfModule = require('../../../shared-scripts/modules/module.csrf');
var ajaxSetup = require('../../../shared-scripts/app-util/ajax.setup.js');
var cookieConsentModule = require('~institution-specific-config/modules/module.cookie.consent');
var i18n = require('../../../shared-scripts/app-util/i18n');
var popupModule = require('../../../shared-scripts/modules/module.popup');
var userAgreementModule = require('../../../shared-scripts/modules/module.user.agreement');
var alertMissingProfileFieldsPopupModule = require('../../../shared-scripts/modules/module.alert.missing.profile.fields.popup');
var HandlebarsHelpers = require('../../../shared-scripts/modules/module.i18n.handlebars.helpers');

var loadApp = function () {
	'use strict';
	require('~theme/styles/styles.scss');
	require('../../../assets/common-assets/styles/print.css');
	require('../../../shared-scripts/app-util/backbone.setup.js');
	require('url-search-params-polyfill');

	require('jquery-popup-overlay');

	require('bootstrap/js/dist/collapse.js');

	require('../../../../main/shared-scripts/vendors/stickEmUp.js');
	require('bootstrap-maxlength');

	require('typeio');

	if (window) {
		window.Cleave = require('../../../../node_modules/cleave.js/dist/cleave.min.js');
		window.linkifyHtml = require('linkify-html').default;
	}

	var util = require('../../../shared-scripts/app-util/util.js');
	var androidFocusOnScroll = require('../../../shared-scripts/modules/module.focus.input.android.js');
	var Router = require('./router.index.js');
	new Router();

	if (util.isAndroid()) {
		androidFocusOnScroll.setupScroll();
	}

	popupModule.initializePopup($('#asideUserAgreement'), {onclose: userAgreementModule.onUserAgreementClosed});
	popupModule.initializePopup($('#divAlert'), {onclose: alertMissingProfileFieldsPopupModule.onClosed});
	cookieConsentModule.loadCookieConsent();
	$(document).on('click', 'a[data-history-navigate]', function (evt) {
		var href = {prop: $(this).prop('href'), attr: $(this).attr('href')};
		var root = location.protocol + '//' + location.host + Backbone.history.options.root;

		if (href.prop && href.prop.slice(0, root.length) === root) {
			evt.preventDefault();
			Backbone.history.navigate(href.attr, true);
		}
	});
	Backbone.history.start({pushState: true});
};

i18n.initPromise.then(function (translate) {
	HandlebarsHelpers.registeri18n(translate);
	HandlebarsHelpers.registeri18nWithPartial(translate);
	ajaxSetup('volunteer');
	csrfModule(loadApp);
});

