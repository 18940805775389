(function() {
	'use strict';
	var UserRoleModule = function () {
		this.getUserRole = function (loggedInUser) {
			if (loggedInUser && _.isArray(loggedInUser.roles)) {
				var roles = loggedInUser.roles;
				if (roles.indexOf('VOLUNTEER', 0) !== -1) {
					return 'VOL';
				} else if (roles.indexOf('STAFF', 0) !== -1
						|| roles.indexOf('ADMIN', 0) !== -1) {
					return 'STM';
				}
			}
		};
	};

	module.exports = new UserRoleModule();
})();
