(function() {
	module.exports = function () {
		var authHelper = require('../app-util/auth.helper.js');
		var util = require('../app-util/util.js');
		var LayoutView = require('../views/view.layout.js');
		var _ = require('underscore');
		var popupModule = require('../modules/module.popup');

		var AuthDetailsModel = require('../models/model.auth.details.js');
		var AnnouncementCollection = require('../../apps/index/scripts/collections/collection.announcement');
		var feedbackModule = require('../modules/module.feedback.js');
		var i18next = require('../app-util/i18n');

		var thisBaseController = this;

		this.handle = function (route, options) {
			if (route !== 'study-details' && route !== 'study-results') {
				localStorage.removeItem('scrollToStudy');
			}
			var authDetailsModel = new AuthDetailsModel();
			var announcementCollection = new AnnouncementCollection();
			authDetailsModel.fetch({
				success: function (authDetailsModel) {
					announcementCollection.fetch({
						success: function (announcements) {
							util.setGlobalVar('authDetails', authDetailsModel.attributes);
							util.setGlobalVar('announcements', announcements);
							$(window).scrollTop(0);
							thisBaseController.handlers[route].call(thisBaseController, options);
						}
					});
				}
			});

		};

		var handleAuth = function (securityLevel, authDetails) {
			var loggedInUser = authDetails.loggedInUser;
			var roleMatcher = function (role) {
				return role.toLowerCase() === securityLevel.toLowerCase();
			};

			if (securityLevel) {
				if (securityLevel !== 'public') {
					if (!loggedInUser) {
						authHelper.notAuthenticatedHandler(securityLevel);
					} else if (!_.some(loggedInUser.roles, roleMatcher)) {
						authHelper.notAuthorizedHandler();
					}
				}
			} else {
				alert('You must provide security level for main view!');
			}
		};

		var renderLayoutView = function (layoutViewConstructorArguments) {
			var layoutView = new LayoutView(layoutViewConstructorArguments);
			var authDetails = layoutViewConstructorArguments.authDetails;

			if (thisBaseController.view && thisBaseController.view.subViews.menuView
				&& thisBaseController.view.subViews.mainView && thisBaseController.view.subViews.announcementView) {
				var renderedMenuModel = thisBaseController.view.subViews.menuView.model;
				if (_.isEqual(renderedMenuModel.attributes, authDetails)) {
					thisBaseController.destroyMainView();
					thisBaseController.view = layoutView.renderMainView();
				} else {
					thisBaseController.destroyAllViews();
					thisBaseController.view = layoutView.render();
				}
			} else {
				thisBaseController.view = layoutView.render();
			}

			if (layoutViewConstructorArguments.feedback) {
				feedbackModule.showFeedback(layoutViewConstructorArguments.mainView, layoutViewConstructorArguments.feedback);
			}
			thisBaseController.view.subViews.menuView.setActiveClass();
		};

		this.loadView = function (layoutViewConstructorArguments) {
			var securityLevel = layoutViewConstructorArguments.securityLevel.toLowerCase();
			var authDetails = util.getGlobalVar('authDetails');
			handleAuth(securityLevel, authDetails);

			layoutViewConstructorArguments.authDetails = authDetails;
			layoutViewConstructorArguments.announcements = util.getGlobalVar('announcements');

			var preferredLanguage = layoutViewConstructorArguments.language;
			if (preferredLanguage) {
				i18next.changeLanguage(preferredLanguage).then(function () {
					$.ajaxSetup({headers: {'Accept-Language': preferredLanguage}});
					renderLayoutView(layoutViewConstructorArguments);
				});
			} else {
				renderLayoutView(layoutViewConstructorArguments);
			}
		};

		this.destroyAllViews = function () {
			if (this.view) {
				if (this.view.close) {
					this.view.close();
				} else {
					this.view.remove();
				}
			}
			this.view = null;
			this.clearPopups();
		};

		this.destroyMainView = function () {
			if (this.view && this.view.subViews.mainView) {
				var mainView = this.view.subViews.mainView;
				if (mainView.close) {
					mainView.close();
				} else {
					mainView.remove();
				}
				this.view.subViews.mainView = null;
			}
			this.clearPopups();
		};

		this.clearPopups = function () {
			//destroy popups because they are not a part of the backbone view
			$('.popup_content').each(function () {
				// Don't need to destroy the user agreement and mobile menu popup. Their containers are on the index page.
				// They got initialized only once when app.index.js loads.
				// Other popups are initialized by backbone views that got rendered depending on SPA navigations
				if (this.id !== 'asideUserAgreement' && this.id !== 'asideMobileMenuPopup' && this.id !== 'divAlert') {
					popupModule.destroyPopup(this.id);
				}
			});
		};
	};

})();
