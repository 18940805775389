(function() {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		urlRoot: '/backend/lookup-values',
		initialize: function(lookupValueType) {
			if (lookupValueType) {
				this.urlRoot += '?type=' + lookupValueType;
			}
		}
	});
	module.exports = Model;
})();

