var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"menu-badge\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"totalNewStudyCount") || (depth0 != null ? lookupProperty(depth0,"totalNewStudyCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"totalNewStudyCount","hash":{},"data":data,"loc":{"start":{"line":19,"column":35},"end":{"line":19,"column":57}}}) : helper)))
    + "<span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:screenReader.newStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":83},"end":{"line":19,"column":133}}}))
    + "</span></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span class=\"menu-badge\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"totalUnreadMessageCount") || (depth0 != null ? lookupProperty(depth0,"totalUnreadMessageCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"totalUnreadMessageCount","hash":{},"data":data,"loc":{"start":{"line":25,"column":35},"end":{"line":25,"column":62}}}) : helper)))
    + "<span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:screenReader.newMessages",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":25,"column":88},"end":{"line":25,"column":139}}}))
    + "</span></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li>\n        <a data-history-navigate id=\"aPopupSelectLovedOne\" href=\"/volunteers/select-loved-one\"><span class=\"fa fa-users fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"volunteerHeader:switchUser",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":44,"column":169},"end":{"line":44,"column":206}}}))
    + "</a>\n      </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header>\n  <h1 class=\"grid-sm-1\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:menu",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":55}}}))
    + "</h1>\n  <div class=\"top-close-button\">\n      <a data-history-navigate href=\"javascript:void(0);\" id=\"aPopupClose\" class=\"asideMobileMenuPopup_close\"><span class=\"fa pe-7s-close\" aria-hidden=\"true\"></span><span class=\"hide show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:closeButtonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":196},"end":{"line":4,"column":239}}}))
    + "</span></a>\n  </div>\n</header>\n<section class=\"popup-main\">\n  <div class=\"logged-in-as-info\">\n      "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:loggedInUser",{"name":"i18n","hash":{"userFullName":(depth0 != null ? lookupProperty(depth0,"userFullName") : depth0)},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":71}}}))
    + "\n  </div>\n\n  <nav class=\"main-menu-nav\">\n    <ul class=\"nav-list\">\n      <li>\n          <a data-history-navigate href=\"/volunteers/studies-for-you/suggested-by-study-teams\">\n            <span class=\"fa fa-thumbs-up fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:studies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":90},"end":{"line":16,"column":124}}}))
    + "\n          </a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalNewStudyCount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":10},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + "      </li>\n      <li>\n          <a data-history-navigate href=\"/volunteers/messages\"><span class=\"fa fa-comment fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:messages",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":139},"end":{"line":23,"column":174}}}))
    + "</a>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalUnreadMessageCount") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":10},"end":{"line":26,"column":17}}})) != null ? stack1 : "")
    + "      </li>\n      <li>\n          <a data-history-navigate href=\"/volunteers/profile\"><span class=\"fa fa-user fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:profile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":135},"end":{"line":29,"column":169}}}))
    + "</a>\n      </li>\n      <li>\n          <a data-history-navigate href=\"/volunteers/history/showed-interest\"><span class=\"fa fa-history fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:history",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":32,"column":154},"end":{"line":32,"column":188}}}))
    + "</a>\n      </li>\n    </ul>\n  </nav>\n\n  <nav class=\"loved-one-nav\">\n    <ul class=\"nav-list\">\n      <li>\n          <a data-history-navigate href=\"/volunteers/add-loved-one\"><span class=\"fa fa-user-plus fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:addLovedOne",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":146},"end":{"line":40,"column":184}}}))
    + "</a>\n      </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lovedOneExists") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":46,"column":13}}})) != null ? stack1 : "")
    + "    </ul>\n  </nav>\n\n  <nav class=\"account-settings-nav\">\n    <ul class=\"nav-list\">\n      <li>\n          <a data-history-navigate href=\"/volunteers/account-settings\"><span class=\"fa fa-cogs fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:accountSettings",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":53,"column":144},"end":{"line":53,"column":186}}}))
    + "</a>\n      </li>\n  </nav>\n  <nav>\n    <ul class=\"nav-list\">\n      <li>\n          <a data-history-navigate href=\"mailto:"
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1), depth0))
    + "\"><span class=\"fa fa-envelope fa-margin-right fa-fw\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:contactUs",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":162},"end":{"line":59,"column":198}}}))
    + "</a>\n      </li>\n    </ul>\n  </nav>\n  <nav class=\"logout\">\n    <ul class=\"nav-list\">\n      <li>\n          <a data-history-navigate id=\"aLogout\" href=\"javascript:void(0);\">\n              <span class=\"fa fa-sign-out fa-margin-right fa-fw\" aria-hidden=\"true\"></span>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"volunteerHeader:logout",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":68,"column":14},"end":{"line":68,"column":47}}}))
    + "\n          </a>\n      </li>\n    </ul>\n  </nav>\n</section>\n";
},"useData":true});