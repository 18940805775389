(function () {
	'use strict';
	var Backbone = require('backbone');
	var popupModule = require('../modules/module.popup');
	var AlertPopupView = require('../views/view.alert.popup');

	var AlertMissingRequiredFieldsPopupModule = function () {
		var alertPopupView;

		this.showPopup = function (params) {
			var modelData = params && params.data ? {data: params.data}:{data: []};
			var alertPopupModel = new Backbone.Model(modelData);
			alertPopupView = new AlertPopupView({
				model: alertPopupModel,
				messageKey: 'errorMessages:missingRequiredFields.text',
				partialTemplate: 'missingProfileFields.list'
			});
			alertPopupView.render();
			popupModule.showPopup($('#divAlert'));
		};
		this.onClosed = function () {
			localStorage.setItem('missingRequiredFieldsDismissed', 'true');
			alertPopupView.closePopup();
		};
	};
	module.exports = new AlertMissingRequiredFieldsPopupModule();
})();
