(function () {
	'use strict';
	var template = require('../../templates/hbs/advanced.search.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var StudyModel = require('../models/model.study');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values.js');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aSearchForStudies': 'searchForStudies',
			'click input[name=gender]': 'genderSelected',
			'click #aClearPregnancy': 'clearPregnancy',
			'click #aFindStudyBystudyNum': 'findStudyByStudyNum'
		},

		initialize: function (option) {
			if (option) {
				this.seedModel = option.seedModel;
				this.piNames = option.studyTeamMemberNameModel.get('piNames');
				this.constantLookupValues = new ConstantLookupValues(option.seedModel.attributes);
			}
			this.subViews = {};
		},

		genderSelected: function () {
			var divPregnancy = $('#divPregnancy');
			var inputPregnancy = $('input[name=pregnant]');
			var selectedGenderCheckbox = $('input[name=gender]:checked');
			var selectedGenderIds = selectedGenderCheckbox.map(function () {
				return Number($(this).val());
			}).get();

			var hasMale = selectedGenderIds.includes(this.constantLookupValues.get('GENDER','MALE'));
			var hasFemaleOrIntersex = selectedGenderIds.includes(this.constantLookupValues.get('GENDER','FEMALE')) || selectedGenderIds.includes(this.constantLookupValues.get('GENDER','INTERSEX'));

			if (!hasMale && hasFemaleOrIntersex) {
				divPregnancy.removeClass('hide');
				inputPregnancy.prop('disabled', false);
			}else{
				divPregnancy.addClass('hide');
				inputPregnancy.prop('disabled', true);
				inputPregnancy.prop('checked', false);
			}
		},

		clearPregnancy: function (event) {
			event.preventDefault();
			$('input[name=pregnant]').prop('checked', false);
		},

		searchForStudies: function () {
			var $textAge = $('#textAge');
			$textAge.parsley(parsleyConfig).validate();
			if ($textAge.parsley(parsleyConfig).isValid()) {
				var formArray = $('#formSearchForStudies').serializeArray();
				var searchQuery = this.generateSearchQuery(formArray);
				if (searchQuery) {
					this.goTo('/study-results?' + encodeURI(searchQuery));
				} else {
					this.goTo('/study-results');
				}
			} else {
				$('#divAge')[0].scrollIntoView();
			}
		},

		generateSearchQuery: function (formArray) {
			var searchQuery = '';
			var formNameValueMap = util.buildFormNameValueMap(formArray);

			$.each(formNameValueMap, function (key, value) {
				searchQuery += key + '=' + value.join(',') + '&';
			});

			return searchQuery.substring(0, searchQuery.length - 1);
		},

		findStudyByStudyNum: function () {
			var thisView = this;
			var studyNumber = thisView.$el.find('#textStudy').val().trim();
			if (studyNumber.length > 0) {
				var studyModel = new StudyModel({studyNumber: studyNumber, status: 'any'});
				studyModel.fetch({
					success: function (model) {
						var study = model.get('page')[0];
						if (study) {
							if (study.active) {
								util.redirect('/studies/' + studyNumber);
							} else {
								thisView.$el.find('#divFindStudyByStudyIdentifierErrorText').html(i18n.t('errorMessages:studyNotRecruiting'));
							}
						} else {
							thisView.$el.find('#divFindStudyByStudyIdentifierErrorText').html(i18n.t('errorMessages:studyDoesNotExist'));
						}
					}
				});
			} else {
				thisView.$el.find('#divFindStudyByStudyIdentifierErrorText').html( i18n.t('errorMessages:requiredField'));
			}
		},

		setupTypeIO: function (inputId, initialResults, name, resultsContainerId, source, mode, removeText) {
			$('#' + inputId).typeIO({
				hint: true,
				highlight: true,
				minLength: 1,
				resultsContainer: '#' + resultsContainerId,
				initialResults: initialResults,
				name: name,
				mode: mode,
				removeText: removeText
			},
			{
				limit: 10,
				display: 'text',
				source: source,
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					empty: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		transformTopicsForTypeIO: function (topics) {
			var result = [];
			$.each(topics, function (index, topic) {
				if (topic.visible) {
					result.push({value: topic.id, text: topic.displayText});
				}
			});
			return result;
		},

		transformPINamesForTypeIO: function (piNames) {
			var result = [];
			$.each(piNames, function (index, piName) {
				result.push({
					value: piName.id,
					text: util.formatNameLastFirstMiddle(piName.lastName, piName.firstName, piName.middleName)
				});
			});
			return result;
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
		},

		generateTemplateData: function () {
			util.sortLookupValuesById(this.seedModel.attributes.GENDER);
			var templateData = {};

			templateData.GENDER = this.seedModel.attributes.GENDER;
			templateData.yesLookupValueId = this.constantLookupValues.get('BOOLEAN','TRUE');
			templateData.noLookupValueId = this.constantLookupValues.get('BOOLEAN','FALSE');
			templateData.canBeDoneFromHomeLookupValueId = this.constantLookupValues.get('STUDY_LOCATION','CAN_BE_DONE_FROM_HOME');
			templateData.healthyVolunteerLookupValueId = this.constantLookupValues.get('PARTICIPANT_TYPE','HEALTHY');

			return templateData;
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));

			this.setupTypeIO('textTopics', [], 'topics', 'divTopics', this.transformTopicsForTypeIO(this.seedModel.get('MEDICAL_CONDITION')), 'multi-select', i18n.t('multiSelectRemoveLabel'));
			this.setupTypeIO('textPIName', [], 'pi', 'divPIName', this.transformPINamesForTypeIO(this.piNames), 'single-select', i18n.t('singleSelectRemoveLabel'));

			this.$el.find('#formSearchForStudies').parsley(parsleyConfig);
			this.setupValidationSummaryHandler();
			return this;
		}
	});
})();
