(function() {
	'use strict';
	var Backbone = require('backbone');
	var Model = Backbone.Model.extend({
		url: function () {
			var url = '/backend/secure/volunteer/screening-questionnaire?study-num=' + this.get('studyNum');

			if(this.get('userId')) {
				url += '&user-id=' + this.get('userId');
			}

			return url;
		}
	});
	module.exports = Model;
})();

