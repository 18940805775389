var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./stripped.header.hbs"),depth0,{"name":"stripped.header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"grid-row login-content\" id=\"sectionForgotPassword\">\n    <div class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n        <header>\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"resetPassword:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":46}}}))
    + "</h1>\n        </header>\n        <div id=\"divFeedback\"></div>\n        <form class=\"form-stacked\" id=\"formResetPassword\">\n            <div class=\"field-container password validation-error-container\">\n                <label id=\"labelPassword\" for=\"passwordPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"resetPassword:setNewPassword",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":65},"end":{"line":10,"column":104}}}))
    + "</label>\n                <div class=\"field-help-text\">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"passwordDescription",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":50}}}))
    + "\n                </div>\n                <input class=\"input-1\" id=\"passwordPassword\" type=\"password\" name=\"password\" required\n                       data-parsley-pattern=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"PASSWORD_REGEX") || (depth0 != null ? lookupProperty(depth0,"PASSWORD_REGEX") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"PASSWORD_REGEX","hash":{},"data":data,"loc":{"start":{"line":15,"column":45},"end":{"line":15,"column":63}}}) : helper)))
    + "\"\n                       data-parsley-error-message=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"errorMessages:passwordDoesNotMeetCriteria",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":51},"end":{"line":16,"column":103}}}))
    + "\">\n                <input type=\"checkbox\" name=\"showPassword\" id=\"checkboxShowPassword\">\n                <label class=\"password-show always\" for=\"checkboxShowPassword\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.password.show.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":79},"end":{"line":18,"column":127}}}))
    + "</label>\n\n            </div>\n\n            <button type=\"submit\" class=\"primary-button\" id=\"btnPasswordResetLink\" value=\"SIGN IN\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"resetPassword:resetPassword.buttonLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":66}}}))
    + "\n            </button>\n\n            <div class=\"secondary-font-style text-center margin-top-small\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"resetPassword:desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":16},"end":{"line":27,"column":45}}}))
    + "\n            </div>\n        </form>\n    </div>\n</section>\n";
},"usePartial":true,"useData":true});