(function () {
	'use strict';
	var Backbone = require('backbone');
	module.exports = Backbone.Model.extend({
		idAttribute: 'id',
		defaults: {
			'type': 'info',
			'title': 'default feedback title',
			'message': 'default feedback message',
			'isShowFeedback': true
		}
	});
})();
