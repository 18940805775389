var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div>\n          "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"notAuthorized:noAccess.label",{"name":"i18n","hash":{"studyIdentifier":(depth0 != null ? lookupProperty(depth0,"studyIdentifier") : depth0)},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":81}}}))
    + "\n      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div>\n        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"notAuthorized:reason.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":45}}}))
    + "\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a data-bypass href=\"/study-team/studies/current\"><span class=\"fa fa-chevron-circle-left fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"notAuthorized:goToStudyListPage.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":140},"end":{"line":17,"column":192}}}))
    + "</a> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"notAuthorized:loginWithCorrectCredentials.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":197},"end":{"line":17,"column":255}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <a data-history-navigate href=\"/\"><span class=\"fa fa-chevron-circle-left fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"notAuthorized:goToHomePage.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":124},"end":{"line":19,"column":171}}}))
    + "</a> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"notAuthorized:loginWithCorrectCredentials.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":176},"end":{"line":19,"column":234}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./stripped.header.hbs"),depth0,{"name":"../../apps/index/templates/hbs/stripped.header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div class=grid-row>\n  <section class=\"grid-sm-1 grid-md-8-12 grid-md-centered grid-lg-5-12 grid-lg-centered text-center\">\n    <span class=\"fa fa-ban fa-4x  error-font-color\" aria-hidden=\"true\"></span>\n    <h1 class=\"error-font-color\">STOP</h1>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notAuthStudy") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"margin-top\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"notAuthStudy") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":16,"column":6},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"margin-top\">\n      "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"notAuthorized:contactUs.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":23,"column":6},"end":{"line":23,"column":46}}}))
    + "<a data-history-navigate href=\"mailto:"
    + alias3(((helper = (helper = lookupProperty(helpers,"supportEmail") || (depth0 != null ? lookupProperty(depth0,"supportEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"supportEmail","hash":{},"data":data,"loc":{"start":{"line":23,"column":84},"end":{"line":23,"column":100}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"supportEmail") || (depth0 != null ? lookupProperty(depth0,"supportEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"supportEmail","hash":{},"data":data,"loc":{"start":{"line":23,"column":102},"end":{"line":23,"column":118}}}) : helper)))
    + "</a>\n    </div>\n    <div class=\"margin-top\">\n      <strong>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"notAuthorized:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":14},"end":{"line":26,"column":46}}}))
    + "</strong>\n    </div>\n  </section>\n</div>\n";
},"usePartial":true,"useData":true});