(function() {
	'use strict';
	var template = require('../../templates/hbs/history.hbs');
	var StudyCardListView = require('./view.study.card.list.js');
	var StudyCollection = require('../collections/collection.study.js');

	module.exports = Backbone.View.extend({
		el: 'main',

		events: {
			'click #aPrev': 'goToPrevPage',
			'click #aNext': 'goToNextPage',
			'change #selectPage': 'jumpToPage'
		},

		initialize: function () {
			this.subViews = {};
			var studyCollection = new StudyCollection(this.model.get('page'));
			this.subViews.studyCardListView = new StudyCardListView({model: studyCollection});
		},

		goToNextPage: function (event) {
			event.preventDefault();

			if (this.isLastPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') + 1;
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/history/' + this.model.get('action') + '/page/' + this.model.get('pageNum'));
		},

		isLastPage: function () {
			return Number(this.model.get('pageNum')) === Math.ceil(this.model.get('totalCount') / this.model.get('pageSize'));
		},

		goToPrevPage: function (event) {
			event.preventDefault();

			if (this.isFirstPage()) {
				return;
			}
			var newPageNum = this.model.get('pageNum') - 1;
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/history/' + this.model.get('action') + '/page/' + this.model.get('pageNum'));
		},

		isFirstPage: function () {
			return (Number(this.model.get('pageNum')) === 1);
		},

		jumpToPage: function () {
			var newPageNum = $('#selectPage').val();
			this.model.set('pageNum', newPageNum);
			this.goTo('/volunteers/history/' + this.model.get('action') + '/page/' + this.model.get('pageNum'));
		},

		generateTemplateData: function () {
			var templateData = {};

			if (this.model.get('action') === 'showed-interest') {
				templateData.isShowedInterest = true;
			} else if (this.model.get('action') === 'dismissed') {
				templateData.isDismissed = true;
			}

			templateData.totalStudyCount = this.model.get('totalCount');
			templateData.startStudyCount = (this.model.get('pageNum') - 1) * this.model.get('pageSize') + 1;
			templateData.endStudyCount = templateData.startStudyCount + this.model.get('pageSize') - 1;

			if (templateData.endStudyCount > this.model.get('totalCount')) {
				templateData.endStudyCount = this.model.get('totalCount');
			}

			templateData.pageNumbers = [];
			for (var i = 1; i <= Math.ceil(this.model.get('totalCount') / this.model.get('pageSize')); i++) {
				templateData.pageNumbers.push({pageNumber: i});
			}

			return templateData;
		},

		render: function () {
			this.closeSubViews();
			this.$el.html(template(this.generateTemplateData()));
			this.renderSubviewAppend(this.$el.find('#divStudyCardList'), 'studyCardList', this.subViews.studyCardListView);

			if (this.isFirstPage()) {
				this.$el.find('#aPrev').attr('disabled', 'disabled');
			}
			if (this.isLastPage()) {
				this.$el.find('#aNext').attr('disabled', 'disabled');
			}
			this.$el.find('#selectPage').val(this.model.get('pageNum'));

			$(window).scrollTop(0);
			return this;
		}
	});
})();
