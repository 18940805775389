(function() {

	'use strict';

	var feedbackModelFactory = require('../factories/factory.feedback.model.js');
	var FeedbackView = require('../views/view.feedback.js');

	var FeedbackModule = function () {
		this.hideFeedback = function (containerView) {
			if (containerView) {
				if (containerView.subViews.feedbackView) {
					containerView.subViews.feedbackView.model.set('isShowFeedback', false);
				}
			} else {
				throw new Error('Container view must be provided to hideFeedback()');
			}

		};

		this.showFeedback = function (view, code, params, elementId) {
			elementId = elementId ? elementId : '#divFeedback';
			params = params ? params : [];
			var feedbackModel = feedbackModelFactory.getModelForFeedbackNamed(String(code), params);
			if (view.subViews) {
				if (view.subViews.feedbackView) {
					view.subViews.feedbackView.model.set(feedbackModel.attributes);
				} else {
					var feedbackView = new FeedbackView({model: feedbackModel,  el: $(elementId)});
					view.subViews.feedbackView = feedbackView.render();
				}
			} else {
				throw new Error('container.subViews does not exist');
			}
		};
	};

	module.exports = new FeedbackModule();
})();
