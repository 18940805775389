(function () {
	'use strict';
	var template = require('../../templates/hbs/add.loved.one.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');
	var relationshipCascadingDropdownModule = require('../../../../shared-scripts/modules/module.relationship.cascading.dropdown.js');
	var popupModule = require('../../../../shared-scripts/modules/module.popup.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var userAgreementModule = require('../../../../shared-scripts/modules/module.user.agreement');
	var ConstantLookupValues = require('../../../../shared-scripts/app-util/constant.lookup.values.js');
	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');
	var countries = require('../../../../shared-scripts/config/countries.json');

	module.exports = Backbone.View.extend({
		el: 'main',

		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
				this.constantLookupValues = new ConstantLookupValues(options.seedModel.attributes);
				this.relationshipsModel = options.relationshipsModel;
				this.appSettingsCollection = options.appSettingsCollection;
			}
			this.subViews = {};
		},

		events: {
			'typeahead:select #textCurrentConditions': 'validateTypeahead',
			'typeahead:select #textPastConditions': 'validateTypeahead',
			'click #checkboxNoCurrentConditions': 'toggleTypeahead',
			'click #checkboxNoPastConditions': 'toggleTypeahead',
			'click [name="race"]': 'validateRace',
			'keyup #textBirthDay': 'validateDateOfBirth',
			'change #selectBirthMonth': 'validateDateOfBirth',
			'change #selectRelationshipCategory': 'validateDateOfBirth',
			'keyup #textBirthYear': 'validateDateOfBirth',
			'submit #formAddLovedOne': 'submitAddLovedOneForm',
			'change input[name=gender]': 'genderChanged',
			'change input[name=pregnancy]': 'pregnancyChanged',
			'keyup #textDueYear': 'validateDueDate',
		},

		validateRace: function () {
			$('#textOtherRace').parsley(parsleyConfig).validate();
		},

		validateDateOfBirth: function (event) {
			if (!event.isTrigger) {
				$('#textBirthYear').parsley(parsleyConfig).validate();
			}
		},

		submitAddLovedOneForm: function (event) {
			event.preventDefault();
			this.openUserAgreement();
		},

		openUserAgreement: function () {

			userAgreementModule.showUserAgreement(
				{
					needsConfirm: true,
					userAgreementType: 'VOL',
					hasLovedOnes: true,
					agreeCallback: this.addLovedOne,
					agreeCallbackParams: {view: this}
				}
			);
		},

		addLovedOne: function (params) {
			var thisView = params.view;

			var formArray = $('#formAddLovedOne').serializeArray();
			var lovedOne = thisView.generateLovedOne(formArray);
			thisView.processDueDate(lovedOne);

			thisView.model.set('lovedOne', lovedOne);
			thisView.model.save(null, {
				dataType: 'json',
				contentType: 'application/json',
				success: function (model) {
					thisView.login(model.get('value'));
				}
			});

			popupModule.closePopup($('#asideUserAgreement'));
		},

		generateLovedOne: function (formArray) {
			var lovedOne = {};
			lovedOne.parent = {};
			lovedOne.child = {};
			lovedOne.child.profile = {};
			lovedOne.child.profile.propertyValues = [];
			lovedOne.child.profile.contactInfo = {};

			var lookupValuePropertyValueMap = {};

			for (var i = 0; i < formArray.length; i++) {
				var field = formArray[i];

				if (field.name === 'pregnancy') {
					continue;
				}

				if (field.name.indexOf('-checkbox') !== -1) {
					field.name = field.name.substring(0, field.name.indexOf('-'));
				}

				if (field.name === 'relationship') {
					lovedOne.relationship = field.value;
				} else if (field.name === 'lovedOneFirstName') {
					lovedOne.child.firstName = field.value;
				} else if (field.name === 'lovedOneLastName') {
					lovedOne.child.lastName = field.value;
				} else if (field.name === 'zip' && field.value) {
					lovedOne.child.profile.contactInfo[field.name] = field.value;
				} else if (field.name === 'country' && field.value) {
					lovedOne.child.profile.contactInfo[field.name] = field.value;
				} else if (field.name === 'birthMonth') {
					var dateOfBirth = util.newDate($('#textBirthYear').val(), field.value, $('#textBirthDay').val());
					dateOfBirth = util.formatDateForProperty(dateOfBirth);

					var dateOfBirthPropertyValue = {};
					dateOfBirthPropertyValue.savedValue = dateOfBirth;
					dateOfBirthPropertyValue.property = {name: 'dateOfBirth'};
					lovedOne.child.profile.propertyValues.push(dateOfBirthPropertyValue);
				} else if (field.name === 'otherRace') {
					var otherRacePropertyValue = {};
					otherRacePropertyValue.savedValue = $('#textOtherRace').val();
					otherRacePropertyValue.property = {name: field.name};
					lovedOne.child.profile.propertyValues.push(otherRacePropertyValue);
				} else if (field.name === 'visibleToStudyTeams') {
					lovedOne.child.profile[field.name] = field.value;
				} else if (field.name === 'otherGenderIdentity') {
					var otherGenderIdentityPropertyValue = {};
					otherGenderIdentityPropertyValue.savedValue = $('#textOtherGenderIdentity').val();
					otherGenderIdentityPropertyValue.property = {name: field.name};
					lovedOne.child.profile.propertyValues.push(otherGenderIdentityPropertyValue);
				} else if (field.name === 'otherGenderPronoun') {
					var otherGenderPronounPropertyValue = {};
					otherGenderPronounPropertyValue.savedValue = $('#textOtherGenderPronoun').val();
					otherGenderPronounPropertyValue.property = {name: field.name};
					lovedOne.child.profile.propertyValues.push(otherGenderPronounPropertyValue);
				} else {
					if (field.value) {
						if (!lookupValuePropertyValueMap[field.name]) {
							lookupValuePropertyValueMap[field.name] = {
								property: {name: field.name},
								lookupValues: []
							};
						}
						lookupValuePropertyValueMap[field.name].lookupValues.push({id: field.value});
					}
				}
			}

			for (var lookupValuePropertyName in lookupValuePropertyValueMap) {
				lovedOne.child.profile.propertyValues.push(lookupValuePropertyValueMap[lookupValuePropertyName]);
			}
			lovedOne.child.profile.language = i18n.language;
			return lovedOne;
		},

		processDueDate: function (lovedOne) {
			var dueDate = '';
			if (!$('#divDueDate').hasClass('hide')) {
				dueDate = util.newDate($('#textDueYear').val(), $('#selectDueMonth').val(), 1);
				dueDate = util.formatDateForProperty(dueDate);
				lovedOne.child.profile.propertyValues.push({savedValue: dueDate, property: {name: 'estimatedDueDate'}});
			}
		},

		genderChanged: function (event) {
			if (event.currentTarget.id === 'radio-gender-' + this.constantLookupValues.get('GENDER', 'MALE')) {
				this.$el.find('#divPregnancy').addClass('hide');
				this.$el.find('#radioNoPregnant').prop('checked', true);
				this.$el.find('#divDueDate').addClass('hide');
				this.$el.find('#textDueYear').val('');
			} else {
				this.$el.find('#divPregnancy').removeClass('hide');
			}
		},

		pregnancyChanged: function (event) {
			if (event.currentTarget.id === 'radioYesPregnant') {
				this.$el.find('#divDueDate').removeClass('hide');
			} else {
				this.$el.find('#divDueDate').addClass('hide');
				this.$el.find('#textDueYear').val('');
			}
		},

		validateDueDate: function () {
			$('#textDueYear').parsley(parsleyConfig).validate();
		},

		validateTypeahead: function () {
			this.$el.find('#checkboxNoCurrentConditions').parsley(parsleyConfig).validate();
			this.$el.find('#checkboxNoPastConditions').parsley(parsleyConfig).validate();
		},

		login: function (userId) {
			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: 'POST',
				url: '/backend/secure/volunteer/change-account',
				data: JSON.stringify({id: userId}),
				success: function () {
					localStorage.setItem('feedbackCode', 'ADD_A_LOVED_ONE_SUCCESS');
					util.redirect('/volunteers/studies-for-you/suggested-by-study-teams');
				},
				error: function () {

				}
			});
		},

		toggleTypeahead: function (event) {
			var checkboxId = event.currentTarget.id;

			var $textTypeahead;

			if (checkboxId.indexOf('Current') !== -1) {
				$textTypeahead = this.$el.find('#textCurrentConditions');
			} else if (checkboxId.indexOf('Past') !== -1) {
				$textTypeahead = this.$el.find('#textPastConditions');
			}

			$textTypeahead.typeIO('toggleResultsContainerVisibility');
			$textTypeahead.typeIO('toggleDisabledResultsContainer');

			if ($textTypeahead.is(':disabled')) {
				$textTypeahead.prop('disabled', false);
			} else {
				$textTypeahead.prop('disabled', true);
			}
			this.validateTypeahead();
		},

		populateRelationshipDropdown: function () {
			var dropdownContainer = this.$el.find('#fieldsetRelationship');

			relationshipCascadingDropdownModule.populateCascadingDropdown(dropdownContainer, this.relationshipsModel.attributes);
		},

		setupTypeahead: function (inputId, name, resultsContainerId, sourceAttributeName, removeText) {
			this.$el.find('#' + inputId).typeIO({
				hint: true,
				highlight: true,
				resultsContainer: '#' + resultsContainerId,
				name: name,
				minLength: 1,
				removeText: removeText
			},
			{
				limit: 10,
				name: name,
				display: 'text',
				source: this.transformDataForTypeahead(this.seedModel.get(sourceAttributeName)),
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					empty: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},

		setupTypeaheadCountry: function () {
			this.$el.find('#textCountry').typeIO({
				hint: true,
				highlight: true,
				mode: 'single-select',
				minLength: 1,
				name: 'country',
				removeText: i18n.t('multiSelectRemoveLabel'),
				resultsContainer: '#divCountry'
			},
			{
				limit: 10,
				display: 'text',
				source: countries,
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					notFound: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
			// Because of TypeIO generated markup interfering with the parsley validation we need to manually trigger the validation
			// when a selection is picked from single select type io the input textbox disappears (loses focus) but parsley is not triggered therefore the validation
			// error message is not cleared.
			$('#textCountry').blur(function () {
				// Code to execute when the textbox loses focus
				$('#textCountry').parsley().validate();
			});
		},

		transformDataForTypeahead: function (dataArray) {
			var result = [];
			$.each(dataArray, function (index, data) {
				if (data.visible) {
					result.push({value: data.id, text: data.displayText});
				}
			});
			return result;
		},

		setupPopup: function () {
			popupModule.initializePopup(this.$el.find('#asideTellMeMore'));
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		generateTemplateData: function () {
			util.transformTemplateDataForBooleanLookUpValues(this.seedModel.attributes);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER_IDENTITY);
			util.sortLookupValuesById(this.seedModel.attributes.GENDER_PRONOUN);

			var templateData = $.extend(true, {}, this.seedModel.attributes);

			templateData.noConditionId = this.constantLookupValues.get('MEDICAL_CONDITION', 'NO_CONDITION');

			this.appSettingsCollection.each(function (applicationSetting) {
				templateData[applicationSetting.get('name')] = applicationSetting.get('value');
			});
			templateData.organizationInfo = appConfig.organizationInfo;

			templateData.isLovedOne = true;
			return templateData;
		},

		render: function () {
			this.$el.html(template(this.generateTemplateData()));

			this.populateRelationshipDropdown();

			this.setupPopup();
			this.setupTypeaheadCountry();
			this.$el.find('#textCountry').selectItem('United States of America');
			this.setupTypeahead('textCurrentConditions', 'currentConditions', 'divCurrentConditionsResults', 'MEDICAL_CONDITION', i18n.t('multiSelectRemoveLabel'));
			this.setupTypeahead('textPastConditions', 'pastConditions', 'divPastConditionsResults', 'MEDICAL_CONDITION', i18n.t('multiSelectRemoveLabel'));

			this.setupValidationSummaryHandler();
			this.$el.find('#formAddLovedOne').parsley(parsleyConfig);
			return this;
		}
	});
})();
