(function () {
	'use strict';
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var userAgreementModule = require('../../../../shared-scripts/modules/module.user.agreement');

	var appConfig = require('~institution-specific-config/app.config.json');

	var template;
	var View = Backbone.View.extend({
		el: 'main',
		events: {
			'click #aUserAgreement': 'openUserAgreement'
		},
		initialize: function (options) {
			this.subViews = {};
			if (options.action) {
				switch (options.action) {
				case 'volunteers':
					template = require('../../templates/hbs/about.volunteers.hbs');
					break;
				case 'study-teams':
					template = require('../../templates/hbs/about.study.teams.hbs');
					break;
				default:
					throw new Error('Invalid Action! Please enter either volunteers or study-teams');
				}
			} else {
				throw new Error('Please provide action(e.g. volunteers');
			}
		},

		openUserAgreement: function () {
			userAgreementModule.showUserAgreement({userAgreementType: 'VOL'});
		},

		render: function () {
			this.$el.html(template({organizationInfo: appConfig.organizationInfo}));
			if (localStorage.getItem('feedbackCode')) {
				feedbackModule.showFeedback(this, localStorage.getItem('feedbackCode'));
				localStorage.setItem('feedbackCode', '');
			}
			return this;
		}
	}
	);
	module.exports = View;
})();
