var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "other";
},"3":function(container,depth0,helpers,partials,data) {
    return "self";
},"5":function(container,depth0,helpers,partials,data) {
    return "new";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"time") || (depth0 != null ? lookupProperty(depth0,"time") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"time","hash":{},"data":data,"loc":{"start":{"line":3,"column":74},"end":{"line":3,"column":82}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"attachments\">\n                <h2 class=\"show-for-sr\">Attachments</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"attachments") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":21,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a data-history-navigate href=\"javascript:void(0);\" class=\"attachment\" data-filename=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attachmentFilename") : depth0), depth0))
    + "\" data-title="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attachmentTitle") : depth0), depth0))
    + ">\n                        <span class=\"fa fa-paperclip fa-margin-right\" aria-hidden=\"true\" />\n                        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attachmentTitle") : depth0), depth0))
    + " <em>("
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attachmentFileExtension") : depth0), depth0))
    + ")</em>\n                    </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"senderFirstName") || (depth0 != null ? lookupProperty(depth0,"senderFirstName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"senderFirstName","hash":{},"data":data,"loc":{"start":{"line":31,"column":20},"end":{"line":31,"column":39}}}) : helper)))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "                    You\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"message "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recipientVolunteer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":69}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNewMessage") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":70},"end":{"line":1,"column":100}}})) != null ? stack1 : "")
    + " hide\">\n    <div class=\"message-content\">\n        <time class=\"message-date-time\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"sentDateToDisplay") || (depth0 != null ? lookupProperty(depth0,"sentDateToDisplay") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"sentDateToDisplay","hash":{},"data":data,"loc":{"start":{"line":3,"column":40},"end":{"line":3,"column":61}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"time") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":62},"end":{"line":3,"column":89}}})) != null ? stack1 : "")
    + "</time>\n\n        <div class=\"message-bubble\">\n            <div class=\"banner\">\n                <span class=\"fa fa-star\" aria-hidden=\"true\"></span><span class=\"show-for-sr\">This is a</span>New<span\n                    class=\"show-for-sr\">message</span>\n            </div>\n            <div>\n                "
    + alias4(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":24}}}) : helper)))
    + "\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasAttachments") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"message-sender\">\n            <div class=\"message-sender-media\">\n\n            </div>\n            <div class=\"message-sender-name\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"recipientVolunteer") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":34,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</li>\n";
},"useData":true});