var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"active\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"menu-badge\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"recommendedStudyCounts") : depth0)) != null ? lookupProperty(stack1,"newUserRecStudyCount") : stack1), depth0))
    + "<span class=\"show-for-sr\">"
    + alias1((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studiesForYou:screenReader.newSuggestions",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":118},"end":{"line":22,"column":170}}}))
    + "</span></span>";
},"5":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"menu-badge\"><span class=\"fa fa-spinner fa-pulse fa-fw\"></span></span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"menu-badge\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"recommendedStudyCounts") : depth0)) != null ? lookupProperty(stack1,"newSysRecStudyCount") : stack1), depth0))
    + "<span class=\"show-for-sr\">"
    + alias1((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studiesForYou:screenReader.newMatches",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":34,"column":117},"end":{"line":34,"column":165}}}))
    + "</span></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"matchingFinished") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":59,"column":23}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"white-background shadow margin-top text-center\">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studiesForYou:matchingInProgress",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":45,"column":20},"end":{"line":45,"column":63}}}))
    + "\n                    <div class=\"margin-top\">\n                        <a data-history-navigate href=\"javascript:window.location.reload();\" class=\"primary-button\">\n                            <span class=\"fa fa-refresh fa-margin-right\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studiesForYou:refresh",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":48,"column":79},"end":{"line":48,"column":111}}}))
    + "\n                        </a>\n                    </div>\n                </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <h1 class=\"show-for-sr\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studiesForYou:matchedBySystem",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":53,"column":40},"end":{"line":53,"column":129}}}))
    + "</h1>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":58,"column":27}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"text-center margin-top white-background\">\n                        "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studiesForYou:noMatchedStudies",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":56,"column":114}}}))
    + "\n                    </p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h1 class=\"show-for-sr\">"
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(alias1,"studiesForYou:suggestedByStudyTeams",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":61,"column":36},"end":{"line":61,"column":82}}}))
    + "</h1>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":12},"end":{"line":66,"column":23}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p class=\"text-center margin-top white-background\">\n                    "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studiesForYou:noSuggestedStudies",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":64,"column":112}}}))
    + "\n                </p>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <section class=\"grid-row\">\n        <div class=\"grid-1\">\n            <section class=\"pagination\">\n                <div class=\"pagination-label\">\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:heading",{"name":"i18n","hash":{"entity":"studies","totalStudyCount":(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),"endStudyCount":(depth0 != null ? lookupProperty(depth0,"endStudyCount") : depth0),"startStudyCount":(depth0 != null ? lookupProperty(depth0,"startStudyCount") : depth0)},"data":data,"loc":{"start":{"line":76,"column":20},"end":{"line":76,"column":159}}}))
    + "\n                </div>\n                <a data-history-navigate id=\"aPrev\" href=\"javascript:void(0);\" class=\"small-outline-button\"><span class=\"fa fa-chevron-left\"\n                                                                                            aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:previous",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":79,"column":118},"end":{"line":79,"column":148}}}))
    + "</a>\n                <a data-history-navigate id=\"aNext\" href=\"javascript:void(0);\" class=\"small-outline-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:next",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":80,"column":108},"end":{"line":80,"column":134}}}))
    + "<span\n                        class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span></a>\n                <section class=\"paginate-to-specific-page\">\n                    <a href=\"#divPaginatePageNum\" data-bypass data-bs-toggle=\"collapse\" aria-expanded=\"false\"\n                       aria-controls=\"divPaginatePageNum\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"pagination:open",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":84,"column":58},"end":{"line":84,"column":84}}}))
    + "</a>\n                    <div class=\"collapse\" id=\"divPaginatePageNum\">\n                        <form>\n                            <label for=\"selectPage\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:page",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":87,"column":52},"end":{"line":87,"column":74}}}))
    + "</label>\n                            <select id=\"selectPage\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pageNumbers") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":89,"column":32},"end":{"line":91,"column":41}}})) != null ? stack1 : "")
    + "                            </select>\n                        </form>\n                        <a href=\"#divPaginatePageNum\" data-bypass data-bs-toggle=\"collapse\" aria-expanded=\"false\"\n                           aria-controls=\"divPaginatePageNum\"><span class=\"fa fa-chevron-up\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.close.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":95,"column":119},"end":{"line":95,"column":148}}}))
    + "</a>\n                    </div>\n                </section>\n            </section>\n\n        </div>\n    </section>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"pageNumber") || (depth0 != null ? lookupProperty(depth0,"pageNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data,"loc":{"start":{"line":90,"column":51},"end":{"line":90,"column":65}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"pageNumber") || (depth0 != null ? lookupProperty(depth0,"pageNumber") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data,"loc":{"start":{"line":90,"column":67},"end":{"line":90,"column":81}}}) : helper)))
    + "</option>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <section class=\"grid-row margin-top\">\n        <div class=\"grid-sm-1 grid-lg-9-12 grid-centered text-center\">\n            "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"studiesForYou:searchForStudies",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":106,"column":12},"end":{"line":106,"column":53}}}))
    + "\n        </div>\n    </section>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-9-12 grid-md-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studiesForYou:studiesForYou",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":54}}}))
    + "</h1>\n            <p class=\"secondary-font-color text-center\">\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studiesForYou:desc",{"name":"i18n","hash":{"profileCompleteness":(depth0 != null ? lookupProperty(depth0,"profileCompleteness") : depth0)},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":85}}}))
    + " <a data-history-navigate href=\"/volunteers/profile\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studiesForYou:editProfile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":138},"end":{"line":7,"column":174}}}))
    + "</a>.\n            </p>\n        </header>\n    </section>\n</div>\n\n<div class=\"grid-row grid-no-padding\">\n    <section class=\"grid-sm-1 grid-lg-9-12 grid-centered\">\n        <nav class=\"secondary-nav horizontal\">\n            <a href=\"/volunteers/studies-for-you/suggested-by-study-teams\"\n               "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"matchedBySystem") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":15},"end":{"line":17,"column":67}}})) != null ? stack1 : "")
    + ">\n        <span class=\"fa fa-check-circle fa-margin-right\">\n          <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.youAreHere",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":36},"end":{"line":19,"column":70}}}))
    + "</span>\n        </span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studiesForYou:suggestedByStudyTeams",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":15},"end":{"line":20,"column":61}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"recommendedStudyCounts") : depth0)) != null ? lookupProperty(stack1,"newUserRecStudyCount") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":22,"column":191}}})) != null ? stack1 : "")
    + "\n\n            </a>\n            <a data-history-navigate href=\"/volunteers/studies-for-you/matched-by-system\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"matchedBySystem") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":90},"end":{"line":25,"column":134}}})) != null ? stack1 : "")
    + ">\n    <span class=\"fa fa-check-circle fa-margin-right\">\n          <span class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"screenReader.youAreHere",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":36},"end":{"line":27,"column":70}}}))
    + "</span>\n        </span>\n                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"studiesForYou:matchedBySystem",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":105}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"matchingFinished") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":32,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"recommendedStudyCounts") : depth0)) != null ? lookupProperty(stack1,"newSysRecStudyCount") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":35,"column":23}}})) != null ? stack1 : "")
    + "            </a>\n        </nav>\n    </section>\n</div>\n<section class=\"grid-row study-list\">\n    <div class=\"grid-sm-1 grid-lg-9-12 grid-centered\" id=\"divStudyCardList\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"matchedBySystem") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":42,"column":8},"end":{"line":67,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</section>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"totalStudyCount") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":0},"end":{"line":102,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showFindStudies") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":0},"end":{"line":109,"column":7}}})) != null ? stack1 : "");
},"useData":true});