var Handlebars = require("../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <label for=\"checkbox"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":42}}}) : helper)))
    + "\" class=\"block-label\">\n                    <input type=\"checkbox\" id=\"checkbox"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":55},"end":{"line":11,"column":61}}}) : helper)))
    + "\" name=\"race\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":11,"column":82},"end":{"line":11,"column":88}}}) : helper)))
    + "\" data-parsley-trigger=\"change\">\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"displayText") || (depth0 != null ? lookupProperty(depth0,"displayText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"displayText","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":35}}}) : helper)))
    + "\n                </label>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset>\n    <legend>\n        "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.section.raceAndEthnicity.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":63}}}))
    + "\n    </legend>\n\n    <div class=\"field-container validation-error-container\">\n        <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.race.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":7,"column":15},"end":{"line":7,"column":54}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"RACE") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":15,"column":17}}})) != null ? stack1 : "")
    + "\n        <label for=\"textOtherRace\" class=\"other-label\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.race.other.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":18,"column":57}}}))
    + "\n        </label>\n        <input type=\"text\" name=\"otherRace\" id=\"textOtherRace\" data-parsley-validate-if-empty data-parsley-check-race>\n    </div>\n</fieldset>\n";
},"useData":true});