(function() {
	'use strict';
	var template = require('../../templates/hbs/change.email.password.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var feedbackModule = require('../../../../shared-scripts/modules/module.feedback.js');
	var csrfModule = require('../../../../shared-scripts/modules/module.csrf');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'submit #formChangeEmailPassword': 'changeEmailPassword'
		},

		initialize: function() {
			this.subViews = {};
		},

		changeEmailPassword: function(event) {
			var thisView = this;
			event.preventDefault();
			var changeEmailToken = {
				'newEmail': this.model.get('newEmail'),
				'userId': this.model.get('userId'),
				'token': this.model.get('token'),
				'password': $('#passwordPassword').val()
			};

			csrfModule(function () {
				$.ajax({
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					type: 'POST',
					url: '/backend/public/change-email',
					data: JSON.stringify(changeEmailToken),
					success: function () {
						csrfModule(function () {
							localStorage.setItem('feedbackCode', 'CHANGE_EMAIL_SUCCESS');
							util.redirect('account-settings');
						});
					},
					error: function (jqxhr) {
						var responseJSON = jqxhr.responseJSON;
						if (jqxhr.status === 400 && responseJSON) {
							var code = responseJSON.code;
							feedbackModule.showFeedback(thisView, code,
								code === 'CHANGE_EMAIL_VERIFICATION_LINK_EMAIL_IN_USE' ? [{
									name: 'NEW_EMAIL',
									value: changeEmailToken.newEmail
								}] : []);
						}
					}
				});
			});
		},

		render: function() {
			this.$el.html(template());
			return this;
		}
	}
	);

	module.exports = View;
})();
