(function() {

	'use strict';

	//var util = require('../app-util/util.js');

	var ScrollOnFocusingTypeahedModule = function() {
		var TYPEAHEAD_IDENTIFIER = '.typeahead.tt-input';
		var SCROLL_TOP_PADDING = 8;

		this.setupScroll = function() {
			var scrollTopLast = 0;
			var $htmlOrBody = $('html, body');
			$htmlOrBody.on('focus', TYPEAHEAD_IDENTIFIER, function() {
				// save the current scroll position for blur event
				scrollTopLast = $(window).scrollTop();
				// scroll to the textarea
				$htmlOrBody.animate({scrollTop:($(this).offset().top - SCROLL_TOP_PADDING)},500);
				//$htmlOrBody.scrollTop($(this).offset().top - SCROLL_TOP_PADDING);
			}).blur(function() {
				// scroll back to position before textarea focus
				$htmlOrBody.scrollTop(scrollTopLast);
			});
		};
	};

	module.exports = new ScrollOnFocusingTypeahedModule();
})();
