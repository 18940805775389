(function () {
	'use strict';
	var userAgreementModule = require('../../../../shared-scripts/modules/module.user.agreement');
	var logoutModule = require('../../../../shared-scripts/modules/module.logout');
	var util = require('../../../../shared-scripts/app-util/util');
	var template = require('../../templates/hbs/stm.user.agreement.hbs');
	var i18n = require('../../../../shared-scripts/app-util/i18n');

	var View = Backbone.View.extend({
		el: 'main',
		events: {
			'click #buttonIagree': 'agreeToUserAgreement',
			'click #aDecline': 'declineUserAgreement'
		},

		initialize: function () {
			this.subViews = {};
		},

		agreeToUserAgreement: function () {
			var username = this.model.get('username');
			var redirectUrl = this.model.get('redirectUrl');
			var userAgreement = {
				username: username,
				userAgreementType: 'STM'
			};
			var successCallback = function () {
				util.redirect(redirectUrl);
			};
			userAgreementModule.agree(userAgreement, successCallback);
		},

		declineUserAgreement: function () {
			logoutModule.logout('STM');
		},

		render: function () {
			this.$el.html(template({needConfirmButtons: this.model.get('needConfirmButtons') === 'true'}));
			this.$el.find('#divUserAgreement').prepend(i18n.getLanguageInstitutionSpecificFile('STUDY_TEAM_USER_AGREEMENT'));
			return this;
		}
	});
	module.exports = View;
})();
