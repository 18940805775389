var $ = require('jquery');

module.exports = function (cb) {
	$.get('/backend/csrf', function (csrfToken) {
		var token = csrfToken.token;
		var headerName = csrfToken.headerName;
		document.querySelector('meta[name="csrf-token"]').setAttribute('content', token);
		document.querySelector('meta[name="csrf-token-headername"]').setAttribute('content', headerName);
		cb();
	});
};
