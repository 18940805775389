var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.success",{"name":"i18n","hash":{"newEmail":(depth0 != null ? lookupProperty(depth0,"newEmail") : depth0)},"data":data,"loc":{"start":{"line":50,"column":20},"end":{"line":50,"column":134}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resendFail") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":51,"column":16},"end":{"line":54,"column":16}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.failure",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":52,"column":20},"end":{"line":52,"column":116}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n  <section class=\"grid-sm-1 grid-lg-9-12 grid-lg-centered\">\n    <div class=\"feedback-block info\">\n        <div class=\"feedback-block-media\">\n            <span class=\"fa pe-7s-info\" id=\"spanFeedbackBlockMediaIcon\"></span>\n        </div>\n        <div class=\"feedback-block-body\">\n            <h1 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":30},"end":{"line":8,"column":80}}}))
    + "</h1>\n            <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":9,"column":15},"end":{"line":9,"column":65}}}))
    + "</p>\n        </div>\n    </div>\n    </section>\n</div>\n\n<div class=grid-row>\n  <div class=\"grid-sm-1 grid-md-9-12 grid-md-centered grid-lg-6-12 grid-lg-centered\">\n    <section class=\"faq\">\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.why.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":87}}}))
    + "</h2>\n      <p>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.why.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":10},"end":{"line":20,"column":72}}}))
    + "\n      </p>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whichEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":22,"column":94}}}))
    + "</h2>\n      <p>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whichEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":10},"end":{"line":24,"column":79}}}))
    + "\n        <strong id=\"strongNewEmail\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"newEmail") || (depth0 != null ? lookupProperty(depth0,"newEmail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"newEmail","hash":{},"data":data,"loc":{"start":{"line":25,"column":36},"end":{"line":25,"column":48}}}) : helper)))
    + "</strong>\n      </p>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":106}}}))
    + "</h2>\n      <div>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.desc",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":29,"column":91}}}))
    + ":\n      </div>\n      <ol>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.verifyEmailInfo.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":33,"column":126}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.verifyEmailInfo.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":14},"end":{"line":35,"column":111}}}))
    + "\n          </p>\n        </li>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.checkJunkEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":39,"column":28},"end":{"line":39,"column":125}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.checkJunkEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":41,"column":14},"end":{"line":41,"column":110}}}))
    + "\n          </p>\n        </li>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":45,"column":28},"end":{"line":45,"column":122}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.resendEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":14},"end":{"line":47,"column":107}}}))
    + "\n            <span id=\"divResendResult\" class=\"display-block "
    + alias3(((helper = (helper = lookupProperty(helpers,"resendResult") || (depth0 != null ? lookupProperty(depth0,"resendResult") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"resendResult","hash":{},"data":data,"loc":{"start":{"line":48,"column":60},"end":{"line":48,"column":76}}}) : helper)))
    + "-font-color\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"resendSuccess") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":49,"column":16},"end":{"line":54,"column":23}}})) != null ? stack1 : "")
    + "            </span>\n            </p>\n        </li>\n        <li>\n          <h3 class=\"delta\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.contactUs.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":59,"column":28},"end":{"line":59,"column":120}}}))
    + "</h3>\n          <p>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfEmailNotReceived.contactUs.info",{"name":"i18n","hash":{"supportEmailAddress":((stack1 = (depth0 != null ? lookupProperty(depth0,"supportInfo") : depth0)) != null ? lookupProperty(stack1,"supportEmailAddress") : stack1)},"data":data,"loc":{"start":{"line":61,"column":14},"end":{"line":61,"column":157}}}))
    + "\n          </p>\n        </li>\n      </ol>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfIDontClick.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":65,"column":24},"end":{"line":65,"column":100}}}))
    + "</h2>\n      <p>\n          "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.whatIfIDontClick.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":67,"column":10},"end":{"line":67,"column":85}}}))
    + "\n      </p>\n      <h2 class=\"gamma\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":69,"column":24},"end":{"line":69,"column":97}}}))
    + "</h2>\n      <p>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.info",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":70,"column":9},"end":{"line":70,"column":81}}}))
    + "<br/>\n        <a data-history-navigate href=\"https://www.gmail.com\" class=\"small-outline-button margin-right-small\" target=\"_blank\"><span class=\"fa fa-google fa-margin-right\"aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.gmail",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":71,"column":194},"end":{"line":71,"column":267}}}))
    + "</a>\n\n        <a data-history-navigate href=\"https://mail.yahoo.com/\" target=\"_blank\" class=\"small-outline-button\"><span class=\"fa fa-yahoo fa-margin-right\"aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeEmailSuccess.section.takeMeToEmail.yahoo",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":73,"column":176},"end":{"line":73,"column":249}}}))
    + "</a>\n      </p>\n    </section>\n  </div>\n</div>\n";
},"useData":true});