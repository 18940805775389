(function () {
	'use strict';

	var MessageCardView = require('./view.conversation.card.js');

	var View = Backbone.View.extend({
		tagName: 'ol',
		className: 'message-snippet-list',

		initialize: function () {
			var thisView = this;
			thisView.subViews = {};
			thisView.model.on('reset', thisView.render, this);
			thisView.model.on('add', function (model) {
				var messageCardView = new MessageCardView({model: model});
				thisView.renderSubviewAppend(thisView.$el, model.cid, messageCardView);
			});
		},
		render: function () {
			var thisView = this;
			thisView.closeSubViews();

			_.each(thisView.model.models, function (model) {
				var messageCardView = new MessageCardView({model: model});
				thisView.renderSubviewAppend(thisView.$el, model.cid, messageCardView);
			}, thisView);
			return thisView;
		}
	});
	module.exports = View;
})();
