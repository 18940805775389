(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.language.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var appConfig = require('~institution-specific-config/app.config.json');
	var i18next = require('../../../../shared-scripts/app-util/i18n');

	var View = Backbone.View.extend({
		el: 'main',

		events: {
			'submit #formLanguage': 'saveSection',
			'click #aCancel': 'cancel'
		},

		cancel: function() {
			localStorage.setItem('feedback-card-cancel-container', 'language-card-feedback');
			util.redirect('/volunteers/profile');
		},

		saveSection: function(event) {
			event.preventDefault();

			var requestData = this.getRequestData();

			var userId = this.model.get('userId');

			$.ajax({
				headers : {
					'Accept' : 'application/json',
					'Content-Type' : 'application/json-patch+json'
				},
				type:'PATCH',
				url: '/backend/secure/volunteer/users/' + userId,
				data:JSON.stringify(requestData),

				success: function() {
					localStorage.setItem('feedback-card-success-container', 'language-card-feedback');
					var preferredLanguage = requestData[0].value;

					i18next.changeLanguage(preferredLanguage).then(function(){
						$.ajaxSetup({
							headers: {
								'Accept-Language': preferredLanguage
							}
						});
						util.redirect('/volunteers/profile');
					});
				}
			});
		},

		getRequestData: function() {
			var requestData = [];
			var language = document.querySelector('input[name="availableLanguages"]:checked').value;

			requestData.push(
				{
					'op': 'replace',
					'path': '/profile/language',
					'value':language
				}
			);
			return requestData;
		},

		render: function(language) {
			var preferredLanguage = language || this.model.get('language');

			var availableLanguages = i18next.getSupportedLanguages();

			this.$el.html(template({
				organizationInfo: appConfig.organizationInfo,
				availableLanguages: availableLanguages,
			}));
			this.$el.find('input[name=availableLanguages][value=' + preferredLanguage + ']').prop('checked', true);
			return this;
		}
	});
	module.exports = View;
})();
