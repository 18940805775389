var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"victor-section "
    + alias3(((helper = (helper = lookupProperty(helpers,"backgroundClass") || (depth0 != null ? lookupProperty(depth0,"backgroundClass") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"backgroundClass","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":50}}}) : helper)))
    + "\">\n    <div class=\"grid-row banner-container\">\n        <section class=\"banner\">\n            <blockquote class=\"quote\">\n                "
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quote") : depth0),{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":32}}})) != null ? stack1 : "")
    + "\n            </blockquote>\n            <div class=\"slogan\">\n                "
    + ((stack1 = (lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:slogan",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":43}}})) != null ? stack1 : "")
    + "\n            </div>\n        </section>\n    </div>\n    <section class=\"action-container\">\n        <div class=\"grid-row\">\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:searchHeading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":48}}}))
    + "</h1>\n            <form id=\"formTopics\" class=\"typeahead-container\">\n                <label for=\"inputTopic\" class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:screenReader.searchByTopic",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":60},"end":{"line":16,"column":105}}}))
    + "</label>\n                <input id=\"textTopics\" class=\"typeahead\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:findStudyPlaceHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":70},"end":{"line":17,"column":113}}}))
    + "\">\n                <button type=\"submit\" class=\"primary-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:searchButton",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":61},"end":{"line":18,"column":92}}}))
    + "</button>\n                <a data-history-navigate href=\"/advanced-search\" class=\"more-search-options\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:searchOptions",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":93},"end":{"line":19,"column":125}}}))
    + "</a>\n            </form>\n        </div>\n    </section>\n</section>\n\n<section class=\"grid-row signup-banner\">\n    <div class=\"grid-sm-1 grid-md-golden-larger\">\n        <div class=\"stats\">\n            "
    + alias3(__default(require("../../../../shared-scripts/app-util/handlebars-helpers/formatNumber.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"victorCount") : depth0),{"name":"formatNumber","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":40}}}))
    + "\n        </div>\n        <div class=\"stats-label\">\n            <span class=\"victors\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"marketingContent.interestedParticipantLabel",{"name":"i18n","hash":{"count":(depth0 != null ? lookupProperty(depth0,"victorCount") : depth0)},"data":data,"loc":{"start":{"line":31,"column":34},"end":{"line":31,"column":106}}}))
    + "</span> "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:victorLine",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":31,"column":114},"end":{"line":31,"column":144}}}))
    + "\n        </div>\n    </div>\n    <div class=\"grid-sm-1 grid-md-golden-smaller\">\n        <div class=\"button-container\">\n            <a data-history-navigate href=\"/signup\" class=\"small-special-button\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.signUp.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":81},"end":{"line":36,"column":111}}}))
    + "</a>\n        </div>\n        <div class=\"why-signup\">\n           "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:whySignUp.text",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":39,"column":11},"end":{"line":39,"column":44}}}))
    + " <a data-history-navigate href=\"/about/volunteers\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"tellMeMore.linkLabel",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":39,"column":95},"end":{"line":39,"column":126}}}))
    + "</a>\n        </div>\n    </div>\n</section>\n\n"
    + ((stack1 = container.invokePartial(require("../../../../institution-specific/itm/canned.queries.hbs"),depth0,{"name":"canned.queries","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<section class=\"grid-row four-steps margin-top\">\n    <div class=\"grid-1\">\n        <h1 class=\"special-header-with-top-border center-container\"><em>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":47,"column":72},"end":{"line":47,"column":110}}}))
    + "</em> <span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.text",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":47,"column":122},"end":{"line":47,"column":206}}}))
    + "</span>\n        </h1>\n    </div>\n    <div class=\"grid-sm-1-2 grid-lg-1-4\">\n        <div class=\"step one\">\n            <div class=\"media\" aria-hidden=\"true\">\n                <span class=\"fa fa-search\"></span>\n            </div>\n            <div class=\"content\">\n                <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.first.caption",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":56,"column":20},"end":{"line":56,"column":64}}}))
    + "</h2>\n            </div>\n        </div>\n    </div>\n    <div class=\"grid-sm-1-2 grid-lg-1-4\">\n        <div class=\"step two\">\n            <div class=\"media\" aria-hidden=\"true\">\n                <span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.second.bubbleText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":63,"column":22},"end":{"line":63,"column":70}}}))
    + "</span>\n            </div>\n            <div class=\"content\">\n                <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.second.caption",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":66,"column":20},"end":{"line":66,"column":65}}}))
    + "</h2>\n            </div>\n        </div>\n    </div>\n    <div class=\"grid-sm-1-2 grid-lg-1-4\">\n        <div class=\"step three\">\n            <div class=\"media\" aria-hidden=\"true\">\n                <span class=\"fa fa-comments-o\"></span>\n            </div>\n            <div class=\"content\">\n                <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.third.caption",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":76,"column":20},"end":{"line":76,"column":64}}}))
    + "</h2>\n            </div>\n        </div>\n    </div>\n    <div class=\"grid-sm-1-2 grid-lg-1-4\">\n        <div class=\"step four\">\n            <div class=\"media\" aria-hidden=\"true\">\n                <span class=\"fa fa-check\"></span>\n            </div>\n            <div class=\"content\">\n                <h2>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:simpleSteps.fourth.caption",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":86,"column":20},"end":{"line":86,"column":65}}}))
    + "</h2>\n            </div>\n        </div>\n    </div>\n</section>\n\n<section class=\"grid-row margin-top\">\n    <div class=\"grid-1\">\n        <h1 class=\"special-header-with-top-border center_container\"><em>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:privacyHeading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":94,"column":72},"end":{"line":94,"column":105}}}))
    + "</em></h1>\n        <p class=\"text-center\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:privacyBody",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1),"privacyNoticeUrl":"/privacy"},"data":data,"loc":{"start":{"line":96,"column":12},"end":{"line":96,"column":119}}}))
    + "\n        </p>\n    </div>\n</section>\n\n<section class=\"grid-row margin-top\">\n    <div class=\"grid-1\">\n        <h1 class=\"special-header-with-top-border center_container\"><em>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:consentHeading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":103,"column":72},"end":{"line":103,"column":105}}}))
    + "</em></h1>\n        <p class=\"text-center\">\n            "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:consentBody",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1)},"data":data,"loc":{"start":{"line":105,"column":12},"end":{"line":105,"column":91}}}))
    + "\n        </p>\n    </div>\n</section>\n\n<section class=\"grid-row\">\n    <div class=\"grid-1 special-background text-center\">\n"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"landing:howItHelps",{"name":"i18n","hash":{"applicationName":((stack1 = (depth0 != null ? lookupProperty(depth0,"organizationInfo") : depth0)) != null ? lookupProperty(stack1,"applicationName") : stack1),"aboutStudyTeamsUrl":"/about/study-teams"},"data":data,"loc":{"start":{"line":112,"column":0},"end":{"line":112,"column":118}}}))
    + "\n    </div>\n</section>\n";
},"usePartial":true,"useData":true});