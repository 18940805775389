var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":65,"column":24},"end":{"line":75,"column":31}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"radioMetalYes\" class=\"block-label\">\n                                <input type=\"radio\" id=\"radioMetalYes\" name=\"hasMetalImplants\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":67,"column":102},"end":{"line":67,"column":108}}}) : helper)))
    + "\">\n                                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.yes",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":68,"column":32},"end":{"line":68,"column":68}}}))
    + "\n                            </label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <label for=\"radioMetalNo\" class=\"block-label\">\n                                <input type=\"radio\" id=\"radioMetalNo\" name=\"hasMetalImplants\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":72,"column":101},"end":{"line":72,"column":107}}}) : helper)))
    + "\">\n                                "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:checkBoxOption.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":73,"column":32},"end":{"line":73,"column":67}}}))
    + "\n                            </label>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row\">\n    <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-9-12 grid-lg-centered\">\n        <div id=\"divFeedback\"></div>\n        <header class=\"page-header\">\n            <nav class=\"text-center\">\n                <a data-history-navigate id=\"aAccountSettings\" href=\"/volunteers/profile\">Profile</a> <span class=\"fa fa-chevron-right\"\n                                                                                     aria-hidden=\"true\"></span> <span\n                    class=\"show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.studyInterests.screenReader.isParentOf",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":110}}}))
    + "</span>\n            </nav>\n            <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:editProfile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":46}}}))
    + "</h1>\n        </header>\n\n        <form class=\"form-stacked margin-top\" id=\"formMedicalConditions\">\n            <fieldset>\n                <legend>\n                    "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":16,"column":20},"end":{"line":16,"column":77}}}))
    + "\n                </legend>\n\n                <div class=\"field-container validation-error-container typeahead-container\">\n                    <label for=\"selectCurrentConditions\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.currentConditions.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":138}}}))
    + "</label>\n                    <div id=\"divCurrentConditionsResults\" role=\"list\"></div>\n\n                    <section id=\"the-basics\" class=\"action-container\">\n                        <section class=\"add-container\">\n                            <div class=\"field-help-text show-for-sr\">Add a current condition</div>\n                            <input id=\"textCurrentConditions\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:typeToAddPlaceHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":105},"end":{"line":26,"column":147}}}))
    + "\">\n                        </section>\n                        <div class=\"badge-container\">\n                            <span class=\"badge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.OR",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":48},"end":{"line":29,"column":79}}}))
    + "</span>\n                        </div>\n                        <section class=\"not-container\">\n                            <label for=\"checkboxNoCurrentConditions\" class=\"other-label\">\n                                <input type=\"checkbox\" id=\"checkboxNoCurrentConditions\" name=\"currentConditions-checkbox\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noConditionId") || (depth0 != null ? lookupProperty(depth0,"noConditionId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noConditionId","hash":{},"data":data,"loc":{"start":{"line":33,"column":129},"end":{"line":33,"column":146}}}) : helper)))
    + "\" aria-controls=\"divCurrentConditionsResults\" data-parsley-validate-if-empty data-parsley-check-typeahead-selection>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.currentConditions.edit.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":33,"column":262},"end":{"line":33,"column":344}}}))
    + "\n                            </label>\n                        </section>\n                    </section>\n                </div>\n\n                <div class=\"field-container validation-error-container typeahead-container\">\n                    <label for=\"selectPastConditions\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.pastConditions.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":40,"column":54},"end":{"line":40,"column":132}}}))
    + "</label>\n                    <div id=\"divPastConditionsResults\" role=\"list\"></div>\n\n                    <section id=\"the-basics\" class=\"action-container\">\n                        <section class=\"add-container\">\n                            <div class=\"field-help-text show-for-sr\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.lovedOnePastMedicalConditions.screenReader",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":45,"column":69},"end":{"line":45,"column":140}}}))
    + "</div>\n                            <input id=\"textPastConditions\" class=\"typeahead\" type=\"text\" placeholder=\""
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:typeToAddPlaceHolderText",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":46,"column":102},"end":{"line":46,"column":144}}}))
    + "\">\n                        </section>\n                        <div class=\"badge-container\">\n                            <span class=\"badge\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"signUp:form.field.OR",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":49,"column":48},"end":{"line":49,"column":79}}}))
    + "</span>\n                        </div>\n                        <section class=\"not-container\">\n                            <label for=\"checkboxNoPastConditions\" class=\"other-label\">\n                                <input type=\"checkbox\" id=\"checkboxNoPastConditions\" name=\"pastConditions-checkbox\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"noConditionId") || (depth0 != null ? lookupProperty(depth0,"noConditionId") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"noConditionId","hash":{},"data":data,"loc":{"start":{"line":53,"column":123},"end":{"line":53,"column":140}}}) : helper)))
    + "\" aria-controls=\"divPastConditionsResults\" data-parsley-validate-if-empty data-parsley-check-typeahead-selection>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.pastConditions.edit.answer.no",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":53,"column":253},"end":{"line":53,"column":332}}}))
    + "\n                            </label>\n                        </section>\n                    </section>\n                </div>\n\n                <div class=\"field-container\">\n                    <label>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.metalObjects.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":60,"column":27},"end":{"line":60,"column":103}}}))
    + "</label>\n                    <div class=\"field-help-text\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.medicalConditions.metalObjects.edit.helpTest",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":61,"column":49},"end":{"line":61,"column":125}}}))
    + "\n                    </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"BOOLEAN") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":76,"column":29}}})) != null ? stack1 : "")
    + "                    <div class=\"field-help-text\"><a data-history-navigate id=\"aClearMyAnswer\" href=\"javascript: void(0);\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"common:clearMyAnswer",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":77,"column":122},"end":{"line":77,"column":153}}}))
    + "</a></div>\n                    <div class=\"field-error-text\">\n                    </div>\n\n                </div>\n            </fieldset>\n            <div class=\"center-button-container\">\n                <button type=\"submit\" class=\"primary-button\"><span class=\"fa fa-floppy-o fa-margin-right\"\n                                                                   aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.save.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":85,"column":93},"end":{"line":85,"column":121}}}))
    + "\n                </button>\n                <a data-history-navigate class=\"outline-button\" href=\"javascript:void(0);\" id=\"aCancel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.cancel.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":87,"column":104},"end":{"line":87,"column":134}}}))
    + "</a>\n            </div>\n\n        </form>\n    </section>\n</div>\n";
},"useData":true});