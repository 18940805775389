(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.study.interests.hbs');
	var util = require('../../../../shared-scripts/app-util/util.js');
	var i18n = require('../../../../shared-scripts/app-util/i18n.js');

	module.exports = Backbone.View.extend({
		el: 'main',
		events: {
			'submit form': 'saveSection',
			'click #aCancel': 'cancel'
		},
		cancel: function () {
			localStorage.setItem('feedback-card-cancel-container', 'study-interests-card-feedback');
			util.redirect('/volunteers/profile');
		},
		bindMultiSelect: function (formElementSelector, lookupValues) {
			var $formElement = this.$el.find(formElementSelector);
			var lookupValueIds = _.pluck(lookupValues, 'id');
			if (lookupValueIds.length) {
				$formElement.val(lookupValueIds).trigger('liszt:updated');
			}
		},
		bindCheckBox: function (formElementSelector, lookupValues) {
			var $formElement = this.$el.find(formElementSelector);
			if (lookupValues.length && Number(lookupValues[0].id) === Number($formElement.val())) {
				$formElement.prop('checked', true).trigger('change');
			}
		},
		initialize: function (options) {
			var thisView = this;
			var studyInterestsCriterion = this.model.get('studyInterestsCriterion') ? this.model.get('studyInterestsCriterion') : {
				id: 0,
				clauses: [{id: 0}]
			};

			if (options) {
				this.seedModel = options.seedModel;
				this.lookupValueModel = options.lookupValueModel;

				this.CRITERION_VARIABLE_NAME_MAP = options.criterionVariableNameMap;
				var emptyExpression = {id: 0, value: {id: 0, lookupValues: []}};
				var studyTopicsExpression = this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.STUDY_TOPICS) ? this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.STUDY_TOPICS) : emptyExpression;
				var studyLocationsExpression = this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.STUDY_LOCATIONS) ? this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.STUDY_LOCATIONS) : emptyExpression;
				var studyCompensationExpression = this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.COMPENSATION_PREFERENCE) ? this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.COMPENSATION_PREFERENCE) : emptyExpression;
				var studySeekingParticipantTypeExpression = this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.PARTICIPANT_TYPE_STUDY_SEEKING) ? this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.PARTICIPANT_TYPE_STUDY_SEEKING) : emptyExpression;

				this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.STUDY_TOPICS}).set('lookupValues', this.lookupValueModel.get('MEDICAL_CONDITION'));
				this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.STUDY_LOCATIONS}).set('lookupValues', this.lookupValueModel.get('STUDY_LOCATION'));
				this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.COMPENSATION_PREFERENCE}).set('lookupValues', this.lookupValueModel.get('BOOLEAN'));
				this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.PARTICIPANT_TYPE_STUDY_SEEKING}).set('lookupValues', this.lookupValueModel.get('PARTICIPANT_TYPE'));

				this.FORM_BINDER = {
					STUDY_TOPICS: {
						CRITERION_VARIABLE: this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.STUDY_TOPICS}).attributes,
						EXPRESSION: studyTopicsExpression,
						bind: _.bind(thisView.bindMultiSelect, thisView, '#selectStudyTopics', studyTopicsExpression.value.lookupValues),
						inputId: 'textStudyTopics',
						resultsContainer: 'divStudyTopicsResults',
						findFormElement: function () {
							return thisView.$el.find('select[name=studyTopics]');
						},
						extractExpression: _.bind(thisView.buildExpressionFromTypeahead, thisView, 'STUDY_TOPICS', _.partial(thisView.extractLookupValueArray, 'MULTISELECT')),
						order: 0
					},
					STUDY_LOCATIONS: {
						CRITERION_VARIABLE: this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.STUDY_LOCATIONS}).attributes,
						EXPRESSION: studyLocationsExpression,
						bind: _.bind(thisView.bindMultiSelect, thisView, '#selectStudyLocations', studyLocationsExpression.value.lookupValues),
						inputId: 'textStudyLocations',
						resultsContainer: 'divStudyLocationsResults',
						findFormElement: function () {
							return thisView.$el.find('select[name=studyLocations]');
						},
						extractExpression: _.bind(thisView.buildExpressionFromTypeahead, thisView, 'STUDY_LOCATIONS', _.partial(thisView.extractLookupValueArray, 'MULTISELECT')),
						order: 1
					},
					COMPENSATION_PREFERENCE: {
						CRITERION_VARIABLE: this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.COMPENSATION_PREFERENCE}).attributes,
						EXPRESSION: studyCompensationExpression,
						bind: _.bind(thisView.bindCheckBox, thisView, '#checkboxCompensationOnly', studyCompensationExpression.value.lookupValues),
						findFormElement: function () {
							return thisView.$el.find('#checkboxCompensationOnly');
						},
						extractExpression: _.bind(thisView.buildExpressionFromElement, thisView, 'COMPENSATION_PREFERENCE', _.partial(thisView.extractLookupValueArray, 'CHECKBOX')),
						order: 2
					},
					PARTICIPANT_TYPE_STUDY_SEEKING: {
						CRITERION_VARIABLE: this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.PARTICIPANT_TYPE_STUDY_SEEKING}).attributes,
						EXPRESSION: studySeekingParticipantTypeExpression,
						bind: _.bind(thisView.bindCheckBox, thisView, '#checkboxSeekingHealthyVolunteers', studySeekingParticipantTypeExpression.value.lookupValues),
						findFormElement: function () {
							return thisView.$el.find('#checkboxSeekingHealthyVolunteers');
						},
						extractExpression: _.bind(thisView.buildExpressionFromElement, thisView, 'PARTICIPANT_TYPE_STUDY_SEEKING', _.partial(thisView.extractLookupValueArray, 'CHECKBOX')),
						order: 3
					},
					COMPENSATION_ONLY_LOOKUP_VALUE: this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.COMPENSATION_PREFERENCE}).getLokupValue('TRUE'),
					SEEKING_HEALTHY_PARTICIPANT_LOOKUP_VALUE: this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.PARTICIPANT_TYPE_STUDY_SEEKING}).getLokupValue('HEALTHY'),
					FIND_STUDIES_CRITERION_ID: studyInterestsCriterion.id,
					FIND_STUDIES_CRITERION_CLAUSE_ID: studyInterestsCriterion.clauses[0].id
				};
			}
			this.subViews = {};
		},
		submitRequest: function (userId, requestMethod, requestData) {
			$.ajax({
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				type: requestMethod,
				url: '/backend/secure/volunteer/study-interests?user-id=' + userId,
				data: JSON.stringify(requestData),
				success: function () {
					localStorage.setItem('feedback-card-success-container', 'study-interests-card-feedback');
					util.redirect('/volunteers/profile');
				}
			});
		},
		saveSection: function (event) {
			event.preventDefault();

			var requestData = this.getRequestData();

			var userId = this.model.get('userId');
			var requestMethod = requestData ? 'PUT' : 'DELETE';

			this.submitRequest(userId, requestMethod, requestData);
		},
		extractLookupValueArray: function (formElementType, $formElement) {
			var elementValue;
			switch (formElementType) {
			case 'CHECKBOX':
				elementValue = $formElement.is(':checked') ? [$formElement.val()] : null;
				break;
			case 'MULTISELECT':
				elementValue = $formElement.val();
				break;
			default:
				elementValue = null;
				break;
			}
			if (elementValue && elementValue.length) {
				elementValue = elementValue.map(function (el) {
					return {id: Number(el)};
				});
			}
			return elementValue;
		},
		buildExpressionFromElement: function (formFieldName, valueExtractor) {
			var $formElement = this.FORM_BINDER[formFieldName].findFormElement();
			var variableId = Number($formElement.data('variable-id'));
			var order = Number($formElement.data('order'));
			var operator = $formElement.data('operator');

			var expressionValueLookupValues = valueExtractor($formElement);
			return (expressionValueLookupValues && expressionValueLookupValues.length > 0) ? {
				id: 0,
				order: order,
				operator: operator,
				variable: {id: variableId},
				value: {id: 0, lookupValues: expressionValueLookupValues}
			} : null;
		},

		buildExpressionFromTypeahead: function (formFieldName, valueExtractor) {
			var $formElement = this.FORM_BINDER[formFieldName].findFormElement();
			var variableId = Number(this.FORM_BINDER[formFieldName].CRITERION_VARIABLE.id);//Number($formElement.data('variable-id'));
			var order = Number(this.FORM_BINDER[formFieldName].order);//Number($formElement.data('order'));
			var operator = 'ANY_OF';//$formElement.data('operator');

			var expressionValueLookupValues = valueExtractor($formElement);
			return (expressionValueLookupValues && expressionValueLookupValues.length > 0) ? {
				id: 0,
				order: order,
				operator: operator,
				variable: {id: variableId},
				value: {id: 0, lookupValues: expressionValueLookupValues}
			} : null;
		},

		getRequestData: function () {
			var studyInterestsCriterionId = Number(this.$el.find('#hiddenCriterionId').val());
			var criterion = {id: studyInterestsCriterionId, clauses: [{id: 0, expressionConnector: 'AND'}]};
			var expressions = [];
			for (var formField in this.CRITERION_VARIABLE_NAME_MAP) {
				var expression = this.FORM_BINDER[formField].extractExpression();
				if (expression) {
					expressions.push(expression);
				}
			}
			if (expressions.length) {
				criterion.clauses[0].expressions = expressions;
				return criterion;
			} else {
				return null;
			}
		},

		populateStudyTopics: function () {
			var initialStudyTopicsExpression = this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.STUDY_TOPICS);
			var sourceStudyTopicsLookupValues = this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.STUDY_TOPICS}).attributes.lookupValues;
			var initialStudyTopicsLookupValues = [];
			var inputId = this.FORM_BINDER.STUDY_TOPICS.inputId;
			var name = 'studyTopics';
			var resultsContainerId = this.FORM_BINDER.STUDY_TOPICS.resultsContainer;
			if (initialStudyTopicsExpression) {
				initialStudyTopicsLookupValues = initialStudyTopicsExpression.value.lookupValues;
			}
			this.setupTypeahead(inputId, name, resultsContainerId, initialStudyTopicsLookupValues, sourceStudyTopicsLookupValues, i18n.t('multiSelectRemoveLabel'));
		},

		populateStudyLocations: function () {
			var initialStudyLocationsExpression = this.model.getStudyInterestExpression(this.CRITERION_VARIABLE_NAME_MAP.STUDY_LOCATIONS);
			var sourceStudyLocationsLookupValues = this.seedModel.findWhere({name: this.CRITERION_VARIABLE_NAME_MAP.STUDY_LOCATIONS}).attributes.lookupValues;
			var initialStudyLocationsLookupValues = [];
			var inputId = this.FORM_BINDER.STUDY_LOCATIONS.inputId;
			var name = 'studyLocations';
			var resultsContainerId = this.FORM_BINDER.STUDY_LOCATIONS.resultsContainer;
			if (initialStudyLocationsExpression) {
				initialStudyLocationsLookupValues = initialStudyLocationsExpression.value.lookupValues;
			}
			this.setupTypeahead(inputId, name, resultsContainerId, initialStudyLocationsLookupValues, sourceStudyLocationsLookupValues, i18n.t('multiSelectRemoveLabel'));
		},

		populateForm: function () {
			for (var formField in this.CRITERION_VARIABLE_NAME_MAP) {
				this.FORM_BINDER[formField].bind();
			}
			this.populateStudyTopics();
			this.populateStudyLocations();
		},

		transformDataForTypeahead: function (dataArray) {
			var result = [];
			$.each(dataArray, function (index, data) {
				if (data.visible) {
					result.push({value: data.id, text: data.displayText});
				}
			});
			return result;
		},

		setupTypeahead: function (inputId, name, resultsContainerId, initialLookupValues, sourceLookupValues, removeText) {
			this.$el.find('#' + inputId).typeIO({
				hint: true,
				highlight: true,
				resultsContainer: '#' + resultsContainerId,
				initialResults: this.transformDataForTypeahead(initialLookupValues),
				name: name,
				minLength: 1,
				removeText: removeText
			},
			{
				limit: 10,
				display: 'text',
				source: this.transformDataForTypeahead(sourceLookupValues),
				templates: {
					suggestion: function (data) {
						return '<div>' + data.text + '</div>';
					},
					empty: [
						'<div class="empty-message">',
						i18n.t('noResultsFound'),
						'</div>'
					].join('\n')
				}
			});
		},


		render: function () {
			var thisView = this;
			this.$el.html(template(thisView.FORM_BINDER));
			this.populateForm();
			return this;
		}
	}
	);
})();
