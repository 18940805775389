require('backbone');
var ajaxSetup = function (appName) {
	var $ = require('jquery');
	var util = require('./util.js');
	var i18n = require('./i18n.js');
	var authHelper = require('./auth.helper.js');
	$.ajaxSetup({
		headers :{
			'Accept-Language': i18n.language
		},
		xhrFields: {
			withCredentials: true
		},
		cache: false
	});

	$(document).ajaxError(
		function (event, jqxhr, settings, thrownError) {
			if (jqxhr.responseJSON) {
				var error = jqxhr.responseJSON['authError'];
			}
			switch (jqxhr.status) {
			case 401:
				if (error === 'not-authenticated' || error === 'invalid-csrf') {
					authHelper.notAuthenticatedHandler(appName);
				}
				break;
			case 403:
				if (error === 'not-authorized') {
					authHelper.notAuthorizedHandler(jqxhr.responseJSON);
				} else if (error === 'not-agreed-to-user-agreement') {
					authHelper.notAgreedToUserAgreementHandler(jqxhr.responseJSON);
				}
				break;
			case 404:
				util.redirect('/404-page-not-found');
				break;
			case 500:
				if (jqxhr.exceptionThrower === 'login') {
					return;
				}
				var responseBody = jqxhr.responseJSON;
				var errorId = responseBody.errorId;
				util.redirect('/app-error?error-id=' + errorId);
				break;
			case 503:
				util.redirect('/503.html');
				break;
			default:
				break;
			}
		}
	);

	var isCsrfMethod = function (method) {
		return /^(POST|PUT|DELETE|PATCH)$/i.test(method);
	};

	var getCsrfTokenParam = function (name) {
		return function () {
			return document.querySelector('meta[name="' + name + '"]').getAttribute('content');
		};
	};

	var getCsrfToken = function () {
		return getCsrfTokenParam('csrf-token')();
	};

	var getCsrfTokenHeaderName = function () {
		return getCsrfTokenParam('csrf-token-headername')();
	};

	$(document)
		.ajaxStart(
			function () {
				$('#divWait').removeClass('hide');
			})
		.ajaxStop(
			function () {
				$('#divWait').addClass('hide');
			})
		.ajaxSend(
			function (event, jqxhr, settings) {
				var method = settings.type;
				if (isCsrfMethod(method)) {
					jqxhr.setRequestHeader(getCsrfTokenHeaderName(), getCsrfToken());
				}
			}
		);
};
module.exports = ajaxSetup;
