var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=grid-row>\n  <section class=\"grid-sm-1 grid-md-7-12 grid-md-centered grid-lg-5-12 grid-lg-centered\">\n    <header class=\"page-header\">\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":44}}}))
    + "</h1>\n    </header>\n      <div id=\"divFeedback\"></div>\n      <ul class=\"nav-list\">\n  			<li>\n      		<a data-history-navigate id=\"aChangeEmail\" href=\"/volunteers/change-email\">\n            <article class=\"nav-card\">\n    					<div class=\"content\">\n    						<section class=\"media\">\n    							<span class=\"fa fa-envelope\" aria-hiddden=\"true\"></span>\n    						</section>\n\n    						<section class=\"info\">\n    							<section class=\"primary-info\">\n    								<h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changeLoginEmail.form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":70}}}))
    + "</h1>\n    							</section>\n    						</section>\n    					</div>\n    				</article>\n          </a>\n    		</li>\n  			<li>\n      		<a data-history-navigate id=\"aChangePassword\" href=\"/volunteers/change-password\">\n            <article class=\"nav-card\">\n              <div class=\"content\">\n                <section class=\"media\">\n                  <span class=\"fa fa-key\" aria-hiddden=\"true\"></span>\n                </section>\n\n                <section class=\"info\">\n                  <section class=\"primary-info\">\n                    <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:changePassword.form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":35,"column":76}}}))
    + "</h1>\n                  </section>\n                </section>\n              </div>\n            </article>\n          </a>\n    		</li>\n  			<li>\n      		    <a data-history-navigate id=\"aDeactivateAccount\" href=\"/volunteers/deactivate-account\">\n                    <article class=\"nav-card\">\n    					<div class=\"content\">\n    						<section class=\"media\">\n    							<span class=\"fa fa-power-off\" aria-hiddden=\"true\"></span>\n    						</section>\n\n    						<section class=\"info\">\n    							<section class=\"primary-info\">\n    								<h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"accountSettings:deactivateAccount.form.title",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":52,"column":71}}}))
    + "</h1>\n    							</section>\n    						</section>\n    					</div>\n    				</article>\n                </a>\n    		</li>\n		</ul>\n	</section>\n</div>\n";
},"useData":true});