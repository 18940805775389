var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"grid-row fadeInRight\">\n  <section class=\"grid-sm-1 grid-md-11-12 grid-md-centered grid-lg-8-12 grid-lg-centered\">\n      <div id=\"divFeedback\"></div>\n      <header class=\"page-header\">\n        <nav class=\"text-center\">\n          <a data-history-navigate id=\"aAccountSettings\" href=\"/volunteers/profile\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":6,"column":84},"end":{"line":6,"column":110}}}))
    + "</a> <span class=\"fa fa-chevron-right\" aria-hidden=\"true\"></span> <span class=\"show-for-sr\">is parent of</span>\n        </nav>\n        <h1>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:editProfile",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":42}}}))
    + "</h1>\n    </header>\n\n      <form class=\"form-stacked margin-top\" id=\"formEmailNotifications\">\n        <fieldset class=\"special-fieldset\">\n          <legend>\n              "
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.heading",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":14,"column":14},"end":{"line":14,"column":72}}}))
    + "\n          </legend>\n          <div class=\"field-container\">\n              <label for=\"selectMessageNotification\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.messageNotificationFrequency.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":146}}}))
    + "</label>\n              <select id=\"selectMessageNotification\" class=\"input-1\">\n                <option value=\"IMMEDIATELY\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.messageNotificationFrequency.edit.options.immediate",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":19,"column":44},"end":{"line":19,"column":146}}}))
    + "</option>\n                <option value=\"NEVER\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.messageNotificationFrequency.edit.options.never",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":20,"column":38},"end":{"line":20,"column":136}}}))
    + "</option>\n              </select>\n          </div>\n          <div class=\"field-container\">\n              <label for=\"selectRecommendedStudiesNotification\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.edit.question",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":24,"column":64},"end":{"line":24,"column":164}}}))
    + "</label>\n              <select id=\"selectRecommendedStudiesNotification\" class=\"input-1\">\n                  <option value=\"DAILY\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.edit.options.daily",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":26,"column":40},"end":{"line":26,"column":145}}}))
    + "</option>\n                  <option value=\"WEEKLY\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.edit.options.weekly",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":27,"column":41},"end":{"line":27,"column":147}}}))
    + "</option>\n                  <option value=\"BI_WEEKLY\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.edit.options.biweekly",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":28,"column":44},"end":{"line":28,"column":152}}}))
    + "</option>\n                  <option value=\"MONTHLY\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.edit.options.monthly",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":29,"column":42},"end":{"line":29,"column":149}}}))
    + "</option>\n                  <option value=\"NEVER\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"profile:form.section.emailNotifications.recommendationNotificationFrequency.edit.options.never",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":30,"column":40},"end":{"line":30,"column":145}}}))
    + "</option>\n              </select>\n          </div>\n        </fieldset>\n        <div class=\"center-button-container\">\n          <button type=\"submit\" class=\"primary-button\"><span class=\"fa fa-floppy-o fa-margin-right\" aria-hidden=\"true\"></span>"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.save.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":35,"column":126},"end":{"line":35,"column":154}}}))
    + "</button>\n          <a data-history-navigate class=\"outline-button\" href=\"javascript:void(0);\" id=\"aCancel\">"
    + alias3((lookupProperty(helpers,"i18n")||(depth0 && lookupProperty(depth0,"i18n"))||alias2).call(alias1,"button.cancel.label",{"name":"i18n","hash":{},"data":data,"loc":{"start":{"line":36,"column":98},"end":{"line":36,"column":128}}}))
    + "</a>\n				</div>\n\n    </form>\n  </section>\n</div>\n";
},"useData":true});