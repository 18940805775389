(function() {
	'use strict';
	var template = require('../../templates/hbs/profile.health.info.hbs');

	var util = require('../../../../shared-scripts/app-util/util.js');

	var volunteerProfileModule = require('../modules/module.volunteer.profile.js');

	var parsleyConfig = require('../../../../shared-scripts/modules/module.parsley.config.js');

	module.exports = Backbone.View.extend({
		el: 'main',
		initialize: function (options) {
			if (options) {
				this.seedModel = options.seedModel;
			}
			this.subViews = {};
		},

		events: {
			'keyup #textFeet': 'validateHeight',
			'submit #formHealthInfo': 'saveSection',
			'click #aCancel': 'cancel'
		},

		cancel: function () {
			localStorage.setItem('feedback-card-cancel-container', 'health-info-card-feedback');
			util.redirect('/volunteers/profile');
		},

		saveSection: function (event) {
			event.preventDefault();

			var requestData = volunteerProfileModule.getPropertyValues('#formHealthInfo');

			this.processHeight(requestData);

			var userId = this.model.get('userId');

			volunteerProfileModule.sendPropertyValuesRequest(userId, requestData, 'health-info-card-feedback');
		},

		validateHeight: function () {
			$('#textInches').parsley(parsleyConfig).validate();
		},

		processHeight: function (requestData) {
			var feet = $('#textFeet').val();
			var inches = $('#textInches').val();
			var height = (Number(feet) * 12 + Number(inches));

			if (height) {
				requestData.push({savedValue: height.toString(), property: {name: 'height'}});
			} else {
				requestData.push({savedValue: '', property: {name: 'height'}});
			}
		},

		populateForm: function () {
			var basicHealthInfo = this.model.get('basicHealthInfo');

			for (var i = 0; i < basicHealthInfo.length; i++) {
				switch (basicHealthInfo[i].property.name) {
				case 'weight':
					var weight = basicHealthInfo[i].savedValue;
					if (!isNaN(weight) && Number(weight) !== 0) {
						weight = Math.round(weight);
						this.$el.find('#textWeight').val(weight);
					} else {
						this.$el.find('#textWeight').val('');
					}
					break;
				case 'height':
					var height = basicHealthInfo[i].savedValue;
					if (!isNaN(height) && Number(height) !== 0) {
						height = Math.round(height);
						this.$el.find('#textFeet').val(Math.floor(height / 12));
						this.$el.find('#textInches').val(height % 12);
					}
					break;
				case 'smokingStatus':
					this.$el.find('#selectSmoking').val(basicHealthInfo[i].lookupValues[0].id);
					break;
				}
			}
		},

		setupValidationSummaryHandler: function () {
			parsleyConfig.excluded = '[disabled]';
			this.$el.find('form').parsley(parsleyConfig).on('form:error', function () {
				$('.field-error-text:not(:empty):first').parent().get(0).scrollIntoView();
			});
		},

		render: function () {
			this.$el.html(template(this.seedModel.attributes));
			this.populateForm();
			this.$el.find('form').parsley(parsleyConfig);
			this.setupValidationSummaryHandler();
			return this;
		}
	});
})();
